import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
  padding: 0 20px; // Add padding for smaller screens
`;

const LoginBox = styled.div`
  display: flex;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
  max-width: 600px; // Maintain max-width for larger screens
  flex-direction: column; // Stack vertically on small screens

  @media (min-width: 768px) { // Tablet and larger screens
    flex-direction: row;
  }
`;

const LoginLeft = styled.div`
  flex: 1;
  background-color: #008cba;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  @media (max-width: 767px) { // For mobile screens
    padding: 20px;
    h2 {
      font-size: 20px;
    }
  }
`;

const LoginRight = styled.div`
  flex: 1;
  padding: 40px;
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  p {
    margin-bottom: 20px;
    color: #666;
  }

  @media (max-width: 767px) { // For mobile screens
    padding: 20px;
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
  }
`;

const InputGroup = styled.div`
  margin-bottom: 15px;
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
  }
`;

const LoginButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #008cba;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:disabled {
    background-color: #999;
    cursor: not-allowed;
  }
  &:hover:not(:disabled) {
    background-color: #007bb5;
  }

  @media (max-width: 767px) { // For mobile screens
    font-size: 14px;
    padding: 8px;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 15px;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingSpinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid #008cba;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const Login = () => {
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            Id: id,
            Password: password,
        };

        try {
            const response = await fetch(
                "https://ims-nv9i.onrender.com/login/login",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }
            );

            const result = await response.json();
            console.log(result);
            if (result) {
                localStorage.setItem("Data", JSON.stringify(result));
                localStorage.setItem("loginId", result.savedAttendance._id);
            }
            console.log("Login response:", result.Role);

            if (response.ok) {
                localStorage.setItem("Id", id);
                switch (result.Role) {
                    case 'Student':
                        navigate('/student');
                        break;
                    case 'Admin':
                        navigate('/admin');
                        break;
                    case 'Master':
                        navigate('/master');
                        break;
                    case 'Franchise':
                        navigate('/franchise');
                        break;
                    case 'Employee':
                        navigate('/employee');
                        break;
                    default:
                        setMessage('Invalid role');
                }
            } else {
                setMessage(result.message || 'Invalid ID or Password');
            }
        } catch (error) {
            console.error("Error logging in:", error);
            setError("Error logging in");
        } finally {
            setLoading(false);
        }
    };

    return (
        <LoginContainer>
            <LoginBox>
                <LoginLeft>
                    <h2>Institute Management System</h2>
                </LoginLeft>
                <LoginRight>
                    <h2>Login</h2>
                    <p>Please enter your credentials to log in</p>
                    <InputGroup>
                        <input
                            type="text"
                            placeholder="ID"
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                            required
                        />
                    </InputGroup>

                    <InputGroup>
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </InputGroup>
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                    <LoginButton onClick={handleLogin} disabled={loading}>
                        Login
                    </LoginButton>
                </LoginRight>
            </LoginBox>
            {loading && (
                <LoadingOverlay>
                    <LoadingSpinner />
                </LoadingOverlay>
            )}
        </LoginContainer>
    );
};

export default Login;
