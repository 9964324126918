import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import axios from "axios";


const TableContainer = styled.div`
  margin:20px;
  overflow-x: auto;
  max-height: calc(100vh - 70px - 40px);
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 15px;

  th,
  td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    white-space: nowrap;
  }

  th {
    background-color: #008cba;
    color: #fff;
  }

  td {
    background-color: #f9f9f9;
  }
`;

const ViewButton = styled.button`
  background-color: #008cba;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #005f75;
  }
`;

const Modal = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  font-size: 16px;
  width: 90%;
  max-width: 800px;
  height: 90vh;
  overflow-y: auto;

  h2 {
    margin-top: 0;
    font-size: 22px;
  }

  p {
    margin: 8px 0;
    font-size: 14px;
  }

  button {
    background-color: #008cba;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #005f75;
    }
  }
`;

const ModalOverlay = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ViewEmployee = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const openModal = (employee) => {
    setSelectedEmployee(employee);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEmployee(null);
  };

  const handleViewDocument = (filename) => {
    const url = `https://ims-nv9i.onrender.com/uploads/${filename}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    var Id;
        const localdata = JSON.parse(localStorage.getItem("Data"));
        if (localdata) {
            if (localdata.EmployeeData) {
                Id=(localdata.EmployeeData.FranchiseId);
            } else if (localdata.FranchiseData) {
                Id=(localdata.FranchiseData.FranchiseId);
            } else {
                Id=("Admin");
            }
        } else {
            Id=("Admin");
        }
    axios
      .get("https://ims-nv9i.onrender.com/employees/all")
      .then((response) => {
        const filterData = response.data.filter(data=>data.FranchiseId===Id);
        // console.log(filterData);
        setEmployeeData(filterData);
      })
      .catch((error) => {
        console.error("There was an error fetching the employee data!", error);
      });
  }, []);

  return (
    <>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>Employee ID</th>
              <th>Franchise Name</th>
              <th>Franchise ID</th>
              <th>Name</th>
              <th>Mobile No</th>
              <th>Email</th>
              <th>Salary</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {employeeData.map((employee) => (
              <tr key={employee._id}>
                <td>{employee.EmployeeID}</td>
                <td>{employee.FranchiseName}</td>
                <td>{employee.FranchiseId}</td>
                <td>{employee.Name}</td>
                <td>{employee.MobileNo}</td>
                <td>{employee.Email}</td>
                <td>{employee.Salary}</td>
                <td>{employee.Date}</td>
                <td>
                  <ViewButton onClick={() => openModal(employee)}>
                    View Details
                  </ViewButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <ModalOverlay isOpen={isModalOpen} onClick={closeModal} />
      <Modal isOpen={isModalOpen}>
        {selectedEmployee && (
          <>
            <h2>Employee Details</h2>
            <p>
              <strong>ID:</strong> {selectedEmployee.EmployeeID || "N/A"}
            </p>
            <p>
              <strong>Franchise Name:</strong>{" "}
              {selectedEmployee.FranchiseName || "N/A"}
            </p>
            <p>
              <strong>Franchise ID:</strong>{" "}
              {selectedEmployee.FranchiseId || "N/A"}
            </p>
            <p>
              <strong>Name:</strong> {selectedEmployee.Name || "N/A"}
            </p>
            <p>
              <strong>Mobile No:</strong> {selectedEmployee.MobileNo || "N/A"}
            </p>
            <p>
              <strong>Email:</strong> {selectedEmployee.Email || "N/A"}
            </p>
            <p>
              <strong>Salary:</strong> {selectedEmployee.Salary || "N/A"}
            </p>
            <p>
              <strong>Date:</strong> {selectedEmployee.Date || "N/A"}
            </p>
            {selectedEmployee.Documents && (
              <div>
                <h3>Documents</h3>
                <p>
                  <strong>Photo:</strong>{" "}
                  {selectedEmployee.Documents.Photo ? (
                    <button
                      onClick={() =>
                        handleViewDocument(selectedEmployee.Documents.Photo)
                      }
                      className="view-document-button">
                      View Document
                    </button>
                  ) : (
                    "N/A"
                  )}
                </p>
                <p>
                  <strong>Aadhar Card:</strong>{" "}
                  {selectedEmployee.Documents.AadharCard ? (
                    <button
                      onClick={() =>
                        handleViewDocument(
                          selectedEmployee.Documents.AadharCard
                        )
                      }
                      className="view-document-button">
                      View Document
                    </button>
                  ) : (
                    "N/A"
                  )}
                </p>
                <p>
                  <strong>Qualification File:</strong>{" "}
                  {selectedEmployee.Documents.QualificationFile ? (
                    <button
                      onClick={() =>
                        handleViewDocument(
                          selectedEmployee.Documents.QualificationFile
                        )
                      }
                      className="view-document-button">
                      View Document
                    </button>
                  ) : (
                    "N/A"
                  )}
                </p>
              </div>
            )}
            <button onClick={closeModal}>Close</button>
          </>
        )}
      </Modal>
    </>
  );
};

export default ViewEmployee;
