// App.js
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  background-color: #f4f4f4;
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #ddd;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableData = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const TestData = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch('https://ims-nv9i.onrender.com/result/all')
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <Container>
            <h1>Student Exam Results</h1>
            <Table>
                <thead>
                    <tr>
                        <TableHeader>Student ID</TableHeader>
                        <TableHeader>Course ID</TableHeader>
                        <TableHeader>Score</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {data.map(result => (
                        <TableRow key={result._id}>
                            <TableData>{result.StudentId}</TableData>
                            <TableData>{result.CourseId}</TableData>
                            <TableData>{result.Score}</TableData>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default TestData;
