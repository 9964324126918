import React, { useState, useEffect } from 'react';
import { Hourglass } from 'react-loader-spinner';
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import Sidebar from "./StudentSidebar"; // Update with the correct path to your Sidebar component

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  background-color: #f0f2f5;
`;

const Header = styled.header`
  height: 70px;
  background-color: #008cba;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const MainContent = styled.main`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  @media (max-width: 768px) {
    margin-left: 0;
    padding: 20px 10px;
  }
`;

const HamburgerButton = styled(FaBars)`
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: ${(props) => (props.isOpen ? "none" : "block")};
  @media (min-width: 769px) {
    display: none;
  }
`;

const CoursesContainer = styled.div`
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
`;

const Table = styled.table`
  width: 100%;
  font-size: 15px;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  th, td {
    border: none;
    padding: 12px;
    text-align: left;
  }

  th {
    background-color: #f4f4f4;
    font-weight: 600;
    color: #333;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f0f0f0;
  }
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }
`;

const CourseDetails = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`;

const CourseFetch = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  p {
    margin: 5px 0;
    font-size: 16px;
  }
`;

const MaterialContainer = styled.div`
  margin-top: 20px;
`;

const MaterialList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const MaterialItem = styled.li`
  margin-bottom: 15px;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;

  p {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const VideoContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const PdfContainer = styled.div`
  margin-top: 20px;
`;

const StudentCourse = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [courses, setCourses] = useState([]);
    const [selCourses, setSelCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [studyMaterial, setStudyMaterial] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedPdf, setSelectedPdf] = useState(null);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    let StudentId;

    useEffect(() => {
        const fetchCourses = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/courses/all');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setCourses(data);
            } catch (error) {
                setError('Error fetching courses');
                console.error('Error fetching courses:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchCourses();
    }, []);

    useEffect(() => {
        const Data = JSON.parse(localStorage.getItem("Data"));
        StudentId = Data.StudentData.StudentId;
        const fetchStudent = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await fetch(`https://ims-nv9i.onrender.com/students/get/${StudentId}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const sCourse = [data.Course.CourseId];
                console.log(sCourse);

                const filteredCourses = courses.filter(course => sCourse.includes(course.CourseId));
                setSelCourses(filteredCourses);
            } catch (error) {
                setError('Error fetching student data');
                console.error('Error fetching student data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchStudent();
    }, [courses]);

    const handleView = async (courseId) => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`https://ims-nv9i.onrender.com/courses/get/${courseId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const courseData = await response.json();
            setSelectedCourse(courseData);
            setStudyMaterial(courseData);
        } catch (error) {
            setError('Error fetching course details or study material');
            console.error('Error fetching course details or study material:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleVideoClick = (videoUrl) => {
        const embedUrl = videoUrl.replace("watch?v=", "embed/");
        setSelectedVideo(embedUrl);
        setSelectedPdf(null);
    };

    const handlePdfClick = (pdfUrl) => {
        setSelectedPdf(pdfUrl);
        setSelectedVideo(null);
    };

    if (loading) {
        return <div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}> <Hourglass
            visible={true}
            height="80"
            width="80"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={['#306cce', '#72a1ed']}
        /></div>;
    }

    return (
        <DashboardContainer>
            <Header>
                Student Dashboard
                <HamburgerButton isOpen={isSidebarOpen} onClick={toggleSidebar} />
            </Header>
            <Container>
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <MainContent>
                    <CoursesContainer>
                        <Title>Enrolled Courses</Title>
                        {loading ? (
                            <p>Loading courses...</p>
                        ) : error ? (
                            <p>{error}</p>
                        ) : (
                            <>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Course Id</th>
                                            <th>Course Code</th>
                                            <th>Course Name</th>
                                            <th>Duration</th>
                                            <th>View</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selCourses.length > 0 ? (
                                            selCourses.map(course => (
                                                <tr key={course.CourseId}>
                                                    <td>{course.CourseId}</td>
                                                    <td>{course.CourseCode}</td>
                                                    <td>{course.CourseName}</td>
                                                    <td>{course.CourseDuration}</td>
                                                    <td>
                                                        <Button onClick={() => handleView(course.CourseId)}>View</Button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="5">No courses enrolled</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>

                                {selectedCourse && (
                                    <CourseDetails>
                                        <h3>Course Details</h3>
                                        <CourseFetch>
                                            <p><strong>Course Id:</strong> {selectedCourse.CourseId}</p>
                                            <p><strong>Course Code:</strong> {selectedCourse.CourseCode}</p>
                                        </CourseFetch>
                                        <CourseFetch>
                                            <p><strong>Course Name:</strong> {selectedCourse.CourseName}</p>
                                            <p><strong>Duration:</strong> {selectedCourse.CourseDuration}</p>
                                        </CourseFetch>

                                        <MaterialContainer>
                                            <h3>Study Materials</h3>
                                            {studyMaterial.Video && studyMaterial.Video.length > 0 ? (
                                                <div>
                                                    <h4>Videos</h4>
                                                    <MaterialList>
                                                        {studyMaterial.Video.map((video, index) => (
                                                            <MaterialItem key={index}>
                                                                <p>
                                                                    <strong>{video.Title}</strong>
                                                                    <Button onClick={() => handleVideoClick(video.VideoUrl)}>Watch Video</Button>
                                                                </p>
                                                            </MaterialItem>
                                                        ))}
                                                    </MaterialList>
                                                </div>
                                            ) : (
                                                <p>No videos available</p>
                                            )}
                                            {studyMaterial.Pdf && studyMaterial.Pdf.length > 0 ? (
                                                <div>
                                                    <h4>PDFs</h4>
                                                    <MaterialList>
                                                        {studyMaterial.Pdf.map((pdf, index) => (
                                                            <MaterialItem key={index}>
                                                                <p>
                                                                    <strong>{pdf.Title}</strong>
                                                                    <Button onClick={() => handlePdfClick(pdf.PdfUrl)}>View PDF</Button>
                                                                </p>
                                                            </MaterialItem>
                                                        ))}
                                                    </MaterialList>
                                                </div>
                                            ) : (
                                                <p>No PDFs available</p>
                                            )}
                                        </MaterialContainer>

                                        {selectedVideo && (
                                            <VideoContainer>
                                                <iframe
                                                    width="560"
                                                    height="315"
                                                    src={selectedVideo}
                                                    title="Video Player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>
                                            </VideoContainer>
                                        )}

                                        {selectedPdf && (
                                            <PdfContainer>
                                                <iframe
                                                    src={`https://ims-nv9i.onrender.com/pdf/${selectedPdf}`}
                                                    title="PDF Viewer"
                                                    width="100%"
                                                    height="600"
                                                    frameBorder="0"
                                                ></iframe>
                                            </PdfContainer>
                                        )}
                                    </CourseDetails>
                                )}
                            </>
                        )}
                    </CoursesContainer>
                </MainContent>
            </Container>
        </DashboardContainer>
    );
};

export default StudentCourse;