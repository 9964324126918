import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import Sidebar from "./StudentSidebar"; // Update with the correct path to your Sidebar component

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f2f5;
  font-family: 'Arial', sans-serif;
`;

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  background-color: #f0f2f5;
`;

const Header = styled.header`
   height: 70px;
  background-color: #008cba;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const MainContent = styled.main`
  display: flex;
  flex: 1;
  padding: 20px;
  @media (max-width: 768px) {
    margin-left: 0;
    padding: 20px 10px;
  }
`;

const HamburgerButton = styled(FaBars)`
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: ${(props) => (props.isOpen ? "none" : "block")};
  color: #ecf0f1;
  transition: color 0.3s ease;

  &:hover {
    color: #3498db;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const Container1 = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  padding: 20px;
  width: 100%;
`;

const Heading = styled.h1`
  text-align: center;
  color: #2c3e50;
  margin-bottom: 20px;
  font-size: 28px;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
`;

const Table = styled.table`
  width: 100%;
  font-size: 15px;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
`;

const TableHeadCell = styled.th`
  background-color: #008cba;
  color: #ffffff;
  padding: 12px;
  text-align: left;
  font-weight: bold;
`;

const TableCell = styled.td`
  border-bottom: 1px solid #e0e0e0;
  padding: 12px;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Button = styled.button`
  padding: 8px 16px;
  margin: 4px;
  border: none;
  border-radius: 4px;
  background-color: #2ecc71;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #27ae60;
  }
`;

const StudentCertificate = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
      const Student = JSON.parse(localStorage.getItem("Data"));
      fetch('https://ims-nv9i.onrender.com/marksheet/all')
          .then(response => response.json())
          .then(data => {
              const Data = data.data;
              console.log(Data);
              const filData = Data.filter(data => data.RegistrationNo === Student.StudentData.StudentId);
              setData(filData);
          })
          .catch(error => console.error('Error fetching data:', error));
    }, []);

    // const goToMark = (reg, couid) => {
    //     navigate(`/marksheetprint/${reg}/${couid}`);
    // };

    // const goToCert = (reg, couid) => {
    //     navigate(`/certificatec/${reg}/${couid}`);
    // };

    return (
        <DashboardContainer>
            <Header>
                Student Dashboard
                <HamburgerButton isOpen={isSidebarOpen} onClick={toggleSidebar} />
            </Header>
            <Container>
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <MainContent>
                    <Container1>
                        <Heading>Certificate Data</Heading>
                        <Table>
                            <thead>
                                <TableRow>
                                    <TableHeadCell>Certificate No</TableHeadCell>
                                    <TableHeadCell>Student Name</TableHeadCell>
                                    <TableHeadCell>Course Name</TableHeadCell>
                                    <TableHeadCell>Course Duration</TableHeadCell>
                                    <TableHeadCell>Certificate</TableHeadCell>
                                </TableRow>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.CertificateNo}</TableCell>
                                        <TableCell>{item.StudentName}</TableCell>
                                        <TableCell>{item.CourseName}</TableCell>
                                        <TableCell>{item.CourseDuration}</TableCell>
                                        <TableCell>
                                            {item.Status ? (
                                                <Button 
                                                onClick={() => navigate(`/certificatec/${item.RegistrationNo}/${item.CourseId}`)}
                                                >
                                                    Certificate
                                                </Button>
                                            ) : (
                                                "Certificate Declared Soon"
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </tbody>
                        </Table>
                    </Container1>
                </MainContent>
            </Container>
        </DashboardContainer>
    );
};

export default StudentCertificate;