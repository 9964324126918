import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Expense = () => {
    const [id, setId] = useState('');
    const [type, setType] = useState('');
    const [title, setTitle] = useState('');
    const [amount, setAmount] = useState('');
    const [dateTime, setDateTime] = useState('');
    const [expenses, setExpenses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const localdata = JSON.parse(localStorage.getItem("Data"));
        if (localdata) {
            if (localdata.EmployeeData) {
                setId(localdata.EmployeeData.FranchiseId);
            } else if (localdata.FranchiseData) {
                setId(localdata.FranchiseData.FranchiseId);
            } else {
                setId("Admin");
            }
        } else {
            setId("Admin");
        }
    }, []);

    const fetchExpenses = async () => {
        var Id;
        const localdata = JSON.parse(localStorage.getItem("Data"));
        if (localdata) {
            if (localdata.EmployeeData) {
                Id = (localdata.EmployeeData.FranchiseId);
            } else if (localdata.FranchiseData) {
                Id = (localdata.FranchiseData.FranchiseId);
            } else {
                Id = ("Admin");
            }
        } else {
            Id = ("Admin");
        }
        try {
            console.log(Id);
            const response = await fetch(`https://ims-nv9i.onrender.com/expense/get/${Id}`);
            const data = await response.json();
            if (response.ok) {
                setExpenses(data.Expense || []);
            } else {
                setError(data.message || 'Failed to fetch expenses');
            }
        } catch (error) {
            setError('An error occurred while fetching expenses.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchExpenses();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const expenseData = {
            Id: id,
            newExpense: {
                Type: type,
                Title: title,
                Amount: parseFloat(amount),
                DateTime: dateTime
            }
        };

        try {
            const response = await fetch('https://ims-nv9i.onrender.com/expense/addExpense', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(expenseData)
            });

            if (response.ok) {
                toast.success('Expense added successfully');
                setType('');
                setTitle('');
                setAmount('');
                setDateTime('');
                fetchExpenses();
            } else {
                toast.error('Failed to add expense');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Error adding expense');
        }
    };

    // Calculate totals
    const totalIncome = expenses
        .filter(expense => expense.Type === 'Income')
        .reduce((sum, expense) => sum + expense.Amount, 0).toFixed(2);

    const totalExpense = expenses
        .filter(expense => expense.Type === 'Expense')
        .reduce((sum, expense) => sum + expense.Amount, 0).toFixed(2);

    return (
        <Container>
            <Card>
                <Title>Add Expense</Title>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label htmlFor="type">Expense Type:</Label>
                        <Select
                            id="type"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            required
                        >
                            <option value="">Select Type</option>
                            <option value="Income">Income</option>
                            <option value="Expense">Expense</option>
                        </Select>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="title">Title:</Label>
                        <Input
                            id="title"
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="amount">Amount:</Label>
                        <Input
                            id="amount"
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="dateTime">Date and Time:</Label>
                        <Input
                            id="dateTime"
                            type="datetime-local"
                            value={dateTime}
                            onChange={(e) => setDateTime(e.target.value)}
                            required
                        />
                    </FormGroup>
                    <SubmitButton type="submit">Add Expense</SubmitButton>
                </Form>
            </Card>

            <Card>
                {loading ? <LoadingText>Loading...</LoadingText> : error ? <ErrorText>{error}</ErrorText> : (
                    <>
                        <Totals>
                            <TotalItem><strong>Total Income:</strong> ₹ {totalIncome}</TotalItem>
                            <TotalItem><strong>Total Expense:</strong> ₹ {totalExpense}</TotalItem>
                        </Totals>
                        <Table>
                            <thead>
                                <TableRow>
                                    <TableHeader>Type</TableHeader>
                                    <TableHeader>Title</TableHeader>
                                    <TableHeader>Amount</TableHeader>
                                    <TableHeader>Date and Time</TableHeader>
                                </TableRow>
                            </thead>
                            <tbody>
                                {expenses.length === 0 ? (
                                    <TableRow>
                                        <TableData colSpan="4">No expenses found</TableData>
                                    </TableRow>
                                ) : (
                                    expenses.map((expense, index) => (
                                        <TableRow key={index}>
                                            <TableData>{expense.Type}</TableData>
                                            <TableData>{expense.Title}</TableData>
                                            <TableData>${expense.Amount.toFixed(2)}</TableData>
                                            <TableData>{new Date(expense.DateTime).toLocaleString()}</TableData>
                                        </TableRow>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </>
                )}
            </Card>

            <ToastContainer />
        </Container>
    );
};

// Styled Components
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f4;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
`;

const Card = styled.div`
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
`;

const Title = styled.h2`
    margin-top: 0;
    font-size: 24px;
    color: #333;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px; 
    margin-bottom: 20px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const FormGroup = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
    font-size: 14px;
`;

const Select = styled.select`
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
`;

const SubmitButton = styled.button`
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    background: #fff;
    font-size: 15px;
`;

const TableRow = styled.tr`
    border-bottom: 1px solid #ddd;
`;

const TableHeader = styled.th`
    padding: 12px;
    text-align: left;
    background: #007bff;
    color: #fff;
`;

const TableData = styled.td`
    padding: 12px;
`;

const LoadingText = styled.p`
    text-align: center;
    font-size: 18px;
    color: #666;
`;

const ErrorText = styled.p`
    text-align: center;
    font-size: 18px;
    color: #d9534f;
`;

const Totals = styled.div`
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
`;

const TotalItem = styled.p`
    margin: 5px 0;
`;

export default Expense;
