import React, { useState } from 'react';
import styled from 'styled-components';

const NotificationPopup = ({ onClose, onAddNotification }) => {
  const [receiver, setReceiver] = useState('');
  const [heading, setHeading] = useState('');
  const [message, setMessage] = useState('');

  const handleAddNotification = async () => {
    const notification = { Receiver: receiver, Heading: heading, Message: message };
    try {
      const response = await fetch('https://ims-nv9i.onrender.com/notification/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(notification)
      });
      if (!response.ok) {
        throw new Error('Failed to add notification');
      }
      const data = await response.json();
      onAddNotification(data);
      onClose();
    } catch (error) {
      console.error('Error adding notification:', error);
    }
  };

  return (
    <PopupOverlay>
      <PopupContent>
        <h2>Add Notification</h2>
        <Input
          type="text"
          placeholder="Receiver"
          value={receiver}
          onChange={(e) => setReceiver(e.target.value)}
        />
        <Input
          type="text"
          placeholder="Heading"
          value={heading}
          onChange={(e) => setHeading(e.target.value)}
        />
        <Textarea
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <PopupActions>
          <Button onClick={handleAddNotification}>Add Notification</Button>
          <Button onClick={onClose}>Cancel</Button>
        </PopupActions>
      </PopupContent>
    </PopupOverlay>
  );
};

export default NotificationPopup;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: none;
`;

const PopupActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: white;

  &:last-child {
    background-color: #6c757d;
  }
`;
