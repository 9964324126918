import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const ViewAttendance = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [dateFilter, setDateFilter] = useState('');
    const [batchIdFilter, setBatchIdFilter] = useState('');
    const [roleFilter, setRoleFilter] = useState('');
    const [franchiseIdFilter, setFranchiseIdFilter] = useState('');
    const [roles, setRoles] = useState([]);
    const [batchIds, setBatchIds] = useState([]);

    useEffect(() => {
        var Id;
        const localdata = JSON.parse(localStorage.getItem("Data"));
        if (localdata) {
            if (localdata.EmployeeData) {
                Id = localdata.EmployeeData.FranchiseId;
            } else if (localdata.FranchiseData) {
                Id = localdata.FranchiseData.FranchiseId;
            } else {
                Id = "Admin";
            }
        } else {
            Id = "Admin";
        }
        const fetchData = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/attendanc/all');
                const result = await response.json();
                const filteredData = result.filter(data => data.FranchiseId === Id);
                setData(filteredData);
                setFilteredData(filteredData);

                const uniqueRoles = [...new Set(filteredData.map(item => item.Role))];
                const uniqueBatchIds = [...new Set(filteredData.map(item => item.BatchId))];
                setRoles(uniqueRoles);
                setBatchIds(uniqueBatchIds);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const filtered = data.filter(item => {
            const dateMatch = dateFilter ? item.Date === dateFilter : true;
            const batchIdMatch = batchIdFilter ? item.BatchId === batchIdFilter : true;
            const roleMatch = roleFilter ? item.Role === roleFilter : true;
            const franchiseIdMatch = franchiseIdFilter ? item.FranchiseId === franchiseIdFilter : true;
            return dateMatch && batchIdMatch && roleMatch && franchiseIdMatch;
        });
        setFilteredData(filtered);
    }, [dateFilter, batchIdFilter, roleFilter, franchiseIdFilter, data]);

    return (
        <Container>
            <Heading>Attendance Records</Heading>

            {/* Filter Form */}
            <FilterForm>
                <FilterLabel>
                    Date:
                    <FilterInput
                        type="date"
                        value={dateFilter}
                        onChange={(e) => setDateFilter(e.target.value)}
                    />
                </FilterLabel>
                <FilterLabel>
                    Batch ID:
                    <FilterSelect
                        value={batchIdFilter}
                        onChange={(e) => setBatchIdFilter(e.target.value)}
                    >
                        <option value="">Select Batch ID</option>
                        {batchIds.map(batchId => (
                            <option key={batchId} value={batchId}>{batchId}</option>
                        ))}
                    </FilterSelect>
                </FilterLabel>
                <FilterLabel>
                    Role:
                    <FilterSelect
                        value={roleFilter}
                        onChange={(e) => setRoleFilter(e.target.value)}
                    >
                        <option value="">Select Role</option>
                        {roles.map(role => (
                            <option key={role} value={role}>{role}</option>
                        ))}
                    </FilterSelect>
                </FilterLabel>
            </FilterForm>

            {/* Data Table */}
            <Table>
                <thead>
                    <TableRow>
                        <TableHeader>Date</TableHeader>
                        <TableHeader>Franchise Id</TableHeader>
                        <TableHeader>Role</TableHeader>
                        <TableHeader>Batch Id</TableHeader>
                        <TableHeader>Present Students</TableHeader>
                        <TableHeader>Absent Students</TableHeader>
                    </TableRow>
                </thead>
                <tbody>
                    {filteredData.map((item, index) => (
                        <TableRow key={item._id} odd={index % 2 !== 0}>
                            <TableData>{item.Date}</TableData>
                            <TableData>{item.FranchiseId}</TableData>
                            <TableData>{item.Role}</TableData>
                            <TableData>{item.BatchId}</TableData>
                            <TableData>{item.PresentStu.join(', ')}</TableData>
                            <TableData>{item.AbsentStu.join(', ')}</TableData>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

// Styled-components
const Container = styled.div`
  margin:20px;
  padding:20px;
  width:100%;
  overflow-x: auto;
  max-height: calc(100vh - 70px - 40px);
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const Heading = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  color: #343a40;
`;

const FilterForm = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
`;

const FilterLabel = styled.label`
  font-size: 16px;
  color: #495057;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const FilterInput = styled.input`
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
  flex: 1;
`;

const FilterSelect = styled.select`
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
  flex: 1;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 15px;
`;

const TableRow = styled.tr`
  background-color: ${props => (props.odd ? '#e9ecef' : '#ffffff')};
  &:hover {
    background-color: #f1f3f5;
  }
`;

const TableHeader = styled.th`
  border: 1px solid #dee2e6;
  padding: 12px;
  background-color: #f8f9fa;
  font-weight: bold;
  text-align: left;
`;

const TableData = styled.td`
  border: 1px solid #dee2e6;
  padding: 12px;
  color: #495057;
`;

export default ViewAttendance;
