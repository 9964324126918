import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Styled Components
const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
`;

const Title = styled.h2`
  text-align: center;
  color: #333;
  margin-bottom: 20px;
`;

const TableWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background-color: #f4f4f4;
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 10px;
`;

const ErrorMessage = styled.p`
  text-align: center;
  color: #dc3545;
`;

const AdminReward = () => {
    const [franchises, setFranchises] = useState([]);
    const [students, setStudents] = useState([]);
    const [counts, setCounts] = useState([]);
    const [rewardData, setRewardData] = useState([]);
    const [eligibleFranchises, setEligibleFranchises] = useState([]);

    useEffect(() => {
        const fetchFranchises = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/franchises/all');
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setFranchises(data);
            } catch (error) {
                console.error('Error fetching franchises:', error);
                toast.error('Error fetching franchise data');
            }
        };

        const fetchStudents = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/students/all');
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setStudents(data);
            } catch (error) {
                console.error('Error fetching students:', error);
                toast.error('Error fetching student data');
            }
        };

        const fetchRewards = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/reward/all');
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setRewardData(data);
            } catch (error) {
                console.error('Error fetching reward data:', error);
                toast.error('Error fetching reward data');
            }
        };

        fetchFranchises();
        fetchStudents();
        fetchRewards();
    }, []);

    useEffect(() => {
        if (franchises.length > 0 && students.length > 0) {
            const franchiseCounts = franchises.map(franchise => {
                const masterFranchiseCount = franchises.filter(f => f.MasterFranchiseId === franchise.FranchiseId).length;
                const studentCount = students.filter(student => student.FranchiseId === franchise.FranchiseId).length;

                return {
                    FranchiseId: franchise.FranchiseId,
                    MasterFranchiseCount: masterFranchiseCount,
                    StudentCount: studentCount,
                };
            });

            setCounts(franchiseCounts);
        }
    }, [franchises, students]);

    useEffect(() => {
        if (counts.length > 0 && rewardData.length > 0) {
            const eligible = counts.map(count => {
                const reward = rewardData.find(r =>
                    count.MasterFranchiseCount >= r.FranchiseNo &&
                    count.StudentCount >= r.StudentNo
                );

                return {
                    ...count,
                    Reward: reward ? reward.Level : 'None',
                };
            });

            setEligibleFranchises(eligible);
        }
    }, [counts, rewardData]);

    return (
        <Container>
            <Title>Franchise and Student Data</Title>
            {eligibleFranchises.length > 0 ? (
                <TableWrapper>
                    <Table>
                        <thead>
                            <tr>
                                <TableHeader>Franchise ID</TableHeader>
                                <TableHeader>Number of Franchises with Same Master Franchise ID</TableHeader>
                                <TableHeader>Number of Students with This Franchise ID</TableHeader>
                                <TableHeader>Reward Level</TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {eligibleFranchises.map((franchise) => (
                                <tr key={franchise.FranchiseId}>
                                    <TableCell>{franchise.FranchiseId}</TableCell>
                                    <TableCell>{franchise.MasterFranchiseCount}</TableCell>
                                    <TableCell>{franchise.StudentCount}</TableCell>
                                    <TableCell>{franchise.Reward}</TableCell>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </TableWrapper>
            ) : (
                <ErrorMessage>No data available</ErrorMessage>
            )}

            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Container>
    );
};

export default AdminReward;
