import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Styled components for the form
const FormContainer = styled.div`
  width: 100%;
  margin: 20px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const FormHeading = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
`;

const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
`;

const FormColumn = styled.div`
  flex: 1;
  min-width: 300px;
  box-sizing: border-box;
`;

const FormLabel = styled.label`
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const FormSelect = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const FormTextarea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const FormFileInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 5px;
`;

const ErrorText = styled.p`
  color: red;
  font-size: 14px;
`;

const SubmitButton = styled.button`
  background: #008cba;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  &:hover {
    background: #007bb5;
  }
`;

const ImagePreview = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-top: 10px;
`;

// Validation functions
const isValidMobileNumber = (number) => /^\d{10}$/.test(number);
const isValidPinCode = (number) => /^\d{6}$/.test(number);

const EditFranchise = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({
        FranchiseName: '',
        Address: '',
        City: '',
        State: '',
        Dist: '',
        PinCode: '',
        OwnerName: '',
        Email: '',
        MobileNo: '',
        Plan: '',
        RegisterDate: '',
        ExpiryDate: '',
        DirectorOne: { Name: '', Photo: '', Signature: '', AadharCard: '', Qualification: '', Agreement: '' },
        DirectorTwo: { Name: '', Photo: '', Signature: '', AadharCard: '', Qualification: '', Agreement: '' },
        DirectorThree: { Name: '', Photo: '', Signature: '', AadharCard: '', Qualification: '', Agreement: '' },
        Formats: { ATC: '', MarkSheet: '', Certificate: '' }
    });
    const [errors, setErrors] = useState({});
    const [imageUrls, setImageUrls] = useState({});

    useEffect(() => {
        const fetchFranchiseData = async () => {
            try {
                const response = await fetch(`https://ims-nv9i.onrender.com/franchises/${location.state.id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch franchise data');
                }
                const data = await response.json();
                console.log(data);
                setFormData(data);
                setImageUrls({
                    DirectorOnePhoto: data.DirectorOne?.Photo,
                    DirectorOneSignature: data?.DirectorOne?.Signature,
                    DirectorOneAadharCard: data?.DirectorOne?.AadharCard,
                    DirectorOneQualification: data?.DirectorOne?.Qualification,
                    DirectorOneAgreement: data.DirectorOne?.Agreement,
                    DirectorTwoPhoto: data.DirectorTwo?.Photo,
                    DirectorTwoSignature: data.DirectorTwo?.Signature,
                    DirectorTwoAadharCard: data.DirectorTwo?.AadharCard,
                    DirectorTwoQualification: data.DirectorTwo?.Qualification,
                    DirectorTwoAgreement: data.DirectorTwo?.Agreement,
                    DirectorThreePhoto: data.DirectorThree?.Photo,
                    DirectorThreeSignature: data.DirectorThree?.Signature,
                    DirectorThreeAadharCard: data.DirectorThree?.AadharCard,
                    DirectorThreeQualification: data.DirectorThree?.Qualification,
                    DirectorThreeAgreement: data.DirectorThree?.Agreement,
                    FormatsATC: data.Formats?.ATC,
                    FormatsMarkSheet: data.Formats?.MarkSheet,
                    FormatsCertificate: data.Formats?.Certificate
                });
            } catch (error) {
                console.error('Error fetching franchise data:', error);
                toast.error('Error fetching franchise data');
            }
        };

        fetchFranchiseData();
    }, [location.state.id]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: files ? files[0] : value
        }));
    };

    const validateForm = () => {
        let valid = true;
        let newErrors = {};

        if (!isValidMobileNumber(formData.MobileNo)) {
            valid = false;
            newErrors.MobileNo = 'Mobile number must be exactly 10 digits.';
        }
        if (!isValidPinCode(formData.PinCode)) {
            valid = false;
            newErrors.PinCode = 'Pin code must be exactly 6 digits.';
        }
        if (!formData.Email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.Email)) {
            valid = false;
            newErrors.Email = 'Invalid email address.';
        }

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            toast.error('Please correct the errors before submitting.');
            return;
        }

        const data = new FormData();
        for (const key in formData) {
            if (typeof formData[key] === 'object') {
                for (const subKey in formData[key]) {
                    data.append(`${key}[${subKey}]`, formData[key][subKey]);
                }
            } else {
                data.append(key, formData[key]);
            }
        }

        try {
            const response = await fetch(`https://ims-nv9i.onrender.com/franchises/update/${location.state.id}`, {
                method: 'PUT',
                body: data,
            });

            if (!response.ok) {
                throw new Error('Failed to update franchise data');
            }
            toast.success('Franchise updated successfully');
            setTimeout( navigate(`/admin`), 5000)
             // Redirect to a page where you can see the updated franchise details
        } catch (error) {
            console.error('Error updating franchise data:', error);
            toast.error('Error updating franchise data');
        }
    };

    const states = [
        "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa",
        "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala",
        "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland",
        "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura",
        "Uttar Pradesh", "Uttarakhand", "West Bengal", "Andaman and Nicobar Islands",
        "Chandigarh", "Dadra and Nagar Haveli and Daman and Diu", "Lakshadweep", "Delhi",
        "Puducherry", "Ladakh", "Jammu and Kashmir"
    ];

    return (
        <FormContainer>
        <FormHeading>Edit Franchise</FormHeading>
        <form onSubmit={handleSubmit}>
            {/* Franchise Details */}
            <FormRow>
                <FormColumn>
                    <FormLabel htmlFor="FranchiseName">Franchise Name:</FormLabel>
                    <FormInput
                        id="FranchiseName"
                        name="FranchiseName"
                        type="text"
                        value={formData.FranchiseName}
                        onChange={handleChange}
                    />
                </FormColumn>
            </FormRow>
            <FormRow>
                <FormColumn>
                    <FormLabel htmlFor="Address">Address:</FormLabel>
                    <FormTextarea
                        id="Address"
                        name="Address"
                        value={formData.Address}
                        onChange={handleChange}
                    />
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="City">City:</FormLabel>
                    <FormInput
                        id="City"
                        name="City"
                        type="text"
                        value={formData.City}
                        onChange={handleChange}
                    />
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="State">State:</FormLabel>
                    <FormSelect
                        id="State"
                        name="State"
                        value={formData.State}
                        onChange={handleChange}
                    >
                        <option value="">Select State</option>
                        {states.map((state, index) => (
                            <option key={index} value={state}>{state}</option>
                        ))}
                    </FormSelect>
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="Dist">District:</FormLabel>
                    <FormInput
                        id="Dist"
                        name="Dist"
                        type="text"
                        value={formData.Dist}
                        onChange={handleChange}
                    />
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="PinCode">Pin Code:</FormLabel>
                    <FormInput
                        id="PinCode"
                        name="PinCode"
                        type="text"
                        value={formData.PinCode}
                        onChange={handleChange}
                    />
                    {errors.PinCode && <ErrorText>{errors.PinCode}</ErrorText>}
                </FormColumn>
            </FormRow>
            <FormRow>
                <FormColumn>
                    <FormLabel htmlFor="OwnerName">Owner Name:</FormLabel>
                    <FormInput
                        id="OwnerName"
                        name="OwnerName"
                        type="text"
                        value={formData.OwnerName}
                        onChange={handleChange}
                    />
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="Email">Email:</FormLabel>
                    <FormInput
                        id="Email"
                        name="Email"
                        type="email"
                        value={formData.Email}
                        onChange={handleChange}
                    />
                    {errors.Email && <ErrorText>{errors.Email}</ErrorText>}
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="MobileNo">Mobile No:</FormLabel>
                    <FormInput
                        id="MobileNo"
                        name="MobileNo"
                        type="text"
                        value={formData.MobileNo}
                        onChange={handleChange}
                    />
                    {errors.MobileNo && <ErrorText>{errors.MobileNo}</ErrorText>}
                </FormColumn>
            </FormRow>
            <FormRow>
                <FormColumn>
                    <FormLabel htmlFor="Plan">Plan:</FormLabel>
                    <FormSelect
                        id="Plan"
                        name="Plan"
                        value={formData.Plan}
                        onChange={handleChange}
                    >
                        <option value="">Select Plan</option>
                        <option value="Plan-A">Plan-A</option>
                        <option value="Plan-B">Plan-B</option>
                        <option value="Plan-C">Plan-C</option>
                    </FormSelect>
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="RegisterDate">Register Date:</FormLabel>
                    <FormInput
                        id="RegisterDate"
                        name="RegisterDate"
                        type="date"
                        value={formData.RegisterDate}
                        onChange={handleChange}
                    />
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="ExpiryDate">Expiry Date:</FormLabel>
                    <FormInput
                        id="ExpiryDate"
                        name="ExpiryDate"
                        type="date"
                        value={formData.ExpiryDate}
                        onChange={handleChange}
                    />
                </FormColumn>
            </FormRow>

            {/* Director One Details */}
            <FormHeading>Director One</FormHeading>
            <FormRow>
                <FormColumn>
                    <FormLabel htmlFor="DirectorOne[Name]">Name:</FormLabel>
                    <FormInput
                        id="DirectorOneName"
                        name="DirectorOne[Name]"
                        type="text"
                        value={formData.DirectorOne.Name}
                        onChange={handleChange}
                    />
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="DirectorOnePhoto">Photo:</FormLabel>
                    <FormFileInput
                        id="DirectorOnePhoto"
                        name="DirectorOne[Photo]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.DirectorOnePhoto && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.DirectorOnePhoto}`} alt="Director One Photo" />}
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="DirectorOneSignature">Signature:</FormLabel>
                    <FormFileInput
                        id="DirectorOneSignature"
                        name="DirectorOne[Signature]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.DirectorOneSignature && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.DirectorOneSignature}`} alt="Director One Signature" />}
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="DirectorOneAadharCard">Aadhar Card:</FormLabel>
                    <FormFileInput
                        id="DirectorOneAadharCard"
                        name="DirectorOne[AadharCard]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.DirectorOneAadharCard && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.DirectorOneAadharCard}`} alt="Director One Aadhar Card" />}
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="DirectorOneQualification">Qualification:</FormLabel>
                    <FormFileInput
                        id="DirectorOneQualification"
                        name="DirectorOne[Qualification]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.DirectorOneQualification && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.DirectorOneQualification}`} alt="Director One Qualification" />}
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="DirectorOneAgreement">Agreement:</FormLabel>
                    <FormFileInput
                        id="DirectorOneAgreement"
                        name="DirectorOne[Agreement]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.DirectorOneAgreement && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.DirectorOneAgreement}`} alt="Director One Agreement" />}
                </FormColumn>
            </FormRow>

            {/* Director Two Details */}
            <FormHeading>Director Two</FormHeading>
            <FormRow>
                <FormColumn>
                    <FormLabel htmlFor="DirectorTwo[Name]">Name:</FormLabel>
                    <FormInput
                        id="DirectorTwoName"
                        name="DirectorTwo[Name]"
                        type="text"
                        value={formData?.DirectorTwo?.Name}
                        onChange={handleChange}
                    />
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="DirectorTwoPhoto">Photo:</FormLabel>
                    <FormFileInput
                        id="DirectorTwoPhoto"
                        name="DirectorTwo[Photo]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.DirectorTwoPhoto && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.DirectorTwoPhoto}`} alt="Director Two Photo" />}
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="DirectorTwoSignature">Signature:</FormLabel>
                    <FormFileInput
                        id="DirectorTwoSignature"
                        name="DirectorTwo[Signature]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.DirectorTwoSignature && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.DirectorTwoSignature}`} alt="Director Two Signature" />}
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="DirectorTwoAadharCard">Aadhar Card:</FormLabel>
                    <FormFileInput
                        id="DirectorTwoAadharCard"
                        name="DirectorTwo[AadharCard]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.DirectorTwoAadharCard && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.DirectorTwoAadharCard}`} alt="Director Two Aadhar Card" />}
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="DirectorTwoQualification">Qualification:</FormLabel>
                    <FormFileInput
                        id="DirectorTwoQualification"
                        name="DirectorTwo[Qualification]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.DirectorTwoQualification && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.DirectorTwoQualification}`} alt="Director Two Qualification" />}
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="DirectorTwoAgreement">Agreement:</FormLabel>
                    <FormFileInput
                        id="DirectorTwoAgreement"
                        name="DirectorTwo[Agreement]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.DirectorTwoAgreement && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.DirectorTwoAgreement}`} alt="Director Two Agreement" />}
                </FormColumn>
            </FormRow>

            {/* Director Three Details */}
            <FormHeading>Director Three</FormHeading>
            <FormRow>
                <FormColumn>
                    <FormLabel htmlFor="DirectorThree[Name]">Name:</FormLabel>
                    <FormInput
                        id="DirectorThreeName"
                        name="DirectorThree[Name]"
                        type="text"
                        value={formData?.DirectorThree?.Name}
                        onChange={handleChange}
                    />
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="DirectorThreePhoto">Photo:</FormLabel>
                    <FormFileInput
                        id="DirectorThreePhoto"
                        name="DirectorThree[Photo]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.DirectorThreePhoto && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.DirectorThreePhoto}`} alt="Director Three Photo" />}
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="DirectorThreeSignature">Signature:</FormLabel>
                    <FormFileInput
                        id="DirectorThreeSignature"
                        name="DirectorThree[Signature]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.DirectorThreeSignature && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.DirectorThreeSignature}`} alt="Director Three Signature" />}
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="DirectorThreeAadharCard">Aadhar Card:</FormLabel>
                    <FormFileInput
                        id="DirectorThreeAadharCard"
                        name="DirectorThree[AadharCard]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.DirectorThreeAadharCard && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.DirectorThreeAadharCard}`} alt="Director Three Aadhar Card" />}
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="DirectorThreeQualification">Qualification:</FormLabel>
                    <FormFileInput
                        id="DirectorThreeQualification"
                        name="DirectorThree[Qualification]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.DirectorThreeQualification && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.DirectorThreeQualification}`} alt="Director Three Qualification" />}
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="DirectorThreeAgreement">Agreement:</FormLabel>
                    <FormFileInput
                        id="DirectorThreeAgreement"
                        name="DirectorThree[Agreement]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.DirectorThreeAgreement && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.DirectorThreeAgreement}`} alt="Director Three Agreement" />}
                </FormColumn>
            </FormRow>

            {/* Formats */}
            <FormHeading>Formats</FormHeading>
            <FormRow>
                <FormColumn>
                    <FormLabel htmlFor="Formats[ATC]">ATC:</FormLabel>
                    <FormFileInput
                        id="FormatsATC"
                        name="Formats[ATC]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.FormatsATC && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.FormatsATC}`} alt="ATC" />}
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="Formats[MarkSheet]">Mark Sheet:</FormLabel>
                    <FormFileInput
                        id="FormatsMarkSheet"
                        name="Formats[MarkSheet]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.FormatsMarkSheet && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.FormatsMarkSheet}`} alt="Mark Sheet" />}
                </FormColumn>
                <FormColumn>
                    <FormLabel htmlFor="Formats[Certificate]">Certificate:</FormLabel>
                    <FormFileInput
                        id="FormatsCertificate"
                        name="Formats[Certificate]"
                        type="file"
                        onChange={handleChange}
                    />
                    {imageUrls.FormatsCertificate && <img src={`https://ims-nv9i.onrender.com/uploads/${imageUrls.FormatsCertificate}`} alt="Certificate" />}
                </FormColumn>
            </FormRow>

            <SubmitButton type="submit">Update Franchise</SubmitButton>
        </form>
        <ToastContainer />
    </FormContainer>
    );
};

export default EditFranchise;
