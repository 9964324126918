import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  margin: 20px;
  font-family: 'Arial', sans-serif;
`;

const CountsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

const CountBox = styled.div`
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  width: 100%;
  max-width: 200px;
  font-size:15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const EligibleRewardsBox = styled(CountBox)`
  background-color: #e8f5e9;
  border: 1px solid #c8e6c9;
  color: #2e7d32;
`;

const RewardsTableWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  overflow-x: auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Table = styled.table`
  font-size:15px;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background-color: #008cba;
  color: #fff;
  padding: 12px;
  border: 1px solid #ddd;
  font-weight: bold;
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 12px;
  color: #555;
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
  margin: 20px 0;
`;

const NoRewardsMessage = styled.p`
  color: #555;
`;

const ViewReward = () => {
    const [studentCount, setStudentCount] = useState(0);
    const [franchiseCount, setFranchiseCount] = useState(0);
    const [rewards, setRewards] = useState([]);
    const [eligibleRewards, setEligibleRewards] = useState([]);

    useEffect(() => {
        var Id;
        const localdata = JSON.parse(localStorage.getItem("Data"));
        if (localdata) {
            if (localdata.EmployeeData) {
                Id = localdata.EmployeeData.FranchiseId;
            } else if (localdata.FranchiseData) {
                Id = localdata.FranchiseData.FranchiseId;
            } else {
                Id = "Admin";
            }
        } else {
            Id = "Admin";
        }

        const fetchStudent = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/students/all');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const filteredData = data.filter(ele => ele.FranchiseId === Id);
                setStudentCount(filteredData.length);
            } catch (error) {
                console.error('Error fetching students:', error);
            }
        };

        const fetchFranchise = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/franchises/all');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const filteredData = data.filter(ele => ele.MasterFranchiseId === Id);
                setFranchiseCount(filteredData.length);
            } catch (error) {
                console.error('Error fetching franchises:', error);
            }
        };

        const fetchRewards = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/reward/all');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setRewards(data);

                // Filter rewards based on student and franchise counts
                const eligible = data.filter(reward =>
                    studentCount >= parseInt(reward.StudentNo) &&
                    franchiseCount >= parseInt(reward.FranchiseNo)
                );
                setEligibleRewards(eligible);
            } catch (error) {
                console.error('Error fetching rewards:', error);
            }
        };

        fetchRewards();
        fetchStudent();
        fetchFranchise();
    }, [studentCount, franchiseCount]);

    return (
        <>
            <Container>
                <CountsWrapper>
                    <CountBox>
                        <h4>Students</h4>
                        <p>{studentCount}</p>
                    </CountBox>
                    <CountBox>
                        <h4>Franchise</h4>
                        <p>{franchiseCount}</p>
                    </CountBox>
                    <EligibleRewardsBox>
                        <h4>Eligible Rewards</h4>
                        {eligibleRewards.length > 0 ? (
                            <ul>
                                {eligibleRewards.map((reward) => (
                                    <li key={reward._id}>
                                        Level {reward.Level} - Bonus: {reward.BonusAmount} - Tour: {reward.Tour}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <NoRewardsMessage>No eligible rewards</NoRewardsMessage>
                        )}
                    </EligibleRewardsBox>
                </CountsWrapper>
                <RewardsTableWrapper>
                    <Title>Rewards List</Title>
                    <Table>
                        <thead>
                            <tr>
                                <TableHeader>Level</TableHeader>
                                <TableHeader>Franchise No</TableHeader>
                                <TableHeader>Student No</TableHeader>
                                <TableHeader>Bonus Amount</TableHeader>
                                <TableHeader>Tour</TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {rewards.map((reward) => (
                                <tr key={reward._id}>
                                    <TableCell>{reward.Level}</TableCell>
                                    <TableCell>{reward.FranchiseNo}</TableCell>
                                    <TableCell>{reward.StudentNo}</TableCell>
                                    <TableCell>{reward.BonusAmount}</TableCell>
                                    <TableCell>{reward.Tour}</TableCell>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </RewardsTableWrapper>
            </Container>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </>
    );
};

export default ViewReward;
