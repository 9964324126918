import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ViewBatch = () => {
    const [batches, setBatches] = useState([]);

    // Function to fetch batches data
    const fetchBatches = async () => {
        var Id;
        const localdata = JSON.parse(localStorage.getItem("Data"));
        if (localdata) {
            if (localdata.EmployeeData) {
                Id=(localdata.EmployeeData.FranchiseId);
            } else if (localdata.FranchiseData) {
                Id=(localdata.FranchiseData.FranchiseId);
            } else {
                Id=("Admin");
            }
        } else {
            Id=("Admin");
        }
        try {
            const response = await fetch('https://ims-nv9i.onrender.com/batch/all');
            const data = await response.json();
            const filData = data.filter((ele) => ele.FranchiseId === Id);
            console.log(filData)
            setBatches(filData);
        } catch (error) {
            console.error('Error fetching batch data:', error);
        }
    };

    // Fetch batches data on component mount
    useEffect(() => {
        fetchBatches();
    }, []);

    return (
        <Container>
            <Table>
                <thead>
                    <tr>
                        <TableHeader>Batch ID</TableHeader>
                        <TableHeader>Batch Name</TableHeader>
                        <TableHeader>Franchise ID</TableHeader>
                        <TableHeader>Course</TableHeader>
                        <TableHeader>Total Students</TableHeader>
                        <TableHeader>Session Time</TableHeader>
                        <TableHeader>Students ID</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {batches.map((batch) => (
                        <TableRow key={batch._id}>
                            <TableData>{batch.BatchId}</TableData>
                            <TableData>{batch.BatchName}</TableData>
                            <TableData>{batch.FranchiseId}</TableData>
                            <TableData>{batch.Course}</TableData>
                            <TableData>{batch.TotalStudent}</TableData>
                            <TableData>{batch.SessionTime}</TableData>
                            <TableData>{batch.StudentsId.join(', ')}</TableData>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

// Styled-components
const Container = styled.div`
    width: 100%;
    margin: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
`;

const Title = styled.h2`
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 600;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-size: 15px;
    color: #555;
    border-radius: 5px;
`;

const TableHeader = styled.th`
    padding: 12px;
    background-color: #008cba;
    color: #fff;
    text-align: left;
    border-bottom: 2px solid #ddd;
`;

const TableRow = styled.tr`
    border-bottom: 1px solid #ddd;
`;

const TableData = styled.td`
    padding: 12px;
    border-bottom: 1px solid #ddd;
    background-color: #f9f9f9;
`;

export default ViewBatch;
