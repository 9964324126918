import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useLocation } from "react-router-dom";
import styled from 'styled-components';

const PaymentTable = ({ paymentData, studentData }) => {
  const handleDownload = () => {
    if (!paymentData) return;

    const doc = new jsPDF();
    const currentDate = new Date().toLocaleDateString();
    
    const course = studentData.Courses.find(
      (course) => course.CourseId === paymentData.CourseId
    );
    
    // Add CourseName to studentData
    if (course) {
      studentData.CourseName = course.CourseName;
    }

    doc.setFontSize(20);
    doc.setTextColor("#0070C0");
    doc.text("INVOICE", 105, 40, null, null, 'center');

    doc.setFontSize(12);
    doc.setTextColor("#0070C0");
    doc.text(`Student ID: ${paymentData.StudentID}`, 20, 50);
    doc.text(`Student Name: ${studentData.StudentName}`, 20, 60);
    doc.text(`Father Name: ${studentData.FatherName}`, 20, 70);
    doc.text(`Course ID: ${studentData.CourseName}`, 20, 80);
    doc.text(`Franchise Name: ${studentData.FranchiseName}`, 20, 90);

    doc.text(`Date: ${currentDate}`, 160, 60);
    doc.text(`INVOICE # ${paymentData.StudentID}`, 160, 67);
    doc.text(`Total Fee: ${paymentData.TotalFee}`, 160, 74);

    // Table Headers
    const headers = [["Date", "Received Fee", "Due Fee"]];

    // Table Rows
    const rows = paymentData.Payment.map((payment) => [
      payment.Date,
      payment.ReceivedFee,
      payment.DueFee,
    ]);

    doc.autoTable({
      startY: 100,
      head: headers,
      body: rows,
      styles: { halign: "center" },
      headStyles: { fillColor: "#0070C0" },
      alternateRowStyles: { fillColor: "#E0E0E0" },
    });

    const totalDue = paymentData.Payment[paymentData.Payment.length - 1].DueFee;

    doc.setFontSize(12);
    doc.setTextColor("#ff0000");
    doc.text(`Total Due: ${totalDue}`, 155, doc.lastAutoTable.finalY + 10);

    // Add Terms & Conditions
    doc.setTextColor("#0070C0");
    const finalY = doc.lastAutoTable.finalY + 20;
    doc.text("TERMS & CONDITIONS", 20, finalY + 10);

    doc.setFontSize(10);
    doc.text("1. Lorem ipsum dolor sit amet, consectetur adipiscing elit", 20, finalY + 20);
    doc.text("2. Lorem ipsum dolor sit amet, consectetur adipiscing elit", 20, finalY + 25);
    doc.text("3. Lorem ipsum dolor sit amet, consectetur adipiscing elit", 20, finalY + 30);

    // Add Footer
    doc.setFontSize(12);
    doc.text("0110 456 789", 20, finalY + 50);
    doc.text("business@email.com", 80, finalY + 50);
    doc.text("www.businessdomain.com", 140, finalY + 50);

    doc.save("invoice.pdf");
  };

  return (
    <TableContainer>
      <Title>Payment Details</Title>
      {paymentData && (
        <>
          <Info>
            <p>Student ID: {paymentData.StudentID}</p>
            <p>Course ID: {paymentData.CourseId}</p>
            <p>Franchise ID: {paymentData.FranchiseId}</p>
            <p>Total Fee: {paymentData.TotalFee}</p>
          </Info>
          <Table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Received Fee</th>
                <th>Due Fee</th>
              </tr>
            </thead>
            <tbody>
              {paymentData.Payment &&
                paymentData.Payment.map((payment) => (
                  <tr key={payment._id}>
                    <td>{payment.Date}</td>
                    <td>{payment.ReceivedFee}</td>
                    <td>{payment.DueFee}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <DownloadButton onClick={handleDownload}>Download Invoice</DownloadButton>
        </>
      )}
    </TableContainer>
  );
};

const Invoice = () => {
  const location = useLocation();
  const [paymentData, setPaymentData] = useState(null);
  const [studentData, setStudentData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { studentId } = location.state || {};

  useEffect(() => {
    if (studentId) {
      fetch(`https://ims-nv9i.onrender.com/fee/${studentId}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setPaymentData(data);
          return fetch(`https://ims-nv9i.onrender.com/students/${data.StudentID}`);
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((newData) => {
          setStudentData(newData);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, [studentId]);

  if (loading) return <Loading>Loading...</Loading>;
  if (error) return <Error>Error: {error.message}</Error>;

  return (
    <InvoiceContainer>
      <PaymentTable paymentData={paymentData} studentData={studentData} />
    </InvoiceContainer>
  );
};

// Styled Components
const InvoiceContainer = styled.div`
  padding: 20px;
  background: #f9f9f9;
  min-height: 100vh;
`;

const TableContainer = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  max-width: 800px;
`;

const Title = styled.h1`
  text-align: center;
  color: #0070C0;
  margin-bottom: 20px;
`;

const Info = styled.div`
  margin-bottom: 20px;
  p {
    margin: 5px 0;
    font-size: 16px;
  }
`;

const Table = styled.table`
  font-size: 14px;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  thead {
    background-color: #0070C0;
    color: #fff;
  }

  th, td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }

  tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tbody tr:hover {
    background-color: #ddd;
  }
`;

const DownloadButton = styled.button`
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Loading = styled.div`
  text-align: center;
  font-size: 18px;
  color: #0070C0;
`;

const Error = styled.div`
  text-align: center;
  font-size: 18px;
  color: #ff0000;
`;

export default Invoice;
