import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #f4f4f9;
  padding: 20px;
`;

const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
`;

const FormContainer = styled.div`
  /* margin: 20px; */
  width: 100%;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100%;
    padding: 15px;
  }
`;

const FormSection = styled.div`
  margin-bottom: 40px;
`;

const FormGroup = styled.div`
  flex: 1 1 calc(33.333% - 15px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
`;

const Label = styled.label`
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
`;

const InputField = styled.input`
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 16px;
  background: #fff;

  &:focus {
    border-color: #4a90e2;
    outline: none;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    width: 100%;
  }
`;

const FileInputField = styled.input`
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 18px;
  font-size: 16px;
  background: #fff;

  &:focus {
    border-color: #4a90e2;
    outline: none;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const SelectField = styled.select`
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 16px;
  background: #fff;
  box-sizing: border-box;

  &:focus {
    border-color: #4a90e2;
    outline: none;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    width: 100%;
  }
`;

const Option = styled.option`
  font-size: 16px;
  background: #fff;
`;

const SubmitButton = styled.button`
  flex: 1 1 100%;
  padding: 15px;
  background-color: #4a90e2;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #357abd;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Button = styled.button`
    background-color: #4a90e2;
    color: #fff;
    border: none;
  border-radius: 6px;
  padding: 10px;
    &:hover {
    background-color: #357abd;
  }
`;

const Error = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 4px;
`;

const SectionTitle = styled.h3`
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
  width: 100%;
`;


const ImagePreview = styled.img`
  height: 100px;
  width: max-content;
  margin-top: 10px;
  /* display: block; */
  `;

const Navbar = styled.div`
    height: 40px;
    gap: 10px;
    display: flex;
    justify-content: flex-end;
    `;

const TableContainer = styled.div`
  overflow-x: auto;
  margin: 20px 0;
`;

const Table = styled.table`
  width: 100%;
  font-size: 16px;
  border-collapse: collapse;
  margin-top: 20px;

  th, td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }

  th {
    background-color: #008cba;  // Updated color
    color: white;
  }

  tr:nth-child(even) {
    background-color: #f1f1f1;
  }
`;

const TableHeader = styled.th`
  background-color: #008cba;  // Updated color
  color: white;
  padding: 12px 15px;
  text-align: left;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px; // Slightly smaller text for smaller screens
  }
`;

const TableCell = styled.td`
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: left;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 12px; // Slightly smaller text for smaller screens
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  &:hover {
    background-color: #e1e1e1; // A lighter shade for hover effect
  }
`;

const NoDataRow = styled.tr`
  td {
    text-align: center;
    padding: 12px 15px;
    font-size: 14px;
    color: #888;
    @media (max-width: 768px) {
      font-size: 12px; // Slightly smaller text for smaller screens
    }
  }
`;


// Helper Function
const calculateGrade = (percentage) => {
  console.log(percentage)
  if (percentage >= 85) return 'A+';
  if (percentage >= 75) return 'A';
  if (percentage >= 65) return 'B';
  if (percentage >= 45) return 'C';
  return 'D';
};

// Main Component
const UpdateStudent = () => {
  const navigate = useNavigate();
  const { studentId } = useParams();
  const [data, setData] = useState({});
  const [student, setStudent] = useState({
    StudentId: '',
    StudentName: '',
    FatherName: '',
    MotherName: '',
    Gender: '',
    DOB: '',
    Qualification: '',
    AadharNo: '',
    MobileNo: '',
    EmailId: '',
    Address: '',
    City: '',
    State: '',
    Dist: '',
    PinCode: '',
    StudentPhoto: '',
    StudentSignature: '',
    AadharCard: '',
    QualificationFile: '',
  });

  const [markSheet, setMarkSheet] = useState({
    CertificateNo: '',
    Subjects: [],
    MarkSheetDate: '',
    Status: 'NA'
  });

  const [files, setFiles] = useState({
    StudentPhoto: null,
    StudentSignature: null,
    AadharCard: null,
    QualificationFile: null
  });

  useEffect(() => {
    const fetchStudent = async () => {
      try {
        const response = await axios.get(`https://ims-nv9i.onrender.com/students/get/${studentId}`);
        const data = response.data;
        console.log(data);
        setData(data);
        setStudent({
          StudentId: data.StudentId,
          StudentName: data.StudentName,
          FatherName: data.FatherName,
          MotherName: data.MotherName,
          Gender: data.Gender,
          DOB: data.DOB,
          Qualification: data.Qualification,
          AadharNo: data.AadharNo,
          MobileNo: data.MobileNo,
          EmailId: data.EmailId,
          Address: data.Address,
          City: data.City,
          State: data.State,
          Dist: data.Dist,
          PinCode: data.PinCode,
          StudentPhoto: data.Documents.StudentPhoto,
          StudentSignature: data.Documents.StudentSignature,
          AadharCard: data.Documents.AadharCard,
          QualificationFile: data.Documents.QualificationFile
        });
        setMarkSheet({
          CertificateNo: data.Course.MarkSheet.CertificateNo,
          Subjects: data.Course.MarkSheet.Subjects,
          MarkSheetDate: data.Course.MarkSheet.MarkSheetDate || '',
          Status: data.Course.MarkSheet.Status
        });
      } catch (error) {
        console.error('Error fetching student', error);
      }
    };

    fetchStudent();
  }, [studentId]);

  const handleStudentChange = (e) => {
    const { name, value } = e.target;
    setStudent(prevStudent => ({
      ...prevStudent,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFiles(prevFiles => ({
      ...prevFiles,
      [name]: files[0] || null
    }));
  };

  const handleMarkSheetChange = (e) => {
    const { name, value } = e.target;
    setMarkSheet(prevMarkSheet => ({
      ...prevMarkSheet,
      [name]: value
    }));
  };

  const handleSubjectChange = useCallback((index, e) => {
    const { name, value } = e.target;

    const updatedSubjects = markSheet.Subjects.map((subject, i) => {
      if (i === index) {
        const updatedSubject = { ...subject, [name]: value };
        const totalMarks = parseFloat(updatedSubject.TheoryMarks || 0) + parseFloat(updatedSubject.PracticalMarks || 0);
        updatedSubject.Grade = calculateGrade(totalMarks);
        return updatedSubject;
      }
      return subject;
    });

    setMarkSheet(prevMarkSheet => ({
      ...prevMarkSheet,
      Subjects: updatedSubjects
    }));
  }, [markSheet.Subjects]);

  const handleUpdateStudentSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    for (const key in student) {
      formData.append(key, student[key]);
    }
    for (const key in files) {
      if (files[key]) {
        formData.append(key, files[key]);
      }
    }

    try {
      await axios.put(`https://ims-nv9i.onrender.com/students/update/${studentId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert('Student updated successfully');
    } catch (error) {
      console.error('Error updating student', error);
      alert('Error updating student');
    }
  };

  const handleUpdateMarkSheetSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://ims-nv9i.onrender.com/students/updateMarkSheet`, {
        StudentId: studentId,
        CertificateNo: markSheet.CertificateNo,
        updatedSubjects: markSheet.Subjects,
        MarkSheetDate: markSheet.MarkSheetDate,
        Status: markSheet.Status
      });
      alert('Mark sheet updated successfully');
    } catch (error) {
      console.error('Error updating mark sheet', error);
      alert('Error updating mark sheet');
    }
  };
  return (
    <Container>
      <FormContainer>
        <Navbar>
          <Button onClick={() => navigate(`/idcard/${studentId}`)}>ID Card</Button>
          <Button onClick={() => navigate(`/certificate/${studentId}`)}>Certificate</Button>
          <Button onClick={() => navigate(`/marksheet/${studentId}`)}>MarkSheet</Button>
          {/* <Button>ID Card</Button> */}
        </Navbar>
        <h2>Update Student Details</h2>

        {/* Basic Student Update Form */}
        <FormSection>
          <Form onSubmit={handleUpdateStudentSubmit}>
            <FormGroup>
              <Label htmlFor="StudentId">Student Id</Label>
              <InputField
                type="text"
                id="StudentId"
                name="StudentId"
                value={student.StudentId}
                disabled
              />
            </FormGroup>
            <FormGroup style={{ alignItems: 'center' }}>
              {student.StudentPhoto && (
                <ImagePreview src={`https://ims-nv9i.onrender.com/uploads/${student.StudentPhoto}`} alt="Student Photo" />
              )}
              <Label htmlFor="StudentPhoto">Student Photo</Label>
              <FileInputField
                type="file"
                id="StudentPhoto"
                name="StudentPhoto"
                onChange={handleFileChange}
              />
            </FormGroup>
            <FormGroup style={{ alignItems: 'center' }}>
              {student.StudentSignature && (
                <ImagePreview src={`https://ims-nv9i.onrender.com/uploads/${student.StudentSignature}`} alt="Student Signature" />
              )}
              <Label htmlFor="StudentSignature">Student Signature</Label>
              <FileInputField
                type="file"
                id="StudentSignature"
                name="StudentSignature"
                onChange={handleFileChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="StudentName">Student Name</Label>
              <InputField
                type="text"
                id="StudentName"
                name="StudentName"
                value={student.StudentName}
                onChange={handleStudentChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="FatherName">Father Name</Label>
              <InputField
                type="text"
                id="FatherName"
                name="FatherName"
                value={student.FatherName}
                onChange={handleStudentChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="MotherName">Mother Name</Label>
              <InputField
                type="text"
                id="MotherName"
                name="MotherName"
                value={student.MotherName}
                onChange={handleStudentChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="Gender">Gender</Label>
              <SelectField
                id="Gender"
                name="Gender"
                value={student.Gender}
                onChange={handleStudentChange}
                required
              >
                <Option value="">Select Gender</Option>
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
                <Option value="Other">Other</Option>
              </SelectField>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="DOB">Date of Birth</Label>
              <InputField
                type="date"
                id="DOB"
                name="DOB"
                value={student.DOB}
                onChange={handleStudentChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="Qualification">Qualification</Label>
              <InputField
                type="text"
                id="Qualification"
                name="Qualification"
                value={student.Qualification}
                onChange={handleStudentChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="AadharNo">Aadhar Number</Label>
              <InputField
                type="text"
                id="AadharNo"
                name="AadharNo"
                value={student.AadharNo}
                onChange={handleStudentChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="MobileNo">Mobile Number</Label>
              <InputField
                type="text"
                id="MobileNo"
                name="MobileNo"
                value={student.MobileNo}
                onChange={handleStudentChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="EmailId">Email ID</Label>
              <InputField
                type="email"
                id="EmailId"
                name="EmailId"
                value={student.EmailId}
                onChange={handleStudentChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="Address">Address</Label>
              <InputField
                type="text"
                id="Address"
                name="Address"
                value={student.Address}
                onChange={handleStudentChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="City">City</Label>
              <InputField
                type="text"
                id="City"
                name="City"
                value={student.City}
                onChange={handleStudentChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="State">State</Label>
              <InputField
                type="text"
                id="State"
                name="State"
                value={student.State}
                onChange={handleStudentChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="Dist">District</Label>
              <InputField
                type="text"
                id="Dist"
                name="Dist"
                value={student.Dist}
                onChange={handleStudentChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="PinCode">Pin Code</Label>
              <InputField
                type="number"
                id="PinCode"
                name="PinCode"
                value={student.PinCode}
                onChange={handleStudentChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="AadharCard">Aadhar Card</Label>
              <FileInputField
                type="file"
                id="AadharCard"
                name="AadharCard"
                onChange={handleFileChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="QualificationFile">Qualification File</Label>
              <FileInputField
                type="file"
                id="QualificationFile"
                name="QualificationFile"
                onChange={handleFileChange}
              />
            </FormGroup>
            <SubmitButton type="submit">Update Student</SubmitButton>
          </Form>
        </FormSection>

        {/* Mark Sheet Update Form */}
        <FormSection>
          <SectionTitle>Update Mark Sheet</SectionTitle>
          <Form onSubmit={handleUpdateMarkSheetSubmit}>
            <Table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Full Marks</th>
                  <th>Theory Marks</th>
                  <th>Practical Marks</th>
                  <th>Grade</th>
                </tr>
              </thead>
              <tbody>
                {markSheet.Subjects.map((subject, index) => (
                  <tr key={subject._id}>
                    <td>{subject.Title}</td>
                    <td>{subject.FullMarks}</td>
                    <td>
                      <InputField
                        type="number"
                        name="TheoryMarks"
                        value={subject.TheoryMarks}
                        onChange={(e) => handleSubjectChange(index, e)}
                      />
                    </td>
                    <td>
                      <InputField
                        type="number"
                        name="PracticalMarks"
                        value={subject.PracticalMarks}
                        onChange={(e) => handleSubjectChange(index, e)}
                      />
                    </td>
                    <td>
                      <InputField
                        type="text"
                        name="Grade"
                        value={subject.Grade}
                        readOnly
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <FormGroup>
              <Label htmlFor="CertificateNo">Certificate Number</Label>
              <InputField
                type="text"
                id="CertificateNo"
                name="CertificateNo"
                value={markSheet.CertificateNo}
                onChange={handleMarkSheetChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="MarkSheetDate">Mark Sheet Date</Label>
              <InputField
                type="date"
                id="MarkSheetDate"
                name="MarkSheetDate"
                value={markSheet.MarkSheetDate}
                onChange={handleMarkSheetChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="Status">Status</Label>
              <SelectField
                id="Status"
                name="Status"
                value={markSheet.Status}
                onChange={handleMarkSheetChange}
              >
                <Option value="NA">Not Available</Option>
                <Option value="Requested">Requested</Option>
                <Option value="Approved">Approved</Option>
              </SelectField>
            </FormGroup>
            <SubmitButton type="submit">Update Mark Sheet</SubmitButton>
          </Form>
        </FormSection>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <TableHeader>Exam Id</TableHeader>
                <TableHeader>Exam Name</TableHeader>
                <TableHeader>Score</TableHeader>
                <TableHeader>Date</TableHeader>
              </tr>
            </thead>
            <tbody>
              {data?.Course?.Exam?.length > 0 ? (
                data.Course.Exam.map((ele, id) => (
                  <TableRow key={id}>
                    <TableCell>{ele.ExamId}</TableCell>
                    <TableCell>{ele.ExamName}</TableCell>
                    <TableCell>{ele.Score}</TableCell>
                    <TableCell>{new Date(ele.ExamDate).toLocaleDateString()}</TableCell>
                  </TableRow>
                ))
              ) : (
                <NoDataRow>
                  <TableCell colSpan="4">No data Available</TableCell>
                </NoDataRow>
              )}
            </tbody>
          </Table>
        </TableContainer>
      </FormContainer>
    </Container>
  );
};

export default UpdateStudent;
