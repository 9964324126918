import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddEmployeeAttendance = () => {
    const navigate = useNavigate();
    const [id, setId] = useState("");
    const [employees, setEmployees] = useState([]);
    const [attendance, setAttendance] = useState({});
    const [error, setError] = useState('');
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]); // Default to today's date

    useEffect(() => {
        const localdata = JSON.parse(localStorage.getItem("Data"));
        if (localdata) {
            if (localdata.EmployeeData) {
                setId(localdata.EmployeeData.FranchiseId);
            } else if (localdata.FranchiseData) {
                setId(localdata.FranchiseData.FranchiseId);
            } else {
                setId("Admin");
            }
        } else {
            setId("Admin");
        }

        const fetchEmployees = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/employees/all');
                if (!response.ok) {
                    throw new Error(`Error fetching employees: ${response.statusText}`);
                }
                const data = await response.json();
                const filteredData = data.filter(employee => employee.FranchiseId === id);
                setEmployees(filteredData);

                // Initialize attendance with default "Present" for all employees
                const initialAttendance = {};
                filteredData.forEach(employee => {
                    initialAttendance[employee.EmployeeID] = 'present';
                });
                setAttendance(initialAttendance);
            } catch (error) {
                console.error('Error fetching employees:', error);
                setError('Failed to load employees');
            }
        };

        fetchEmployees();
    }, [id]);

    const handleAttendanceChange = (employeeId, status) => {
        setAttendance(prev => ({
            ...prev,
            [employeeId]: status,
        }));
    };

    const handleDateChange = (event) => {
        setDate(event.target.value);
    };

    const handleSubmit = async () => {
        const presentEmployees = [];
        const absentEmployees = [];

        for (const [employeeId, status] of Object.entries(attendance)) {
            if (status === 'present') {
                presentEmployees.push(employeeId);
            } else if (status === 'absent') {
                absentEmployees.push(employeeId);
            }
        }

        const attendanceData = {
            Date: date,
            FranchiseId: id,
            Role: 'Employee',
            BatchId: '', // Add BatchId if applicable
            PresentStu: presentEmployees,
            AbsentStu: absentEmployees,
        };

        try {
            const response = await fetch('https://ims-nv9i.onrender.com/attendanc/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(attendanceData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to submit attendance');
            }

            const data = await response.json();
            console.log('Attendance saved:', data);
            toast.success('Attendance added successfully!');
            
            // Clear the input fields after successful submission
            setDate(new Date().toISOString().split('T')[0]);
            const clearedAttendance = {};
            employees.forEach(employee => {
                clearedAttendance[employee.EmployeeID] = 'present';
            });
            setAttendance(clearedAttendance);
        } catch (error) {
            console.error('Error saving attendance:', error);
            setError(error.message || 'Failed to save attendance');
        }
    };

    if (error) {
        return <Error>{error}</Error>;
    }

    if (employees.length === 0) {
        return <NoData>No data present</NoData>;
    }

    return (
        <Container>
            <Title>Employee Attendance</Title>
            <Card>
                <Subtitle>Franchise ID: {id}</Subtitle>
                <DateContainer>
                    <Label>Date:</Label>
                    <DateInput
                        type="date"
                        value={date}
                        onChange={handleDateChange}
                    />
                </DateContainer>
                <Subtitle>Employees</Subtitle>
                <Table>
                    <thead>
                        <tr>
                            <TableHeader>Employee ID</TableHeader>
                            <TableHeader>Name</TableHeader>
                            <TableHeader>Attendance</TableHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {employees.length > 0 ? (
                            employees.map((employee) => (
                                <TableRow key={employee.EmployeeID}>
                                    <TableData>{employee.EmployeeID}</TableData>
                                    <TableData>{employee.Name}</TableData>
                                    <TableData>
                                        <AttendanceContainer>
                                            <label>
                                                <RadioInput
                                                    type="radio"
                                                    name={`attendance-${employee.EmployeeID}`}
                                                    value="present"
                                                    checked={attendance[employee.EmployeeID] === 'present'}
                                                    onChange={() => handleAttendanceChange(employee.EmployeeID, 'present')}
                                                />
                                                Present
                                            </label>
                                            <label>
                                                <RadioInput
                                                    type="radio"
                                                    name={`attendance-${employee.EmployeeID}`}
                                                    value="absent"
                                                    checked={attendance[employee.EmployeeID] === 'absent'}
                                                    onChange={() => handleAttendanceChange(employee.EmployeeID, 'absent')}
                                                />
                                                Absent
                                            </label>
                                        </AttendanceContainer>
                                    </TableData>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableData colSpan="3">No employees found</TableData>
                            </TableRow>
                        )}
                    </tbody>
                </Table>
                <SubmitButton onClick={handleSubmit}>Submit Attendance</SubmitButton>
            </Card>
            <ToastContainer />
        </Container>
    );
};

// Styled-components
const Container = styled.div`
  width: 100%;
  margin: 20px;
  padding: 20px;
  text-align: center;
  background-color: #f7f9fc;
  border-radius: 5px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Subtitle = styled.h2`
  font-size: 18px;
  color: #555;
  margin-bottom: 15px;
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-size: 16px;
  color: #333;
  margin-right: 10px;
`;

const DateInput = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 100%;
  max-width: 250px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 16px;
`;

const TableHeader = styled.th`
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  text-align: center;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableData = styled.td`
  padding: 10px;
`;

const AttendanceContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-around;
`;

const RadioInput = styled.input`
  margin-right: 5px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #218838;
  }
`;

const Error = styled.p`
  color: red;
  font-weight: bold;
  margin-bottom: 20px;
`;

const NoData = styled.p`
  color: #888;
  font-size: 16px;
`;

export default AddEmployeeAttendance;
