import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Wallet = () => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [id, setId] = useState("");
    const [error, setError] = useState(null);
    const [amount, setAmount] = useState('');
    const [receiver, setReceiver] = useState('');
    const [message, setMessage] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        const localdata = JSON.parse(localStorage.getItem("Data"));
        if (localdata) {
            if (localdata.EmployeeData) {
                setId(localdata.EmployeeData.FranchiseId);
            } else if (localdata.FranchiseData) {
                setId(localdata.FranchiseData.FranchiseId);
            } else {
                setId("Admin");
            }
        } else {
            setId("Admin");
        }
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch('https://ims-nv9i.onrender.com/transactions/all');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const userTransactions = data.filter(transaction =>
                transaction.Sender === id || transaction.Receiver === id
            );
            userTransactions.reverse();

            const test = userTransactions.reduce((acc, transaction) => {
                if (transaction.Sender === id) {
                    return acc - transaction.Amount;
                } else {
                    return acc + transaction.Amount;
                }
            }, 0);

            setTotalAmount(test);
            setTransactions(userTransactions);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoadingSubmit(true);

        const newTransaction = {
            Sender: id,
            Receiver: receiver,
            Amount: amount,
            Message: message,
            Date: new Date().toISOString()
        };

        try {
            const response = await fetch('https://ims-nv9i.onrender.com/transactions/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newTransaction),
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.log(errorData)
                throw new Error(errorData.message || 'Network response was not ok');
            }

            fetchData();
            setReceiver('');
            setAmount('');
            setMessage('')
            setLoadingSubmit(false);
            toast.success('Transaction added successfully');
        } catch (error) {
            toast.error(error.message);
            toast.error('Failed to add transaction');
            setLoadingSubmit(false)
        }
    };

    if (loading) return <LoadingText>Loading...</LoadingText>;
    if (error) return <ErrorText>Error: {error}</ErrorText>;

    return (
        <Container>
            <Title>Transaction List</Title>

            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label htmlFor="receiver">Receiver:</Label>
                    <Input
                        type="text"
                        id="receiver"
                        value={receiver}
                        onChange={(e) => setReceiver(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="amount">Amount:</Label>
                    <Input
                        type="number"
                        id="amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="message">Message:</Label>
                    <Input
                        type="text"
                        id="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    />
                </FormGroup>
                <ButtonContainer>
                    {loadingSubmit ? <SubmitButton style={{ cursor: "not-allowed" }} disabled>Loading...</SubmitButton> : <SubmitButton type="submit">Add Transaction</SubmitButton>}
                </ButtonContainer>
            </Form>
            {id === "Admin" ? null : <TotalAmount>Total Amount: ₹ {totalAmount.toFixed(2)}</TotalAmount>}


            <Table>
                <thead>
                    <TableRow>
                        <TableHeader>Sr No</TableHeader>
                        <TableHeader>Sender</TableHeader>
                        <TableHeader>Receiver</TableHeader>
                        <TableHeader>Message</TableHeader>
                        <TableHeader>Amount</TableHeader>
                        <TableHeader>Date</TableHeader>
                    </TableRow>
                </thead>
                <tbody>
                    {transactions.length === 0 ? (
                        <TableRow>
                            <TableData colSpan="5">No transactions found</TableData>
                        </TableRow>
                    ) : (
                        transactions.map((transaction, index) => (
                            <TableRow key={transaction.id}>
                                <TableData>{index + 1}</TableData>
                                <TableData>{transaction.Sender}</TableData>
                                <TableData>{transaction.Receiver}</TableData>
                                <TableData>{transaction.Message}</TableData>
                                <TableData>₹ {transaction.Amount}</TableData>
                                <TableData>{new Date(transaction.Date).toLocaleDateString()}</TableData>
                            </TableRow>
                        ))
                    )}
                </tbody>
            </Table>

            <ToastContainer />
        </Container>
    );
};

// Styled Components
const Container = styled.div`
    padding: 20px;
    background: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: 20px;
`;

const Title = styled.h1`
    margin-bottom: 20px;
    color: #333;
`;

const Form = styled.form`
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
`;

const FormGroup = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
    font-size: 14px;
`;

const Input = styled.input`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
`;

const ButtonContainer = styled.div`
    display:flex;
    justify-content: center;
`;

const SubmitButton = styled.button`
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
        background-color: #0056b3;
    }
`;

const TotalAmount = styled.h2`
    color: #333;
    margin-bottom: 20px;
`;

const Table = styled.table`
    font-size:15px;
    text-align: center;
    width: 100%;
    border-collapse: collapse;
`;

const TableRow = styled.tr`
    border-bottom: 1px solid #ddd;
`;

const TableHeader = styled.th`
    padding: 10px;
    text-align: center;
    background: #008cba;
    color:#fff;
`;

const TableData = styled.td`
    padding: 10px;
`;

const LoadingText = styled.p`
    text-align: center;
    font-size: 18px;
    color: #666;
`;

const ErrorText = styled.p`
    text-align: center;
    font-size: 18px;
    color: #d9534f;
`;

export default Wallet;
