import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import Sidebar from "./EmployeeSidebar"; // Adjust the path as needed
import axios from "axios";

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  background-color: #f0f2f5;
`;

const Header = styled.header`
  height: 70px;
  background-color: #008cba;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  position: relative;
`;

const MainContent = styled.main`
  flex: 1;
  padding: 20px;
  overflow-x: auto;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const HamburgerButton = styled(FaBars)`
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 20px;
  display: ${(props) => (props.isOpen ? "none" : "block")};

  @media (min-width: 769px) {
    display: none;
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
  max-height: calc(100vh - 70px - 40px);
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 14px;

  th,
  td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    white-space: nowrap;
  }

  th {
    background-color: #008cba;
    color: #fff;
  }

  td {
    background-color: #f9f9f9;
  }
`;

const ViewButton = styled.button`
  background-color: #008cba;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #005f75;
  }
`;

const Modal = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  font-size: 16px;
  width: 90%;
  max-width: 800px;
  height: 90vh;
  overflow-y: auto;

  h2 {
    margin-top: 0;
    font-size: 22px;
  }

  p {
    margin: 8px 0;
    font-size: 14px;
  }

  button {
    background-color: #008cba;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #005f75;
    }
  }
`;

const ModalOverlay = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const EmployeeViewMaster = () => {
    const navigate =useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [franchiseData, setFranchiseData] = useState([]);
    const [selectedFranchise, setSelectedFranchise] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const openModal = (franchise) => {
        setSelectedFranchise(franchise);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedFranchise(null);
    };

    const handleViewDocument = (filename) => {
        const url = `https://ims-nv9i.onrender.com/uploads/${filename}`;
        window.open(url, '_blank');
    };

    const goTo = (id) => {
        navigate(`/atc/${id}`);
    };

    useEffect(() => {
        axios.get("https://ims-nv9i.onrender.com/franchises/all")
            .then(response => {
              const filterData = response.data.filter(data =>data.Approved === false)
              // console.log(filterData);
                setFranchiseData(filterData);
            })
            .catch(error => {
                console.error("There was an error fetching the franchise data!", error);
            });
    }, []);

    return (
        <DashboardContainer>
            <Header>
                Employee Dashboard
                <HamburgerButton isOpen={isSidebarOpen} onClick={toggleSidebar} />
            </Header>
            <Container>
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <MainContent>
                    <TableContainer>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Franchise ID</th>
                                    <th>Name</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Owner Name</th>
                                    <th>Email</th>
                                    <th>Mobile No</th>
                                    <th>Actions</th>
                                    <th>ATC</th>
                                </tr>
                            </thead>
                            <tbody>
                                {franchiseData.map((franchise) => (
                                    <tr key={franchise._id}>
                                        <td>{franchise.FranchiseId}</td>
                                        <td>{franchise.FranchiseName}</td>
                                        <td>{franchise.City}</td>
                                        <td>{franchise.State}</td>
                                        <td>{franchise.OwnerName}</td>
                                        <td>{franchise.Email}</td>
                                        <td>{franchise.MobileNo}</td>
                                        <td>
                                            <ViewButton onClick={() => openModal(franchise)}>
                                                View Details
                                            </ViewButton>
                                        </td>
                                        <td><ViewButton onClick={() => goTo(franchise.FranchiseId)}>View</ViewButton></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </TableContainer>
                    <ModalOverlay isOpen={isModalOpen} onClick={closeModal} />
                    <Modal isOpen={isModalOpen}>
                        {selectedFranchise && (
                            <>
                                <h2>Franchise Details</h2>
                                <p><strong>ID:</strong> {selectedFranchise.FranchiseId || "N/A"}</p>
                                <p><strong>Name:</strong> {selectedFranchise.FranchiseName || "N/A"}</p>
                                <p><strong>Address:</strong> {selectedFranchise.Address || "N/A"}</p>
                                <p><strong>City:</strong> {selectedFranchise.City || "N/A"}</p>
                                <p><strong>State:</strong> {selectedFranchise.State || "N/A"}</p>
                                <p><strong>Dist:</strong> {selectedFranchise.Dist || "N/A"}</p>
                                <p><strong>PinCode:</strong> {selectedFranchise.PinCode || "N/A"}</p>
                                <p><strong>Owner Name:</strong> {selectedFranchise.OwnerName || "N/A"}</p>
                                <p><strong>Email:</strong> {selectedFranchise.Email || "N/A"}</p>
                                <p><strong>Mobile No:</strong> {selectedFranchise.MobileNo || "N/A"}</p>
                                <p><strong>Plan:</strong> {selectedFranchise.Plan || "N/A"}</p>
                                <p><strong>Register Date:</strong> {selectedFranchise.RegisterDate || "N/A"}</p>
                                <p><strong>Expiry Date:</strong> {selectedFranchise.ExpiryDate || "N/A"}</p>
                                <p><strong>Master Franchise ID:</strong> {selectedFranchise.MasterFranchiseId || "N/A"}</p>
                                <p><strong>Approved:</strong> {selectedFranchise.Approved ? "Yes" : "No"}</p>
                                <p><strong>Requested:</strong> {selectedFranchise.Requested ? "Yes" : "No"}</p>
                                <p><strong>Active:</strong> {selectedFranchise.Active ? "Yes" : "No"}</p>
                                {selectedFranchise.DirectorOne ? <div>
                                    <h3>Director One</h3>
                                    <p><strong>Name:</strong> {selectedFranchise.DirectorOne?.Name || "N/A"}</p>
                                    <p><strong>Photo:</strong> {selectedFranchise.DirectorOne?.Photo ? <button onClick={() => handleViewDocument(selectedFranchise.DirectorOne.Photo)} className="view-document-button">View Document</button> : "N/A"}</p>
                                    <p><strong>Signature:</strong> {selectedFranchise.DirectorOne?.Signature ? <button onClick={() => handleViewDocument(selectedFranchise.DirectorOne.Signature)} className="view-document-button">View Document</button> : "N/A"}</p>
                                    <p><strong>Aadhar Card:</strong> {selectedFranchise.DirectorOne?.AadharCard ? <button onClick={() => handleViewDocument(selectedFranchise.DirectorOne.AadharCard)} className="view-document-button">View Document</button> : "N/A"}</p>
                                    <p><strong>Qualification:</strong> {selectedFranchise.DirectorOne?.Qualification ? <button onClick={() => handleViewDocument(selectedFranchise.DirectorOne.Qualification)} className="view-document-button">View Document</button> : "N/A"}</p>
                                    <p><strong>Agreement:</strong> {selectedFranchise.DirectorOne?.Agreement ? <button onClick={() => handleViewDocument(selectedFranchise.DirectorOne.Agreement)} className="view-document-button">View Document</button> : "N/A"}</p>
                                </div> : null}
                                {selectedFranchise.DirectorTwo ? <div>
                                    <h3>Director Two</h3>
                                    <p><strong>Name:</strong> {selectedFranchise.DirectorTwo?.Name || "N/A"}</p>
                                    <p><strong>Photo:</strong> {selectedFranchise.DirectorTwo?.Photo ? <button onClick={() => handleViewDocument(selectedFranchise.DirectorTwo.Photo)} className="view-document-button">View Document</button> : "N/A"}</p>
                                    <p><strong>Signature:</strong> {selectedFranchise.DirectorTwo?.Signature ? <button onClick={() => handleViewDocument(selectedFranchise.DirectorTwo.Signature)} className="view-document-button">View Document</button> : "N/A"}</p>
                                    <p><strong>Aadhar Card:</strong> {selectedFranchise.DirectorTwo?.AadharCard ? <button onClick={() => handleViewDocument(selectedFranchise.DirectorTwo.AadharCard)} className="view-document-button">View Document</button> : "N/A"}</p>
                                    <p><strong>Qualification:</strong> {selectedFranchise.DirectorTwo?.Qualification ? <button onClick={() => handleViewDocument(selectedFranchise.DirectorTwo.Qualification)} className="view-document-button">View Document</button> : "N/A"}</p>
                                    <p><strong>Agreement:</strong> {selectedFranchise.DirectorTwo?.Agreement ? <button onClick={() => handleViewDocument(selectedFranchise.DirectorTwo.Agreement)} className="view-document-button">View Document</button> : "N/A"}</p>
                                </div> : null}
                                {selectedFranchise.DirectorThree ? <div>
                                    <h3>Director Three</h3>
                                    <p><strong>Name:</strong> {selectedFranchise.DirectorThree?.Name || "N/A"}</p>
                                    <p><strong>Photo:</strong> {selectedFranchise.DirectorThree?.Photo ? <button onClick={() => handleViewDocument(selectedFranchise.DirectorThree.Photo)} className="view-document-button">View Document</button> : "N/A"}</p>
                                    <p><strong>Signature:</strong> {selectedFranchise.DirectorThree?.Signature ? <button onClick={() => handleViewDocument(selectedFranchise.DirectorThree.Signature)} className="view-document-button">View Document</button> : "N/A"}</p>
                                    <p><strong>Aadhar Card:</strong> {selectedFranchise.DirectorThree?.AadharCard ? <button onClick={() => handleViewDocument(selectedFranchise.DirectorThree.AadharCard)} className="view-document-button">View Document</button> : "N/A"}</p>
                                    <p><strong>Qualification:</strong> {selectedFranchise.DirectorThree?.Qualification ? <button onClick={() => handleViewDocument(selectedFranchise.DirectorThree.Qualification)} className="view-document-button">View Document</button> : "N/A"}</p>
                                    <p><strong>Agreement:</strong> {selectedFranchise.DirectorThree?.Agreement ? <button onClick={() => handleViewDocument(selectedFranchise.DirectorThree.Agreement)} className="view-document-button">View Document</button> : "N/A"}</p>
                                </div> : null}
                                {selectedFranchise.Formats ?
                                    <div>
                                        <h3>Formats</h3>
                                        <p><strong>ATC:</strong> {selectedFranchise.Formats?.ATC ? <button onClick={() => handleViewDocument(selectedFranchise.Formats.ATC)} className="view-document-button">View Document</button> : "N/A"}</p>
                                        <p><strong>MarkSheet:</strong> {selectedFranchise.Formats?.MarkSheet ? <button onClick={() => handleViewDocument(selectedFranchise.Formats.MarkSheet)} className="view-document-button">View Document</button> : "N/A"}</p>
                                        <p><strong>Certificate:</strong> {selectedFranchise.Formats?.Certificate ? <button onClick={() => handleViewDocument(selectedFranchise.Formats.Certificate)} className="view-document-button">View Document</button> : "N/A"}</p>
                                    </div>
                                    : null}
                                <button onClick={closeModal}>Close</button>
                            </>
                        )}
                    </Modal>
                </MainContent>
            </Container>
        </DashboardContainer>
    );
};

export default EmployeeViewMaster;
