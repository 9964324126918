import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PaymentModal from './AddPayment';

// Container for the entire page
const Container = styled.div`
    padding: 20px;
    margin: 20px;
    width: 100%;
    background-color: #f4f4f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

// Heading style
const Heading = styled.h1`
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
`;

// Table styles
const Table = styled.table`
font-size: 15px;
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const TableHeader = styled.th`
    border: 1px solid #ddd;
    padding: 12px;
    background-color: #008cba;
    color: #fff;
    text-align: left;
`;

const TableData = styled.td`
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    color: #555;
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #f9f9f9;
    }

    &:hover {
        background-color: #f1f1f1;
    }
`;

// Button style
const Button = styled.button`
    padding: 8px 16px;
    background-color: #28a745;
    color: white;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #218838;
    }

    &:disabled {
        background-color: #d6d6d6;
        cursor: not-allowed;
    }
`;

// Error and loading styles
const Error = styled.div`
    color: #dc3545;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
`;

const Loading = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
    color: #007bff;
`;

const NoData = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #6c757d;
`;

// Payments component
const AddPaymentList = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);

    const fetchData = async () => {
        var Id;
        const localdata = JSON.parse(localStorage.getItem("Data"));
        if (localdata) {
            if (localdata.EmployeeData) {
                Id=(localdata.EmployeeData.FranchiseId);
            } else if (localdata.FranchiseData) {
                Id=(localdata.FranchiseData.FranchiseId);
            } else {
                Id=("Admin");
            }
        } else {
            Id=("Admin");
        }

        try {
            const response = await fetch('https://ims-nv9i.onrender.com/fee/all'); // Updated API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const filData = data.filter((item) => Id === item.FranchiseId);
            setData(filData);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handlePayClick = (record) => {
        setCurrentRecord(record);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setCurrentRecord(null);
    };

    const handlePaymentSuccess = () => {
        fetchData();
        handleModalClose();
        // Navigate to the Invoice page with currentRecord details
        if (currentRecord) {
            navigate('/invoice', { state: { paymentData: currentRecord } });
        }
    };

    if (loading) {
        return <Loading>Loading...</Loading>;
    }

    if (error) {
        return <Error>Error: {error.message}</Error>;
    }

    if (data.length === 0) {
        return <NoData>No current Data</NoData>;
    }

    return (
        <Container>
            <Heading>Payment Data</Heading>
            <Table>
                <thead>
                    <tr>
                        <TableHeader>Student ID</TableHeader>
                        <TableHeader>Course ID</TableHeader>
                        <TableHeader>Total Fee</TableHeader>
                        <TableHeader>Payment Received</TableHeader>
                        <TableHeader>Due Fee</TableHeader>
                        <TableHeader>Pay</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {data.map(record => (
                        <TableRow key={record._id}>
                            <TableData>{record.StudentID}</TableData>
                            <TableData>{record.CourseId}</TableData>
                            <TableData>{record.TotalFee}</TableData>
                            <TableData>{(record.TotalFee) - (record.Payment[record.Payment.length - 1].DueFee)}</TableData>
                            <TableData>{record.Payment[record.Payment.length - 1].DueFee}</TableData>
                            <TableData>
                                {record.Payment[record.Payment.length - 1].DueFee === 0 ? "Paid" :
                                    <Button onClick={() => handlePayClick(record)}>
                                        Pay
                                    </Button>}
                            </TableData>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
            {modalOpen && currentRecord && (
                <PaymentModal
                    isOpen={modalOpen}
                    onClose={handleModalClose}
                    onSubmit={handlePaymentSuccess}
                    record={currentRecord}
                />
            )}
        </Container>
    );
};

export default AddPaymentList;
