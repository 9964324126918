import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddReward = () => {
    const [rewards, setRewards] = useState([]);
    const [formData, setFormData] = useState({
        Level: '',
        FranchiseNo: '',
        StudentNo: '',
        BonusAmount: '',
        Tour: ''
    });
    const [isEditing, setIsEditing] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    useEffect(() => {
        const fetchRewards = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/reward/all');
                const data = await response.json();
                setRewards(data);
            } catch (error) {
                console.error('Error fetching rewards:', error);
            }
        };

        fetchRewards();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'FranchiseNo' || name === 'StudentNo' || name === 'BonusAmount') {
            // Allow only digits
            if (/^\d*$/.test(value)) {
                setFormData({
                    ...formData,
                    [name]: value,
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEditing && editingId) {
                // Update reward using PATCH
                const response = await fetch(`https://ims-nv9i.onrender.com/reward/update/${editingId}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });
                if (response.ok) {
                    const updatedReward = await response.json();
                    setRewards(rewards.map(reward => (reward._id === editingId ? updatedReward : reward)));
                    toast.success('Reward updated successfully');
                } else {
                    toast.error('Failed to update reward');
                }
            } else {
                // Add new reward using POST
                const response = await fetch('https://ims-nv9i.onrender.com/reward/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });
                if (response.ok) {
                    const newReward = await response.json();
                    setRewards([...rewards, newReward]);
                    toast.success('Reward added successfully');
                } else {
                    toast.error('Failed to add reward');
                }
            }
            setIsEditing(false);
            setEditingId(null);
            setFormData({
                Level: '',
                FranchiseNo: '',
                StudentNo: '',
                BonusAmount: '',
                Tour: ''
            });
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleEdit = (reward) => {
        setIsEditing(true);
        setEditingId(reward._id);
        setFormData({
            Level: reward.Level,
            FranchiseNo: reward.FranchiseNo,
            StudentNo: reward.StudentNo,
            BonusAmount: reward.BonusAmount,
            Tour: reward.Tour
        });
    };

    const openModal = (id) => {
        setDeleteId(id);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setDeleteId(null);
    };

    const handleDelete = async () => {
        try {
            const response = await fetch(`https://ims-nv9i.onrender.com/reward/delete/${deleteId}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                setRewards(rewards.filter(reward => reward._id !== deleteId));
                toast.success('Reward deleted successfully');
            } else {
                toast.error('Failed to delete reward');
            }
        } catch (error) {
            console.error('Error deleting reward:', error);
        } finally {
            closeModal();
        }
    };

    return (
        <Container>
            <Header>{isEditing ? 'Edit Reward' : 'Add Reward'}</Header>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label>Level</Label>
                    <Input type="text" name="Level" value={formData.Level} onChange={handleChange} required />
                </FormGroup>
                <FormGroup>
                    <Label>Franchise No</Label>
                    <Input type="text" name="FranchiseNo" value={formData.FranchiseNo} onChange={handleChange} required />
                </FormGroup>
                <FormGroup>
                    <Label>Student No</Label>
                    <Input type="text" name="StudentNo" value={formData.StudentNo} onChange={handleChange} required />
                </FormGroup>
                <FormGroup>
                    <Label>Bonus Amount</Label>
                    <Input type="text" name="BonusAmount" value={formData.BonusAmount} onChange={handleChange} required />
                </FormGroup>
                <FormGroup>
                    <Label>Tour</Label>
                    <Input type="text" name="Tour" value={formData.Tour} onChange={handleChange} required />
                </FormGroup>
                <ButtonGroup>
                    <Button type="submit">{isEditing ? 'Update Reward' : 'Add Reward'}</Button>
                    <Button type="button" onClick={() => setIsEditing(false)}>Clear</Button>
                </ButtonGroup>
            </Form>
            <Table>
                <thead>
                    <TableRow>
                        <TableHeader>Level</TableHeader>
                        <TableHeader>Franchise No</TableHeader>
                        <TableHeader>Student No</TableHeader>
                        <TableHeader>Bonus Amount</TableHeader>
                        <TableHeader>Tour</TableHeader>
                        <TableHeader>Actions</TableHeader>
                    </TableRow>
                </thead>
                <tbody>
                    {rewards.map((reward) => (
                        <TableRow key={reward._id}>
                            <TableData>{reward.Level}</TableData>
                            <TableData>{reward.FranchiseNo}</TableData>
                            <TableData>{reward.StudentNo}</TableData>
                            <TableData>{reward.BonusAmount}</TableData>
                            <TableData>{reward.Tour}</TableData>
                            <TableData>
                                <ActionButton onClick={() => handleEdit(reward)}>Edit</ActionButton>
                                <ActionButton onClick={() => openModal(reward._id)}>Delete</ActionButton>
                            </TableData>
                        </TableRow>
                    ))}
                </tbody>
            </Table>

            {/* Confirmation Modal */}
            {isModalOpen && (
                <ModalOverlay>
                    <ModalContent>
                        <ModalHeader>Confirm Deletion</ModalHeader>
                        <ModalBody>Are you sure you want to delete this reward?</ModalBody>
                        <ModalFooter>
                            <ModalButton onClick={handleDelete}>Yes, Delete</ModalButton>
                            <ModalButton onClick={closeModal}>Cancel</ModalButton>
                        </ModalFooter>
                    </ModalContent>
                </ModalOverlay>
            )}

            <ToastContainer />
        </Container>
    );
};

// Styled Components
const Container = styled.div`
    padding: 20px;
    background: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: 20px;
`;

const Header = styled.h1`
    margin-bottom: 20px;
    color: #333;
`;

const Form = styled.form`
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
`;

const FormGroup = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
    font-size: 14px;
`;

const Input = styled.input`
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 10px;
`;

const Button = styled.button`
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
        background-color: #0056b3;
    }
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size:15px;
`;

const TableRow = styled.tr`
    border-bottom: 1px solid #ddd;
`;

const TableHeader = styled.th`
    padding: 10px;
    text-align: center;
    background: #008cba;
    color:#fff;
`;

const TableData = styled.td`
    padding: 10px;
`;

const ActionButton = styled.button`
    background-color: ${props => props.children === 'Edit' ? '#28a745' : '#dc3545'};
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-right: 5px;

    &:hover {
        background-color: ${props => props.children === 'Edit' ? '#218838' : '#c82333'};
    }
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ModalContent = styled.div`
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 100%;
`;

const ModalHeader = styled.h2`
    margin: 0 0 10px;
    font-size: 18px;
    color: #333;
`;

const ModalBody = styled.p`
    margin: 0 0 20px;
    color: #555;
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;

const ModalButton = styled.button`
    background-color: ${props => props.children === 'Yes, Delete' ? '#dc3545' : '#6c757d'};
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
        background-color: ${props => props.children === 'Yes, Delete' ? '#c82333' : '#5a6268'};
    }
`;

export default AddReward;
