// StudentNotification.js
import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import Sidebar from "./StudentSidebar"; // Update with the correct path to your Sidebar component

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f2f5;
  font-family: Arial, sans-serif;
`;

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  background-color: #f0f2f5;
`;

const Header = styled.header`
   height: 70px;
  background-color: #008cba;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const MainContent = styled.main`
  display: flex;
  flex: 1;
  padding: 20px;
  
  @media (max-width: 768px) {
    margin-left: 0;
    padding: 20px 10px;
  }
`;

const HamburgerButton = styled(FaBars)`
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: ${(props) => (props.isOpen ? "none" : "block")};

  @media (min-width: 769px) {
    display: none;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
`;

const Heading = styled.h2`
  text-align: center;
  color: #008cba;
  margin-bottom: 2rem;
  font-size: 20px;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Card = styled.div`
  border: 1px solid #ddd;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const CardTitle = styled.h3`
  margin: 0 0 1rem 0;
  color: #008cba;
  font-size: 16px;
`;

const CardContent = styled.p`
  margin: 0.5rem 0;
  color: #333;
  font-size: 13px;
`;

const StudentNotification = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [role, setRole] = useState("");

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const Data = JSON.parse(localStorage.getItem("Data"));
    setRole(Data.Role);

    const fetchNotifications = async () => {
      try {
        const response = await fetch('https://ims-nv9i.onrender.com/notification/all');
        const data = await response.json();
        setNotifications(data);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, []);

  const filteredNotifications = notifications.filter(notification => notification.Receiver === role);

  return (
    <DashboardContainer>
      <Header>
        Student Dashboard
        <HamburgerButton isOpen={isSidebarOpen} onClick={toggleSidebar} />
      </Header>
      <Container>
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <MainContent>
          <MainContainer>
            <Heading>Notifications</Heading>
            <CardsContainer>
              {filteredNotifications.map((notification) => (
                <Card key={notification._id}>
                  <CardTitle>{notification.Heading}</CardTitle>
                  <CardContent>
                    <strong>Receiver:</strong> {notification.Receiver}
                  </CardContent>
                  <CardContent>
                    <strong>Message:</strong> {notification.Message}
                  </CardContent>
                </Card>
              ))}
            </CardsContainer>
          </MainContainer>
        </MainContent>
      </Container>
    </DashboardContainer>
  );
};

export default StudentNotification;