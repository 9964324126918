import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import statesData from '../assets/state.json';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Styled Components
const FormContainer = styled.div`
    /* width: 90%; */
    margin: 20px;
    padding: 20px;
    /* max-width: 1200px; */
    background: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        width: 100%;
        padding: 15px;
    }
`;

const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
`;

const FormGroup = styled.div`
    flex: 1 1 calc(33.333% - 15px);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    @media (max-width: 768px) {
        flex: 1 1 100%;
    }
`;

const Label = styled.label`
    font-size: 14px;
    color: #333;
    margin-bottom: 8px;
`;

const InputField = styled.input`
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 20px;
    font-size: 16px;
    background: #fff;

    &:focus {
        border-color: #4a90e2;
        outline: none;
    }

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const FileInputField = styled.input`
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 18px;
    font-size: 16px;
    background: #fff;

    &:focus {
        border-color: #4a90e2;
        outline: none;
    }

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const SubmitButton = styled.button`
    flex: 1 1 100%;
    padding: 15px;
    background-color: #4a90e2;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #357abd;
    }

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Error = styled.div`
    color: red;
    font-size: 14px;
    margin-top: 4px;
`;

const SectionTitle = styled.h3`
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
    width: 100%;
`;

const SelectField = styled.select`
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 20px;
    font-size: 16px;
    background: #fff;
    box-sizing: border-box;

    &:focus {
        border-color: #4a90e2;
        outline: none;
    }

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Option = styled.option`
    font-size: 16px;
    background: #fff;
`;


// Main Component
const AddStudentForm = () => {
    const [formData, setFormData] = useState({
        StudentId: "",
        StudentName: "",
        FatherName: "",
        MotherName: "",
        Gender: "",
        DOB: "",
        Qualification: "",
        AadharNo: "",
        MobileNo: "",
        EmailId: "",
        Address: "",
        City: "",
        State: "",
        Dist: "",
        PinCode: "",
        FranchiseId: "",
        FranchiseName: "",
        fee: "",
        CourseId: "",
        CourseName: "",
        BatchName: "",
        BatchId: "",
        Plan: "Plan-A"
    });

    const [courses, setCourses] = useState([]);
    const [batches, setBatches] = useState([]);
    const [filteredBatches, setFilteredBatches] = useState([]);
    const [fileData, setFileData] = useState({
        StudentPhoto: null,
        StudentSignature: null,
        AadharCard: null,
        QualificationFile: null
    });
    const [id, setId] = useState('');
    const [states, setStates] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');

    useEffect(() => {
        const localdata = JSON.parse(localStorage.getItem("Data"));
        if (localdata) {
            if (localdata.EmployeeData) {
                setId(localdata.EmployeeData.FranchiseId);
                axios
                    .get(`https://ims-nv9i.onrender.com/franchises/${localdata.EmployeeData.FranchiseId}`)
                    .then((response) => {
                        setFormData(prevState => ({
                            ...prevState,
                            Plan: response.data.Plan,
                        }));
                    })
                    .catch((error) => {
                        console.error("There was an error fetching the student data!", error);
                    });
                setFormData(prevState => ({
                    ...prevState,
                    FranchiseId: localdata.EmployeeData.FranchiseId,
                    FranchiseName: localdata.EmployeeData.FranchiseName
                }));
            } else if (localdata.FranchiseData) {
                setId(localdata.FranchiseData.FranchiseId);
                setFormData(prevState => ({
                    ...prevState,
                    FranchiseId: localdata.FranchiseData.FranchiseId,
                    FranchiseName: localdata.FranchiseData.FranchiseName,
                    Plan: localdata.FranchiseData.Plan
                }));
            } else {
                setId("Admin");
                setFormData(prevState => ({
                    ...prevState,
                    FranchiseId: "Admin",
                    FranchiseName: "Admin"
                }));
            }
        } else {
            setId("Admin");
            setFormData(prevState => ({
                ...prevState,
                FranchiseId: "Admin",
                FranchiseName: "Admin"
            }));
        }
    }, []);

    useEffect(() => {
        axios.get('https://ims-nv9i.onrender.com/courses/all')
            .then(response => {
                setCourses(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the courses!", error);
            });
    }, []);

    useEffect(() => {
        axios.get('https://ims-nv9i.onrender.com/batch/all')
            .then(response => {
                const filterData = response.data.filter(data => data.FranchiseId === id);
                setBatches(filterData);
            })
            .catch(error => {
                console.error("There was an error fetching the batches!", error);
            });
    }, [id]);

    useEffect(() => {
        setStates(statesData.states);
    }, []);

    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};

        // Basic validation rules
        if (!formData.StudentName) newErrors.StudentName = 'Student Name is required';
        if (!formData.FatherName) newErrors.FatherName = 'Father Name is required';
        if (!formData.MotherName) newErrors.MotherName = 'Mother Name is required';
        if (!formData.Gender) newErrors.Gender = 'Gender is required';
        if (!formData.DOB) newErrors.DOB = 'Date of Birth is required';
        if (!formData.MobileNo) newErrors.MobileNo = 'Mobile No is required';
        if (!/^\d{10}$/.test(formData.MobileNo)) newErrors.MobileNo = 'Mobile No must be 10 digits';
        if (!formData.EmailId) newErrors.EmailId = 'Email ID is required';
        if (!/\S+@\S+\.\S+/.test(formData.EmailId)) newErrors.EmailId = 'Email ID is invalid';
        if (!formData.Address) newErrors.Address = 'Address is required';
        if (!formData.City) newErrors.City = 'City is required';
        if (!formData.State) newErrors.State = 'State is required';
        if (!formData.Dist) newErrors.Dist = 'District is required';
        if (!formData.PinCode) newErrors.PinCode = 'Pin Code is required';
        if (!/^\d{6}$/.test(formData.PinCode)) newErrors.PinCode = 'Pin Code must be 6 digits';
        if (!formData.AadharNo) newErrors.AadharNo = 'Aadhar Number is required';
        if (!/^\d{12}$/.test(formData.AadharNo)) newErrors.AadharNo = 'Aadhar Number must be 12 digits';
        if (!formData.CourseName) newErrors.CourseName = 'Course is required';
        if (!formData.BatchName) newErrors.BatchName = 'Batch is required';
        if (!formData.fee) newErrors.fee = 'Fee is required';
        if (formData.fee && isNaN(formData.fee)) newErrors.fee = 'Fee must be a number';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleFileChange = (e) => {
        setFileData({
            ...fileData,
            [e.target.name]: e.target.files[0]
        });
    };

    const handleStateChange = (e) => {
        const state = e.target.value;
        setSelectedState(state);
        setFormData(prevState => ({
            ...prevState,
            State: state
        }));

        const stateData = states.find(s => s.state === state);
        if (stateData) {
            setDistricts(stateData.districts);
            setSelectedDistrict('');
            setFormData(prevState => ({
                ...prevState,
                Dist: ''
            }));
        } else {
            setDistricts([]);
        }
    };

    const handleDistrictChange = (e) => {
        const district = e.target.value;
        setSelectedDistrict(district);
        setFormData(prevState => ({
            ...prevState,
            Dist: district
        }));
    };

    const handleCourseChange = (e) => {
        const selectedCourseName = e.target.value;
        setFormData(prevState => ({
            ...prevState,
            CourseName: selectedCourseName,
            CourseId: courses.find(course => course.CourseName === selectedCourseName)?.CourseId || ""
        }));

        // Filter batches based on the selected course
        const filtered = batches.filter(batch => batch.Course === selectedCourseName);
        setFilteredBatches(filtered);
    };

    const handleBatchChange = (e) => {
        const selectedBatchName = e.target.value;
        console.log(filteredBatches.find(batch => batch.BatchName === selectedBatchName)?.BatchId)
        setFormData(prevState => ({
            ...prevState,
            BatchName: selectedBatchName,
            BatchId: filteredBatches.find(batch => batch.BatchName === selectedBatchName)?.BatchId || ""
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return; // If validation fails, don't submit
        }

        const data = new FormData();
        Object.keys(formData).forEach((key) => {
            data.append(key, formData[key]);
        });

        Object.keys(fileData).forEach((key) => {
            if (fileData[key]) {
                data.append(key, fileData[key]);
            }
        });

        try {
            const response = await axios.post('https://ims-nv9i.onrender.com/students/add', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Student added:', response.data);
            toast.success('Student added successfully!');
            setFormData({
                StudentId: "",
                StudentName: "",
                FatherName: "",
                MotherName: "",
                Gender: "",
                DOB: "",
                Qualification: "",
                AadharNo: "",
                MobileNo: "",
                EmailId: "",
                Address: "",
                City: "",
                State: "",
                Dist: "",
                PinCode: "",
                FranchiseId: "",
                FranchiseName: "",
                fee: "",
                CourseId: "",
                CourseName: "",
                BatchName: "",
                BatchId: "",
                Plan: "Plan-A"
            });
            setFileData({
                StudentPhoto: null,
                StudentSignature: null,
                AadharCard: null,
                QualificationFile: null
            });
        } catch (error) {
            console.error("There was an error submitting the form!", error);
            toast.error('Error adding student.');
        }
    };

    return (
        <FormContainer>
            <Form onSubmit={handleSubmit}>
                <SectionTitle>Personal Information</SectionTitle>
                <FormGroup>
                    <Label htmlFor="StudentName">Student Name</Label>
                    <InputField type="text" name="StudentName" placeholder="Student Name" onChange={handleInputChange} value={formData.StudentName} />
                    {errors.StudentName && <Error>{errors.StudentName}</Error>}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="FatherName">Father Name</Label>
                    <InputField type="text" name="FatherName" placeholder="Father Name" onChange={handleInputChange} value={formData.FatherName} />
                    {errors.FatherName && <Error>{errors.FatherName}</Error>}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="MotherName">Mother Name</Label>
                    <InputField type="text" name="MotherName" placeholder="Mother Name" onChange={handleInputChange} value={formData.MotherName} />
                    {errors.MotherName && <Error>{errors.MotherName}</Error>}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="Gender">Gender</Label>
                    <SelectField name="Gender" onChange={handleInputChange} value={formData.Gender}>
                        <Option value="">Select Gender</Option>
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                        <Option value="Other">Other</Option>
                    </SelectField>
                    {errors.Gender && <Error>{errors.Gender}</Error>}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="DOB">Date of Birth</Label>
                    <InputField type="date" name="DOB" onChange={handleInputChange} value={formData.DOB} />
                    {errors.DOB && <Error>{errors.DOB}</Error>}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="Qualification">Qualification</Label>
                    <InputField type="text" name="Qualification" placeholder="Qualification" onChange={handleInputChange} value={formData.Qualification} />
                    {errors.Qualification && <Error>{errors.Qualification}</Error>}
                </FormGroup>
                <SectionTitle>Contact Information</SectionTitle>
                <FormGroup>
                    <Label htmlFor="MobileNo">Mobile No</Label>
                    <InputField type="text" name="MobileNo" placeholder="Mobile No" onChange={handleInputChange} value={formData.MobileNo} />
                    {errors.MobileNo && <Error>{errors.MobileNo}</Error>}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="EmailId">Email ID</Label>
                    <InputField type="email" name="EmailId" placeholder="Email ID" onChange={handleInputChange} value={formData.EmailId} />
                    {errors.EmailId && <Error>{errors.EmailId}</Error>}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="AadharNo">Aadhar Number</Label>
                    <InputField type="text" name="AadharNo" placeholder="Aadhar Number" onChange={handleInputChange} value={formData.AadharNo} />
                    {errors.AadharNo && <Error>{errors.AadharNo}</Error>}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="Address">Address</Label>
                    <InputField type="text" name="Address" placeholder="Address" onChange={handleInputChange} value={formData.Address} />
                    {errors.Address && <Error>{errors.Address}</Error>}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="State">State</Label>
                    <SelectField name="State" onChange={handleStateChange} value={selectedState}>
                        <Option value="">Select State</Option>
                        {states.map((state, index) => (
                            <Option key={index} value={state.state}>{state.state}</Option>
                        ))}
                    </SelectField>
                    {errors.State && <Error>{errors.State}</Error>}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="Dist">District</Label>
                    <SelectField name="Dist" onChange={handleDistrictChange} value={selectedDistrict}>
                        <Option value="">Select District</Option>
                        {districts.map((district, index) => (
                            <Option key={index} value={district}>{district}</Option>
                        ))}
                    </SelectField>
                    {errors.Dist && <Error>{errors.Dist}</Error>}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="City">City</Label>
                    <InputField type="text" name="City" placeholder="City" onChange={handleInputChange} value={formData.City} />
                    {errors.City && <Error>{errors.City}</Error>}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="PinCode">Pin Code</Label>
                    <InputField type="text" name="PinCode" placeholder="Pin Code" onChange={handleInputChange} value={formData.PinCode} />
                    {errors.PinCode && <Error>{errors.PinCode}</Error>}
                </FormGroup>
                <SectionTitle>Course and Batch</SectionTitle>
                <FormGroup>
                    <Label htmlFor="CourseName">Course</Label>
                    <SelectField name="CourseName" onChange={handleCourseChange} value={formData.CourseName}>
                        <Option value="">Select Course</Option>
                        {courses.map((course, index) => (
                            <Option key={index} value={course.CourseName}>{course.CourseName}</Option>
                        ))}
                    </SelectField>
                    {errors.CourseName && <Error>{errors.CourseName}</Error>}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="BatchName">Batch</Label>
                    <SelectField name="BatchName" onChange={handleBatchChange} value={formData.BatchName}>
                        <Option value="">Select Batch</Option>
                        {filteredBatches.map((batch, index) => (
                            <Option key={index} value={batch.BatchName}>{batch.BatchName}</Option>
                        ))}
                    </SelectField>
                    {errors.BatchName && <Error>{errors.BatchName}</Error>}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="StudentPhoto">Student Photo</Label>
                    <FileInputField type="file" name="StudentPhoto" onChange={handleFileChange} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="StudentSignature">Student Signature</Label>
                    <FileInputField type="file" name="StudentSignature" onChange={handleFileChange} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="AadharCard">Aadhar Card</Label>
                    <FileInputField type="file" name="AadharCard" onChange={handleFileChange} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="QualificationFile">Qualification File</Label>
                    <FileInputField type="file" name="QualificationFile" onChange={handleFileChange} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="fee">Fee</Label>
                    <InputField type="text" name="fee" placeholder="Fee" onChange={handleInputChange} value={formData.fee} />
                    {errors.fee && <Error>{errors.fee}</Error>}
                </FormGroup>
                <SubmitButton type="submit">Submit</SubmitButton>
            </Form>
            <ToastContainer />
        </FormContainer>
    );
};

export default AddStudentForm;
