import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const AddMarksheet = () => {
    const { StudentId, CourseId } = useParams();
    const [course, setCourse] = useState({});
    const [formData, setFormData] = useState({
        StudentId: StudentId,
        CourseId: CourseId,
        Subjects: [],
    });

    const [error, setError] = useState(null);

    const calculateGrade = (marks) => {
        if (marks >= 85) return 'A+';
        if (marks >= 75) return 'A';
        if (marks >= 65) return 'B';
        if (marks >= 45) return 'C';
        return 'D';
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://ims-nv9i.onrender.com/courses/get/${CourseId}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setCourse(data);

                // Initialize subjects from course data
                const subjects = data.Syllabus.map(subject => ({
                    Title: subject.Subject,
                    FullMarks: subject.Marks,
                    TheoryMarks: '',
                    PracticalMarks: '',
                    Grades: ''
                }));

                setFormData(prevData => ({
                    ...prevData,
                    Subjects: subjects
                }));
            } catch (error) {
                console.error('Error fetching course data:', error);
                setError('Failed to fetch course data.');
            }
        };

        fetchData();
    }, [CourseId]);

    const handleSubjectsChange = (index, e) => {
        const { name, value } = e.target;
        const updatedSubjects = formData.Subjects.map((subject, i) => {
            if (i === index) {
                const updatedSubject = { ...subject, [name]: value };
                const totalMarks = parseFloat(updatedSubject.TheoryMarks || 0) + parseFloat(updatedSubject.PracticalMarks || 0);
                updatedSubject.Grades = calculateGrade(totalMarks);
                return updatedSubject;
            }
            return subject;
        });
        setFormData(prevData => ({
            ...prevData,
            Subjects: updatedSubjects,
        }));
    };

    const handleAddSubject = () => {
        setFormData(prevData => ({
            ...prevData,
            Subjects: [...prevData.Subjects, { Title: '', FullMarks: '', TheoryMarks: '', PracticalMarks: '', Grades: '' }],
        }));
    };

    const handleRemoveSubject = (index) => {
        const updatedSubjects = formData.Subjects.filter((_, i) => i !== index);
        setFormData(prevData => ({
            ...prevData,
            Subjects: updatedSubjects,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://ims-nv9i.onrender.com/marksheet/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            alert('MarkSheet created successfully');
            setFormData({
                StudentId: '',
                CourseId: '',
                Subjects: [],
            });
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Create MarkSheet</h1>
            <p>Student ID: {StudentId}</p>
            <p>Course ID: {CourseId}</p>
            <form onSubmit={handleSubmit} style={styles.form}>
                <div style={styles.subjectContainer}>
                    <h2>Enter Marks</h2>
                    {formData.Subjects.map((subject, index) => (
                        <div key={index} style={styles.subject}>
                            <h3>Subject {index + 1}</h3>
                            <table style={styles.table}>
                                <tbody>
                                    <tr>
                                        <td><label>Subject Name:</label></td>
                                        <td><input type="text" name="Title" value={subject.Title} onChange={(e) => handleSubjectsChange(index, e)} required style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td><label>Full Marks:</label></td>
                                        <td><input type="number" name="FullMarks" value={subject.FullMarks} onChange={(e) => handleSubjectsChange(index, e)} required style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td><label>Theory Marks:</label></td>
                                        <td><input type="number" name="TheoryMarks" value={subject.TheoryMarks} onChange={(e) => handleSubjectsChange(index, e)} required style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td><label>Practical Marks:</label></td>
                                        <td><input type="number" name="PracticalMarks" value={subject.PracticalMarks} onChange={(e) => handleSubjectsChange(index, e)} required style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td><label>Grades:</label></td>
                                        <td><input type="text" name="Grades" value={subject.Grades} readOnly style={styles.input} /></td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* <button type="button" onClick={() => handleRemoveSubject(index)} style={styles.removeButton}>Remove Subject</button> */}
                        </div>
                    ))}
                    <button type="button" onClick={handleAddSubject} style={styles.addButton}>Add Subject</button>
                </div>
                <button type="submit" style={styles.submitButton}>Submit</button>
            </form>
            {error && <p style={styles.error}>{error}</p>}
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '600px',
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
    },
    header: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    subjectContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    subject: {
        border: '1px solid #ddd',
        padding: '10px',
        borderRadius: '4px',
        backgroundColor: '#f9f9f9',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    input: {
        width: '100%',
        padding: '8px',
        margin: '5px 0',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    addButton: {
        padding: '10px 15px',
        backgroundColor: '#28a745',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '10px',
    },
    removeButton: {
        padding: '10px 15px',
        backgroundColor: '#dc3545',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '10px',
    },
    submitButton: {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        textAlign: 'center',
        marginTop: '10px',
    },
};

export default AddMarksheet;
