import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Login from './Components/Login'
import Admin from './Components/SuperAdmin/Admin'
import AdminViewMaster from './Components/SuperAdmin/AdminViewMaster'
import AdminViewFranchise from './Components/SuperAdmin/AdminViewFranchise'
import AdminViewEmployee from './Components/SuperAdmin/AdminViewEmployee'
import AdminViewStudent from './Components/SuperAdmin/AdminViewStudent'
import AdminAddMaster from './Components/SuperAdmin/AdminAddMaster'
import AdminAddFranchise from './Components/SuperAdmin/AdminAddFranchise'
import AdminMyMaster from './Components/SuperAdmin/AdminMyMaster'
import AdminMyFranchise from './Components/SuperAdmin/AdminMyFranchise'
import AdminAddEmployee from './Components/SuperAdmin/AdminAddEmployee'
import AdminAddStudent from './Components/SuperAdmin/AdminAddStudent'
import AdminMyStudent from './Components/SuperAdmin/AdminMyStudent'
import AdminMyEmployee from './Components/SuperAdmin/AdminMyEmployee'
import AdminPaymentList from './Components/SuperAdmin/AdminPaymentList'
import AdminViewAttendance from './Components/SuperAdmin/AdminViewAttendance'
import AdminAddEmployeeAttendance from './Components/SuperAdmin/AdminAddEmployeeAttendance'
import AdminAddStudentAttendance from './Components/SuperAdmin/AdminAddStudentAttendance'
import AdminExpense from './Components/SuperAdmin/AdminExpense'
import AdminWallet from './Components/SuperAdmin/AdminWallet'
import AdminAddReward from './Components/SuperAdmin/AdminAddReward'
import AdminAddNotification from './Components/SuperAdmin/AdminAddNotification'
import AdminAddCourse from './Components/SuperAdmin/AdminAddCourse'
import AdminAddExam from './Components/SuperAdmin/AdminAddExam'
import AdminAddQuestion from './Components/SuperAdmin/AdminAddQuestion'
import AdminAddSet from './Components/SuperAdmin/AdminAddSet'
import AdminAddBatch from './Components/SuperAdmin/AdminAddBatch'
import AdminViewBatch from './Components/SuperAdmin/AdminViewBatch'
import AdminViewExam from './Components/SuperAdmin/AdminViewExam'
import AdminViewResult from './Components/SuperAdmin/AdminViewResult'
import AdminViewSet from './Components/SuperAdmin/AdminViewSet'
import AdminViewCourse from './Components/SuperAdmin/AdminViewCourse'
import AdminViewNotification from './Components/SuperAdmin/AdminViewNotification'
import AdminViewReward from './Components/SuperAdmin/AdminViewReward'



import Master from './Components/Master/Master'
import MasterViewMaster from './Components/Master/MasterViewMaster'
import MasterViewFranchise from './Components/Master/MasterViewFranchise'
import MasterViewEmployee from './Components/Master/MasterViewEmployee'
import MasterViewStudent from './Components/Master/MasterViewStudent'
import MasterAddMaster from './Components/Master/MasterAddMaster'
import MasterAddFranchise from './Components/Master/MasterAddFranchise'
import MasterMyMaster from './Components/Master/MasterMyMaster'
import MasterMyFranchise from './Components/Master/MasterMyFranchise'
import MasterAddEmployee from './Components/Master/MasterAddEmployee'
import MasterAddStudent from './Components/Master/MasterAddStudent'
import MasterMyStudent from './Components/Master/MasterMyStudent'
import MasterMyEmployee from './Components/Master/MasterMyEmployee'
import MasterPaymentList from './Components/Master/MasterPaymentList'
import MasterViewAttendance from './Components/Master/MasterViewAttendance'
import MasterAddEmployeeAttendance from './Components/Master/MasterAddEmployeeAttendance'
import MasterAddStudentAttendance from './Components/Master/MasterAddStudentAttendance'
import MasterExpense from './Components/Master/MasterExpense'
import MasterWallet from './Components/Master/MasterWallet'
import MasterAddReward from './Components/Master/MasterAddReward'
import MasterAddNotification from './Components/Master/MasterAddNotification'
import MasterAddCourse from './Components/Master/MasterAddCourse'
import MasterAddExam from './Components/Master/MasterAddExam'
import MasterAddQuestion from './Components/Master/MasterAddQuestion'
import MasterAddSet from './Components/Master/MasterAddSet'
import MasterAddBatch from './Components/Master/MasterAddBatch'
import MasterViewBatch from './Components/Master/MasterViewBatch'
import MasterViewExam from './Components/Master/MasterViewExam'
import MasterViewResult from './Components/Master/MasterViewResult'
import MasterViewSet from './Components/Master/MasterViewSet'
import MasterViewCourse from './Components/Master/MasterViewCourse'
import MasterViewNotification from './Components/Master/MasterViewNotification'
import MasterViewReward from './Components/Master/MasterViewReward'


import AttendanceAdd from './Components/Universal/AttendanceAdd'



import Franchise from './Components/Franchise/Franchise'
import FranchiseViewFranchise from './Components/Franchise/FranchiseViewFranchise'
import FranchiseViewEmployee from './Components/Franchise/FranchiseViewEmployee'
import FranchiseViewStudent from './Components/Franchise/FranchiseViewStudent'
import FranchiseAddFranchise from './Components/Franchise/FranchiseAddFranchise'
import FranchiseMyFranchise from './Components/Franchise/FranchiseMyFranchise'
import FranchiseAddEmployee from './Components/Franchise/FranchiseAddEmployee'
import FranchiseAddStudent from './Components/Franchise/FranchiseAddStudent'
import FranchiseMyStudent from './Components/Franchise/FranchiseMyStudent'
import FranchiseMyEmployee from './Components/Franchise/FranchiseMyEmployee'
import FranchisePaymentList from './Components/Franchise/FranchisePaymentList'
import FranchiseViewAttendance from './Components/Franchise/FranchiseViewAttendance'
import FranchiseAddEmployeeAttendance from './Components/Franchise/FranchiseAddEmployeeAttendance'
import FranchiseAddStudentAttendance from './Components/Franchise/FranchiseAddStudentAttendance'
import FranchiseExpense from './Components/Franchise/FranchiseExpense'
import FranchiseWallet from './Components/Franchise/FranchiseWallet'
import FranchiseAddReward from './Components/Franchise/FranchiseAddReward'
import FranchiseAddNotification from './Components/Franchise/FranchiseAddNotification'
import FranchiseAddCourse from './Components/Franchise/FranchiseAddCourse'
import FranchiseAddExam from './Components/Franchise/FranchiseAddExam'
import FranchiseAddQuestion from './Components/Franchise/FranchiseAddQuestion'
import FranchiseAddSet from './Components/Franchise/FranchiseAddSet'
import FranchiseAddBatch from './Components/Franchise/FranchiseAddBatch'
import FranchiseViewBatch from './Components/Franchise/FranchiseViewBatch'
import FranchiseViewExam from './Components/Franchise/FranchiseViewExam'
import FranchiseViewResult from './Components/Franchise/FranchiseViewResult'
import FranchiseViewSet from './Components/Franchise/FranchiseViewSet'
import FranchiseViewCourse from './Components/Franchise/FranchiseViewCourse'
import FranchiseViewNotification from './Components/Franchise/FranchiseViewNotification'
import FranchiseViewReward from './Components/Franchise/FranchiseViewReward'
import FranchiseViewMaster from './Components/Franchise/FranchiseViewFranchise'
import FranchiseMyMaster from './Components/Franchise/FranchiseMyMaster'
import FranchiseAddMaster from './Components/Franchise/FranchiseAddFranchise'




import Employee from './Components/Employee/Employee'
import EmployeeViewMaster from './Components/Employee/EmployeeViewMaster'
import EmployeeViewFranchise from './Components/Employee/EmployeeViewFranchise'
import EmployeeViewEmployee from './Components/Employee/EmployeeViewEmployee'
import EmployeeViewStudent from './Components/Employee/EmployeeViewStudent'
import EmployeeAddMaster from './Components/Employee/EmployeeAddMaster'
import EmployeeAddFranchise from './Components/Employee/EmployeeAddFranchise'
import EmployeeMyMaster from './Components/Employee/EmployeeMyMaster'
import EmployeeMyFranchise from './Components/Employee/EmployeeMyFranchise'
import EmployeeAddEmployee from './Components/Employee/EmployeeAddEmployee'
import EmployeeAddStudent from './Components/Employee/EmployeeAddStudent'
import EmployeeMyStudent from './Components/Employee/EmployeeMyStudent'
import EmployeeMyEmployee from './Components/Employee/EmployeeMyEmployee'
import EmployeePaymentList from './Components/Employee/EmployeePaymentList'
import EmployeeViewAttendance from './Components/Employee/EmployeeViewAttendance'
import EmployeeAddEmployeeAttendance from './Components/Employee/EmployeeAddEmployeeAttendance'
import EmployeeAddStudentAttendance from './Components/Employee/EmployeeAddStudentAttendance'
import EmployeeExpense from './Components/Employee/EmployeeExpense'
import EmployeeWallet from './Components/Employee/EmployeeWallet'
import EmployeeAddReward from './Components/Employee/EmployeeAddReward'
import EmployeeAddNotification from './Components/Employee/EmployeeAddNotification'
import EmployeeAddCourse from './Components/Employee/EmployeeAddCourse'
import EmployeeAddExam from './Components/Employee/EmployeeAddExam'
import EmployeeAddQuestion from './Components/Employee/EmployeeAddQuestion'
import EmployeeAddSet from './Components/Employee/EmployeeAddSet'
import EmployeeAddBatch from './Components/Employee/EmployeeAddBatch'
import EmployeeViewBatch from './Components/Employee/EmployeeViewBatch'
import EmployeeViewExam from './Components/Employee/EmployeeViewExam'
import EmployeeViewResult from './Components/Employee/EmployeeViewResult'
import EmployeeViewSet from './Components/Employee/EmployeeViewSet'
import EmployeeViewCourse from './Components/Employee/EmployeeViewCourse'
import EmployeeViewNotification from './Components/Employee/EmployeeViewNotification'
import EmployeeViewReward from './Components/Employee/EmployeeViewReward'







import Student from './Components/Student/Student'
import StudentCourse from './Components/Student/StudentCourse'
import StudentPaymentInfo from './Components/Student/StudentPaymentInfo'
import StudentResult from './Components/Student/StudentResult'
import StudentNotification from './Components/Student/StudentNotification'
import StudentExam from './Components/Student/StudentExam'
import StudentCertificate from './Components/Student/StudentCertificate'
import StudentAdmitcard from './Components/Student/StudentAdmitcard'
import StudentIdcard from './Components/Student/StudentIdcards'
import Idcard from './Components/Student/Idcards'


import NotFoundPage from './Components/Universal/NotFoundPage'
import Atc from './Components/layouts/ATC'
import CertificateC from './Components/Student/CertificateC'
import Certificate from './Components/Student/Certificate'
import StudentFeeDetails from './Components/Student/StudentFeeDetail'
import MarksheetPrint from './Components/Student/MarksheetPrint'
import Marksheet from './Components/Student/Marksheet'
import Invoice from './Components/layouts/Invoice'
import AddCourse from './Components/Universal/AddCourse'
import AddStudyMaterial from './Components/Universal/AddStudyMaterial'
import ExamPaper from './Components/Student/ExamPaper'
import SAAddBatch from './Components/Universal/SAAddBatch'
import NotificationEmployee from './Components/Employee/NotificationEmployee'
import CerMarReq from './Components/Universal/CerMarReq'
import MarksheetList from './Components/Universal/MarksheetList'
import AddMarksheet from './Components/Universal/AddMarkSheet'
import PrintingRequest from './PrintingRequest'
import MarksheetData from './Components/Universal/MarksheedData'
import TestData from './Components/Universal/TestData'
import AdminAddMarkSheet from './Components/SuperAdmin/AdminAddMarksheet'
import AdminRequest from './Components/SuperAdmin/AdminRequest'
import AdminMarksheetData from './Components/SuperAdmin/AdminMarksheetData'
import MasterMarksheetData from './Components/Master/MasterMarksheetData'
import MasterAddMarkSheet from './Components/Master/MasterAddMarksheet'
import AdminEditStudent from './Components/SuperAdmin/AdminEditStudent'
import AdminPayFees from './Components/SuperAdmin/AdminPayFees'
import AdminFees from './Components/SuperAdmin/AdminFees'
import AddStudentForm from './Components/Test'
import AdminEditFranchise from './Components/SuperAdmin/AdminEditFranchise'
import AdmitCard from './Components/Universal/AdmitCard'
import MasterFees from './Components/Master/MasterFees'
import FranchiseFees from './Components/Franchise/FranchiseFees'
import EmployeeFees from './Components/Employee/EmployeeFees'
import MasterEditStudent from './Components/Master/MasterEditStudent'
import FranchiseEditStudent from './Components/Franchise/FranchiseEditStudent'
import EmployeeEditStudent from './Components/Employee/EmployeeEditStudent'


const App = () => {
  return (
    <>
      <Routes>
        {/* Universal */}
        <Route path="/" element={<Login />} />
        <Route path="/AddStudentToBatchSA" element={<SAAddBatch />} />




        {/* Admin */}
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/view/master" element={<AdminViewMaster />} />
        <Route path="/admin/view/franchise" element={<AdminViewFranchise />} />
        <Route path="/admin/view/employee" element={<AdminViewEmployee />} />
        <Route path="/admin/view/student" element={<AdminViewStudent />} />
        <Route path="/admin/view/my/master" element={<AdminMyMaster />} />
        <Route path="/admin/view/my/franchise" element={<AdminMyFranchise />} />
        <Route path="/admin/view/my/employee" element={<AdminMyEmployee />} />
        <Route path="/admin/view/my/student" element={<AdminMyStudent />} />
        <Route path="/admin/view/attendance" element={<AdminViewAttendance />} />
        <Route path="/admin/view/batch" element={<AdminViewBatch />} />
        <Route path="/admin/view/exam" element={<AdminViewExam />} />
        <Route path="/admin/view/result" element={<AdminViewResult />} />
        <Route path="/admin/view/set" element={<AdminViewSet />} />
        <Route path="/admin/view/course" element={<AdminViewCourse />} />
        <Route path="/admin/view/notification" element={<AdminViewNotification />} />
        <Route path="/admin/view/reward" element={<AdminViewReward />} />
        <Route path="/admin/Add/master" element={<AdminAddMaster />} />
        <Route path="/admin/Add/franchise" element={<AdminAddFranchise />} />
        <Route path="/admin/Add/employee" element={<AdminAddEmployee />} />
        <Route path="/admin/Add/student" element={<AdminAddStudent />} />
        <Route path="/admin/Add/payment" element={<AdminPaymentList />} />
        <Route path="/admin/Add/batch" element={<AdminAddBatch />} />
        <Route path="/admin/Add/set" element={<AdminAddSet />} />
        <Route path="/admin/Add/question" element={<AdminAddQuestion />} />
        <Route path="/admin/Add/exam" element={<AdminAddExam />} />
        <Route path="/admin/Add/course" element={<AdminAddCourse />} />
        <Route path="/admin/Add/notification" element={<AdminAddNotification />} />
        <Route path="/admin/Add/reward" element={<AdminAddReward />} />
        <Route path="/admin/wallet" element={<AdminWallet />} />
        <Route path="/admin/expense" element={<AdminExpense />} />
        <Route path="/admin/request" element={<AdminRequest />} />
        <Route path="/admin/Add/Student/Attendance" element={<AdminAddStudentAttendance />} />
        <Route path="/admin/Add/Employee/Attendance" element={<AdminAddEmployeeAttendance />} />
        <Route path="/admin/view/MarksheetList" element={<AdminMarksheetData />} />
        <Route path="/admin/add/MarksheetList" element={<AdminAddMarkSheet />} />
        <Route path="/admin/edit/student/:studentId" element={<AdminEditStudent />} />
        <Route path="/admin/pay/fees/student" element={<AdminPayFees />} />
        <Route path="/admin/edit/franchise" element={<AdminEditFranchise />} />

        {/* Master */}
        <Route path="/master" element={<Master />} />
        <Route path="/master/view/my/franchise" element={<MasterMyFranchise />} />
        <Route path="/master/view/my/employee" element={<MasterMyEmployee />} />
        <Route path="/master/view/my/student" element={<MasterMyStudent />} />
        <Route path="/master/view/attendance" element={<MasterViewAttendance />} />
        <Route path="/master/view/batch" element={<MasterViewBatch />} />
        <Route path="/master/course" element={<MasterViewCourse />} />``
        <Route path="/master/notification" element={<MasterViewNotification />} />
        <Route path="/master/reward" element={<MasterViewReward />} />
        <Route path="/master/Add/franchise" element={<MasterAddFranchise />} />
        <Route path="/master/Add/employee" element={<MasterAddEmployee />} />
        <Route path="/master/Add/student" element={<MasterAddStudent />} />
        <Route path="/master/Add/payment" element={<MasterPaymentList />} />
        <Route path="/master/Add/batch" element={<MasterAddBatch />} />
        <Route path="/master/Add/notification" element={<MasterAddNotification />} />
        <Route path="/master/wallet" element={<MasterWallet />} />
        <Route path="/master/expense" element={<MasterExpense />} />
        <Route path="/master/Add/Student/Attendance" element={<MasterAddStudentAttendance />} />
        <Route path="/master/Add/Employee/Attendance" element={<MasterAddEmployeeAttendance />} />
        <Route path="/master/view/MarksheetList" element={<MasterMarksheetData />} />
        <Route path="/master/add/MarksheetList" element={<MasterAddMarkSheet />} />
        <Route path="/master/edit/student/:studentId" element={<MasterEditStudent />} />


        {/* Franchise */}
        <Route path="/franchise" element={<Franchise />} />
        <Route path="/franchise/view/my/master" element={<FranchiseMyMaster />} />
        <Route path="/franchise/view/my/franchise" element={<FranchiseMyFranchise />} />
        <Route path="/franchise/view/my/employee" element={<FranchiseMyEmployee />} />
        <Route path="/franchise/view/my/student" element={<FranchiseMyStudent />} />
        <Route path="/franchise/view/attendance" element={<FranchiseViewAttendance />} />
        <Route path="/franchise/view/batch" element={<FranchiseViewBatch />} />
        <Route path="/franchise/course" element={<FranchiseViewCourse />} />
        <Route path="/franchise/notification" element={<FranchiseViewNotification />} />
        <Route path="/franchise/reward" element={<FranchiseViewReward />} />
        <Route path="/franchise/Add/employee" element={<FranchiseAddEmployee />} />
        <Route path="/franchise/Add/student" element={<FranchiseAddStudent />} />
        <Route path="/franchise/Add/payment" element={<FranchisePaymentList />} />
        <Route path="/franchise/Add/batch" element={<FranchiseAddBatch />} />
        <Route path="/franchise/wallet" element={<FranchiseWallet />} />
        <Route path="/franchise/expense" element={<FranchiseExpense />} />
        <Route path="/franchise/Add/Student/Attendance" element={<FranchiseAddStudentAttendance />} />
        <Route path="/franchise/Add/Employee/Attendance" element={<FranchiseAddEmployeeAttendance />} />
        <Route path="/franchise/edit/student/:studentId" element={<FranchiseEditStudent />} />


        {/* Employee */}
        <Route path="/employee" element={<Employee />} />
        <Route path="/employee/view/master" element={<EmployeeViewMaster />} />
        <Route path="/employee/view/franchise" element={<EmployeeViewFranchise />} />
        <Route path="/employee/view/employee" element={<EmployeeViewEmployee />} />
        <Route path="/employee/view/student" element={<EmployeeViewStudent />} />
        <Route path="/employee/view/my/master" element={<EmployeeMyMaster />} />
        <Route path="/employee/view/my/franchise" element={<EmployeeMyFranchise />} />
        <Route path="/employee/view/my/employee" element={<EmployeeMyEmployee />} />
        <Route path="/employee/view/my/student" element={<EmployeeMyStudent />} />
        <Route path="/employee/view/attendance" element={<EmployeeViewAttendance />} />
        <Route path="/employee/view/batch" element={<EmployeeViewBatch />} />
        <Route path="/employee/view/exam" element={<EmployeeViewExam />} />
        <Route path="/employee/view/result" element={<EmployeeViewResult />} />
        <Route path="/employee/view/set" element={<EmployeeViewSet />} />
        <Route path="/employee/view/course" element={<EmployeeViewCourse />} />
        <Route path="/employee/view/notification" element={<EmployeeViewNotification />} />
        <Route path="/employee/notification" element={<NotificationEmployee />} />
        <Route path="/employee/view/reward" element={<EmployeeViewReward />} />
        <Route path="/employee/Add/master" element={<EmployeeAddMaster />} />
        <Route path="/employee/Add/franchise" element={<EmployeeAddFranchise />} />
        <Route path="/employee/Add/employee" element={<EmployeeAddEmployee />} />
        <Route path="/employee/Add/student" element={<EmployeeAddStudent />} />
        <Route path="/employee/Add/payment" element={<EmployeePaymentList />} />
        <Route path="/employee/Add/batch" element={<EmployeeAddBatch />} />
        <Route path="/employee/Add/set" element={<EmployeeAddSet />} />
        <Route path="/employee/Add/question" element={<EmployeeAddQuestion />} />
        <Route path="/employee/Add/exam" element={<EmployeeAddExam />} />
        <Route path="/employee/Add/course" element={<EmployeeAddCourse />} />
        <Route path="/employee/Add/notification" element={<EmployeeAddNotification />} />
        <Route path="/employee/Add/reward" element={<EmployeeAddReward />} />
        <Route path="/employee/wallet" element={<EmployeeWallet />} />
        <Route path="/employee/expense" element={<EmployeeExpense />} />
        <Route path="/employee/Add/Student/Attendance" element={<EmployeeAddStudentAttendance />} />
        <Route path="/employee/Add/Employee/Attendance" element={<EmployeeAddEmployeeAttendance />} />
        <Route path="/employee/edit/student/:studentId" element={<EmployeeEditStudent />} />


        {/* Student */}
        <Route path="/student" element={<Student />} />
        <Route path="/student/course" element={<StudentCourse />} />
        <Route path="/student/paymentinfo" element={<StudentPaymentInfo />} />
        <Route path="/student/result" element={<StudentResult />} />
        <Route path="/student/notification" element={<StudentNotification />} />
        <Route path="/student/exam" element={<StudentExam />} />
        <Route path="/student/certificate" element={<StudentCertificate />} />
        <Route path="/student/admitcard" element={<StudentAdmitcard />} />
        <Route path="/student/idcard" element={<StudentIdcard />} />
        <Route path="/idcard/:StudentId" element={<Idcard />} />
        <Route path="/certificatec/:StudentId/:CourseId" element={<CertificateC />} />
        <Route path="/certificate/:StudentId" element={<Certificate />} />
        <Route path="/studentfeedetail" element={<StudentFeeDetails />} />
        <Route path="/marksheetprint/:StudentId/:CourseId" element={<MarksheetPrint />} />
        <Route path="/marksheet/:StudentId" element={<Marksheet />} />
        <Route path="/exampaper" element={<ExamPaper />} />

        <Route path="*" element={<NotFoundPage />} />
        <Route path="/atc/:id" element={<Atc />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/attendance/add/:id" element={<AttendanceAdd />} />
        <Route path="/createcourse" element={<AddCourse />} />
        <Route path="/addstudymaterial" element={<AddStudyMaterial />} />
        <Route path="/MarkSheetList" element={<MarksheetList />} />
        <Route path="/MarkSheetData" element={<MarksheetData />} />
        <Route path="/PrintingRequest" element={<PrintingRequest />} />
        <Route path="/addmarks/:StudentId/:CourseId" element={<AddMarksheet />} />
        <Route path="/cer-mark-request" element={<CerMarReq />} />
        <Route path="/test-data" element={<TestData />} />
        <Route path="/temp" element={<AddStudentForm />} />

        <Route path="/employee/fees/:StudentId" element={<EmployeeFees />} />
        <Route path="/franchise/fees/:StudentId" element={<FranchiseFees />} />
        <Route path="/master/fees/:StudentId" element={<MasterFees />} />
        <Route path="/admin/fees/:StudentId" element={<AdminFees />} />
        <Route path="/admit/:StudentId/:ExamId" element={<AdmitCard />} />
      </Routes>
    </>
  )
}

export default App