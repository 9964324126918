import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [notificationToDelete, setNotificationToDelete] = useState(null);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axios.get('https://ims-nv9i.onrender.com/notification/all');
                setNotifications(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching notifications:', error);
                toast.error('Failed to fetch notifications.');
                setLoading(false);
            }
        };

        fetchNotifications();
    }, []);

    const openModal = (notificationId) => {
        setNotificationToDelete(notificationId);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setNotificationToDelete(null);
    };

    const handleDelete = async () => {
        if (notificationToDelete) {
            try {
                await axios.delete(`https://ims-nv9i.onrender.com/notification/delete/${notificationToDelete}`);
                setNotifications(notifications.filter(notification => notification._id !== notificationToDelete));
                toast.success('Notification deleted successfully.');
            } catch (error) {
                console.error('Error deleting notification:', error);
                toast.error('Failed to delete notification.');
            }
            closeModal();
        }
    };

    return (
        <>
            <Container>
                <h2 style={{ textAlign: "center", fontSize: "32px" }}>Notifications</h2>
                <CardContainer>
                    {loading ? (
                        <p>Loading...</p>
                    ) : notifications.length ? (
                        notifications.map(notification => (
                            <NotificationCard key={notification._id}>
                                <CardHeader>{notification.Heading}</CardHeader>
                                <CardBody>{notification.Message}</CardBody>
                                <CardFooter>
                                    <span>{notification.Date}</span>
                                    <DeleteButton onClick={() => openModal(notification._id)}>Delete</DeleteButton>
                                </CardFooter>
                            </NotificationCard>
                        ))
                    ) : (
                        <p>No notifications available.</p>
                    )}
                    <ToastContainer />
                </CardContainer>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Delete Confirmation"
                    style={modalStyles}
                >
                    <ModalTitle>Confirm Deletion</ModalTitle>
                    <ModalMessage>Are you sure you want to delete this notification?</ModalMessage>
                    <ButtonGroup>
                        <ConfirmButton onClick={handleDelete}>Confirm</ConfirmButton>
                        <CancelButton onClick={closeModal}>Cancel</CancelButton>
                    </ButtonGroup>
                </Modal>
            </Container>
        </>
    );
};

// Styled Components
const Container = styled.div`
    padding: 20px;
    background: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: 20px;
`;

const CardContainer = styled.div`
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
    gap: 20px;
`;

const NotificationCard = styled.div`
    min-width:30%;
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CardHeader = styled.h3`
    margin: 0;
    font-size: 18px;
    color: #333;
`;

const CardBody = styled.p`
    margin: 10px 0;
    color: #555;
    font-size: 13px;
`;

const CardFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
`;

const DeleteButton = styled.button`
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #c82333;
    }
`;

const ModalTitle = styled.h2`
    margin: 0;
    font-size: 20px;
    color: #333;
`;

const ModalMessage = styled.p`
    margin: 10px 0;
    color: #555;
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 10px;
`;

const ConfirmButton = styled.button`
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #218838;
    }
`;

const CancelButton = styled.button`
    background-color: #6c757d;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #5a6268;
    }
`;

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        borderRadius: '8px',
        width: '400px',
        maxWidth: '90%'
    }
};

Modal.setAppElement('#root'); // Important for accessibility reasons

export default Notifications;
