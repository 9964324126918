import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { FaTimes, FaChevronDown, FaChevronRight } from "react-icons/fa";

const SidebarContainer = styled.nav`
  width: 250px;
  background-color: #333;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? "0" : "-100%")};
  height: 100%;
  z-index: 1000;
  color: #fff;

  @media (min-width: 769px) {
    position: relative;
    left: 0;
    height: auto;
    z-index: auto;
  }
`;

const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: #fff;
`;

const CloseButton = styled(FaTimes)`
  font-size: 24px;
  cursor: pointer;
  display: ${(props) => (props.isOpen ? "block" : "none")};

  @media (min-width: 769px) {
    display: none;
  }
`;

const SidebarLink = styled(Link)`
  display: block;
  padding: 10px 0;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  border-bottom: 1px solid #444;

  &:hover {
    background-color: #555;
  }
`;

const ExpandableItem = styled.div`
  border-bottom: 1px solid #444;
`;

const ExpandButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  text-align: left;

  &:hover {
    color: #ddd;
  }
`;

const ChildLinksContainer = styled.div`
  padding-left: 20px;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const ChildLink = styled(Link)`
  display: block;
  padding: 5px 0;
  color: #bbb;
  text-decoration: none;
  font-size: 16px;

  &:hover {
    color: #fff;
  }
`;

const Sidebar = ({ isOpen, toggleSidebar }) => {
    const [expandedSections, setExpandedSections] = useState({});
    const [assignedFeatures, setAssignedFeatures] = useState([]);


    useEffect(() => {
        // Fetch assigned features from local storage or API
        const fetchAssignedFeatures = async () => {
            var Id;
            const localdata = JSON.parse(localStorage.getItem("Data"));
            Id = (localdata.EmployeeData.EmployeeID);
            try {
                const response = await fetch(`https://ims-nv9i.onrender.com/employees/get/${Id}`); // Adjust URL
                if (!response.ok) throw new Error('Failed to fetch assigned features');
                const data = await response.json();
                setAssignedFeatures(data.AssignFeature);
            } catch (error) {
                console.error('Error fetching assigned features:', error);
                // Handle error, perhaps show a message to the user
            }
        };

        fetchAssignedFeatures();
    }, []);

    const toggleExpand = (section) => {
        setExpandedSections((prevSections) => ({
            ...prevSections,
            [section]: !prevSections[section],
        }));
    };

    const navigate = useNavigate()
    useEffect(() => {
        var Id;
        const localdata = JSON.parse(localStorage.getItem("Data"));
        Id = (localdata.Role);
        if (Id !== "Employee") {
            navigate('/');
        }
    }, [])

    const logout = () => {
        localStorage.removeItem("Data");
        localStorage.removeItem('Id');
        navigate('/');
    }

    return (
        <SidebarContainer isOpen={isOpen}>
            <SidebarHeader>
                <h3>Welcome, Admin</h3>
                <CloseButton isOpen={isOpen} onClick={toggleSidebar} />
            </SidebarHeader>
            <div>
                <SidebarLink to="/employee" onClick={toggleSidebar}>
                    Home
                </SidebarLink>


                {assignedFeatures.includes("View All") && (
                    <ExpandableItem>
                        <ExpandButton onClick={() => toggleExpand("viewAll")}>
                            View All
                            {expandedSections.viewAll ? <FaChevronDown /> : <FaChevronRight />}
                        </ExpandButton>
                        <ChildLinksContainer isOpen={expandedSections.viewAll}>
                            <ChildLink to="/employee/view/master" onClick={toggleSidebar}>
                                View Masters
                            </ChildLink>
                            <ChildLink to="/employee/view/franchise" onClick={toggleSidebar}>
                                View Franchises
                            </ChildLink>
                            <ChildLink to="/employee/view/employee" onClick={toggleSidebar}>
                                View Employees
                            </ChildLink>
                            <ChildLink to="/employee/view/student" onClick={toggleSidebar}>
                                View Students
                            </ChildLink>
                        </ChildLinksContainer>
                    </ExpandableItem>
                )}

                {assignedFeatures.includes("Master Franchise") && (
                    <ExpandableItem>
                        <ExpandButton onClick={() => toggleExpand("masterFranchise")}>
                            Master Franchise
                            {expandedSections.masterFranchise ? (
                                <FaChevronDown />
                            ) : (
                                <FaChevronRight />
                            )}
                        </ExpandButton>
                        <ChildLinksContainer isOpen={expandedSections.masterFranchise}>
                            <ChildLink to="/employee/add/master" onClick={toggleSidebar}>
                                Add
                            </ChildLink>
                            <ChildLink to="/employee/view/my/master" onClick={toggleSidebar}>
                                View
                            </ChildLink>
                        </ChildLinksContainer>
                    </ExpandableItem>
                )}

                {assignedFeatures.includes("Franchise") && (
                    <ExpandableItem>
                        <ExpandButton onClick={() => toggleExpand("franchise")}>
                            Franchise
                            {expandedSections.franchise ? <FaChevronDown /> : <FaChevronRight />}
                        </ExpandButton>
                        <ChildLinksContainer isOpen={expandedSections.franchise}>
                            <ChildLink to="/employee/add/franchise" onClick={toggleSidebar}>
                                Add
                            </ChildLink>
                            <ChildLink to="/employee/view/my/franchise" onClick={toggleSidebar}>
                                View
                            </ChildLink>
                        </ChildLinksContainer>
                    </ExpandableItem>
                )}

                {assignedFeatures.includes("Employee") && (
                    <ExpandableItem>
                        <ExpandButton onClick={() => toggleExpand("employee")}>
                            Employee
                            {expandedSections.employee ? <FaChevronDown /> : <FaChevronRight />}
                        </ExpandButton>
                        <ChildLinksContainer isOpen={expandedSections.employee}>
                            <ChildLink to="/employee/add/employee" onClick={toggleSidebar}>
                                Add
                            </ChildLink>
                            <ChildLink to="/employee/view/my/employee" onClick={toggleSidebar}>
                                View
                            </ChildLink>
                        </ChildLinksContainer>
                    </ExpandableItem>
                )}

                {assignedFeatures.includes("Student") && (
                    <ExpandableItem>
                        <ExpandButton onClick={() => toggleExpand("student")}>
                            Student
                            {expandedSections.student ? <FaChevronDown /> : <FaChevronRight />}
                        </ExpandButton>
                        <ChildLinksContainer isOpen={expandedSections.student}>
                            <ChildLink to="/employee/add/student" onClick={toggleSidebar}>
                                Add
                            </ChildLink>
                            <ChildLink to="/employee/view/my/student" onClick={toggleSidebar}>
                                View
                            </ChildLink>
                            {/* <ChildLink to="/employee/pay/fees/student" onClick={toggleSidebar}>
                                Payment
                            </ChildLink>
                            <ChildLink to="/employee/add/MarksheetList" onClick={toggleSidebar}>
                                Add Marksheet
                            </ChildLink>
                            <ChildLink to="/employee/view/MarksheetList" onClick={toggleSidebar}>
                                View Marksheet
                            </ChildLink> */}
                        </ChildLinksContainer>
                    </ExpandableItem>
                )}

                {assignedFeatures.includes("Attendance") && (
                    <ExpandableItem>
                        <ExpandButton onClick={() => toggleExpand("attendance")}>
                            Attendance
                            {expandedSections.attendance ? <FaChevronDown /> : <FaChevronRight />}
                        </ExpandButton>
                        <ChildLinksContainer isOpen={expandedSections.attendance}>
                            <ChildLink to="/employee/add/student/attendance" onClick={toggleSidebar}>
                                Student
                            </ChildLink>
                            <ChildLink to="/employee/add/employee/attendance" onClick={toggleSidebar}>
                                Employee
                            </ChildLink>
                            <ChildLink to="/employee/view/attendance" onClick={toggleSidebar}>
                                View
                            </ChildLink>
                        </ChildLinksContainer>
                    </ExpandableItem>
                )}

                {assignedFeatures.includes("Batch") && (
                    <ExpandableItem>
                        <ExpandButton onClick={() => toggleExpand("batch")}>
                            Batch
                            {expandedSections.batch ? <FaChevronDown /> : <FaChevronRight />}
                        </ExpandButton>
                        <ChildLinksContainer isOpen={expandedSections.batch}>
                            <ChildLink to="/employee/add/batch" onClick={toggleSidebar}>
                                Add
                            </ChildLink>
                            <ChildLink to="/employee/view/batch" onClick={toggleSidebar}>
                                View
                            </ChildLink>
                        </ChildLinksContainer>
                    </ExpandableItem>
                )}

                {assignedFeatures.includes("Exam") && (
                    <ExpandableItem>
                        <ExpandButton onClick={() => toggleExpand("exam")}>
                            Exam
                            {expandedSections.exam ? <FaChevronDown /> : <FaChevronRight />}
                        </ExpandButton>
                        <ChildLinksContainer isOpen={expandedSections.exam}>
                            <ChildLink to="/employee/add/set" onClick={toggleSidebar}>
                                Add Set
                            </ChildLink>
                            <ChildLink to="/employee/add/question" onClick={toggleSidebar}>
                                Add Question
                            </ChildLink>
                            <ChildLink to="/employee/add/exam" onClick={toggleSidebar}>
                                Add Exam
                            </ChildLink>
                            <ChildLink to="/employee/view/exam" onClick={toggleSidebar}>
                                View Exam
                            </ChildLink>
                            <ChildLink to="/employee/view/result" onClick={toggleSidebar}>
                                View Result
                            </ChildLink>
                            <ChildLink to="/employee/view/set" onClick={toggleSidebar}>
                                View Set
                            </ChildLink>
                        </ChildLinksContainer>
                    </ExpandableItem>
                )}

                {assignedFeatures.includes("Course") && (
                    <ExpandableItem>
                        <ExpandButton onClick={() => toggleExpand("course")}>
                            Course
                            {expandedSections.course ? <FaChevronDown /> : <FaChevronRight />}
                        </ExpandButton>
                        <ChildLinksContainer isOpen={expandedSections.course}>
                            <ChildLink to="/employee/add/course" onClick={toggleSidebar}>
                                Add
                            </ChildLink>
                            <ChildLink to="/employee/view/course" onClick={toggleSidebar}>
                                View
                            </ChildLink>
                        </ChildLinksContainer>
                    </ExpandableItem>
                )}

                {assignedFeatures.includes("Notification") && (
                    <ExpandableItem>
                        <ExpandButton onClick={() => toggleExpand("notification")}>
                            Notification
                            {expandedSections.notification ? <FaChevronDown /> : <FaChevronRight />}
                        </ExpandButton>
                        <ChildLinksContainer isOpen={expandedSections.notification}>
                            <ChildLink to="/employee/add/notification" onClick={toggleSidebar}>
                                Add
                            </ChildLink>
                            <ChildLink to="/employee/view/notification" onClick={toggleSidebar}>
                                View
                            </ChildLink>
                        </ChildLinksContainer>
                    </ExpandableItem>
                )}

                <SidebarLink to="/employee/view/notification" onClick={toggleSidebar}>
                    Notification
                </SidebarLink>

                {assignedFeatures.includes("Reward") && (
                    <ExpandableItem>
                        <ExpandButton onClick={() => toggleExpand("reward")}>
                            Reward
                            {expandedSections.reward ? <FaChevronDown /> : <FaChevronRight />}
                        </ExpandButton>
                        <ChildLinksContainer isOpen={expandedSections.reward}>
                            <ChildLink to="/employee/add/reward" onClick={toggleSidebar}>
                                Add
                            </ChildLink>
                            <ChildLink to="/employee/view/reward" onClick={toggleSidebar}>
                                View
                            </ChildLink>
                        </ChildLinksContainer>
                    </ExpandableItem>
                )}

                {assignedFeatures.includes("Request") && (
                    <SidebarLink to="/employee/request" onClick={toggleSidebar}>
                        Request
                    </SidebarLink>
                )}

                {assignedFeatures.includes("Wallet") && (
                    <SidebarLink to="/employee/wallet" onClick={toggleSidebar}>
                        Wallet
                    </SidebarLink>
                )}

                {assignedFeatures.includes("Expense") && (
                    <SidebarLink to="/employee/expense" onClick={toggleSidebar}>
                        Expense
                    </SidebarLink>
                )}

                <div onClick={logout}>
                    <SidebarLink>
                        Logout
                    </SidebarLink>
                </div>
            </div>
        </SidebarContainer>
    );
};

export default Sidebar;


