import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NotificationForm = () => {
    const [receiver, setReceiver] = useState('');
    const [heading, setHeading] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post('https://ims-nv9i.onrender.com/notification/add', {
                Receiver: receiver,
                Heading: heading,
                Message: message
            });

            toast.success('Notification added successfully!');
            setReceiver('');
            setHeading('');
            setMessage('');
        } catch (error) {
            toast.error('Failed to add notification.');
            setError('Failed to add notification.');
        }
    };

    return (
        <Container>
            <h2>Add Notification</h2>
            <Form onSubmit={handleSubmit}>
                <Select
                    value={receiver}
                    onChange={(e) => setReceiver(e.target.value)}
                    required
                >
                    <option value="" disabled>Select Receiver</option>
                    <option value="Student">Student</option>
                    <option value="Employee">Employee</option>
                    <option value="Franchise">Franchise</option>
                    <option value="Master">Master</option>
                </Select>
                <Input
                    type="text"
                    placeholder="Heading"
                    value={heading}
                    onChange={(e) => setHeading(e.target.value)}
                    required
                />
                <TextArea
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                />
                <Button type="submit">Add Notification</Button>
            </Form>
            <ToastContainer />
        </Container>
    );
};

// Styled Components
const Container = styled.div`
    padding: 20px;
    background: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: auto;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const Input = styled.input`
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
`;

const TextArea = styled.textarea`
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    resize: vertical;
`;

const Select = styled.select`
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
`;

const Button = styled.button`
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
        background-color: #0056b3;
    }
`;

export default NotificationForm;
