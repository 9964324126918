import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const extractNumber = (str) => {
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
};

const AddCourse = () => {
    const { state } = useLocation();
    const [courseData, setCourseData] = useState(state ? state.courseData : null);
    const [courseName, setCourseName] = useState(courseData?.CourseName || "");
    const [courseCode, setCourseCode] = useState(courseData?.CourseCode || "");
    const [courseFee, setCourseFee] = useState(courseData?.CourseFee || "");
    const [courseDuration, setCourseDuration] = useState(() => extractNumber(courseData?.CourseDuration || ""));
    const [plans, setPlans] = useState(courseData?.Plan.map((plan, index) => ({
        id: index + 1,
        name: plan.PlanName,
        price: plan.PlanPrice,
    })) || [{ id: 1, name: "", price: "" }]);
    const [syllabus, setSyllabus] = useState(courseData?.Syllabus.map((subject, index) => ({
        id: index + 1,
        subject: subject.Subject,
        fullMark: subject.Marks,
    })) || [{ id: 1, subject: "", fullMark: "" }]);
    const [courseDescription, setCourseDescription] = useState(courseData?.CourseDescription.map((content, index) => ({
        id: index + 1,
        content: content,
    })) || [{ id: 1, content: "" }]);
    const [typingCourse, setTypingCourse] = useState(courseData?.TypingCourse || false);
    const [status, setStatus] = useState(courseData?.Status || true);
    const [image, setImage] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('CourseName', courseName);
        formData.append('CourseCode', courseCode);
        formData.append('CourseFee', courseFee);
        formData.append('CourseDuration', courseDuration);
        formData.append('TypingCourse', typingCourse);
        formData.append('Status', status);

        if (image && !courseData?.image) {
            formData.append('file', image);
        }

        formData.append('Plan', JSON.stringify(plans.map((plan) => ({
            PlanName: plan.name,
            PlanPrice: parseFloat(plan.price),
        }))));

        formData.append('Syllabus', JSON.stringify(syllabus.map(subject => ({
            Subject: subject.subject,
            Marks: parseFloat(subject.fullMark),
        }))));

        formData.append('CourseDescription', JSON.stringify(courseDescription.map(content => content.content)));

        try {
            const response = await fetch(courseData ? `https://ims-nv9i.onrender.com/courses/update/${courseData._id}` : 'https://ims-nv9i.onrender.com/courses/add', {
                method: courseData ? 'PUT' : 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Network response was not ok');
            }

            const result = await response.json();
            setSuccess(courseData ? 'Course updated successfully!' : 'Course created successfully!');
            toast.success(courseData ? 'Course updated successfully!' : 'Course created successfully!');

            // Reset form fields
            setCourseName('');
            setCourseCode('');
            setCourseFee('');
            setCourseDuration('');
            setPlans([{ id: 1, name: "", price: "" }]);
            setSyllabus([{ id: 1, subject: "", fullMark: "" }]);
            setCourseDescription([{ id: 1, content: "" }]);
            setTypingCourse(false);
            setStatus(true);
            setImage(null); // Clear the file input

        } catch (error) {
            setError('Failed to save course');
            toast.error('Failed to save course');
        }
    };

    const handleSyllabusChange = (id, field, value) => {
        setSyllabus(syllabus.map(subject =>
            subject.id === id ? { ...subject, [field]: value } : subject
        ));
    };

    const removeSyllabus = (id) => {
        setSyllabus(syllabus.filter(subject => subject.id !== id));
    };

    const addSyllabus = () => {
        setSyllabus([...syllabus, { id: syllabus.length + 1, subject: "", fullMark: "" }]);
    };

    const handleCourseContentChange = (id, value) => {
        setCourseDescription(courseDescription.map(content =>
            content.id === id ? { ...content, content: value } : content
        ));
    };

    const removeCourseContent = (id) => {
        setCourseDescription(courseDescription.filter(content => content.id !== id));
    };

    const addCourseContent = () => {
        setCourseDescription([...courseDescription, { id: courseDescription.length + 1, content: "" }]);
    };

    const handlePlanChange = (id, field, value) => {
        setPlans(plans.map(plan =>
            plan.id === id ? { ...plan, [field]: value } : plan
        ));
    };

    const removePlan = (id) => {
        setPlans(plans.filter(plan => plan.id !== id));
    };

    const addPlan = () => {
        setPlans([...plans, { id: plans.length + 1, name: "", price: "" }]);
    };

    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                <Header>{courseData ? "Edit Course" : "Create Course"} Form</Header>
                {error && <Message type="error">{error}</Message>}
                {success && <Message type="success">{success}</Message>}
                <FormContent>
                    <LeftColumn>
                        <InputGroup>
                            <Input
                                type="text"
                                placeholder="Course Name"
                                value={courseName}
                                onChange={(e) => setCourseName(e.target.value)}
                                required
                            />
                            <Input
                                type="text"
                                placeholder="Course Code"
                                value={courseCode}
                                onChange={(e) => setCourseCode(e.target.value)}
                                required
                            />
                        </InputGroup>
                        <InputGroup>
                            <Input
                                type="number"
                                placeholder="Course Fee"
                                value={courseFee}
                                onChange={(e) => setCourseFee(e.target.value)}
                                required
                            />
                            <Input
                                type="number"
                                placeholder="Course Duration"
                                value={courseDuration}
                                onChange={(e) => setCourseDuration(e.target.value)}
                                required
                            />
                        </InputGroup>
                        {!courseData && (
                            <FileInput>
                                <Input
                                    type="file"
                                    onChange={(e) => setImage(e.target.files[0])}
                                />
                            </FileInput>
                        )}
                        <Section>
                            <SectionHeader>Syllabus</SectionHeader>
                            {syllabus.map((subject) => (
                                <SyllabusEntry key={subject.id}>
                                    <InputGroup>
                                        <Input
                                            type="text"
                                            placeholder="Subject Name"
                                            value={subject.subject}
                                            onChange={(e) => handleSyllabusChange(subject.id, "subject", e.target.value)}
                                            required
                                        />
                                        <Input
                                            type="number"
                                            placeholder="Full Marks"
                                            value={subject.fullMark}
                                            onChange={(e) => handleSyllabusChange(subject.id, "fullMark", e.target.value)}
                                            required
                                        />
                                    </InputGroup>
                                    <Button onClick={() => removeSyllabus(subject.id)}>Remove</Button>
                                </SyllabusEntry>
                            ))}
                            <Button onClick={addSyllabus}>Add Subject</Button>
                        </Section>

                        <Section>
                            <SectionHeader>Course Description</SectionHeader>
                            {courseDescription.map((content) => (
                                <DescriptionEntry key={content.id}>
                                    <Input
                                        type="text"
                                        placeholder="Content"
                                        value={content.content}
                                        onChange={(e) => handleCourseContentChange(content.id, e.target.value)}
                                    />
                                    <Button onClick={() => removeCourseContent(content.id)}>Remove</Button>
                                </DescriptionEntry>
                            ))}
                            <Button onClick={addCourseContent}>Add Content</Button>
                        </Section>

                        <Options>
                            <div>
                                <label>Typing Course:</label>
                                <Checkbox
                                    type="checkbox"
                                    checked={typingCourse}
                                    onChange={(e) => setTypingCourse(e.target.checked)}
                                />
                            </div>
                            <div>
                                <label>Status:</label>
                                <Checkbox
                                    type="checkbox"
                                    checked={status}
                                    onChange={(e) => setStatus(e.target.checked)}
                                />
                            </div>
                        </Options>
                        <SubmitButton type="submit">
                            {courseData ? "Update Course" : "Create Course"}
                        </SubmitButton>
                    </LeftColumn>

                    <RightColumn>
                        <Section>
                            <SectionHeader>Plans</SectionHeader>
                            {plans.map((plan) => (
                                <PlanEntry key={plan.id}>
                                    <InputGroup>
                                        <Input
                                            type="text"
                                            placeholder="Plan Name"
                                            value={plan.name}
                                            onChange={(e) => handlePlanChange(plan.id, "name", e.target.value)}
                                            required
                                        />
                                        <Input
                                            type="number"
                                            placeholder="Plan Price"
                                            value={plan.price}
                                            onChange={(e) => handlePlanChange(plan.id, "price", e.target.value)}
                                            required
                                        />
                                    </InputGroup>
                                    <Button onClick={() => removePlan(plan.id)}>Remove</Button>
                                </PlanEntry>
                            ))}
                            <Button onClick={addPlan}>Add Plan</Button>
                        </Section>

                        <Section>
                            <SectionHeader>Syllabus Table</SectionHeader>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Subject</th>
                                        <th>Full Marks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {syllabus.map((subject) => (
                                        <tr key={subject.id}>
                                            <td>{subject.subject}</td>
                                            <td>{subject.fullMark}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Section>
                    </RightColumn>
                </FormContent>
            </Form>
            <ToastContainer />
        </Container>
    );
};

const Container = styled.section`
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  padding: 20px;
  background: #f9f9f9;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const Header = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
`;

const Message = styled.p`
  text-align: center;
  font-weight: bold;
  color: ${({ type }) => (type === "error" ? "red" : "green")};
  margin-bottom: 20px;
`;

const FormContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftColumn = styled.div`
  flex: 1;
  margin-right: 20px;
`;

const RightColumn = styled.div`
  flex: 1;
`;

const InputGroup = styled.div`
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const FileInput = styled.div`
  margin: 20px 0;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const SectionHeader = styled.h3`
  margin-bottom: 10px;
  font-size: 15px;
`;

const SyllabusEntry = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const DescriptionEntry = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const PlanEntry = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Button = styled.button`
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;

  &:hover {
    background: #0056b3;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  background: #28a745;

  &:hover {
    background: #218838;
  }
`;

const Options = styled.div`
  margin-top: 20px;
  font-size: 14px;
`;

const Checkbox = styled.input`
  margin-left: 10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 15px;

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background: #f4f4f4;
  }
`;

export default AddCourse;
