import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ViewNotification = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        var Id;
        const localdata = JSON.parse(localStorage.getItem("Data"));
        if (localdata) {
            if (localdata.EmployeeData) {
                Id = localdata.Role;
            } else if (localdata.FranchiseData) {
                Id = localdata.Role;
            } else {
                Id = "Admin";
            }
        } else {
            Id = "Admin";
        }

        const fetchNotifications = async () => {
            try {
                const response = await axios.get('https://ims-nv9i.onrender.com/notification/all');
                const filter = response.data.filter(data=>data.Receiver===Id);
                setNotifications(filter);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching notifications:', error);
                toast.error('Failed to fetch notifications.');
                setLoading(false);
            }
        };

        fetchNotifications();
    }, []);

    return (
        <>
            <Container>
                <h2 style={{ textAlign: "center", fontSize: "32px" }}>Notifications</h2>
                <CardContainer>
                    {loading ? (
                        <p>Loading...</p>
                    ) : notifications.length ? (
                        notifications.map(notification => (
                            <NotificationCard key={notification._id}>
                                <CardHeader>{notification.Heading}</CardHeader>
                                <CardBody>{notification.Message}</CardBody>
                                <CardFooter>
                                    <span>{notification.Date}</span>
                                </CardFooter>
                            </NotificationCard>
                        ))
                    ) : (
                        <p>No notifications available.</p>
                    )}
                </CardContainer>
                <ToastContainer />
            </Container>
        </>
    );
};

// Styled Components
const Container = styled.div`
    padding: 20px;
    background: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: 20px;
`;

const CardContainer = styled.div`
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
    gap: 20px;
`;

const NotificationCard = styled.div`
    min-width:30%;
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const CardHeader = styled.h3`
    margin: 0;
    font-size: 18px;
    color: #333;
`;

const CardBody = styled.p`
    margin: 10px 0;
    color: #555;
    font-size: 14px;
`;

const CardFooter = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 13px;
`;

export default ViewNotification;
