import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const AddPayment = ({ isOpen, onClose, onSubmit, record }) => {
  const navigate = useNavigate();
  const [receivedFee, setReceivedFee] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (receivedFee <= 0) {
      setError('Received Fee must be greater than 0');
      return;
    }

    try {
      const response = await fetch(`https://ims-nv9i.onrender.com/fee/add-payment/${record._id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ReceivedFee: Number(receivedFee) }),
      });

      const result = await response.json();

      if (response.ok) {
        navigate('/invoice', { state: { studentId: record._id } });
      } else {
        setError(result.message);
      }
    } catch (error) {
      setError('An error occurred while adding the payment.');
    }
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <h2>Payment</h2>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <label htmlFor="receivedFee">Received Fee:</label>
            <input
              type="number"
              id="receivedFee"
              value={receivedFee}
              onChange={(e) => setReceivedFee(e.target.value)}
              required
            />
          </FormGroup>
          <SubmitButton type="submit">Submit Payment</SubmitButton>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </form>
      </ModalContent>
    </ModalOverlay>
  );
};

// Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: auto;
`;

const ModalContent = styled.div`
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const CloseButton = styled.span`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 24px;
  color: #333;
  transition: color 0.3s ease;

  &:hover {
    color: #ff4d4d;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
  }

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
  }
`;

const SubmitButton = styled.button`
  padding: 12px 20px;
  background: #007bff;
  border: none;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s ease;

  &:hover {
    background: #0056b3;
  }
`;

const ErrorMessage = styled.p`
  color: #ff4d4d;
  margin-top: 10px;
  font-size: 14px;
`;

export default AddPayment;
