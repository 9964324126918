import React, { useEffect, useState } from 'react';
import axios from 'axios';
import html2canvas from 'html2canvas';
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import logo from '../../assets/fake.jpg';
import { useParams } from 'react-router-dom';

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f2f5;
  font-family: 'Arial', sans-serif;
`;

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  background-color: #f0f2f5;
`;

const Header = styled.header`
  height: 70px;
  background-color: #008cba;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const MainContent = styled.main`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media (max-width: 768px) {
    margin-left: 0;
    padding: 20px 10px;
  }
`;

const HamburgerButton = styled(FaBars)`
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: ${(props) => (props.isOpen ? "none" : "block")};
  transition: color 0.3s ease;

  &:hover {
    color: #f0f2f5;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const CardContainer = styled.div`
  width: 400px;
  border: 2px solid #FF4500;
  padding: 16px;
  border-radius: 15px;
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #fff;
  text-align: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  position: relative;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const Header1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  background-color: #FF4500;
  padding: 10px;
  border-radius: 10px 10px 0 0;
`;

const Logo = styled.img`
  width: 40px;
  margin-right: 10px;
`;

const PhotoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;

const Photo = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 3px solid #FF4500;
  object-fit: cover;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0;
  color: #FF4500;
`;

const Details = styled.div`
  text-align: left;
  font-size: 14px;
  margin-bottom: 15px;
  line-height: 1.6;

  p {
    margin: 8px 0;
  }

  strong {
    color: #FF4500;
  }
`;

const Footer = styled.div`
  font-size: 10px;
  color: #FF4500;
  margin-top: 15px;
  border-top: 1px solid #FF4500;
  padding-top: 10px;
`;

const DownloadButton = styled.button`
  margin-top: 20px;
  padding: 12px 20px;
  border: none;
  border-radius: 25px;
  background-color: #FF4500;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #E63E00;
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const Idcard = () => {
  const { StudentId } = useParams();
  const [studentData, setStudentData] = useState(null);

  

  useEffect(() => {
    const fetchData = async() => {
      try{
      const response = await axios.get(`https://ims-nv9i.onrender.com/students/get/${StudentId}`);
      const data = response.data;
      setStudentData(data);
    } catch (error) {
      console.error('Error fetching student', error);
    }
    };

    fetchData();
  }, []);

  const downloadPNG = () => {
    const cardElement = document.querySelector("#id-card");

    html2canvas(cardElement, {
      useCORS: true,
      allowTaint: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'id-card.png';
      link.click();
    });
  };

  if (!studentData) {
    return <p>Loading...</p>;
  }

  return (
    <DashboardContainer>
        <MainContent>
          <div>
            <CardContainer id="id-card">
              <Header1>
                <Logo src={logo} alt="AIIES Logo" />
              </Header1>
              <PhotoContainer>
                <Photo
                  src={studentData.Documents.StudentPhoto ? `https://ims-nv9i.onrender.com/uploads/${studentData.Documents.StudentPhoto}` : logo}
                  alt="Student"
                  crossOrigin="anonymous"
                />
              </PhotoContainer>
              <Title>IDENTITY CARD</Title>
              <Details>
                <p><strong>Reg. No.</strong>: {studentData.StudentId}</p>
                <p><strong>Name</strong>: {studentData.StudentName}</p>
                <p><strong>Father's Name</strong>: {studentData.FatherName}</p>
                <p><strong>Date of Birth</strong>: {new Date(studentData.DOB).toLocaleDateString()}</p>
                <p><strong>Franchise Id</strong>: {studentData.FranchiseId}</p>
                <p><strong>Course</strong>: {studentData.Course.CourseName || 'N/A'}</p>
                <p><strong>Duration</strong>: {studentData.Course.BatchName || 'N/A'}</p>
              </Details>
              <Footer>
                <p>Website: aiieseducation.in</p>
                <p>Vici Computer Training Centre (AIIES05378), NAWAGARHI, PIN CODE-811211, MUNGER</p>
              </Footer>
            </CardContainer>
            <DownloadButton onClick={downloadPNG}>Download PNG</DownloadButton>
          </div>
        </MainContent>
    </DashboardContainer>
  );
};

export default Idcard;