import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TotalCourse = () => {
    const [courses, setCourses] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await fetch("https://ims-nv9i.onrender.com/courses/all");
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                setCourses(data);
            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        };

        fetchCourses();
    }, []);

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`https://ims-nv9i.onrender.com/courses/delete/${id}`, {
                method: "DELETE",
            });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            setCourses(courses.filter((course) => course.CourseId !== id));
            toast.success('Course deleted successfully!');
        } catch (error) {
            console.error("Error deleting course:", error);
            toast.error('Error deleting course.');
        }
    };

    const handleEdit = (course) => {
        navigate('/createcourse', { state: { courseData: course } });
    };

    const handleAddStudyMaterial = (courseId) => {
        navigate(`/addstudymaterial`, { state: { id: courseId } });
    };

    return (
        <Container>
            <Title>Total Courses</Title>
            <Table>
                <thead>
                    <TableRow>
                        <TableHeader>Sr No</TableHeader>
                        <TableHeader>Course Name</TableHeader>
                        <TableHeader>Course Code</TableHeader>
                        <TableHeader>Course Study Material</TableHeader>
                        <TableHeader>Duration</TableHeader>
                        <TableHeader>Actions</TableHeader>
                    </TableRow>
                </thead>
                <tbody>
                    {courses.map((course, index) => (
                        <TableRow key={course.CourseId}>
                            <TableData>{index + 1}</TableData>
                            <TableData>{course.CourseName}</TableData>
                            <TableData>{course.CourseCode}</TableData>
                            <TableData>
                                <ActionButton onClick={() => handleAddStudyMaterial(course.CourseId)}>
                                    Study Material
                                </ActionButton>
                            </TableData>
                            <TableData>{course.CourseDuration}</TableData>
                            <TableData>
                                <ActionButton onClick={() => handleEdit(course)}>
                                    Edit
                                </ActionButton>
                                <ActionButton onClick={() => handleDelete(course.CourseId)} style={{ marginLeft: '8px' }}>
                                    Delete
                                </ActionButton>
                            </TableData>
                        </TableRow>
                    ))}
                </tbody>
            </Table>
            <ToastContainer />
        </Container>
    );
};

// Styled Components
const Container = styled.div`
  width:100%;
  padding: 20px;
  background: #f9f9f9;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 18px;
`;

const Table = styled.table`
  font-size:15px;
  width: 100%;
  border-collapse: collapse;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableHeader = styled.th`
  padding: 12px;
  text-align: left;
  background: #008cba;
  color: white;
`;

const TableData = styled.td`
  padding: 12px;
  text-align: left;
  color: #555;
`;

const ActionButton = styled.button`
  background: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background: #0056b3;
  }
`;

export default TotalCourse;
