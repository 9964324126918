import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./SAddEmployee.css";

const features = [
  "Home",
  "View All",
  "Master Franchise",
  "Franchise",
  "Employee",
  "Student",
  "Attendance",
  "Batch",
  "Exam",
  "Course",
  "Notification",
  "Reward",
  "Request",
  "Wallet",
  "Expense",
  "Logout"
];

const SAddEmployee = () => {
  const [formData, setFormData] = useState({
    Name: "",
    Salary: "",
    FranchiseName: "",
    FranchiseId: "",
    AssignFeature: [],
    MobileNo: "",
    Email: "",
    Photo: null,
    AadharCard: null,
    QualificationFile: null,
  });

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [features, setFeatures] = useState([]);

  const setIdName = () => {
    const localdata = JSON.parse(localStorage.getItem("Data"));
    if (localdata) {
      if (localdata.EmployeeData) {
        setFormData(prevFormData => ({
          ...prevFormData,
          FranchiseId: localdata.EmployeeData.FranchiseId || "Admin",
          FranchiseName: localdata.EmployeeData.FranchiseName || "Admin",
        }));
        setFeatures([
          "Employee",
          "Student",
          "Attendance",
          "Batch",
          "Notification",
          "Wallet",
          "Expense"
        ])

      } else if (localdata.FranchiseData) {
        setFormData(prevFormData => ({
          ...prevFormData,
          FranchiseId: localdata.FranchiseData.FranchiseId || "Admin",
          FranchiseName: localdata.FranchiseData.FranchiseName || "Admin",
        }));
        if (localdata.FranchiseData.Approved) {
          setFeatures([
            "Franchise",
            "Employee",
            "Student",
            "Attendance",
            "Batch",
            "Exam",
            "Course",
            "Notification",
            "Wallet",
            "Expense"
          ])
        } else {
          setFeatures([
            "Employee",
            "Student",
            "Attendance",
            "Batch",
            "Notification",
            "Reward",
            "Request",
            "Wallet",
            "Expense"
          ])
        }
      } else {
        setFormData(prevFormData => ({
          ...prevFormData,
          FranchiseId: "Admin",
          FranchiseName: "Admin",
        }));
        setFeatures([
          "View All",
          "Master Franchise",
          "Franchise",
          "Employee",
          "Student",
          "Attendance",
          "Batch",
          "Notification",
          "Reward",
          "Request",
          "Wallet",
          "Expense"
        ])
      }
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        FranchiseId: "Admin",
        FranchiseName: "Admin",
      }));
      setFeatures([
        "View All",
        "Master Franchise",
        "Franchise",
        "Employee",
        "Student",
        "Attendance",
        "Batch",
        "Exam",
        "Course",
        "Notification",
        "Reward",
        "Request",
        "Wallet",
        "Expense"
      ])
    }
    console.log(formData)
  }

  useEffect(() => {
    setIdName();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "MobileNo" || name === "AadharNo" || name === "PinCode") {
      const regex = /^[0-9]*$/;
      if (!regex.test(value)) return;
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: files ? files[0] : value,
    }));
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: e.target.files[0],
    }));
  };

  const handleFeatureChange = (feature) => {
    setFormData(prevFormData => {
      const updatedFeatures = prevFormData.AssignFeature.includes(feature)
        ? prevFormData.AssignFeature.filter(f => f !== feature)
        : [...prevFormData.AssignFeature, feature];
      return { ...prevFormData, AssignFeature: updatedFeatures };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append("Name", formData.Name);
    data.append("Salary", formData.Salary);
    data.append("FranchiseName", formData.FranchiseName);
    data.append("FranchiseId", formData.FranchiseId);
    data.append("AssignFeature", JSON.stringify(formData.AssignFeature));
    data.append("MobileNo", formData.MobileNo);
    data.append("Email", formData.Email);
    data.append("Photo", formData.Photo);
    data.append("AadharCard", formData.AadharCard);
    data.append("QualificationFile", formData.QualificationFile);

    try {
      const response = await fetch("https://ims-nv9i.onrender.com/employees/add", {
        method: "POST",
        body: data,
      });

      if (!response.ok) {
        throw new Error("Failed to add employee");
      }

      // Success
      toast.success("Employee added successfully!");
      setMessage("Employee added successfully");
      setPopupVisible(true);

      // Clear form data on successful submission
      setFormData({
        Name: "",
        Salary: "",
        FranchiseName: "",
        FranchiseId: "",
        AssignFeature: [],
        MobileNo: "",
        Email: "",
        Photo: null,
        AadharCard: null,
        QualificationFile: null,
      });
      setErrors({});
      setIdName();
    } catch (error) {
      toast.error("Failed to add employee");
      setMessage("Failed to add employee");
      setPopupVisible(true);
    }
  };

  return (
    <div className="add-employee-container">
      <h1 className="form-heading">Add New Employee</h1>
      <form className="employee-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <label className="form-label">
            Name:
            <input
              type="text"
              name="Name"
              value={formData.Name}
              onChange={handleChange}
              required
              className="form-input"
            />
            {errors.Name && <span className="error">{errors.Name}</span>}
          </label>
          <label className="form-label">
            Salary:
            <input
              type="number"
              name="Salary"
              value={formData.Salary}
              onChange={handleChange}
              required
              className="form-input"
            />
            {errors.Salary && <span className="error">{errors.Salary}</span>}
          </label>
        </div>
        <div className="form-row">
          <label className="form-label">
            Mobile No:
            <input
              type="text"
              name="MobileNo"
              value={formData.MobileNo}
              onChange={handleChange}
              required
              className="form-input"
            />
          </label>
          <label className="form-label">
            Email:
            <input
              type="email"
              name="Email"
              value={formData.Email}
              onChange={handleChange}
              required
              className="form-input"
            />
            {errors.Email && <span className="error">{errors.Email}</span>}
          </label>
        </div>
        <div className="form-section-heading">Assign Feature:</div>
        <div className="feature-selection">
          {features.map((feature, index) => (
            <div key={index} className="feature-toggle">
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  fontSize: "13px"
                }}>
                {feature}
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={formData.AssignFeature.includes(feature)}
                    onChange={() => handleFeatureChange(feature)}
                  />
                  <span className="slider round"></span>
                </label>
              </label>
            </div>
          ))}
        </div>
        <div className="form-row">
          <label className="form-label">
            Photo:
            <input
              type="file"
              name="Photo"
              onChange={handleFileChange}
              required
              className="form-input-file"
            />
            {errors.Photo && <span className="error">{errors.Photo}</span>}
          </label>
          <label className="form-label">
            Aadhar Card:
            <input
              type="file"
              name="AadharCard"
              onChange={handleFileChange}
              required
              className="form-input-file"
            />
          </label>
          <label className="form-label">
            Qualification File:
            <input
              type="file"
              name="QualificationFile"
              onChange={handleFileChange}
              required
              className="form-input-file"
            />
            {errors.QualificationFile && (
              <span className="error">{errors.QualificationFile}</span>
            )}
          </label>
        </div>
        <button type="submit" className="form-submit-button">
          Add Employee
        </button>
      </form>
      {popupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <p className="message">{message}</p>
            <button
              className="popup-close-button"
              onClick={() => setPopupVisible(false)}>
              Close
            </button>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default SAddEmployee;
