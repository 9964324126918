// Admin.js
import React, { useState, useEffect } from "react";
import { Hourglass } from 'react-loader-spinner';
import styled from "styled-components";
import NotificationPopup from "./NotificationPopup";
import { BsPeople } from "react-icons/bs";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { IoPersonAddOutline } from "react-icons/io5";
import { PiStudent } from "react-icons/pi";
import { FaBars } from "react-icons/fa";
import Sidebar from "./AdminSidebar"; // Update with the correct path to your Sidebar component

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  background-color: #f0f2f5;
`;

const Header = styled.header`
  height: 70px;
  background-color: #008cba;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  position: relative;
`;

const MainContent = styled.main`
  /* display: flex; */
  flex: 1;
  @media (max-width: 768px) {
    margin-left: 0;
    padding: 20px 10px;
  }
`;

const HamburgerButton = styled(FaBars)`
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 20px;
  display: ${(props) => (props.isOpen ? "none" : "block")};

  @media (min-width: 769px) {
    display: none;
  }
`;

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [franchiseCount, setFranchiseCount] = useState(0);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [masterCount, setMasterCount] = useState(0);
  const [studentCount, setStudentCount] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [franchises, setFranchises] = useState([]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const approve = (id) => {
    console.log(id);
  };

  useEffect(() => {
    setLoading(true);
    const fetchCounts = async () => {
      try {
        const response = await fetch("https://ims-nv9i.onrender.com/login/all");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const franchises = data.filter(
          (item) => item.Role === "Franchise"
        ).length;
        const employees = data.filter(
          (item) => item.Role === "Employee"
        ).length;
        const masters = data.filter((item) => item.Role === "Master").length;

        setFranchiseCount(franchises);
        setEmployeeCount(employees);
        setMasterCount(masters);
      } catch (error) {
        console.error("Error fetching counts data:", error);
      }
    };

    const fetchStudentCount = async () => {
      try {
        const response = await fetch(
          "https://ims-nv9i.onrender.com/students/all"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setStudentCount(data.length);
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    };

    const fetchFranchiseData = async () => {
      try {
        const response = await fetch(
          "https://ims-nv9i.onrender.com/franchises/all"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const franchiseData = data.filter(
          (item) => item.Requested === true && item.Approved === false
        );
        setFranchises(franchiseData);
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching franchise data:", error);
      }
    };

    fetchFranchiseData();
    fetchCounts();
    fetchStudentCount();
    setLoading(false);
  }, []);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleAddNotification = (newNotification) => {
    // Add any logic to handle the new notification if necessary
  };

  if (loading) {
    return <div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}> <Hourglass
      visible={true}
      height="80"
      width="80"
      ariaLabel="hourglass-loading"
      wrapperStyle={{}}
      wrapperClass=""
      colors={['#306cce', '#72a1ed']}
    /></div>;
  }

  return (
    <DashboardContainer>
      <Header>
        Admin Dashboard
        <HamburgerButton isOpen={isSidebarOpen} onClick={toggleSidebar} />
      </Header>
      <Container>
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <MainContent>
          <>
            <MainSection>
              <Container1>
                <FranchiseDetails>
                  <Card className="mf-card-one">
                    <CardLeft>
                      <h6>Master Franchise</h6>
                      <p>{masterCount}</p>
                    </CardLeft>
                    <CardRight>
                      <BsPeople />
                    </CardRight>
                  </Card>
                  <Card className="mf-card-two">
                    <CardLeft>
                      <h6>No. of Franchise</h6>
                      <p>{franchiseCount}</p>
                    </CardLeft>
                    <CardRight>
                      <HiOutlineBuildingOffice2 />
                    </CardRight>
                  </Card>
                  <Card className="mf-card-three">
                    <CardLeft>
                      <h6>No. of Employees</h6>
                      <p>{employeeCount}</p>
                    </CardLeft>
                    <CardRight>
                      <IoPersonAddOutline />
                    </CardRight>
                  </Card>
                  <Card className="mf-card-four">
                    <CardLeft>
                      <h6>No. of Students</h6>
                      <p>{studentCount}</p>
                    </CardLeft>
                    <CardRight>
                      <PiStudent />
                    </CardRight>
                  </Card>
                </FranchiseDetails>
                <RequestAccount>
                  <RequestSection>
                    <h4>Request :</h4>
                    <h5>{franchises.length}</h5>
                  </RequestSection>
                  <AccountSection>
                    <h4>Account Balance :</h4>
                    <h5>Unlimited</h5>
                  </AccountSection>
                  <NotificationSection>
                    <button onClick={handleOpenPopup}>Add Notification</button>
                  </NotificationSection>
                </RequestAccount>
                <FranchiseTable>
                  <table>
                    <thead>
                      <tr>
                        <th>Franchise Id</th>
                        <th>Franchise Name</th>
                        <th>Approve</th>
                      </tr>
                    </thead>
                    <tbody>
                      {franchises.map((franchise) => (
                        <tr key={franchise.Id}>
                          <td>{franchise.FranchiseId}</td>
                          <td>{franchise.FranchiseName}</td>
                          <td>
                            <button
                              onClick={() => approve(franchise.FranchiseId)}
                            >
                              Approve
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </FranchiseTable>
              </Container1>
            </MainSection>
            {isPopupOpen && (
              <NotificationPopup
                onClose={handleClosePopup}
                onAddNotification={handleAddNotification}
              />
            )}
          </>
        </MainContent>
      </Container>
    </DashboardContainer>
  );
};

export default Dashboard;

const MainSection = styled.section`
  padding: 20px;
`;

const Container1 = styled.div`
  padding: 20px;
`;

const FranchiseDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Card = styled.div`
  background: #f8f9fa;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 22%;
`;

const CardLeft = styled.div`
  h6 {
    margin-bottom: 5px;
    font-size: 16px;
    color: #333;
  }
  p {
    font-size: 24px;
    color: #007bff;
  }
`;

const CardRight = styled.div`
  font-size: 32px;
  color: #007bff;
`;

const RequestAccount = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const RequestSection = styled.div`
  h4 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  h5 {
    font-size: 24px;
    color: #007bff;
  }
`;

const AccountSection = styled.div`
  h4 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  h5 {
    font-size: 24px;
    color: #007bff;
  }
`;

const NotificationSection = styled.div`
  button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
`;

const FranchiseTable = styled.div`
font-size: 15px;
  table {
    width: 100%;
    border-collapse: collapse;
    th,
    td {
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }
    th {
      background-color: #f8f9fa;
      font-weight: bold;
    }
    tr:hover {
      background-color: #f1f1f1;
    }
    button {
      background-color: #28a745;
      color: white;
      padding: 8px 12px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
    }
  }
`;
