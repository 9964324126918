import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';

const AddStudyMaterial = () => {
    const location = useLocation();
    const [courses, setCourses] = useState([]);
    const [pdfFile, setPdfFile] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [pdfTitle, setPdfTitle] = useState('');
    const [newVideo, setNewVideo] = useState({ Title: '', VideoUrl: '' });
    const [existingMaterials, setExistingMaterials] = useState({ Pdf: [], Video: [] });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentFileUrl, setCurrentFileUrl] = useState('');
    const [isViewingPdf, setIsViewingPdf] = useState(false); // To determine if the modal is for PDF or Video

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/courses/all');
                const data = await response.json();
                setCourses(data);
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };

        fetchCourses();
        if (location.state?.id) {
            setSelectedCourse(location.state.id);
            handleCourseChange(location.state.id);
        }
    }, [location.state?.id]);

    const handleCourseChange = async (courseId) => {
        if (courseId) {
            try {
                const response = await fetch(`https://ims-nv9i.onrender.com/courses/get/${courseId}`);
                const data = await response.json();
                setExistingMaterials(data);
                console.log('Fetched existing materials:', data); // Debug log
            } catch (error) {
                console.error('Error fetching study materials:', error);
            }
        } else {
            setExistingMaterials({ Pdf: [], Video: [] });
        }
    };

    const handlePdfChange = (e) => {
        setPdfFile(e.target.files[0]);
    };

    const handleAddPdf = async () => {
        const formData = new FormData();
        formData.append('Title', pdfTitle);
        formData.append('file', pdfFile);

        try {
            const response = await fetch(`https://ims-nv9i.onrender.com/courses/${selectedCourse}/add-pdf`, {
                method: 'PATCH',
                body: formData,
            });

            if (response.ok) {
                setPdfTitle('');
                setPdfFile(null);
                toast.success("PDF Added Successfully");
                handleCourseChange(selectedCourse);
            } else {
                console.error('Error adding PDF:', response.statusText);
                toast.error('Error adding PDF');
            }
        } catch (error) {
            console.error('Error adding PDF:', error);
            toast.error('Error adding PDF');
        }
    };

    const handleAddVideo = async () => {
        try {
            const response = await fetch(`https://ims-nv9i.onrender.com/courses/${selectedCourse}/add-video`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newVideo),
            });

            if (response.ok) {
                handleCourseChange(selectedCourse);
                setNewVideo({ Title: '', VideoUrl: '' });
                toast.success("Video Added Successfully");
            } else {
                console.error('Error adding Video:', response.statusText);
                toast.error('Error adding Video');
            }
        } catch (error) {
            console.error('Error adding Video:', error);
            toast.error('Error adding Video');
        }
    };

    const handleDeletePdf = async (pdfId) => {
        if (window.confirm('Are you sure you want to delete this PDF?')) {
            try {
                const response = await fetch(`https://ims-nv9i.onrender.com/courses/${selectedCourse}/delete-pdf/${pdfId}`, {
                    method: 'DELETE',
                });

                if (response.ok) {
                    handleCourseChange(selectedCourse);
                    toast.success('PDF Deleted Successfully');
                } else {
                    console.error('Error deleting PDF:', response.statusText);
                    toast.error('Error deleting PDF');
                }
            } catch (error) {
                console.error('Error deleting PDF:', error);
                toast.error('Error deleting PDF');
            }
        }
    };

    const handleDeleteVideo = async (videoId) => {
        if (window.confirm('Are you sure you want to delete this video?')) {
            try {
                const response = await fetch(`https://ims-nv9i.onrender.com/courses/${selectedCourse}/delete-video/${videoId}`, {
                    method: 'DELETE',
                });

                if (response.ok) {
                    handleCourseChange(selectedCourse);
                    toast.success('Video Deleted Successfully');
                } else {
                    console.error('Error deleting Video:', response.statusText);
                    toast.error('Error deleting Video');
                }
            } catch (error) {
                console.error('Error deleting Video:', error);
                toast.error('Error deleting Video');
            }
        }
    };

    const openModal = (url, isPdf) => {
        setCurrentFileUrl(url);
        setIsViewingPdf(isPdf);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setCurrentFileUrl('');
    };

    // Extract video ID from URL for embedding
    const getYouTubeEmbedUrl = (url) => {
        const videoId = url.split('v=')[1]?.split('&')[0];
        return videoId ? `https://www.youtube.com/embed/${videoId}` : '';
    };

    return (
        <Container>
            <Title>Manage Study Materials</Title>
            <FormGroup>
                <SectionTitle>Add PDF</SectionTitle>
                <Input
                    type="text"
                    placeholder="PDF Title"
                    value={pdfTitle}
                    onChange={(e) => setPdfTitle(e.target.value)}
                />
                <Input
                    type="file"
                    onChange={handlePdfChange}
                />
                <Button onClick={handleAddPdf}>Add PDF</Button>
            </FormGroup>
            <FormGroup>
                <SectionTitle>Add Video</SectionTitle>
                <Input
                    type="text"
                    placeholder="Video Title"
                    value={newVideo.Title}
                    onChange={(e) => setNewVideo({ ...newVideo, Title: e.target.value })}
                />
                <Input
                    type="text"
                    placeholder="Video URL"
                    value={newVideo.VideoUrl}
                    onChange={(e) => setNewVideo({ ...newVideo, VideoUrl: e.target.value })}
                />
                <Button onClick={handleAddVideo}>Add Video</Button>
            </FormGroup>
            <ExistingMaterials>
                <SectionTitle>Existing Study Materials</SectionTitle>
                <SubSectionTitle>PDFs</SubSectionTitle>
                <MaterialList>
                    {existingMaterials.Pdf.length ? (
                        existingMaterials.Pdf.map((pdf) => (
                            <MaterialItem key={pdf._id}>
                                <span>{pdf.Title} - <a href={pdf.PdfUrl} target="_blank" rel="noopener noreferrer">{pdf.PdfUrl}</a></span>
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <Button onClick={() => openModal(pdf.PdfUrl, true)}>View</Button>
                                    <DeleteButton onClick={() => handleDeletePdf(pdf._id)}>Delete</DeleteButton>
                                </div>
                            </MaterialItem>
                        ))
                    ) : (
                        <p>No PDFs available.</p>
                    )}
                </MaterialList>
                <SubSectionTitle>Videos</SubSectionTitle>
                <MaterialList>
                    {existingMaterials.Video.length ? (
                        existingMaterials.Video.map((video) => (
                            <MaterialItem key={video._id}>
                                <span>{video.Title} - <a href={video.VideoUrl} target="_blank" rel="noopener noreferrer">{video.VideoUrl}</a></span>
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <Button onClick={() => openModal(video.VideoUrl, false)}>View</Button>
                                    <DeleteButton onClick={() => handleDeleteVideo(video._id)}>Delete</DeleteButton>
                                </div>
                            </MaterialItem>
                        ))
                    ) : (
                        <p>No videos available.</p>
                    )}
                </MaterialList>
            </ExistingMaterials>
            <ToastContainer />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="File Modal"
                style={modalStyles}
            >
                <CloseButton onClick={closeModal}>Close</CloseButton>
                {isViewingPdf ? (
                    <Iframe
                        src={`https://ims-nv9i.onrender.com/pdf/${currentFileUrl}`}
                        frameBorder="0"
                        style={{ width: '100%', height: '500px' }}
                    />
                ) : (
                    <Iframe
                        src={getYouTubeEmbedUrl(currentFileUrl)}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{ width: '100%', height: '500px' }}
                    />
                )}
            </Modal>
        </Container>
    );
};

// Styled Components
const Container = styled.div`
  padding: 20px;
  background: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  margin-bottom: 10px;
  color: #444;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;

const ExistingMaterials = styled.div`
  margin-top: 20px;
`;

const SubSectionTitle = styled.h3`
  margin-bottom: 10px;
  color: #555;
`;

const MaterialList = styled.ul`
  list-style: none;
  padding: 0;
`;

const MaterialItem = styled.li`
  background: #fff;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DeleteButton = styled.button`
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #c82333;
  }
`;

const CloseButton = styled.button`
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover {
    background-color: #c82333;
  }
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 500px;
`;

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        borderRadius: '8px',
        width: '80%',
        maxWidth: '900px'
    }
};

Modal.setAppElement('#root'); // Important for accessibility reasons

export default AddStudyMaterial;
