import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Styled Components
const FormContainer = styled.div`
  overflow-y: auto;
  height: 100vh;
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
  padding: 40px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
`;

const FormHeading = styled.h1`
  text-align: center;
  margin-bottom: 30px;
  color: #2c3e50;
  font-size: 2.5rem;
  font-weight: 700;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const FormSection = styled.div`
  background: #f8f9fa;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const FormSectionHeading = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #34495e;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
`;

const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 15px;
`;

const FormLabel = styled.label`
  flex: 1;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 15px;
  color: #34495e;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #bdc3c7;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  }
`;

const FormSelect = styled.select`
  width: 100%;
  padding: 12px;
  border: 1px solid #bdc3c7;
  border-radius: 6px;
  font-size: 1rem;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23333' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 12px;

  &:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  }
`;

const FormInputFile = styled.input`
  font-size: 1rem;
  color: #34495e;

  &::file-selector-button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background-color: #3498db;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #2980b9;
    }
  }
`;

const FormSubmitButton = styled.button`
  padding: 14px 20px;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #27ae60;
  }

  &:active {
    background-color: #219653;
  }
`;

const FormMessage = styled.p`
  text-align: center;
  font-size: 1rem;
  color: ${(props) => (props.success ? "#2ecc71" : "#e74c3c")};
  margin-top: 20px;
  padding: 10px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.success ? "rgba(46, 204, 113, 0.1)" : "rgba(231, 76, 60, 0.1)"};
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const PopupCloseButton = styled.button`
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }

  &:active {
    background-color: #2471a3;
  }
`;

// Array of States
const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
    "Ladakh",
    "Jammu and Kashmir"
];

// AddFranchise Component
const AddFranchise = () => {
    const [formData, setFormData] = useState({
        FranchiseName: '',
        Email: '',
        MobileNo: '',
        Address: '',
        City: '',
        State: '',
        Dist: '',
        PinCode: '',
        OwnerName: '',
        Plan: 'Plan-A',
        MasterFranchiseId:'',
        ATC: null,
        MarkSheet: null,
        Certificate: null,
        NameOne: '',
        NameTwo: '',
        NameThree: '',
        PhotoOne: null,
        SignatureOne: null,
        AadharCardOne: null,
        QualificationOne: null,
        AgreementOne: null,
        PhotoTwo: null,
        SignatureTwo: null,
        AadharCardTwo: null,
        QualificationTwo: null,
        AgreementTwo: null,
        PhotoThree: null,
        SignatureThree: null,
        AadharCardThree: null,
        QualificationThree: null,
        AgreementThree: null,
    });

    const [message, setMessage] = useState('');
    const [popupVisible, setPopupVisible] = useState(false);
    const [Id, setId] = useState("");

    useEffect(() => {
        const localdata = JSON.parse(localStorage.getItem("Data"));
        if (localdata) {
            if (localdata.EmployeeData) {
                setId(localdata.EmployeeData.FranchiseId);
                setFormData(prevState => ({
                    ...prevState,
                    MasterFranchiseId: localdata.EmployeeData.FranchiseId
                }));
            } else if (localdata.FranchiseData) {
                setId(localdata.FranchiseData.FranchiseId);
                setFormData(prevState => ({
                    ...prevState,
                    MasterFranchiseId: localdata.FranchiseData.FranchiseId
                }));
            } else {
                setId("Admin");
                setFormData(prevState => ({
                    ...prevState,
                    MasterFranchiseId: "Admin"
                }));
            }
        } else {
            setId("Admin");
            setFormData(prevState => ({
                ...prevState,
                MasterFranchiseId: "Admin"
            }));
        }
    }, []);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            setFormData(prevState => ({
                ...prevState,
                [name]: files[0]
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        for (const key in formData) {
            if (formData[key] !== null && formData[key] !== '') {
                data.append(key, formData[key]);
            }
        }

        try {
            const response = await fetch('https://ims-nv9i.onrender.com/franchises/add', {
                method: 'POST',
                body: data
            });

            if (!response.ok) {
                const errorData = await response.json();
                setMessage('Error: ' + (errorData.error || 'Unknown error'));
                toast.error('Error: ' + (errorData.error || 'Unknown error'));
            } else {
                const responseData = await response.json();
                setMessage('Success: ' + responseData.message);
                toast.success('Data added successfully!');
                setPopupVisible(true);
                setFormData({
                    FranchiseName: '',
                    Email: '',
                    MobileNo: '',
                    Address: '',
                    City: '',
                    State: '',
                    Dist: '',
                    PinCode: '',
                    OwnerName: '',
                    Plan: 'Plan-A',
                    MasterFranchiseId: Id,
                    ATC: null,
                    MarkSheet: null,
                    Certificate: null,
                    NameOne: '',
                    NameTwo: '',
                    NameThree: '',
                    PhotoOne: null,
                    SignatureOne: null,
                    AadharCardOne: null,
                    QualificationOne: null,
                    AgreementOne: null,
                    PhotoTwo: null,
                    SignatureTwo: null,
                    AadharCardTwo: null,
                    QualificationTwo: null,
                    AgreementTwo: null,
                    PhotoThree: null,
                    SignatureThree: null,
                    AadharCardThree: null,
                    QualificationThree: null,
                    AgreementThree: null,
                });
            }
        } catch (error) {
            setMessage('Error: ' + error.message);
            toast.error('Error: ' + error.message);
        }
    };

    return (
        <FormContainer>
            <FormHeading>Add Franchise</FormHeading>
            <Form onSubmit={handleSubmit}>

                {/* Personal Information Section */}
                <FormSection>
                    <FormSectionHeading>Personal Information</FormSectionHeading>
                    <FormRow>
                        <FormLabel>
                            Franchise Name:
                            <FormInput
                                type="text"
                                name="FranchiseName"
                                value={formData.FranchiseName}
                                onChange={handleChange}
                                required
                            />
                        </FormLabel>
                        <FormLabel>
                            Owner Name:
                            <FormInput
                                type="text"
                                name="OwnerName"
                                value={formData.OwnerName}
                                onChange={handleChange}
                                required
                            />
                        </FormLabel>
                        <FormLabel>
                            Email:
                            <FormInput
                                type="email"
                                name="Email"
                                value={formData.Email}
                                onChange={handleChange}
                                required
                            />
                        </FormLabel>
                        <FormLabel>
                            Mobile No:
                            <FormInput
                                type="text"
                                name="MobileNo"
                                value={formData.MobileNo}
                                onChange={handleChange}
                                required
                            />
                        </FormLabel>
                    </FormRow>
                    <FormRow>
                        <FormLabel>
                            Address:
                            <FormInput
                                type="text"
                                name="Address"
                                value={formData.Address}
                                onChange={handleChange}
                                required
                            />
                        </FormLabel>
                        <FormLabel>
                            City:
                            <FormInput
                                type="text"
                                name="City"
                                value={formData.City}
                                onChange={handleChange}
                                required
                            />
                        </FormLabel>
                        <FormLabel>
                            State:
                            <FormSelect
                                name="State"
                                value={formData.State}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select State</option>
                                {states.map(state => (
                                    <option key={state} value={state}>
                                        {state}
                                    </option>
                                ))}
                            </FormSelect>
                        </FormLabel>
                        <FormLabel>
                            District:
                            <FormInput
                                type="text"
                                name="Dist"
                                value={formData.Dist}
                                onChange={handleChange}
                                required
                            />
                        </FormLabel>
                        <FormLabel>
                            Pin Code:
                            <FormInput
                                type="text"
                                name="PinCode"
                                value={formData.PinCode}
                                onChange={handleChange}
                                required
                            />
                        </FormLabel>
                    </FormRow>
                </FormSection>

                {/* Professional Information Section */}
                <FormSection>
                    <FormSectionHeading>Professional Information</FormSectionHeading>
                    <FormRow>
                        <FormLabel>
                            Plan:
                            <FormSelect
                                name="Plan"
                                value={formData.Plan}
                                onChange={handleChange}
                                required
                            >
                                <option value="Plan-A">Plan-A</option>
                                <option value="Plan-B">Plan-B</option>
                                <option value="Plan-C">Plan-C</option>
                            </FormSelect>
                        </FormLabel>
                        <FormLabel>
                            ATC:
                            <FormInputFile
                                type="file"
                                name="ATC"
                                onChange={handleChange}
                            />
                        </FormLabel>
                        <FormLabel>
                            Mark Sheet:
                            <FormInputFile
                                type="file"
                                name="MarkSheet"
                                onChange={handleChange}
                            />
                        </FormLabel>
                        <FormLabel>
                            Certificate:
                            <FormInputFile
                                type="file"
                                name="Certificate"
                                onChange={handleChange}
                            />
                        </FormLabel>
                    </FormRow>
                </FormSection>

                {/* Director Information Sections */}
                <FormSection>
                    <FormSectionHeading>Director One</FormSectionHeading>
                    <FormRow>
                        <FormLabel>
                            Director One Name:
                            <FormInput
                                type="text"
                                name="NameOne"
                                value={formData.NameOne}
                                onChange={handleChange}
                            />
                        </FormLabel>
                        <FormLabel>
                            Director One Photo:
                            <FormInputFile
                                type="file"
                                name="PhotoOne"
                                onChange={handleChange}
                            />
                        </FormLabel>
                        <FormLabel>
                            Director One Signature:
                            <FormInputFile
                                type="file"
                                name="SignatureOne"
                                onChange={handleChange}
                            />
                        </FormLabel>
                        <FormLabel>
                            Director One Aadhar Card:
                            <FormInputFile
                                type="file"
                                name="AadharCardOne"
                                onChange={handleChange}
                            />
                        </FormLabel>
                        <FormLabel>
                            Director One Qualification:
                            <FormInputFile
                                type="file"
                                name="QualificationOne"
                                onChange={handleChange}
                            />
                        </FormLabel>
                        <FormLabel>
                            Director One Agreement:
                            <FormInputFile
                                type="file"
                                name="AgreementOne"
                                onChange={handleChange}
                            />
                        </FormLabel>
                    </FormRow>
                </FormSection>
                <FormSection>
                    <FormSectionHeading>Director TWO</FormSectionHeading>
                    <FormRow>
                        <FormLabel>
                            Director TWO Name:
                            <FormInput
                                type="text"
                                name="NameTwo"
                                value={formData.NameTwo}
                                onChange={handleChange}
                            />
                        </FormLabel>
                        <FormLabel>
                            Director TWO Photo:
                            <FormInputFile
                                type="file"
                                name="PhotoTwo"
                                onChange={handleChange}
                            />
                        </FormLabel>
                        <FormLabel>
                            Director TWO Signature:
                            <FormInputFile
                                type="file"
                                name="SignatureTwo"
                                onChange={handleChange}
                            />
                        </FormLabel>
                        <FormLabel>
                            Director TWO Aadhar Card:
                            <FormInputFile
                                type="file"
                                name="AadharCardTwo"
                                onChange={handleChange}
                            />
                        </FormLabel>
                        <FormLabel>
                            Director TWO Qualification:
                            <FormInputFile
                                type="file"
                                name="QualificationTwo"
                                onChange={handleChange}
                            />
                        </FormLabel>
                        <FormLabel>
                            Director TWO Agreement:
                            <FormInputFile
                                type="file"
                                name="AgreementTwo"
                                onChange={handleChange}
                            />
                        </FormLabel>
                    </FormRow>
                </FormSection>
                <FormSection>
                    <FormSectionHeading>Director Three</FormSectionHeading>
                    <FormRow>
                        <FormLabel>
                            Director Three Name:
                            <FormInput
                                type="text"
                                name="NameThree"
                                value={formData.NameThree}
                                onChange={handleChange}
                            />
                        </FormLabel>
                        <FormLabel>
                            Director Three Photo:
                            <FormInputFile
                                type="file"
                                name="PhotoThree"
                                onChange={handleChange}
                            />
                        </FormLabel>
                        <FormLabel>
                            Director Three Signature:
                            <FormInputFile
                                type="file"
                                name="SignatureThree"
                                onChange={handleChange}
                            />
                        </FormLabel>
                        <FormLabel>
                            Director Three Aadhar Card:
                            <FormInputFile
                                type="file"
                                name="AadharCardThree"
                                onChange={handleChange}
                            />
                        </FormLabel>
                        <FormLabel>
                            Director Three Qualification:
                            <FormInputFile
                                type="file"
                                name="QualificationThree"
                                onChange={handleChange}
                            />
                        </FormLabel>
                        <FormLabel>
                            Director Three Agreement:
                            <FormInputFile
                                type="file"
                                name="AgreementThree"
                                onChange={handleChange}
                            />
                        </FormLabel>
                    </FormRow>
                </FormSection>

                <FormSubmitButton type="submit">Submit</FormSubmitButton>

            </Form>
            {message && (
                <FormMessage success={message.startsWith('Success')}>
                    {message}
                </FormMessage>
            )}

            {/* Popup Modal */}
            <PopupOverlay visible={popupVisible}>
                <PopupContent>
                    <p>Data added successfully!</p>
                    <PopupCloseButton onClick={() => setPopupVisible(false)}>Close</PopupCloseButton>
                </PopupContent>
            </PopupOverlay>

            <ToastContainer /> {/* Add the ToastContainer to your component */}
        </FormContainer>
    );
};

export default AddFranchise;