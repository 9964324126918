import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { FaEye, FaRupeeSign } from "react-icons/fa";
import { RiFileEditFill } from "react-icons/ri";

const TableContainer = styled.div`
  overflow-x: auto;
  width: calc(100vw - 270px);
  max-height: calc(100vh - 70px - 40px);
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 15px;

  th,
  td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    white-space: nowrap;
  }

  th {
    background-color: #008cba;
    color: #fff;
  }

  td {
    background-color: #f9f9f9;
  }
`;

const ViewButton = styled.button`
  background-color: #008cba;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px;

  &:hover {
    background-color: #005f75;
  }
`;

const Modal = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  font-size: 16px;
  height:80vh;
  width: 50vw;
  overflow-y: scroll;

  h2 {
    margin-top: 0;
    font-size: 22px;
  }

  p {
    margin: 8px 0;
    font-size: 14px;
  }

  button {
    background-color: #008cba;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #005f75;
    }
  }
`;

const ModalOverlay = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ViewStudent = () => {
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (student) => {
    setSelectedStudent(student);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedStudent(null);
  };

  const handleViewDocument = (filename) => {
    const url = `https://ims-nv9i.onrender.com/uploads/${filename}`;
    window.open(url, "_blank");
  };

  const handleEditStudent = (student) => {
    console.log(student.StudentId)
    navigate('/admin/edit/student', { state: { id: student.StudentId } });
  };

  const handlePayFeesStudent = (student) => {
    console.log(student.StudentId)
    navigate('/admin/fees/:StudentId', { state: { id: student.StudentId } });
  };


  useEffect(() => {
    var Id;
    const localdata = JSON.parse(localStorage.getItem("Data"));
    if (localdata) {
      if (localdata.EmployeeData) {
        Id = (localdata.EmployeeData.FranchiseId);
      } else if (localdata.FranchiseData) {
        Id = (localdata.FranchiseData.FranchiseId);
      } else {
        Id = ("Admin");
      }
    } else {
      Id = ("Admin");
    }
    axios
      .get("https://ims-nv9i.onrender.com/students/all")
      .then((response) => {
        const filterData = response.data.filter(data => data.FranchiseId === Id);
        console.log(filterData);
        setStudentData(filterData);
      })
      .catch((error) => {
        console.error("There was an error fetching the student data!", error);
      });
  }, []);

  const addCourse = (id) => {
    navigate('/AddStudentToBatchSA', { state: { id: id } })
  }

  return (
    <>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Student ID</th>
              <th>Student Name</th>
              <th>Mobile No</th>
              <th>Email</th>
              <th>City</th>
              <th>State</th>
              <th>Franchise ID</th>
              <th>Franchise Name</th>
              <th>Date</th>
              <th>Actions</th>
              <th>Add Course</th>
            </tr>
          </thead>
          <tbody>
            {studentData.map((student, index) => (
              <tr key={student._id}>
                <td>{index + 1}</td>
                <td>{student.StudentId}</td>
                <td>{student.StudentName}</td>
                <td>{student.MobileNo}</td>
                <td>{student.EmailId}</td>
                <td>{student.City}</td>
                <td>{student.State}</td>
                <td>{student.FranchiseId}</td>
                <td>{student.FranchiseName}</td>
                <td>{student.Course.DateOfAdmission}</td>
                <td>
                  <ViewButton onClick={() => openModal(student)}>
                    <FaEye />
                  </ViewButton>
                  <ViewButton onClick={() => handleEditStudent(student)}>
                    <RiFileEditFill />
                  </ViewButton>
                  <ViewButton onClick={() => handlePayFeesStudent(student)}>
                    <FaRupeeSign />
                  </ViewButton>
                </td>
                <td>
                  <ViewButton onClick={() => addCourse(student.StudentId)}>
                    Add
                  </ViewButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <ModalOverlay isOpen={isModalOpen} onClick={closeModal} />
      <Modal isOpen={isModalOpen}>
        {selectedStudent && (
          <>
            <Header>
              <h2>Student Details</h2>
              <button onClick={closeModal}>X</button>
            </Header>
            <p>
              <strong>ID:</strong> {selectedStudent.StudentId || "N/A"}
            </p>
            <p>
              <strong>Name:</strong> {selectedStudent.StudentName || "N/A"}
            </p>
            <p>
              <strong>Father's Name:</strong>{" "}
              {selectedStudent.FatherName || "N/A"}
            </p>
            <p>
              <strong>Mother's Name:</strong>{" "}
              {selectedStudent.MotherName || "N/A"}
            </p>
            <p>
              <strong>Gender:</strong> {selectedStudent.Gender || "N/A"}
            </p>
            <p>
              <strong>DOB:</strong> {selectedStudent.DOB || "N/A"}
            </p>
            <p>
              <strong>Qualification:</strong>{" "}
              {selectedStudent.Qualification || "N/A"}
            </p>
            <p>
              <strong>Aadhar No:</strong> {selectedStudent.AadharNo || "N/A"}
            </p>
            <p>
              <strong>Mobile No:</strong> {selectedStudent.MobileNo || "N/A"}
            </p>
            <p>
              <strong>Email:</strong> {selectedStudent.EmailId || "N/A"}
            </p>
            <p>
              <strong>Address:</strong> {selectedStudent.Address || "N/A"}
            </p>
            <p>
              <strong>City:</strong> {selectedStudent.City || "N/A"}
            </p>
            <p>
              <strong>State:</strong> {selectedStudent.State || "N/A"}
            </p>
            <p>
              <strong>Dist:</strong> {selectedStudent.Dist || "N/A"}
            </p>
            <p>
              <strong>Pin Code:</strong> {selectedStudent.PinCode || "N/A"}
            </p>
            <p>
              <strong>Franchise ID:</strong>{" "}
              {selectedStudent.FranchiseId || "N/A"}
            </p>
            <p>
              <strong>Franchise Name:</strong>{" "}
              {selectedStudent.FranchiseName || "N/A"}
            </p>
            <p>
              <strong>Date:</strong> {selectedStudent.Course.DateOfAdmission || "N/A"}
            </p>
            {selectedStudent.Documents && (
              <div>
                <h3>Documents</h3>
                <p>
                  <strong>Student Photo:</strong>{" "}
                  {selectedStudent.Documents.StudentPhoto ? (
                    <button
                      onClick={() =>
                        handleViewDocument(
                          selectedStudent.Documents.StudentPhoto
                        )
                      }
                      className="view-document-button">
                      View Document
                    </button>
                  ) : (
                    "N/A"
                  )}
                </p>
                <p>
                  <strong>Student Signature:</strong>{" "}
                  {selectedStudent.Documents.StudentSignature ? (
                    <button
                      onClick={() =>
                        handleViewDocument(
                          selectedStudent.Documents.StudentSignature
                        )
                      }
                      className="view-document-button">
                      View Document
                    </button>
                  ) : (
                    "N/A"
                  )}
                </p>
                <p>
                  <strong>Aadhar Card:</strong>{" "}
                  {selectedStudent.Documents.AadharCard ? (
                    <button
                      onClick={() =>
                        handleViewDocument(selectedStudent.Documents.AadharCard)
                      }
                      className="view-document-button">
                      View Document
                    </button>
                  ) : (
                    "N/A"
                  )}
                </p>
                <p>
                  <strong>Qualification File:</strong>{" "}
                  {selectedStudent.Documents.QualificationFile ? (
                    <button
                      onClick={() =>
                        handleViewDocument(
                          selectedStudent.Documents.QualificationFile
                        )
                      }
                      className="view-document-button">
                      View Document
                    </button>
                  ) : (
                    "N/A"
                  )}
                </p>
              </div>
            )}
            <button onClick={closeModal}>X</button>
          </>
        )}
      </Modal>
    </>
  );
};

export default ViewStudent;
