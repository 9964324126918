import React, { useState, useEffect } from 'react';
import { Hourglass } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import styled from 'styled-components';
import backgroundUrl from '../../assets/blank computer courses marksheet-1.png';
import QRCode from 'qrcode';

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Button = styled.button`
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #0056b3;
  }
`;

const PdfContainer = styled.div`
  width: 100%;
  height: 80vh;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
`;

const Iframe = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
`;

const DownloadLink = styled.a`
  margin-top: 20px;
  display: inline-block;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #218838;
    text-decoration: none;
  }
`;

const MarksSummary = styled.div`
font-size: 20px;
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Marksheet = () => {
  const { StudentId } = useParams();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [marksData, setMarksData] = useState(null);
  const [franchise, setFranchise] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(`https://ims-nv9i.onrender.com/students/get/${StudentId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch student data');
        }
        const studentData = await response.json();

        const franchiseResponse = await fetch(`https://ims-nv9i.onrender.com/franchises/${studentData.FranchiseId}`);
        if (!franchiseResponse.ok) {
          throw new Error('Failed to fetch franchise data');
        }
        const franchiseData = await franchiseResponse.json();

        setData(studentData);
        setFranchise(franchiseData);
        calculateMarks(studentData.Course.MarkSheet);

      } catch (err) {
        setError(err.message || 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [StudentId]);

  const calculateMarks = (markSheet) => {
    if (!markSheet || !markSheet.Subjects) return;

    const subjects = markSheet.Subjects;

    let superTotal = 0;
    let obtainedTotal = 0;
    let maxMarks = 0;

    const subjectsWithTotals = subjects.map(subject => {
      const totalMarks = subject.TheoryMarks + subject.PracticalMarks;
      const fullMarks = subject.FullMarks;
      const percentage = (totalMarks / fullMarks) * 100;

      superTotal += totalMarks;
      obtainedTotal += totalMarks;
      maxMarks += fullMarks;


      return {
        ...subject,
        TotalMarks: totalMarks,
        Percentage: percentage.toFixed(2),
      };
    });

    const finalPercentage = (obtainedTotal / maxMarks) * 100;
    const finalGrade = getGrade(finalPercentage);

    setMarksData({
      subjects: subjectsWithTotals,
      superTotal,
      maxMarks,
      finalPercentage: finalPercentage.toFixed(2),
      finalGrade
    });
  };

  const getGrade = (percentage) => {
    if (percentage >= 85) return 'A+';
    if (percentage >= 75) return 'A';
    if (percentage >= 65) return 'B';
    if (percentage >= 45) return 'C';
    return 'D';
  };

  const generatePdf = async () => {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: 'a4',
    });

    try {
      const backgroundImage = await loadImage(backgroundUrl);
      const studentPhotoUrl = `https://ims-nv9i.onrender.com/uploads/${data.Documents.StudentPhoto}`;
      const studentPhoto = await loadImage(studentPhotoUrl);

      const studentSignUrl = `https://ims-nv9i.onrender.com/uploads/${data.Documents.StudentSignature}`;
      const studentSign = await loadImage(studentSignUrl);

      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      doc.addImage(backgroundImage, 'PNG', 0, 0, pageWidth, pageHeight);

      if (studentPhoto) {
        doc.addImage(studentPhoto, 'JPEG', 320, 120, 40, 40);
      }
      if (studentSign) {
        doc.addImage(studentSign, 'JPEG', 320, 160, 40, 15);
    }

      doc.setFontSize(11);
      doc.setFont('Times', 'normal');
      doc.setTextColor(0, 0, 0);
      doc.text(`${data.StudentName}`, 139, 184);

      doc.setFontSize(11);
      doc.text(`${data.FatherName}`, 139, 201);

      doc.setFontSize(11);
      doc.text(`${data.MotherName}`, 139, 218);

      doc.setFontSize(11);
      doc.text(`${data.Course.CourseName}`, 139, 230);

      doc.setFontSize(11);
      doc.text(`${data.Course.CourseDuration}`, 139, 243);

      doc.setFontSize(11);
      doc.text(`${data.StudentId}`, 335, 184);

      doc.setFontSize(11);
      doc.text(`${data.DOB}`, 335, 201);

      doc.setFontSize(11);
      doc.text(`${data.AadharNo}`, 333, 218);

      const qrCodeDataUrl = await generateQRCode(`https://ims-frontend-self.vercel.app/verify/${data.StudentId}`);

      doc.addImage(qrCodeDataUrl, 'PNG', 63, 120, 40, 40);

      const startX = 90;
      const startY = 270;
      const tableWidth = pageWidth - 25;
      const rowHeight = 12;
      const colWidths = [tableWidth * 0.3, tableWidth * 0.08, tableWidth * 0.1, tableWidth * 0.08, tableWidth * 0.1];

      marksData.subjects.forEach((subject, index) => {
        const rowY = startY + (index + 1) * rowHeight;
        doc.text(subject.Title, startX, rowY);
        doc.text(subject.FullMarks.toString(), startX + colWidths[0], rowY);
        doc.text(subject.TheoryMarks.toString(), startX + colWidths[0] + colWidths[1], rowY);
        doc.text(subject.PracticalMarks.toString(), startX + colWidths[0] + colWidths[1] + colWidths[2], rowY);
        doc.text(subject.TotalMarks.toString(), startX + colWidths[0] + colWidths[1] + colWidths[2] + colWidths[3], rowY);
        doc.text(subject.Grade.toString(), startX + colWidths[0] + colWidths[1] + colWidths[2] + colWidths[3] + colWidths[4], rowY);
      });

      doc.setFontSize(9);
      doc.text(`${marksData.superTotal}`, 322, 386);
      doc.setFontSize(8);
      doc.text(`${marksData.finalPercentage}%`, 112, 385);
      doc.text(`${marksData.finalGrade}`, 169, 385);
      const franchiseSignUrl = `https://ims-nv9i.onrender.com/uploads/${franchise.DirectorOne.Signature}`;
      const franchiseSign = await loadImage(franchiseSignUrl);
      if (franchiseSign) {
        doc.addImage(franchiseSign, 'JPEG', 63, 510, 40, 20);
      }
      doc.text(`${franchise.DirectorOne.Name}`, 70, 550);
      doc.setFontSize(9);
      doc.text(`${data.Course.MarkSheet.CertificateNo}`, 230, 564);
      doc.text(`${data.Course.MarkSheet.MarkSheetDate}`, 230, 575);

      const pdfBlob = doc.output('blob');
      const pdfUrl = URL.createObjectURL(pdfBlob);

      setPdfUrl(pdfUrl);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL('image/png'));
      };
      img.onerror = reject;
      img.src = url;
    });
  };

  const generateQRCode = (text) => {
    return new Promise((resolve, reject) => {
      QRCode.toDataURL(text, { width: 40 }, (error, url) => {
        if (error) {
          console.error('Error generating QR code:', error);
          reject(error);
        } else {
          resolve(url);
        }
      });
    });
  };

  if (loading) {
    return <div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}> <Hourglass
      visible={true}
      height="80"
      width="80"
      ariaLabel="hourglass-loading"
      wrapperStyle={{}}
      wrapperClass=""
      colors={['#306cce', '#72a1ed']}
    /></div>;
  }

  return (
    <Container>
      <Button onClick={generatePdf}>Generate PDF</Button>
      {pdfUrl && (
        <PdfContainer>
          <Iframe src={pdfUrl} title="PDF Preview" />
          <DownloadLink href={pdfUrl} download="generated.pdf">
            Download PDF
          </DownloadLink>
        </PdfContainer>
      )}
      {marksData && (
        <MarksSummary>
          <h3>Results Summary</h3>
          <p><strong>Final Percentage:</strong> {marksData.finalPercentage}%</p>
          <p><strong>Final Grade:</strong> {marksData.finalGrade}</p>
        </MarksSummary>
      )}
    </Container>
  );
};

export default Marksheet;