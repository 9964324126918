import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import QRCode from 'qrcode';
import backgroundUrl from '../../assets/AUTHORISED TRAINING CERTIFICATE (32)-1.png';
import styled from 'styled-components';

const Container = styled.div`
    padding: 20px;
    text-align: center;
`;

const GenerateButton = styled.button`
    background-color: #008cba;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;

    &:hover {
        background-color: #005f75;
    }
`;

const PdfPreview = styled.iframe`
    border: none;
    margin-top: 20px;
    width: 100%;
    height: 600px;
`;

const DownloadLink = styled.a`
    display: inline-block;
    margin-top: 20px;
    color: #008cba;
    font-size: 16px;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const Atc = () => {
    const { id } = useParams();
    const [pdfUrl, setPdfUrl] = useState(null);
    const [data, setData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://ims-nv9i.onrender.com/franchises/${id}`);
                const Data = await response.json();
                setData(Data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [id]);

    const generatePdf = async () => {
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: 'a4',
        });

        const backgroundImage = await loadImage(backgroundUrl);
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        doc.addImage(backgroundImage, 'PNG', 0, 0, pageWidth, pageHeight);

        doc.setFontSize(24);
        doc.setFont('Times', 'normal');
        doc.setTextColor(0, 0, 0);
        const franchiseName = data.FranchiseName;
        const franchiseNameWidth = doc.getTextWidth(franchiseName);
        const xFranchise = (pageWidth - franchiseNameWidth) / 2;
        doc.text(franchiseName, xFranchise, 150);

        doc.setFontSize(12);
        const address = data.Address;
        const margin = 50;
        const maxWidth = pageWidth - margin * 2;
        const addressLines = doc.splitTextToSize(address, maxWidth);
        const addressY = 175;

        addressLines.forEach((line, index) => {
            const xAddress = (pageWidth - doc.getTextWidth(line)) / 2;
            doc.text(line, xAddress, addressY + (index * 20));
        });

        doc.setFontSize(12);
        doc.text(`${data.OwnerName}`, 220, 248);

        doc.setFontSize(12);
        doc.text(`${data.FranchiseId}`, 220, 262);

        doc.setFontSize(8);
        doc.text(`${data.RegisterDate}`, 220, 497.5);

        doc.setFontSize(8);
        doc.text(`${data.ExpiryDate}`, 220, 507);

        const qrCodeDataUrl = await generateQRCode(`https://ims-frontend-self.vercel.app/verify-atc/${data.FranchiseId}`);
        doc.addImage(qrCodeDataUrl, 'PNG', 55, 423, 40, 40);

        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
    };

    const loadImage = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                resolve(canvas.toDataURL('image/png'));
            };
            img.onerror = reject;
            img.src = url;
        });
    };

    const generateQRCode = (text) => {
        return new Promise((resolve, reject) => {
            QRCode.toDataURL(text, { width: 40 }, (error, url) => {
                if (error) {
                    console.error('Error generating QR code:', error);
                    reject(error);
                } else {
                    resolve(url);
                }
            });
        });
    };

    return (
        <Container>
            <GenerateButton onClick={generatePdf}>Generate PDF</GenerateButton>
            {pdfUrl && (
                <>
                    <PdfPreview src={pdfUrl} title="PDF Preview" />
                    <DownloadLink href={pdfUrl} download="generated.pdf">Download PDF</DownloadLink>
                </>
            )}
        </Container>
    );
};

export default Atc;
