import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AttendanceAdd = () => {
    const { id } = useParams();
    const [batchDetails, setBatchDetails] = useState(null);
    const [students, setStudents] = useState([]);
    const [attendance, setAttendance] = useState({});
    const [error, setError] = useState('');
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]); // Default to today’s date

    useEffect(() => {
        const fetchBatchDetails = async () => {
            try {
                const response = await fetch(`https://ims-nv9i.onrender.com/batch/${id}`);
                if (!response.ok) {
                    throw new Error(`Error fetching batch details: ${response.statusText}`);
                }
                const data = await response.json();
                setBatchDetails(data);

                const initialAttendance = {};
                data.StudentsId.forEach(studentId => {
                    initialAttendance[studentId] = 'present';
                });
                setAttendance(initialAttendance);
            } catch (error) {
                console.error('Error fetching batch details:', error);
                setError('Failed to load batch details');
            }
        };

        const fetchStudents = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/students/all');
                if (!response.ok) {
                    throw new Error(`Error fetching students: ${response.statusText}`);
                }
                const data = await response.json();
                setStudents(data);
            } catch (error) {
                console.error('Error fetching students:', error);
                setError('Failed to load students');
            }
        };

        fetchBatchDetails();
        fetchStudents();
    }, [id]);

    const getStudentNameById = (studentId) => {
        const student = students.find(student => student.StudentId === studentId);
        return student ? student.StudentName : 'Unknown Student';
    };

    const handleAttendanceChange = (studentId, status) => {
        setAttendance(prev => ({
            ...prev,
            [studentId]: status,
        }));
    };

    const handleDateChange = (event) => {
        setDate(event.target.value);
    };

    const handleSubmit = async () => {
        const presentStudents = [];
        const absentStudents = [];

        for (const [studentId, status] of Object.entries(attendance)) {
            if (status === 'present') {
                presentStudents.push(studentId);
            } else if (status === 'absent') {
                absentStudents.push(studentId);
            }
        }

        const attendanceData = {
            Date: date,
            FranchiseId: batchDetails.FranchiseId,
            Role: 'Student',
            BatchId: batchDetails.BatchId,
            PresentStu: presentStudents,
            AbsentStu: absentStudents,
        };

        try {
            const response = await fetch('https://ims-nv9i.onrender.com/attendanc/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(attendanceData),
            });

            if (!response.ok) {
                throw new Error('Failed to submit attendance');
            }

            const data = await response.json();
            console.log('Attendance saved:', data);
            toast.success('Attendance added successfully!');
            // Clear the input fields after successful submission
            setDate(new Date().toISOString().split('T')[0]);
            const clearedAttendance = {};
            batchDetails.StudentsId.forEach(studentId => {
                clearedAttendance[studentId] = 'present';
            });
            setAttendance(clearedAttendance);
        } catch (error) {
            console.error('Error saving attendance:', error);
            setError('Failed to save attendance');
        }
    };

    if (error) {
        return <Error>{error}</Error>;
    }

    if (!batchDetails || students.length === 0) {
        return <Loading>Loading...</Loading>;
    }

    return (
        <Container>
            <Title>Batch Details</Title>
            <Card>
                <CardHeader>{batchDetails.BatchName}</CardHeader>
                <CardContent>
                    <CardHeadingContent>
                        <p><strong>Batch ID:</strong> {batchDetails.BatchId}</p>
                        <p style={{textAlign:"right"}}><strong>Franchise ID:</strong> {batchDetails.FranchiseId}</p>
                        <p><strong>Course:</strong> {batchDetails.Course}</p>
                        <p style={{textAlign:"right"}}><strong>Session Time:</strong> {batchDetails.SessionTime}</p>
                    </CardHeadingContent>
                    <DateLabel>
                        <strong>Date:</strong>
                        <DateInput
                            type="date"
                            value={date}
                            onChange={handleDateChange}
                        />
                    </DateLabel>
                    <h3>Students</h3>
                    <Table>
                        <thead>
                            <TableRow>
                                <TableHeader>Student ID</TableHeader>
                                <TableHeader>Student Name</TableHeader>
                                <TableHeader>Attendance</TableHeader>
                            </TableRow>
                        </thead>
                        <tbody>
                            {batchDetails.StudentsId.length > 0 ? (
                                batchDetails.StudentsId.map((studentId) => (
                                    <TableRow key={studentId}>
                                        <TableData>{studentId}</TableData>
                                        <TableData>{getStudentNameById(studentId)}</TableData>
                                        <TableData>
                                            <AttendanceContainer>
                                                <AttendanceLabel>
                                                    <input
                                                        type="radio"
                                                        name={`attendance-${studentId}`}
                                                        value="present"
                                                        checked={attendance[studentId] === 'present'}
                                                        onChange={() => handleAttendanceChange(studentId, 'present')}
                                                    />
                                                    Present
                                                </AttendanceLabel>
                                                <AttendanceLabel>
                                                    <input
                                                        type="radio"
                                                        name={`attendance-${studentId}`}
                                                        value="absent"
                                                        checked={attendance[studentId] === 'absent'}
                                                        onChange={() => handleAttendanceChange(studentId, 'absent')}
                                                    />
                                                    Absent
                                                </AttendanceLabel>
                                            </AttendanceContainer>
                                        </TableData>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableData colSpan="3">No students enrolled</TableData>
                                </TableRow>
                            )}
                        </tbody>
                    </Table>
                    <ButtonContainer>
                        <SubmitButton onClick={handleSubmit}>Submit Attendance</SubmitButton>
                    </ButtonContainer>
                </CardContent>
            </Card>
            <ToastContainer />
        </Container>
    );
};

// Styled-components
const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 20px;
  background-color: #e9ecef;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 32px;
  margin-bottom: 20px;
  color: #343a40;
  text-align: center;
  font-weight: 600;
`;

const Card = styled.div`
  width: 100%;
  max-width: 900px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const CardHeader = styled.h2`
  margin-top: 0;
  font-size: 24px;
  color: #495057;
  margin-bottom: 20px;
`;

const CardHeadingContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px; /* Add some space between columns */
  padding-bottom: 20px;
`;

const CardContent = styled.div`
  font-size: 16px;
  color: #495057;
  line-height: 1.5;
`;

const DateLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #495057;
  margin-bottom: 20px;
`;

const DateInput = styled.input`
  margin-left: 10px;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  
  &:focus {
    border-color: #28a745;
    outline: none;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const TableRow = styled.tr`
  background-color: ${(props) => (props.odd ? '#f8f9fa' : '#ffffff')};
  &:hover {
    background-color: #e9ecef;
  }
`;

const TableHeader = styled.th`
  border: 1px solid #dee2e6;
  padding: 15px;
  background-color: #007bff;
  text-align: left;
  font-weight: 600;
  color: #fff;
`;

const TableData = styled.td`
  border: 1px solid #dee2e6;
  padding: 15px;
  color: #495057;
`;

const AttendanceContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const AttendanceLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #495057;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; /* Center the button horizontally */
  margin-top: 20px;
`;

const SubmitButton = styled.button`
  padding: 12px 24px;
  background-color: #28a745;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #218838;
  }
  
  &:focus {
    outline: none;
  }
`;

const Error = styled.p`
  color: #dc3545;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
`;

const Loading = styled.p`
  font-size: 18px;
  text-align: center;
  color: #6c757d;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

export default AttendanceAdd;
