import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Hourglass } from 'react-loader-spinner';
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import Sidebar from "./StudentSidebar"; // Update with the correct path to your Sidebar component

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  background-color: #f0f2f5;
`;

const Header = styled.header`
   height: 70px;
  background-color: #008cba;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const MainContent = styled.main`
  display: flex;
  flex: 1;
  padding: 20px;
  @media (max-width: 768px) {
    margin-left: 0;
    padding: 20px 10px;
  }
`;

const HamburgerButton = styled(FaBars)`
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 23px;
  display: ${(props) => (props.isOpen ? "none" : "block")};
  color: #ecf0f1;
  transition: color 0.3s ease;

  &:hover {
    color: #3498db;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const Container1 = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
`;

const Heading = styled.h1`
  font-size: 28px;
  margin-bottom: 20px;
  color: #2c3e50;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
`;

const SubHeading = styled.h2`
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #34495e;
`;

const Table = styled.table`
  width: 100%;
  font-size: 15px;
  border-collapse: separate;
  border-spacing: 0;
  margin: 20px 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
`;

const TableHead = styled.thead`
  background-color: #3498db;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  &:hover {
    background-color: #e6f7ff;
  }
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  word-break: break-word;
`;

const TableHeaderCell = styled.th`
  border: 1px solid #2980b9;
  padding: 12px;
  text-align: left;
  background-color: #008cba;
  color: white;
  font-weight: bold;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #27ae60;
  }
`;

const NoDataMessage = styled.p`
  color: #7f8c8d;
  font-style: italic;
  font-size: 12px;
`;

const StudentExam = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [student, setStudent] = useState({});
    const navigate = useNavigate();
    const [liveExams, setLiveExams] = useState([]);
    const [completedExams, setCompletedExams] = useState([]);
    const [upcomingExams, setUpcomingExams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        const Data = JSON.parse(localStorage.getItem("Data"));
        const StudentId = Data?.StudentData?.StudentId;
        const fetchExams = async () => {
            try {
                const examResponse = await fetch('https://ims-nv9i.onrender.com/examQuestions/all');
                if (!examResponse.ok) {
                    throw new Error('Network response was not ok');
                }
                const examData = await examResponse.json();

                const studentResponse = await fetch(`https://ims-nv9i.onrender.com/students/get/${StudentId}`);
                if (!studentResponse.ok) {
                    throw new Error('Network response was not ok');
                }
                const studentData = await studentResponse.json();
                setStudent(studentData);
                console.log(studentData.Course.Exam)

                const batchIds = studentData.Course.BatchId;
                const courseIds = studentData.Course.CourseId;

                const filteredExams = examData.filter(exam =>
                    exam.BatchId.some(id => batchIds.includes(id)) &&
                    courseIds.includes(exam.CourseId)
                );

                categorizeEvents(filteredExams);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchExams();
    }, []);

    const categorizeEvents = (data) => {
        const now = new Date();
        const live = [];
        const completed = [];
        const upcoming = [];

        data.forEach(event => {
            const eventDate = new Date(event.DateTime);
            const eventEndTime = new Date(eventDate.getTime() + event.Duration * 60000);

            if (now >= eventDate && now <= eventEndTime) {
                live.push(event);
            } else if (now > eventEndTime) {
                completed.push(event);
            } else {
                upcoming.push(event);
            }
        });

        setLiveExams(live);
        setCompletedExams(completed);
        setUpcomingExams(upcoming);
    };

    if (loading) {
        return <div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Hourglass
                visible={true}
                height="80"
                width="80"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={['#306cce', '#72a1ed']}
            />
        </div>;
    }

    if (error) {
        return <Container>Error: {error.message}</Container>;
    }

    return (
        <DashboardContainer>
            <Header>
                Student Dashboard
                <HamburgerButton isOpen={isSidebarOpen} onClick={toggleSidebar} />
            </Header>
            <Container>
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <MainContent>
                    <Container1>
                        <Heading>Exams</Heading>

                        <SubHeading>Live Exams</SubHeading>
                        {liveExams.length === 0 ? <NoDataMessage>No live exams</NoDataMessage> : (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableHeaderCell>Franchise Name</TableHeaderCell>
                                        <TableHeaderCell>Name</TableHeaderCell>
                                        <TableHeaderCell>Course ID</TableHeaderCell>
                                        <TableHeaderCell>Duration (minutes)</TableHeaderCell>
                                        <TableHeaderCell>Date & Time</TableHeaderCell>
                                        <TableHeaderCell>Action</TableHeaderCell>
                                    </TableRow>
                                </TableHead>
                                <tbody>
                                    {liveExams.map(event => (
                                        <TableRow key={event._id}>
                                            <TableCell>{event.FranchiseName}</TableCell>
                                            <TableCell>{event.Name}</TableCell>
                                            <TableCell>{event.CourseId}</TableCell>
                                            <TableCell>{event.Duration}</TableCell>
                                            <TableCell>{new Date(event.DateTime).toLocaleString()}</TableCell>
                                            <TableCell>{student.Course.Exam.filter(data=>data.ExamId===event._id)?"Submitted":<Button onClick={() => navigate('/exampaper', { state: { id: event._id } })}>Start Test</Button>}</TableCell>
                                        </TableRow>
                                    ))}
                                </tbody>
                            </Table>
                        )}

                        <SubHeading>Past Exams</SubHeading>
                        {completedExams.length === 0 ? <NoDataMessage>No completed exams</NoDataMessage> : (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableHeaderCell>Franchise Name</TableHeaderCell>
                                        <TableHeaderCell>Name</TableHeaderCell>
                                        <TableHeaderCell>Course ID</TableHeaderCell>
                                        <TableHeaderCell>Duration (minutes)</TableHeaderCell>
                                        <TableHeaderCell>Date & Time</TableHeaderCell>
                                    </TableRow>
                                </TableHead>
                                <tbody>
                                    {completedExams.map(event => (
                                        <TableRow key={event._id}>
                                            <TableCell>{event.FranchiseName}</TableCell>
                                            <TableCell>{event.Name}</TableCell>
                                            <TableCell>{event.CourseId}</TableCell>
                                            <TableCell>{event.Duration}</TableCell>
                                            <TableCell>{new Date(event.DateTime).toLocaleString()}</TableCell>
                                        </TableRow>
                                    ))}
                                </tbody>
                            </Table>
                        )}

                        <SubHeading>Upcoming Exams</SubHeading>
                        {upcomingExams.length === 0 ? <NoDataMessage>No upcoming exams</NoDataMessage> : (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableHeaderCell>Franchise Name</TableHeaderCell>
                                        <TableHeaderCell>Name</TableHeaderCell>
                                        <TableHeaderCell>Course ID</TableHeaderCell>
                                        <TableHeaderCell>Duration (minutes)</TableHeaderCell>
                                        <TableHeaderCell>Date & Time</TableHeaderCell>
                                    </TableRow>
                                </TableHead>
                                <tbody>
                                    {upcomingExams.map(event => (
                                        <TableRow key={event._id}>
                                            <TableCell>{event.FranchiseName}</TableCell>
                                            <TableCell>{event.Name}</TableCell>
                                            <TableCell>{event.CourseId}</TableCell>
                                            <TableCell>{event.Duration}</TableCell>
                                            <TableCell>{new Date(event.DateTime).toLocaleString()}</TableCell>
                                        </TableRow>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </Container1>
                </MainContent>
            </Container>
        </DashboardContainer>
    );
};

export default StudentExam;