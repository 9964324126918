import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoMdClose } from 'react-icons/io';
import { set } from 'react-hook-form';

const AddExam = () => {
    const [franchises, setFranchises] = useState([]);
    // const [formSubmitting,  ]
    const [courses, setCourses] = useState([]);
    const [batches, setBatches] = useState([]);
    const [filterBatches, setFilterBatches] = useState([]);
    const [filterBatches1, setFilterBatches1] = useState([]);
    const [sets, setSets] = useState([]);
    const [selectedSets, setSelectedSets] = useState([]);
    const [selectedBatches, setSelectedBatches] = useState([]);
    const [formData, setFormData] = useState({
        FranchiseId: '',
        FranchiseName: '',
        Name: '',
        Passing: '',
        CourseId: '',
        CourseName: '',
        Duration: '',
        Sets: [],
        DateTime: '',
        BatchId: [],
        BatchName: [],
        StudentNo: '',
        CenterId: '',
        CenterName: ''
    });

    useEffect(() => {
        const fetchFranchises = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/franchises/all');
                const data = await response.json();
                setFranchises(data);
            } catch (error) {
                console.error('Error fetching franchises:', error);
            }
        };

        const fetchCourses = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/courses/all');
                const data = await response.json();
                setCourses(data);
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };

        const fetchBatches = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/batch/all');
                const data = await response.json();
                setBatches(data);
            } catch (error) {
                console.error('Error fetching batches:', error);
            }
        };

        const fetchSets = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/set/all');
                const data = await response.json();
                setSets(data);
            } catch (error) {
                console.error('Error fetching sets:', error);
            }
        };

        fetchFranchises();
        fetchCourses();
        fetchBatches();
        fetchSets();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const selectedOption = e.target.options?.[e.target.selectedIndex]?.text;

        if (name === 'FranchiseId') {
            if (value === 'ALL') {
                // Set all franchise IDs
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    FranchiseId: 'ALL',
                    FranchiseName: 'ALL',
                    CourseId: '',
                    CourseName: ''
                }));
                setFilterBatches(batches);

            } else {
                // Set the selected franchise ID
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    FranchiseId: value,
                    FranchiseName: selectedOption,
                    CourseId: '',
                    CourseName: ''
                }));
                const batch = batches.filter(batch => batch.FranchiseId === value);
                setFilterBatches(batch);
            }
        } else if (name === 'CenterId') {
            if (value === 'ALL') {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    CenterId: 'ALL',
                    CenterName: 'ALL',
                }));
            } else {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    CenterId: value,
                    CenterName: selectedOption,
                }));
            }
        } else {

            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value
            }));

            if (name === 'CourseId') {
                const course = courses.find(cou => cou.CourseId === value);
                console.log(value, course.CourseName);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    CourseId: value,
                    CourseName: course.CourseName,
                }));
                const batch = filterBatches.filter(batch => batch.Course === course.CourseName);
                setFilterBatches1(batch);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const finalData = {
            ...formData,
            Sets: selectedSets,
            BatchId: selectedBatches.map(batch => batch.id),
            BatchName: selectedBatches.map(batch => batch.name)
        };

        try {
            const response = await fetch('https://ims-nv9i.onrender.com/examQuestions/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(finalData)
            });

            if (response.ok) {
                const result = await response.json();
                toast.success('Exam added successfully!');
                // Clear the form
                setFormData({
                    FranchiseId: '',
                    FranchiseName: '',
                    Name: '',
                    Passing: '',
                    CourseId: '',
                    CourseName: '',
                    Duration: '',
                    Sets: [],
                    DateTime: '',
                    BatchId: [],
                    BatchName: [],
                    StudentNo: '',
                    CenterId: '',
                    CenterName: ''
                });
                setSelectedSets([]);
                setSelectedBatches([]);
            } else {
                const errorText = await response.text();
                toast.error(`Failed to add exam: ${errorText}`);
            }
        } catch (error) {
            toast.error('Error adding exam.');
            console.error('Error adding exam question:', error);
        }
    };

    const handleSetClick = (set) => {
        if (!selectedSets.includes(set)) {
            setSelectedSets([...selectedSets, set]);
        }
    };

    const handleRemoveSet = (set) => {
        setSelectedSets(selectedSets.filter((selectedSet) => selectedSet !== set));
    };

    const handleBatchClick = (batch) => {
        if (!selectedBatches.some(selectedBatch => selectedBatch.id === batch.BatchId)) {
            setSelectedBatches([...selectedBatches, { id: batch.BatchId, name: batch.BatchName }]);
        }
    };

    const handleRemoveBatch = (batch) => {
        setSelectedBatches(selectedBatches.filter((selectedBatch) => selectedBatch.id !== batch.id));
    };

    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                <Section>
                    <Label htmlFor="FranchiseId">Select Franchise</Label>
                    <Select name="FranchiseId" id="FranchiseId" value={formData.FranchiseId} onChange={handleChange}>
                        <option value="">Select Franchise</option>
                        <option value="ALL">All Franchises</option>
                        {franchises.map((franchise) => (
                            <option key={franchise._id} value={franchise.FranchiseId}>{franchise.FranchiseName}</option>
                        ))}
                    </Select>
                </Section>
                <Section>
                    <Input
                        type="text"
                        name="Name"
                        placeholder="Exam Name"
                        value={formData.Name}
                        onChange={handleChange}
                    />
                    <Input
                        type="text"
                        name="Passing"
                        placeholder="Pass Marks"
                        value={formData.Passing}
                        onChange={handleChange}
                    />
                    <Input
                        type="text"
                        name="Duration"
                        placeholder="Exam Duration"
                        value={formData.Duration}
                        onChange={handleChange}
                    />
                    <Input
                        type="datetime-local"
                        name="DateTime"
                        id="examDate"
                        value={formData.DateTime}
                        onChange={handleChange}
                    />
                    <Select name="Sets" id="Sets" onChange={(e) => handleSetClick(e.target.value)}>
                        <option value="">Select Sets</option>
                        {sets.map((set) => (
                            <option key={set._id} value={set.Name}>{set.Name}</option>
                        ))}
                    </Select>
                    <Select name="CourseId" id="CourseId" value={formData.CourseId} onChange={handleChange}>
                        <option value="">Select Courses</option>
                        {courses.map((course) => (
                            <option key={course._id} value={course.CourseId}>{course.CourseName}</option>
                        ))}
                    </Select>
                    <Select name="BatchId" id="BatchId" onChange={(e) => handleBatchClick({
                        BatchId: e.target.value,
                        BatchName: e.target.options[e.target.selectedIndex].text
                    })}>
                        <option value="">Select Batch</option>
                        {filterBatches1.map((batch) => (
                            <option key={batch._id} value={batch.BatchId}>{batch.BatchName}</option>
                        ))}
                    </Select>
                    <Input
                        type="number"
                        name="StudentNo"
                        placeholder="No of Students"
                        value={formData.StudentNo}
                        onChange={handleChange}
                    />
                </Section>
                <Select name="CenterId" id="CenterId" value={formData.CenterId} onChange={handleChange}>
                    <option value="">Select Center for Exam</option>
                    <option value="ALL">All Franchises</option>
                    {franchises.map((franchise) => (
                        <option key={franchise._id} value={franchise.FranchiseId}>{franchise.FranchiseName}</option>
                    ))}
                </Select>
                <Section>
                    <Label style={{marginTop:"10px"}}>Selected Sets</Label>
                    <List>
                        {selectedSets.map((set, index) => (
                            <ListItem key={index}>
                                {set}
                                <RemoveButton type="button" onClick={() => handleRemoveSet(set)}>
                                    <IoMdClose />
                                </RemoveButton>
                            </ListItem>
                        ))}
                    </List>
                    <Label style={{marginTop:"10px"}}>Selected Batch</Label>
                    <List>
                        {selectedBatches.map((batch, index) => (
                            <ListItem key={index}>
                                {batch.name}
                                <RemoveButton type="button" onClick={() => handleRemoveBatch(batch)}>
                                    <IoMdClose />
                                </RemoveButton>
                            </ListItem>
                        ))}
                    </List>
                </Section>
                <SubmitButton type="submit">Submit</SubmitButton>
            </Form>
            <ToastContainer />
        </Container>
    );
};

// Styled-components
const Container = styled.section`
    display: flex;
    width:100%;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 70px);
    background-color: #f9f9f9;
    padding: 20px;
`;

const Form = styled.form`
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 800px;
    width: 100%;
`;

const Section = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.label`
    display: block;
    font-size: 16px;
    margin-bottom: 8px;
    color: #333;
`;

const Select = styled.select`
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    font-size: 16px;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    font-size: 16px;
`;

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const ListItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
`;

const RemoveButton = styled.button`
    background: none;
    border: none;
    color: #dc3545;
    cursor: pointer;
    font-size: 20px;
    &:hover {
        color: #c82333;
    }
`;

const SubmitButton = styled.button`
    width: 100%;
    padding: 15px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
        background-color: #218838;
    }
    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

export default AddExam;
