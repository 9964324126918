import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CSVLink } from 'react-csv';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ViewResults = () => {
    const [marksheetData, setMarksheetData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [sortedData, setSortedData] = useState([]);
    const [sortType, setSortType] = useState('none');
    const [error, setError] = useState('');
    const [selectedFranchiseId, setSelectedFranchiseId] = useState('all');
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [selectedCourseName, setSelectedCourseName] = useState('all');
    const [franchiseOptions, setFranchiseOptions] = useState([]);
    const [courseOptions, setCourseOptions] = useState([]);

    useEffect(() => {
        const fetchMarksheetData = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/marksheet/all');
                if (!response.ok) {
                    throw new Error(`Error fetching marksheet data: ${response.statusText}`);
                }
                const { data } = await response.json();
                setMarksheetData(data);

                // Set options for filters
                const uniqueFranchiseIds = Array.from(new Set(data.map(item => item.FranchiseId).filter(id => id)));
                setFranchiseOptions(['all', ...uniqueFranchiseIds]);

                const uniqueCourseNames = Array.from(new Set(data.map(item => item.CourseName).filter(name => name)));
                setCourseOptions(['all', ...uniqueCourseNames]);

                // Apply filters initially
                applyFilters(data);
            } catch (error) {
                console.error('Error fetching marksheet data:', error);
                setError('Failed to load marksheet data');
                toast.error('Failed to load marksheet data');
            }
        };

        fetchMarksheetData();
    }, []);

    useEffect(() => {
        applyFilters(marksheetData);
    }, [selectedFranchiseId, selectedStatus, selectedCourseName]);

    useEffect(() => {
        handleSort(sortType);
    }, [filteredData, sortType]);

    const applyFilters = (data) => {
        let filteredArray = data;

        // Apply FranchiseId filter
        if (selectedFranchiseId !== 'all') {
            filteredArray = filteredArray.filter(item => item.FranchiseId === selectedFranchiseId);
        }

        // Apply Status filter
        if (selectedStatus !== 'all') {
            filteredArray = filteredArray.filter(item => item.Status.toString() === selectedStatus);
        }

        // Apply CourseName filter
        if (selectedCourseName !== 'all') {
            filteredArray = filteredArray.filter(item => item.CourseName === selectedCourseName);
        }

        setFilteredData(filteredArray);
    };

    const handleSort = (type) => {
        let sortedArray = [...filteredData];
        if (type === 'registration') {
            sortedArray.sort((a, b) => a.RegistrationNo.localeCompare(b.RegistrationNo));
        } else if (type === 'marks') {
            sortedArray.sort((a, b) => calculateTotalMarks(b.Subjects) - calculateTotalMarks(a.Subjects));
        }
        setSortedData(sortedArray);
    };

    const calculateTotalMarks = (subjects) => {
        return subjects.reduce((total, subject) => {
            return total + subject.TheoryMarks + subject.PracticalMarks;
        }, 0);
    };

    const csvHeaders = [
        { label: "Registration No", key: "RegistrationNo" },
        { label: "Date", key: "Date" },
        { label: "Course Name", key: "CourseName" },
        { label: "Total Marks", key: "totalMarks" }
    ];

    const csvData = sortedData.map(row => ({
        RegistrationNo: row.RegistrationNo,
        Date: row.Date,
        CourseName: row.CourseName,
        totalMarks: calculateTotalMarks(row.Subjects)
    }));

    return (
        <Container>
            <Header>Marksheet Data</Header>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <FiltersContainer>
                <FilterWrapper>
                    <FilterSelect value={selectedFranchiseId} onChange={(e) => setSelectedFranchiseId(e.target.value)}>
                        {franchiseOptions.map((franchise, index) => (
                            <option key={index} value={franchise}>
                                {franchise === 'all' ? 'All Franchises' : franchise}
                            </option>
                        ))}
                    </FilterSelect>

                    <FilterSelect value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                        <option value="all">All Statuses</option>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                    </FilterSelect>

                    <FilterSelect value={selectedCourseName} onChange={(e) => setSelectedCourseName(e.target.value)}>
                        {courseOptions.map((course, index) => (
                            <option key={index} value={course}>
                                {course === 'all' ? 'All Courses' : course}
                            </option>
                        ))}
                    </FilterSelect>
                </FilterWrapper>
                
                <ButtonWrapper>
                    <Button onClick={() => handleSort('registration')}>Sort by Registration No</Button>
                    <Button onClick={() => handleSort('marks')}>Sort by Total Marks</Button>
                    <CSVButton>
                        <CSVLink
                            data={csvData}
                            headers={csvHeaders}
                            filename={"marksheet_data.csv"}
                            style={{ color: 'white', textDecoration: 'none' }}
                        >
                            Export to CSV
                        </CSVLink>
                    </CSVButton>
                </ButtonWrapper>
            </FiltersContainer>
            <Table>
                <thead>
                    <tr>
                        <th>Registration No</th>
                        <th>Date</th>
                        <th>Course Name</th>
                        <th>Total Marks</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedData.length > 0 ? (
                        sortedData.map((row, index) => (
                            <tr key={index}>
                                <td>{row.RegistrationNo}</td>
                                <td>{row.Date}</td>
                                <td>{row.CourseName}</td>
                                <td>{calculateTotalMarks(row.Subjects)}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4">No results available.</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <ToastContainer />
        </Container>
    );
};

// Styled-components
const Container = styled.div`
    padding: 20px;
    background-color: #f9f9f9;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Header = styled.h1`
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
`;

const ErrorMessage = styled.p`
    color: #dc3545;
    font-size: 16px;
    margin-bottom: 20px;
`;

const FiltersContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
`;

const FilterWrapper = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
`;

const FilterSelect = styled.select`
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
    flex: 1;
`;

const ButtonWrapper = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
`;

const Button = styled.button`
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #008cba;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #005f6b;
    }
`;

const CSVButton = styled.div`
    a {
        display: inline-block;
        padding: 10px 20px;
        border-radius: 4px;
        background-color: #28a745;
        color: white;
        font-size: 16px;
        text-decoration: none;
        text-align: center;
        transition: background-color 0.3s;

        &:hover {
            background-color: #218838;
        }
    }
`;

const Table = styled.table`
    font-size: 15px;
    width: 100%;
    max-width: 1200px;
    border-collapse: collapse;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;

    th,
    td {
        padding: 10px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    th {
        background-color: #008cba;
        color: #fff;
    }

    tbody tr:nth-child(even) {
        background-color: #f2f2f2;
    }
`;

export default ViewResults;
