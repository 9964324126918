import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import Sidebar from "./StudentSidebar";


const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  background-color: #f0f2f5;
`;

const Header = styled.header`
  height: 70px;
  background-color: #008cba;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  position: relative;
`;

const MainContent = styled.main`
  /* display: flex; */
  flex: 1;
  @media (max-width: 768px) {
    margin-left: 0;
    padding: 20px 10px;
  }
`;

const HamburgerButton = styled(FaBars)`
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 20px;
  display: ${(props) => (props.isOpen ? "none" : "block")};

  @media (min-width: 769px) {
    display: none;
  }
`;


const StudentFeeDetails = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  useEffect(() => {
    fetch(`https://ims-nv9i.onrender.com/fee/${location.state.id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [location.state.id]);

  const handleBackClick = () => {
    navigate(-1);
  };

  if (loading) {
    return <LoadingMessage>Loading...</LoadingMessage>;
  }

  if (error) {
    return <ErrorMessage>Error: {error.message}</ErrorMessage>;
  }

  if (!data || !data.Payment) {
    return <NoDataMessage>No data available</NoDataMessage>;
  }

  return (
    <DashboardContainer>
    <Header>
      Admin Dashboard
      <HamburgerButton isOpen={isSidebarOpen} onClick={toggleSidebar} />
    </Header>
    <Container>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <MainContent>
    <Container1>
      <BackButton onClick={handleBackClick}>Back</BackButton>
      <Title>Student Fee Details</Title>
      <DetailSection>
        <Detail>
          <strong>Student ID:</strong> {data.StudentID}
        </Detail>
        <Detail>
          <strong>Course ID:</strong> {data.CourseId}
        </Detail>
        <Detail>
          <strong>Franchise ID:</strong> {data.FranchiseId}
        </Detail>
        <Detail>
          <strong>Total Fee:</strong> ₹{data.TotalFee}
        </Detail>
        <Detail>
          <strong>Due Fee:</strong> ₹{data.Payment[data.Payment.length - 1].DueFee}
        </Detail>
      </DetailSection>

      <Subtitle>Payment History</Subtitle>
      <TableWrapper>
        <Table>
          <thead>
            <TableRow>
              <Th>Date</Th>
              <Th>Received Fee</Th>
              <Th>Due Fee</Th>
            </TableRow>
          </thead>
          <tbody>
            {data.Payment.map((payment) => (
              <TableRow key={payment._id}>
                <Td>{payment.Date}</Td>
                <Td>₹{payment.ReceivedFee}</Td>
                <Td>₹{payment.DueFee}</Td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </Container1>
    </MainContent>
      </Container>
    </DashboardContainer>
  );
};

// Styled components
const Container1 = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const BackButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const Title = styled.h1`
  color: #333;
  margin: 20px 0;
`;

const DetailSection = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const Detail = styled.p`
  margin: 10px 0;
  font-size: 16px;
  color: #444;

  strong {
    color: #333;
  }
`;

const Subtitle = styled.h2`
  color: #444;
  margin-top: 30px;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f8f9fa;
  }
`;

const Th = styled.th`
  border: 1px solid #dee2e6;
  padding: 12px;
  text-align: left;
  background-color: #e9ecef;
  color: #333;
  font-weight: bold;
`;

const Td = styled.td`
  border: 1px solid #dee2e6;
  padding: 12px;
  text-align: left;
  color: #444;
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 18px;
  margin-top: 50px;
  color: #666;
`;

const ErrorMessage = styled.div`
  text-align: center;
  font-size: 18px;
  margin-top: 50px;
  color: #dc3545;
`;

const NoDataMessage = styled.div`
  text-align: center;
  font-size: 18px;
  margin-top: 50px;
  color: #666;
`;

export default StudentFeeDetails;