import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
`;

const TableHeader = styled.th`
  background-color: #007bff;
  color: white;
  padding: 12px;
  border: 1px solid #ddd;
`;

const TableCell = styled.td`
  padding: 12px;
  border: 1px solid #ddd;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  background-color: #28a745;
  color: white;
  cursor: pointer;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #218838;
  }
`;

const NoDataMessage = styled.div`
  text-align: center;
  color: #dc3545;
  font-size: 18px;
`;

const MarksheetList = () => {
    const navigate = useNavigate();
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const localdata = JSON.parse(localStorage.getItem("Data"));
        const Id = localdata.EmployeeData?.FranchiseId || localdata.FranchiseData?.FranchiseId || "Admin";

        const fetchStudentData = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/students/all');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const filteredData = data.filter((student) => Id === student.FranchiseId);
                setStudents(filteredData);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchStudentData();
    }, []);

    const addMarks = (StudentId, CourseId) => {
        navigate(`/addmarks/${StudentId}/${CourseId}`);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (students.length === 0) return <NoDataMessage>No current Data</NoDataMessage>;

    return (
        <Container>
            <Table>
                <thead>
                    <tr>
                        <TableHeader>Student ID</TableHeader>
                        <TableHeader>Student Name</TableHeader>
                        <TableHeader>Course Name</TableHeader>
                        <TableHeader>Exam Type</TableHeader>
                        <TableHeader>Batch ID</TableHeader>
                        <TableHeader>Batch Name</TableHeader>
                        <TableHeader>Add Marks</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {students.map((student) => (
                        student.Courses.length > 0 ? (
                            student.Courses.map((course, index) => (
                                <tr key={`${student.StudentId}-${index}`}>
                                    {index === 0 && (
                                        <>
                                            <TableCell rowSpan={student.Courses.length}>{student.StudentId}</TableCell>
                                            <TableCell rowSpan={student.Courses.length}>{student.StudentName}</TableCell>
                                        </>
                                    )}
                                    <TableCell>{course.CourseName}</TableCell>
                                    <TableCell>{course.ExamType}</TableCell>
                                    <TableCell>{course.BatchId}</TableCell>
                                    <TableCell>{course.BatchName}</TableCell>
                                    <TableCell>
                                        {course.Result ? "Marks Added" : 
                                            <Button onClick={() => addMarks(student.StudentId, course.CourseId)}>
                                                Add Marks
                                            </Button>
                                        }
                                    </TableCell>
                                </tr>
                            ))
                        ) : (
                            <tr key={student.StudentId}>
                                <TableCell>{student.StudentId}</TableCell>
                                <TableCell>{student.StudentName}</TableCell>
                                <TableCell colSpan="6" style={{ textAlign: 'center' }}>No Courses</TableCell>
                            </tr>
                        )
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default MarksheetList;
