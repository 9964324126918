import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  margin: 20px;
  font-family: Arial, sans-serif;
`;

const Heading = styled.h1`
  text-align: center;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
`;

const Button = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const MarksheetData = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [franchiseId, setFranchiseId] = useState('');

    // Fetch FranchiseId from local storage
    useEffect(() => {
        const localData = JSON.parse(localStorage.getItem('Data'));
        const id = localData.EmployeeData?.FranchiseId || localData.FranchiseData?.FranchiseId || "Admin"; // Adjust the key as necessary
        setFranchiseId(id);
    }, []);

    useEffect(() => {
        fetch('https://ims-nv9i.onrender.com/marksheet/all')
            .then(response => response.json())
            .then(data => {
                const allData = data.data;
                setData(allData);

                // Filter data based on FranchiseId
                const filtered = allData.filter(item => item.FranchiseId === franchiseId);
                setFilteredData(filtered);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, [franchiseId]); // Refetch data when FranchiseId changes

    const goToMark = (reg, couid) => {
        navigate(`/marksheetprint/${reg}/${couid}`);
    };

    const goToCert = (reg, couid) => {
        navigate(`/certificatec/${reg}/${couid}`);
    };

    const toggleStatus = async (regNo, courseId, stat) => {
        const value = !stat;
        try {
            const response = await fetch(`https://ims-nv9i.onrender.com/marksheet/${regNo}/${courseId}/status`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: value }),
            });
            const result = await response.json();
            if (response.ok) {
                alert("Success");
                fetch('https://ims-nv9i.onrender.com/marksheet/all')
                    .then(response => response.json())
                    .then(data => {
                        const allData = data.data;
                        setData(allData);

                        // Filter data based on FranchiseId
                        const filtered = allData.filter(item => item.FranchiseId === franchiseId);
                        setFilteredData(filtered);
                    })
                    .catch(error => console.error('Error fetching data:', error));
            } else {
                console.error('Error updating status:', result.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <Container>
            <Heading>Marksheet Data</Heading>
            <Table>
                <thead>
                    <tr>
                        <TableHeader>MarkSheet No</TableHeader>
                        <TableHeader>Student Name</TableHeader>
                        <TableHeader>Father Name</TableHeader>
                        <TableHeader>Mother Name</TableHeader>
                        <TableHeader>Course Name</TableHeader>
                        <TableHeader>Course Duration</TableHeader>
                        <TableHeader>Status</TableHeader>
                        <TableHeader>MarkSheet</TableHeader>
                        <TableHeader>Certificate</TableHeader>
                        <TableHeader>Action</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((item, index) => (
                        <tr key={index}>
                            <TableCell>{item.MarkSheetNo}</TableCell>
                            <TableCell>{item.StudentName}</TableCell>
                            <TableCell>{item.FatherName}</TableCell>
                            <TableCell>{item.MotherName}</TableCell>
                            <TableCell>{item.CourseName}</TableCell>
                            <TableCell>{item.CourseDuration}</TableCell>
                            <TableCell>{item.Status ? "Active" : "Inactive"}</TableCell>
                            <TableCell>
                                <Button onClick={() => goToMark(item.RegistrationNo, item.CourseId)}>
                                    Marksheet
                                </Button>
                            </TableCell>
                            <TableCell>
                                <Button onClick={() => goToCert(item.RegistrationNo, item.CourseId)}>
                                    Certificate
                                </Button>
                            </TableCell>
                            <TableCell>
                                <Button onClick={() => toggleStatus(item.RegistrationNo, item.CourseId, item.Status)}>
                                    {item.Status ? "Deactivate" : "Activate"}
                                </Button>
                            </TableCell>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default MarksheetData;
