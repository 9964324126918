import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Hourglass } from 'react-loader-spinner';
import { FaBars } from "react-icons/fa";
import Sidebar from "./StudentSidebar"; // Update with the correct path to your Sidebar component

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: #f0f2f5;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Header = styled.header`
  height: 70px;
  background-color: #008cba;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  position: relative;
`;

const MainContent = styled.main`
  flex: 1;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px;
  
  @media (max-width: 768px) {
    padding: 20px;
    margin: 10px;
  }
`;

const HamburgerButton = styled(FaBars)`
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 20px;
  display: ${(props) => (props.isOpen ? "none" : "block")};
  @media (min-width: 769px) {
    display: none;
  }
`;

const Loading = styled.p`
  font-size: 18px;
  color: #007bff;
  text-align: center;
  margin-top: 40px;
`;

const Error = styled.p`
  font-size: 18px;
  color: #dc3545;
  text-align: center;
  margin-top: 40px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  padding: 10px;
`;

const StudentProfile = styled.div`
  background: #f8f9fa;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
`;

const StudentProfileUpper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const Left = styled.div`
  width: 60%;
  margin: 10px 0px;
  
  p {
    font-size: 16px;
    margin: 12px 0;
    color: #333;
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

const Right = styled.div`
  img {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    object-fit: cover;
    border: 4px solid #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const StudentProfileLower = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const InfoLower = styled.div`
  width: 48%;
  
  p {
    font-size: 16px;
    margin: 12px 0;
    color: #555;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const Data = JSON.parse(localStorage.getItem("Data"));
    const StudentId = Data?.StudentData?.StudentId;

    const fetchStudent = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `https://ims-nv9i.onrender.com/students/get/${StudentId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setStudent(data);
      } catch (error) {
        setError("Error fetching student data");
        console.error("Error fetching student data:", error);
      } finally {            
        setLoading(false);
      }
    };

    if (StudentId) {
      fetchStudent();
    }
  }, []);

  if (loading) {
    return <div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}> <Hourglass
        visible={true}
        height="80"
        width="80"
        ariaLabel="hourglass-loading"
        wrapperStyle={{}}
        wrapperClass=""
        colors={['#306cce', '#72a1ed']}
    /></div>;
}

  return (
    <DashboardContainer>
      <Header>
        Student Dashboard
        <HamburgerButton isOpen={isSidebarOpen} onClick={toggleSidebar} />
      </Header>
      <Container>
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <MainContent>
          {loading && !student ? (
            <Loading>Loading student data...</Loading>
          ) : error && !student ? (
            <Error>{error}</Error>
          ) : (
            student && (
              <StudentProfile>
                <h1>Student Profile</h1>
                <StudentProfileUpper>
                  <Left>
                    <p>Student Id: {student.StudentId}</p>
                    <p>Student Name: {student.StudentName}</p>
                    <p>Course: {student.Course.CourseName}</p>
                  </Left>
                  <Right>
                    <img
                      src={`https://ims-nv9i.onrender.com/uploads/${student.Documents.StudentPhoto}`}
                      alt="Student"
                    />
                  </Right>
                </StudentProfileUpper>
                <StudentProfileLower>
                  <InfoLower>
                    <p>FatherName: {student.FatherName}</p>
                    <p>MotherName: {student.MotherName}</p>
                    <p>Gender: {student.Gender}</p>
                    <p>DOB: {student.DOB}</p>
                    <p>Qualification: {student.Qualification}</p>
                    <p>AadharNo: {student.AadharNo}</p>
                    <p>MobileNo: {student.MobileNo}</p>
                    <p>EmailId: {student.EmailId}</p>
                  </InfoLower>
                  <InfoLower>
                    <p>Address: {student.Address}</p>
                    <p>City: {student.City}</p>
                    <p>State: {student.State}</p>
                    <p>Dist: {student.Dist}</p>
                    <p>PinCode: {student.PinCode}</p>
                    <p>FranchiseId: {student.FranchiseId}</p>
                    <p>FranchiseName: {student.FranchiseName}</p>
                  </InfoLower>
                </StudentProfileLower>
              </StudentProfile>
            )
          )}
        </MainContent>
      </Container>
    </DashboardContainer>
  );
};

export default Dashboard;