import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { FaTimes, FaChevronDown, FaChevronRight } from "react-icons/fa";

const SidebarContainer = styled.nav`
  width: 250px;
  background-color: #333;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? "0" : "-100%")};
  height: 100%;
  z-index: 1000;
  color: #fff;

  @media (min-width: 769px) {
    position: relative;
    left: 0;
    height: auto;
    z-index: auto;
  }
`;

const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: #fff;
`;

const CloseButton = styled(FaTimes)`
  font-size: 24px;
  cursor: pointer;
  display: ${(props) => (props.isOpen ? "block" : "none")};

  @media (min-width: 769px) {
    display: none;
  }
`;

const SidebarLink = styled(Link)`
  display: block;
  padding: 10px 0;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  border-bottom: 1px solid #444;

  &:hover {
    background-color: #555;
  }
`;

const ExpandableItem = styled.div`
  border-bottom: 1px solid #444;
`;

const ExpandButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  text-align: left;

  &:hover {
    color: #ddd;
  }
`;

const ChildLinksContainer = styled.div`
  padding-left: 20px;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const ChildLink = styled(Link)`
  display: block;
  padding: 5px 0;
  color: #bbb;
  text-decoration: none;
  font-size: 16px;

  &:hover {
    color: #fff;
  }
`;

const Sidebar = ({ isOpen, toggleSidebar }) => {
    const [expandedSections, setExpandedSections] = useState({});

    const toggleExpand = (section) => {
        setExpandedSections((prevSections) => ({
            ...prevSections,
            [section]: !prevSections[section],
        }));
    };

    const navigate = useNavigate()
    useEffect(() => {
        var Id;
        const localdata = JSON.parse(localStorage.getItem("Data"));
        Id = (localdata.Role);
        if (Id !== "Franchise") {
            navigate('/');
        }
    }, [])

    const logout = () => {
        localStorage.removeItem("Data");
        localStorage.removeItem('Id');
        navigate('/');
    }

    return (
        <SidebarContainer isOpen={isOpen}>
            <SidebarHeader>
                <h3>Welcome, Franchise</h3>
                <CloseButton isOpen={isOpen} onClick={toggleSidebar} />
            </SidebarHeader>
            <div>
                <SidebarLink to="/franchise" onClick={toggleSidebar}>
                    Home
                </SidebarLink>

                <ExpandableItem>
                    <ExpandButton onClick={() => toggleExpand("employee")}>
                        Employee
                        {expandedSections.employee ? <FaChevronDown /> : <FaChevronRight />}
                    </ExpandButton>
                    <ChildLinksContainer isOpen={expandedSections.employee}>
                        <ChildLink to="/franchise/add/employee" onClick={toggleSidebar}>
                            Add
                        </ChildLink>
                        <ChildLink to="/franchise/view/my/employee" onClick={toggleSidebar}>
                            View
                        </ChildLink>
                    </ChildLinksContainer>
                </ExpandableItem>

                <ExpandableItem>
                    <ExpandButton onClick={() => toggleExpand("student")}>
                        Student
                        {expandedSections.student ? <FaChevronDown /> : <FaChevronRight />}
                    </ExpandButton>
                    <ChildLinksContainer isOpen={expandedSections.student}>
                        <ChildLink to="/franchise/add/student" onClick={toggleSidebar}>
                            Add
                        </ChildLink>
                        <ChildLink to="/franchise/view/my/student" onClick={toggleSidebar}>
                            View
                        </ChildLink>
                        {/* <ChildLink to="/franchise/add/payment" onClick={toggleSidebar}>
                            Payment
                        </ChildLink> */}
                    </ChildLinksContainer>
                </ExpandableItem>

                <ExpandableItem>
                    <ExpandButton onClick={() => toggleExpand("attendance")}>
                        Attendance
                        {expandedSections.attendance ? (
                            <FaChevronDown />
                        ) : (
                            <FaChevronRight />
                        )}
                    </ExpandButton>
                    <ChildLinksContainer isOpen={expandedSections.attendance}>
                        <ChildLink to="/franchise/add/student/attendance" onClick={toggleSidebar}>
                            Student
                        </ChildLink>
                        <ChildLink to="/franchise/add/employee/attendance" onClick={toggleSidebar}>
                            Employee
                        </ChildLink>
                        <ChildLink to="/franchise/view/attendance" onClick={toggleSidebar}>
                            View
                        </ChildLink>
                    </ChildLinksContainer>
                </ExpandableItem>

                <ExpandableItem>
                    <ExpandButton onClick={() => toggleExpand("batch")}>
                        Batch
                        {expandedSections.batch ? (
                            <FaChevronDown />
                        ) : (
                            <FaChevronRight />
                        )}
                    </ExpandButton>
                    <ChildLinksContainer isOpen={expandedSections.batch}>
                        <ChildLink to="/franchise/add/batch" onClick={toggleSidebar}>
                            Add
                        </ChildLink>
                        <ChildLink to="/franchise/view/batch" onClick={toggleSidebar}>
                            View
                        </ChildLink>
                    </ChildLinksContainer>
                </ExpandableItem>

                <SidebarLink to="/franchise/course" onClick={toggleSidebar}>
                    Course
                </SidebarLink>

                <SidebarLink to="/MarkSheetList" onClick={toggleSidebar}>
                    Add Marksheet
                </SidebarLink>

                <SidebarLink to="/PrintingRequest" onClick={toggleSidebar}>
                    Printing Request
                </SidebarLink>

                <SidebarLink to="/franchise/notification" onClick={toggleSidebar}>
                    Notification
                </SidebarLink>

                <SidebarLink to="/franchise/reward" onClick={toggleSidebar}>
                    Reward
                </SidebarLink>

                <SidebarLink to="/franchise/wallet" onClick={toggleSidebar}>
                    Wallet
                </SidebarLink>
                <SidebarLink to="/franchise/expense" onClick={toggleSidebar}>
                    Expense
                </SidebarLink>
                <div onClick={logout}>
                    <SidebarLink>
                        Logout
                    </SidebarLink>
                </div>
            </div>
        </SidebarContainer>
    );
};

export default Sidebar;
