import React, { useState, useEffect } from 'react';
import { Hourglass } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import Sidebar from "./StudentSidebar"; // Update with the correct path to your Sidebar component

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f2f5;
  font-family: 'Arial', sans-serif;
`;

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  background-color: #f0f2f5;
`;

const Header = styled.header`
  height: 70px;
  background-color: #008cba;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const MainContent = styled.main`
  display: flex;
  flex: 1;
  padding: 20px;
  @media (max-width: 768px) {
    margin-left: 0;
    padding: 20px 10px;
  }
`;

const HamburgerButton = styled(FaBars)`
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: ${(props) => (props.isOpen ? "none" : "block")};
  color: #ecf0f1;
  transition: color 0.3s ease;

  &:hover {
    color: #3498db;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const NoExamsMessage = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #7f8c8d;
  text-align: center;
  padding: 20px;
  background-color: #ecf0f1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const ContentWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  padding: 20px;
  width: 100%;
`;

const Heading = styled.h1`
  color: #2c3e50;
  margin-bottom: 20px;
  font-size: 28px;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
`;

const Table = styled.table`
  width: 100%;
  font-size: 15px;
  border-collapse: separate;
  border-spacing: 0;
  margin: 20px 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
`;

const Th = styled.th`
  background-color: #3498db;
  color: #ffffff;
  padding: 12px;
  text-align: left;
  font-weight: bold;
`;

const Td = styled.td`
  border-bottom: 1px solid #e0e0e0;
  padding: 12px;
  text-align: left;
  word-break: break-word;
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #2ecc71;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #27ae60;
  }
`;

const StudentAdmitcard = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
      const fetchData = async () => {
          try {
              const data = JSON.parse(localStorage.getItem("Data"));
              if (!data || !data.StudentData || !data.StudentData.StudentId) {
                  throw new Error('Student data is not available');
              }

              const studentResponse = await fetch(`https://ims-nv9i.onrender.com/students/get/${data.StudentData.StudentId}`);
              if (!studentResponse.ok) {
                  throw new Error('Network response was not ok');
              }
              const studentData = await studentResponse.json();

              const batchIds = studentData.Course.BatchId;
              const courseIds = studentData.Course.CourseId;

              const eventsResponse = await fetch('https://ims-nv9i.onrender.com/examQuestions/all');
              if (!eventsResponse.ok) {
                  throw new Error('Network response was not ok');
              }
              const eventsData = await eventsResponse.json();

              const now = new Date();
              const filteredEvents = eventsData.filter(event =>
                  event.BatchId.some(id => batchIds.includes(id)) &&
                  courseIds.includes(event.CourseId) &&
                  new Date(event.DateTime) > now
              );

              setEvents(filteredEvents);
              setLoading(false);
          } catch (error) {
            console.log(error)
              setError(error);
              setLoading(false);
          }
      };

      fetchData();
  }, []);

  if (loading) {
    return <div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}> <Hourglass
        visible={true}
        height="80"
        width="80"
        ariaLabel="hourglass-loading"
        wrapperStyle={{}}
        wrapperClass=""
        colors={['#306cce', '#72a1ed']}
    /></div>;
}

  if (error) {
      return <div>Error: {error.message}</div>;
  }

  const noExamsMessage = events.length === 0 ? <NoExamsMessage>No upcoming exams</NoExamsMessage> : null;

  const formatDateTime = (dateTime) => {
      const options = { 
          year: 'numeric', 
          month: 'long', 
          day: 'numeric', 
          hour: '2-digit', 
          minute: '2-digit', 
          second: '2-digit', 
          hour12: true 
      };
      return new Date(dateTime).toLocaleDateString('en-US', options);
  };

  const goTo = (examId) => {
      const data = JSON.parse(localStorage.getItem("Data"));
      navigate(`/admit/${data.StudentData.StudentId}/${examId}`);
  };

  return (
      <DashboardContainer>
          <Header>
              Student Dashboard
              <HamburgerButton isOpen={isSidebarOpen} onClick={toggleSidebar} />
          </Header>
          <Container>
              <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
              <MainContent>
                  <ContentWrapper>
                      <Heading>Upcoming Exams</Heading>
                      {noExamsMessage}
                      {events.length > 0 && (
                          <Table>
                              <thead>
                                  <Tr>
                                      <Th>Franchise Name</Th>
                                      <Th>Name</Th>
                                      <Th>Course ID</Th>
                                      <Th>Duration (minutes)</Th>
                                      <Th>Sets</Th>
                                      <Th>Student No</Th>
                                      <Th>Batch ID</Th>
                                      <Th>Batch Name</Th>
                                      <Th>Date & Time</Th>
                                      <Th>Action</Th>
                                  </Tr>
                              </thead>
                              <tbody>
                                  {events.map(event => (
                                      <Tr key={event._id}>
                                          <Td>{event.FranchiseName}</Td>
                                          <Td>{event.Name}</Td>
                                          <Td>{event.CourseId}</Td>
                                          <Td>{event.Duration}</Td>
                                          <Td>{event.Sets.join(', ')}</Td>
                                          <Td>{event.StudentNo}</Td>
                                          <Td>{event.BatchId.join(', ')}</Td>
                                          <Td>{event.BatchName.join(', ')}</Td>
                                          <Td>{formatDateTime(event.DateTime)}</Td>
                                          <Td>
                                              <Button onClick={() => goTo(event._id)}>Admit Card</Button>
                                          </Td>
                                      </Tr>
                                  ))}
                              </tbody>
                          </Table>
                      )}
                  </ContentWrapper>
              </MainContent>
          </Container>
      </DashboardContainer>
  );
};

export default StudentAdmitcard;