import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Keyframe Animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideUp = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

// Styled Components
const Container = styled.div`
  padding: 60px 30px;
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  min-height: 100vh;
`;

const Title = styled.h2`
  text-align: center;
  color: #2c3e50;
  margin-bottom: 50px;
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  position: relative;
  animation: ${fadeIn} 1s ease-out;

  &:after {
    content: '';
    display: block;
    width: 100px;
    height: 4px;
    background: linear-gradient(to right, #3498db, #2980b9);
    margin: 20px auto 0;
    border-radius: 2px;
  }
`;

const Loader = styled.div`
  text-align: center;
  color: #3498db;
  font-size: 1.4rem;
  font-weight: 500;
  animation: ${fadeIn} 0.5s ease-out;

  &:after {
    content: '...';
    animation: ellipsis 1.5s infinite;
  }

  @keyframes ellipsis {
    0% { content: '.'; }
    33% { content: '..'; }
    66% { content: '...'; }
  }
`;

const ErrorMessage = styled.p`
  text-align: center;
  color: #e74c3c;
  font-size: 1.4rem;
  font-weight: 500;
  animation: ${fadeIn} 0.5s ease-out;
  padding: 20px;
  background-color: rgba(231, 76, 60, 0.1);
  border-radius: 10px;
`;

const CoursesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  justify-content: center;
  animation: ${fadeIn} 1s ease-out;
`;

const CourseCard = styled.div`
  background: linear-gradient(135deg, #ffffff 0%, #f5f7fa 100%);
  border: none;
  border-radius: 15px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  padding: 30px;
  transition: all 0.3s ease;
  animation: ${slideUp} 0.5s ease-out;

  &:hover {
    transform: translateY(-15px) scale(1.03);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  }
`;

const CourseTitle = styled.h3`
  color: #2c3e50;
  font-size: 1.8rem;
  margin-bottom: 20px;
  font-weight: 600;
  position: relative;
  padding-bottom: 15px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background: linear-gradient(to right, #3498db, #2980b9);
    border-radius: 2px;
  }
`;

const CourseInfo = styled.p`
  color: #34495e;
  margin: 15px 0;
  font-size: 1.6rem;
  line-height: 1.6;
`;

const PlanList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
`;

const PlanItem = styled.li`
  color: #3498db;
  margin: 12px 0;
  font-size: 1.5rem;

  font-weight: 500;
  display: flex;
  align-items: center;

  &:before {
    content: '→';
    margin-right: 10px;
    color: #2980b9;
  }
`;

const DescriptionList = styled.ul`
  list-style-type: none;
  padding: 0 20px;
  margin: 20px 0;
`;

const DescriptionItem = styled.li`
  color: #7f8c8d;
  margin: 10px 0;
  font-size: 1.5rem;
  line-height: 1.5;
  position: relative;

  &:before {
    content: '•';
    color: #3498db;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

const FormContainer = styled.div`
  margin-top: 80px;
  padding: 40px;
  background: linear-gradient(135deg, #ffffff 0%, #f5f7fa 100%);
  border: none;
  border-radius: 20px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  animation: ${slideUp} 0.5s ease-out;
`;

const FormTitle = styled.h3`
  margin-bottom: 40px;
  color: #2c3e50;
  font-size: 2.2rem;
  font-weight: 600;
  text-align: center;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    background: linear-gradient(to right, #3498db, #2980b9);
    margin: 20px auto 0;
    border-radius: 2px;
  }
`;

const FormField = styled.div`
  margin-bottom: 30px;
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  color: #34495e;
  font-size: 1.1rem;
  font-weight: 500;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 15px;
  border: 2px solid #bdc3c7;
  border-radius: 8px;
  font-size: 1.1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
  }
`;

const FormButton = styled.button`
  padding: 15px 30px;
  background: linear-gradient(to right, #3498db, #2980b9);
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
  margin: 30px auto 0;

  &:hover {
    background: linear-gradient(to right, #2980b9, #3498db);
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
`;

const ViewCourse = () => {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [newCourse, setNewCourse] = useState({
        CourseName: '',
        CourseCode: '',
        CourseDuration: '',
        Plan: [{ PlanName: '', PlanPrice: '' }],
        CourseDescription: ['']
    });

    // Fetch all courses from the API
    useEffect(() => {
        const fetchCourses = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/courses/all');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setCourses(data);
            } catch (error) {
                setError('Error fetching courses');
                console.error('Error fetching courses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCourses();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewCourse((prevCourse) => ({ ...prevCourse, [name]: value }));
    };

    const handlePlanChange = (index, e) => {
        const { name, value } = e.target;
        const updatedPlans = [...newCourse.Plan];
        updatedPlans[index] = { ...updatedPlans[index], [name]: value };
        setNewCourse((prevCourse) => ({ ...prevCourse, Plan: updatedPlans }));
    };

    const handleDescriptionChange = (index, e) => {
        const { value } = e.target;
        const updatedDescriptions = [...newCourse.CourseDescription];
        updatedDescriptions[index] = value;
        setNewCourse((prevCourse) => ({ ...prevCourse, CourseDescription: updatedDescriptions }));
    };

    const addCourse = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://ims-nv9i.onrender.com/courses/add', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newCourse)
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            toast.success('Course added successfully!');
            setNewCourse({
                CourseName: '',
                CourseCode: '',
                CourseDuration: '',
                Plan: [{ PlanName: '', PlanPrice: '' }],
                CourseDescription: ['']
            });
        } catch (error) {
            toast.error('Error adding course');
            console.error('Error adding course:', error);
        }
    };

    return (
        <Container>
            <Title>All Courses</Title>
            {loading ? (
                <Loader>Loading courses</Loader>
            ) : error ? (
                <ErrorMessage>{error}</ErrorMessage>
            ) : (
                <CoursesGrid>
                    {courses.length > 0 ? (
                        courses.map(course => (
                            <CourseCard key={course.CourseId}>
                                <CourseTitle>{course.CourseName}</CourseTitle>
                                <CourseInfo><strong>Course Code:</strong> {course.CourseCode}</CourseInfo>
                                <CourseInfo><strong>Duration:</strong> {course.CourseDuration}</CourseInfo>
                                <CourseInfo><strong>Plans:</strong></CourseInfo>
                                <PlanList>
                                    {course.Plan.map((plan, index) => (
                                        <PlanItem key={index}>
                                            {plan.PlanName} - ${plan.PlanPrice}
                                        </PlanItem>
                                    ))}
                                </PlanList>
                                <CourseInfo><strong>Description:</strong></CourseInfo>
                                <DescriptionList>
                                    {course.CourseDescription.map((desc, index) => (
                                        <DescriptionItem key={index}>{desc}</DescriptionItem>
                                    ))}
                                </DescriptionList>
                            </CourseCard>
                        ))
                    ) : (
                        <p>No courses available</p>
                    )}
                </CoursesGrid>
            )}
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Container>
    );
};

export default ViewCourse;