import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import Axios for HTTP requests

// Container for the whole component
const Container = styled.div`
  padding: 20px;
  width: calc(100vw - 270px);
  margin: 0;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

// Header section with search functionality
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #343a40;
  padding: 20px;
  color: white;
  border-radius: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

const Title = styled.h2`
  margin: 0;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Select = styled.select`
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;

  @media (max-width: 768px) {
    margin: 0 0 10px 0;
    width: 100%;
  }
`;

const Button = styled.button`
  background-color: #008cba;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #008cba;
  }
`;

const CreateButton = styled(Button)`
  background-color: #008cba;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 20px;
  @media (max-width: 768px) {
    overflow-x: scroll;
    width: 100%;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  font-size:1rem;
  thead th {
    background-color: #f8f9fa;
    padding: 12px;
    text-align: left;
    border-bottom: 2px solid #dee2e6;
  }

  tbody tr:nth-child(even) {
    background-color: #f8f9fa;
  }

  tbody td {
    padding: 12px;
    border-bottom: 1px solid #dee2e6;
    color: #333;
  }
`;

const StatusBadge = styled.span`
  padding: 5px 10px;
  border-radius: 5px;
  background-color: ${(props) => (props.active ? "#28a745" : "#ffc107")};
  color: white;
`;

const ActionButton = styled.button`
  padding: 5px 10px;
  margin-right: 5px;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: ${(props) => (props.primary ? "#28a745" : "#007bff")};
  cursor: pointer;
  margin-bottom: 5px;

  &:hover {
    background-color: ${(props) =>
    props.primary ? "#218838" : "#0056b3"};
  }
`;

const EditButton = styled(ActionButton)`
  background-color: #17a2b8;
  &:hover {
    background-color: #117a8b;
  }
`;

const DeleteButton = styled(ActionButton)`
  background-color: #dc3545;
  &:hover {
    background-color: #c82333;
  }
`;

const PayFees = () => {
  const navigate = useNavigate(); // Hook to navigate programmatically
  const [students, setStudents] = useState([]);
  const [Role, setRole] = useState("");

  useEffect(() => {
    var Id;
    const localdata = JSON.parse(localStorage.getItem("Data"));
    if (localdata) {
      if (localdata.EmployeeData) {
        setRole("employee")
        Id = (localdata.EmployeeData.FranchiseId);
      } else if (localdata.FranchiseData) {
        if(localdata.FranchiseData.Approved){
          setRole("master")
        }else{
          setRole("franchise")
        }
        Id = (localdata.FranchiseData.FranchiseId);
      } else {
        setRole("admin")
        Id = ("Admin");
      }
    } else {
      setRole("admin")
      Id = ("Admin");
    }
    const fetchStudents = async () => {
      try {
        const response = await axios.get('https://ims-nv9i.onrender.com/students/all');
        var filterData = response.data.filter(data => data.FranchiseId === Id); 
        // console.log(filterData);
        setStudents(filterData);
      } catch (error) {
        console.error("Error fetching students data:", error);
      }
    };

    fetchStudents();
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <Container>
      <Header>
        <Title>Manage Students - REGISTERED</Title>

        <CreateButton onClick={() => navigate(`/${Role}/add/student`)}>Create New</CreateButton>
      </Header>

      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Student Id</th>
              <th>Name</th>
              <th>Franchise</th>
              <th>Course</th>
              <th>Fees</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student, index) => (
              <tr key={student.id}>
                <td> {index + 1} </td>
                <td> {student.StudentId} </td>
                <td>
                  {student.StudentName}
                  <br />
                  {student.paid}
                </td>
                <td> {student.FranchiseName} </td>
                <td> {student.Course.CourseName} </td>
                <td>
                  Dues: <span style={{ color: "red" }}>{student.Fee.DueFee}</span>
                  <br />
                  Total Paid:{" "}
                  <span style={{ color: "green" }}>{student.Fee.PaidFee}</span>
                  <br />
                  Total Fees: {student.Fee.TotalFee}
                </td>
                <td>
                  <ActionButton
                    primary
                    onClick={() => navigate(`/${Role}/fees/${student.StudentId}`)}
                  >
                    Pay Fees
                  </ActionButton>
                  <ActionButton
                    primary
                    onClick={() => navigate(`/${Role}/edit/student/${student.StudentId}`)}
                  >
                    Edit
                  </ActionButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </Container>
  );
};

export default PayFees;
