import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Styled Components
const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #0056b3;
  }
`;

const TableWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const TableHeader = styled.th`
  background-color: #f8f9fa;
  text-align: left;
  padding: 12px;
  border: 1px solid #dee2e6;
`;

const TableCell = styled.td`
  border: 1px solid #dee2e6;
  padding: 12px;
`;

const NoDataMessage = styled.p`
  text-align: center;
  color: #dc3545;
  font-size: 16px;
`;

const Header = styled.h2`
  color: #343a40;
  text-align: center;
  margin-bottom: 20px;
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  margin: 4px;
  border: none;
  border-radius: 4px;
  background-color: #28a745;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background-color: #218838;
  }
`;

const CerMarReq = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [view, setView] = useState('marks'); // 'marks' or 'certificates'

    useEffect(() => {
        const apiUrl = 'https://ims-nv9i.onrender.com/marksheet/all';

        const fetchData = async () => {
            try {
                const response = await fetch(apiUrl);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result.data); // Adjust based on your API response structure
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const markReqData = data.filter((item) => item.MarkReq && !item.PrintMar);
    const cerReqData = data.filter((item) => item.CerReq && !item.PrintCer);

    const ReqCertificate = async (regNo, courseId, stat) => {
        const value = stat ? false : true;
        try {
            const response = await fetch(
                `https://ims-nv9i.onrender.com/marksheet/${regNo}/${courseId}/printCer`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ print: value }),
                }
            );
            const result = await response.json();
            if (response.ok) {
                toast.success('Certificate request updated successfully!');
                // Refresh data
                const updatedData = await fetch('https://ims-nv9i.onrender.com/marksheet/all').then(res => res.json());
                setData(updatedData.data);
            } else {
                toast.error(`Error updating status: ${result.message}`);
            }
        } catch (error) {
            toast.error(`Error: ${error.message}`);
        }
    };

    const ReqMarksheet = async (regNo, courseId, stat) => {
        const value = stat ? false : true;
        try {
            const response = await fetch(
                `https://ims-nv9i.onrender.com/marksheet/${regNo}/${courseId}/printMar`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ print: value }),
                }
            );
            const result = await response.json();
            if (response.ok) {
                toast.success('Marksheet request updated successfully!');
                // Refresh data
                const updatedData = await fetch('https://ims-nv9i.onrender.com/marksheet/all').then(res => res.json());
                setData(updatedData.data);
            } else {
                toast.error(`Error updating status: ${result.message}`);
            }
        } catch (error) {
            toast.error(`Error: ${error.message}`);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <Container>
            <div>
                <Button onClick={() => setView('marks')}>Show Mark Requests</Button>
                <Button onClick={() => setView('certificates')}>Show Certificate Requests</Button>
            </div>

            {view === 'marks' ? (
                <TableWrapper>
                    <Header>Mark Request Table</Header>
                    <Table>
                        <thead>
                            <tr>
                                <TableHeader>MarkSheet No</TableHeader>
                                <TableHeader>Student Name</TableHeader>
                                <TableHeader>Course Name</TableHeader>
                                <TableHeader>Registration No</TableHeader>
                                <TableHeader>Action</TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {markReqData.length > 0 ? (
                                markReqData.map((item) => (
                                    <tr key={item._id}>
                                        <TableCell>{item.MarkSheetNo}</TableCell>
                                        <TableCell>{item.StudentName}</TableCell>
                                        <TableCell>{item.CourseName}</TableCell>
                                        <TableCell>{item.RegistrationNo}</TableCell>
                                        <TableCell>
                                            <ActionButton
                                                onClick={() =>
                                                    ReqMarksheet(item.RegistrationNo, item.CourseId, item.PrintMar)
                                                }
                                            >
                                                Approve
                                            </ActionButton>
                                        </TableCell>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <TableCell colSpan="5">
                                        <NoDataMessage>No Mark Requests</NoDataMessage>
                                    </TableCell>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </TableWrapper>
            ) : (
                <TableWrapper>
                    <Header>Certificate Request Table</Header>
                    <Table>
                        <thead>
                            <tr>
                                <TableHeader>Certificate No</TableHeader>
                                <TableHeader>Student Name</TableHeader>
                                <TableHeader>Course Name</TableHeader>
                                <TableHeader>Registration No</TableHeader>
                                <TableHeader>Action</TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {cerReqData.length > 0 ? (
                                cerReqData.map((item) => (
                                    <tr key={item._id}>
                                        <TableCell>{item.CertificateNo}</TableCell>
                                        <TableCell>{item.StudentName}</TableCell>
                                        <TableCell>{item.CourseName}</TableCell>
                                        <TableCell>{item.RegistrationNo}</TableCell>
                                        <TableCell>
                                            <ActionButton
                                                onClick={() =>
                                                    ReqCertificate(item.RegistrationNo, item.CourseId, item.PrintCer)
                                                }
                                            >
                                                Approve
                                            </ActionButton>
                                        </TableCell>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <TableCell colSpan="5">
                                        <NoDataMessage>No Certificate Requests</NoDataMessage>
                                    </TableCell>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </TableWrapper>
            )}

            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </Container>
    );
};

export default CerMarReq;
