import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const SAAddBatch = () => {
    const location = useLocation();
    const [courses, setCourses] = useState([]);
    const [batches, setBatches] = useState([]);
    const [filteredBatches, setFilteredBatches] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedBatchId, setSelectedBatchId] = useState('');
    const [totalFee, setTotalFee] = useState('');
    const [formData, setFormData] = useState({
        CourseName: '',
        ExamType: '',
        BatchName: ''
    });

    useEffect(() => {
        const localdata = JSON.parse(localStorage.getItem("Data"));
        const Id = localdata?.EmployeeData?.FranchiseId || localdata?.FranchiseData?.FranchiseId || "Admin";

        const fetchCourses = async () => {
            try {
                const response = await fetch("https://ims-nv9i.onrender.com/courses/all");
                const coursesData = await response.json();
                setCourses(coursesData);
            } catch (error) {
                console.error("Error fetching courses:", error);
            }
        };

        const fetchBatches = async () => {
            try {
                const response = await fetch("https://ims-nv9i.onrender.com/batch/all");
                const batchesData = await response.json();
                const filteredBatches = batchesData.filter(batch => batch.FranchiseId === Id);
                setBatches(filteredBatches);
            } catch (error) {
                console.error("Error fetching batches:", error);
            }
        };

        fetchCourses();
        fetchBatches();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        if (name === "CourseName") {
            const selectedCourse = courses.find(course => course.CourseName === value);
            setSelectedCourse(selectedCourse);
            setFilteredBatches(batches.filter(batch => batch.Course === (selectedCourse ? selectedCourse.CourseName : '')));
            setTotalFee(selectedCourse ? selectedCourse.Plan.find(plan => plan.PlanName === formData.PlanName)?.PlanPrice : '');
        }
        if (name === "BatchName") {
            const selectedBatch = batches.find(batch => batch.BatchName === value);
            setSelectedBatchId(selectedBatch ? selectedBatch.BatchId : '');
        }
        if (name === "PlanName") {
            const selectedPlan = selectedCourse?.Plan.find(plan => plan.PlanName === value);
            setTotalFee(selectedPlan ? selectedPlan.PlanPrice : '');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const localdata = JSON.parse(localStorage.getItem("Data"));
        const PlanName = localdata?.EmployeeData?.Plan || localdata?.FranchiseData?.Plan || '';

        if (!formData.CourseName || !formData.ExamType || !formData.BatchName) {
            alert("Please select all values");
            return;
        }

        try {
            const response = await fetch(`https://ims-nv9i.onrender.com/students/add-course/${location.state.id}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    CourseId: selectedCourse?.CourseId,
                    CourseName: formData.CourseName,
                    ExamType: formData.ExamType,
                    BatchId: selectedBatchId,
                    BatchName: formData.BatchName,
                    ReceivedFee: 0,
                    PlanName
                })
            });

            if (response.ok) {
                alert("Course added successfully");
            } else {
                alert("Failed to add course");
            }
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    return (
        <MainContainer>
            <Form onSubmit={handleSubmit}>
                <Title>Add Course</Title>
                <CourseId>
                    <h6>Student ID :</h6>
                    <h6>{location.state.id}</h6>
                </CourseId>
                <FormField>
                    <Label htmlFor="course-name">Course Name:</Label>
                    <Select name="CourseName" id="course-name" onChange={handleChange}>
                        <option value="">Select Course</option>
                        {courses.map(course => (
                            <option key={course.CourseId} value={course.CourseName}>
                                {course.CourseName}
                            </option>
                        ))}
                    </Select>
                </FormField>
                <FormField>
                    <Label htmlFor="exam-type">Exam Type :</Label>
                    <Select name="ExamType" id="exam-type" onChange={handleChange}>
                        <option value="">Select Exam Type</option>
                        <option value="Online">Online</option>
                        <option value="Offline">Offline</option>
                    </Select>
                </FormField>
                <FormField>
                    <Label htmlFor="batch-name">Batch Name :</Label>
                    <Select name="BatchName" id="batch-name" onChange={handleChange}>
                        <option value="">Select Batch</option>
                        {filteredBatches.map(batch => (
                            <option key={batch.BatchId} value={batch.BatchName}>
                                {batch.BatchName}
                            </option>
                        ))}
                    </Select>
                </FormField>
                <SubmitButton type="submit">Submit</SubmitButton>
            </Form>
        </MainContainer>
    );
};

const MainContainer = styled.div`
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const Title = styled.h2`
    text-align: center;
    margin-bottom: 20px;
`;

const CourseId = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    h6 {
        margin: 0;
    }
`;

const FormField = styled.div`
    margin-bottom: 15px;
`;

const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
`;

const Select = styled.select`
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
`;

const SubmitButton = styled.button`
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
    }
`;

export default SAAddBatch;
