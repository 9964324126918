import React, { useState, useEffect } from "react";
import { Hourglass } from 'react-loader-spinner';
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { FaBars } from "react-icons/fa";
import Sidebar from "./StudentSidebar"; // Update with the correct path to your Sidebar component

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  background-color: #f0f2f5;
`;

const Header = styled.header`
  height: 70px;
  background-color: #008cba;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const MainContent = styled.main`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const HamburgerButton = styled(FaBars)`
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  color: white;
  display: ${(props) => (props.isOpen ? "none" : "block")};
  @media (min-width: 769px) {
    display: none;
  }
`;

const Container1 = styled.div`
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Heading = styled.h2`
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
  font-size: 28px;
`;

const FeeData = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  align-self: center;
  gap: 1.5rem;
`;

const PaymentCard = styled.div`
font-size: 15px;
  border: 1px solid #e0e0e0;
  padding: 1.5rem;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }

  h5 {
    font-size: 18px;
    color: #008cba;
    margin-bottom: 1rem;
  }

  p {
    margin: 0.5rem 0;
    color: #555;
  }
`;

const ViewDetailsButton = styled.button`
  padding: 0.6rem 1rem;
  background-color: #008cba;
  color: white;
  font-size: 1.2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 1rem;
  width: 100%;

  &:hover {
    background-color: #006d9a;
  }
`;

const NoDetails = styled.div`
  text-align: center;
  padding: 2rem;
  background-color: #f8f8f8;
  border-radius: 8px;
  color: #666;

  h3 {
    font-size: 20px;
  }
`;

const FeeHead = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

const StudentPaymentInfo = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const [feeDetails, setFeeDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const studentId = JSON.parse(localStorage.getItem("Data")).StudentData.StudentId;

  // Fetch fee details for the student
  useEffect(() => {
    const fetchFeeDetails = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`https://ims-nv9i.onrender.com/students/get/${studentId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(data.Fee)
        setFeeDetails(data.Fee);
      } catch (error) {
        setError('Error fetching fee details');
        console.error(error)
      } finally {
        setLoading(false);
      }
    };

    if (studentId) {
      fetchFeeDetails();
    }
  }, [studentId]);

  if (loading) {
    return <div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}> <Hourglass
      visible={true}
      height="80"
      width="80"
      ariaLabel="hourglass-loading"
      wrapperStyle={{}}
      wrapperClass=""
      colors={['#306cce', '#72a1ed']}
    /></div>;
  }

  return (
    <DashboardContainer>
      <Header>
        Student Dashboard
        <HamburgerButton isOpen={isSidebarOpen} onClick={toggleSidebar} />
      </Header>
      <Container>
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <MainContent>
          <Container1>
            <Heading>Payment Information</Heading>
            <FeeHead>
              <p>Course Fee:{feeDetails.TotalFee} </p>
              <p>Paid Fee: {feeDetails.PaidFee}</p>
              <p>Due Fee: {feeDetails.DueFee}</p>
            </FeeHead>
            <FeeData>
              {feeDetails?.Payments?.map((data,id)=>{
                return(
                  <PaymentCard key={id}>
                    <h5>Amount: {data.Amount}</h5>
                    <p>Date: {data.PaymentDate}</p>
                    <p>Mode: {data.PaymentMode}</p>
                    <p>Reference: {data.PaymentReference}</p>
                  </PaymentCard>
                )
              })

              }
            </FeeData>
          </Container1>
        </MainContent>
      </Container>
    </DashboardContainer>
  );
};

export default StudentPaymentInfo;