import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoPersonAddOutline } from "react-icons/io5";
import { PiStudent } from "react-icons/pi";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import Sidebar from "./FranchiseSidebar";

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  background-color: #f0f2f5;
`;

const Header = styled.header`
  height: 70px;
  background-color: #008cba;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const MainContent = styled.main`
  display: flex;
  flex: 1;
  padding: 20px;
  @media (max-width: 768px) {
    margin-left: 0;
    padding: 20px 10px;
  }
`;

const HamburgerButton = styled(FaBars)`
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 23px;
  display: ${(props) => (props.isOpen ? "none" : "block")};

  @media (min-width: 769px) {
    display: none;
  }
`;

const FranchiseHomeMain = styled.section`
  padding: 20px;
  background-color: #f5f5f5;
  width: 100%;
`;

const FranchiseHomeContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const FranchiseDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 48%;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const CardLeft = styled.div`
  h6 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }
  p {
    font-size: 28px;
    color: #008cba;
    font-weight: bold;
  }
`;

const CardRight = styled.div`
  display: flex;
  align-items: center;
  font-size: 36px;
  color: #008cba;
`;

const RequestAccount = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const RequestSection = styled.div`
  h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }
  h4 {
    font-size: 28px;
    color: #008cba;
    font-weight: bold;
  }
`;

const AccountSection = styled.div`
  button {
    background-color: #008cba;
    color: #ffffff;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #3949ab;
    }
  }
`;

const FranchiseTable = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid #e0e0e0;
    }

    th {
      background-color: #f2f2f2;
      font-weight: bold;
      color: #333;
    }

    tr:hover {
      background-color: #f5f5f5;
    }
  }
`;

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const [employeeCount, setEmployeeCount] = useState(0);
  const [studentCount, setStudentCount] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [totalEnquiries, setTotalEnquiries] = useState(0);
  const [franchiseId, setFranchiseId] = useState("");
  const [enquiryDetails, setEnquiryDetails] = useState([]);

  useEffect(() => {
    const Data = JSON.parse(localStorage.getItem("Data"));
    setFranchiseId(Data.FranchiseData.FranchiseId);

    const fetchEmployeeData = async () => {
      try {
        const response = await fetch(
          "https://ims-nv9i.onrender.com/employees/all"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const filterData = data.filter(
          (ndata) => ndata.FranchiseId == franchiseId
        );
        setEmployeeCount(filterData.length);
        setEmployees(filterData);
        const enquiriesResponse = await fetch(
          "https://ims-nv9i.onrender.com/enquiry/all"
        );
        const enquiriesData = await enquiriesResponse.json();
        setTotalEnquiries(enquiriesData.length);
        setEnquiryDetails(enquiriesData);
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    const fetchStudentCount = async () => {
      try {
        const response = await fetch(
          "https://ims-nv9i.onrender.com/students/all"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const filterData = data.filter(
          (ndata) => ndata.FranchiseId == franchiseId
        );
        setStudentCount(filterData.length);
      } catch (error) {
        console.error("Error fetching student count:", error);
      }
    };

    fetchEmployeeData();
    fetchStudentCount();
  }, [employees]);

  const goTo = () => {
    navigate(`/atc/${franchiseId}`);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <DashboardContainer>
      <Header>
        Franchise Dashboard
        <HamburgerButton isOpen={isSidebarOpen} onClick={toggleSidebar} />
      </Header>
      <Container>
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <MainContent>
          <FranchiseHomeMain>
            <FranchiseHomeContainer>
              <FranchiseDetails>
                <Card>
                  <CardLeft>
                    <h6>No. of Employees</h6>
                    <p>{employeeCount}</p>
                  </CardLeft>
                  <CardRight>
                    <IoPersonAddOutline />
                  </CardRight>
                </Card>
                <Card>
                  <CardLeft>
                    <h6>No. of Students</h6>
                    <p>{studentCount}</p>
                  </CardLeft>
                  <CardRight>
                    <PiStudent />
                  </CardRight>
                </Card>
              </FranchiseDetails>
              <RequestAccount>
                <RequestSection>
                  <h3>Request :</h3>
                  <h4>{totalEnquiries}</h4>
                </RequestSection>
                <AccountSection>
                  <button onClick={goTo}>ATC Certificate</button>
                </AccountSection>
              </RequestAccount>
              <FranchiseTable>
                <table>
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Student Name</th>
                      <th>Father Name</th>
                      <th>Mobile No</th>
                      <th>Email Id</th>
                      <th>Interested Course</th>
                      <th>Qualification</th>
                      <th>City</th>
                      <th>Date</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enquiryDetails.map((enquiry, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{enquiry.StudentName}</td>
                        <td>{enquiry.FatherName}</td>
                        <td>{enquiry.MobileNo}</td>
                        <td>{enquiry.EmailId}</td>
                        <td>{enquiry.InterestedCourse}</td>
                        <td>{enquiry.Qualification}</td>
                        <td>{enquiry.Dist}</td>
                        <td>{enquiry.Date}</td>
                        <td>{enquiry.Time}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </FranchiseTable>
            </FranchiseHomeContainer>
          </FranchiseHomeMain>
        </MainContent>
      </Container>
    </DashboardContainer>
  );
};

export default Dashboard;