import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2';
import { IoPersonAddOutline } from 'react-icons/io5';
import { PiStudent } from 'react-icons/pi';
import Sidebar from "./MasterSidebar";

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  background-color: #f0f2f5;
`;

const Header = styled.header`
     height: 70px;
  background-color: #008cba;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const MainContent = styled.main`
  display: flex;
  flex: 1;
  padding: 20px;
  @media (max-width: 768px) {
    margin-left: 0;
    padding: 20px 10px;
  }
`;

const HamburgerButton = styled(FaBars)`
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 23px;
  display: ${(props) => (props.isOpen ? "none" : "block")};
  color: #ecf0f1;
  transition: color 0.3s ease;

  &:hover {
    color: #3498db;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Container1 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const FranchiseDetails = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  flex-wrap: wrap;
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  width: 30%;
  min-width: 250px;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CardLeft = styled.div`
  flex: 1;

  h4 {
    margin: 0;
    color: #333;
    font-size: 18px;
  }

  p {
    margin: 10px 0 0;
    font-size: 24px;
    font-weight: bold;
    color:  #008cba;
  }
`;

const CardRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  font-size: 40px;
  color:  #008cba;
`;

const RequestAccount = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const RequestSection = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin: 0;
    color: #333;
  }

  h4 {
    margin: 10px 0 0;
    font-size: 24px;
    color:  #008cba;
  }
`;

const AccountSection = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  padding: 12px 24px;
  font-size: 16px;
  color: #fff;
  background-color:  #008cba;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #008cef;
  }
`;

const Table = styled.div`
  overflow-x: auto;
  margin-top: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  th, td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }

  th {
    background-color: #f5f5f5;
    color: #333;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
  }

  tr:last-child td {
    border-bottom: none;
  }

  tr:hover {
    background-color: #f8f8f8;
  }
`;

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const [franchiseCount, setFranchiseCount] = useState(0);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [studentCount, setStudentCount] = useState(0);
  const [franchiseId, setFranchiseId] = useState('');
  const [franchiseData, setFranchiseData] = useState([]);
  const [totalEnquiries, setTotalEnquiries] = useState(0);
  const [enquiryDetails, setEnquiryDetails] = useState([]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const Data = JSON.parse(localStorage.getItem("Data"));
    setFranchiseId(Data.FranchiseData.FranchiseId);

    const fetchCountsAndData = async () => {
      try {
        const response = await fetch('https://ims-nv9i.onrender.com/login/all');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        const franchises = data.filter(item => item.Role === 'Franchise');
        const employees = data.filter(item => item.Role === 'Employee');
        const students = data.filter(item => item.Role === 'Student');

        setFranchiseCount(franchises.length);
        setEmployeeCount(employees.length);
        setStudentCount(students.length);
        setFranchiseData(franchises);

        const enquiriesResponse = await fetch("https://ims-nv9i.onrender.com/enquiry/all");
        const enquiriesData = await enquiriesResponse.json();
        setTotalEnquiries(enquiriesData.length);
        setEnquiryDetails(enquiriesData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCountsAndData();
  }, []);

  const goTo = () => {
    navigate(`/atc/${franchiseId}`);
  };

  return (
    <DashboardContainer>
      <Header>
        Master Dashboard
        <HamburgerButton isOpen={isSidebarOpen} onClick={toggleSidebar} />
      </Header>
      <Container>
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <MainContent>
          <Section>
            <Container1>
              <FranchiseDetails>
                <Card>
                  <CardLeft>
                    <h4>No. of Franchise</h4>
                    <p>{franchiseCount}</p>
                  </CardLeft>
                  <CardRight>
                    <HiOutlineBuildingOffice2 />
                  </CardRight>
                </Card>
                <Card>
                  <CardLeft>
                    <h4>No. of Employees</h4>
                    <p>{employeeCount}</p>
                  </CardLeft>
                  <CardRight>
                    <IoPersonAddOutline />
                  </CardRight>
                </Card>
                <Card>
                  <CardLeft>
                    <h4>No. of Students</h4>
                    <p>{studentCount}</p>
                  </CardLeft>
                  <CardRight>
                    <PiStudent />
                  </CardRight>
                </Card>
              </FranchiseDetails>
              <RequestAccount>
                <RequestSection>
                  <h3>Request :</h3>
                  <h4>{totalEnquiries}</h4>
                </RequestSection>
                <AccountSection>
                  <Button onClick={goTo}>ATC Certificate</Button>
                </AccountSection>
              </RequestAccount>
              {/* <Table>
                <StyledTable>
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Student Name</th>
                      <th>Father Name</th>
                      <th>Mobile No</th>
                      <th>Email Id</th>
                      <th>Interested Course</th>
                      <th>Qualification</th>
                      <th>City</th>
                      <th>Date</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enquiryDetails.map((enquiry, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{enquiry.StudentName}</td>
                        <td>{enquiry.FatherName}</td>
                        <td>{enquiry.MobileNo}</td>
                        <td>{enquiry.EmailId}</td>
                        <td>{enquiry.InterestedCourse}</td>
                        <td>{enquiry.Qualification}</td>
                        <td>{enquiry.Dist}</td>
                        <td>{enquiry.Date}</td>
                        <td>{enquiry.Time}</td>
                      </tr>
                    ))}
                  </tbody>
                </StyledTable>
              </Table> */}
            </Container1>
          </Section>
        </MainContent>
      </Container>
    </DashboardContainer>
  );
};

export default Dashboard;

