import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const styles = {
    container: {
        margin: '20px',
        fontFamily: 'Arial, sans-serif',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginBottom: '20px',
    },
    th: {
        border: '1px solid #ddd',
        padding: '8px',
        backgroundColor: '#f2f2f2',
    },
    td: {
        border: '1px solid #ddd',
        padding: '8px',
    },
    heading: {
        textAlign: 'center',
    },
    button: {
        padding: '8px 16px',
        margin: '4px',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#007bff',
        color: '#fff',
        cursor: 'pointer',
        fontSize: '14px',
    },
};

const PrintingRequest = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [franchiseId, setFranchiseId] = useState('');

    // Fetch FranchiseId from local storage
    useEffect(() => {
        const localData = JSON.parse(localStorage.getItem('Data'));
        const id = localData?.FranchiseData?.FranchiseId || ''; // Adjust the key as necessary
        setFranchiseId(id);
    }, []);

    useEffect(() => {
        fetch('https://ims-nv9i.onrender.com/marksheet/all')
            .then(response => response.json())
            .then(data => {
                const allData = data.data;
                console.log(allData)
                setData(allData);

                // Filter data based on FranchiseId
                const filtered = allData.filter(item => item.FranchiseId === franchiseId);
                setFilteredData(filtered);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, [franchiseId]); // Refetch data when FranchiseId changes

    const goToMark = (reg, couid) => {
        navigate(`/marksheetprint/${reg}/${couid}`);
    };

    const goToCert = (reg, couid) => {
        navigate(`/certificatec/${reg}/${couid}`);
    };

    const toggleStatus = async (regNo, courseId, stat) => {
        const value = stat ? false : true;
        try {
            const response = await fetch(`https://ims-nv9i.onrender.com/marksheet/${regNo}/${courseId}/status`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: value }),
            });
            const result = await response.json();
            if (response.ok) {
                alert("Success")
                fetch('https://ims-nv9i.onrender.com/marksheet/all')
                    .then(response => response.json())
                    .then(data => {
                        const allData = data.data;
                        console.log(allData)
                        setData(allData);

                        // Filter data based on FranchiseId
                        const filtered = allData.filter(item => item.FranchiseId === franchiseId);
                        setFilteredData(filtered);
                    })
                    .catch(error => console.error('Error fetching data:', error));
            } else {
                console.error('Error updating status:', result.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const ReqCertificate = async (regNo, courseId, stat) => {
        const value = stat ? false : true;
        try {
            const response = await fetch(`https://ims-nv9i.onrender.com/marksheet/${regNo}/${courseId}/cerReq`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ cerReq: value }),
            });
            const result = await response.json();
            if (response.ok) {
                alert("Success")
                fetch('https://ims-nv9i.onrender.com/marksheet/all')
                    .then(response => response.json())
                    .then(data => {
                        const allData = data.data;
                        console.log(allData)
                        setData(allData);

                        // Filter data based on FranchiseId
                        const filtered = allData.filter(item => item.FranchiseId === franchiseId);
                        setFilteredData(filtered);
                    })
                    .catch(error => console.error('Error fetching data:', error));
            } else {
                console.error('Error updating status:', result.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const ReqMarksheet = async (regNo, courseId, stat) => {
        const value = stat ? false : true;
        try {
            const response = await fetch(`https://ims-nv9i.onrender.com/marksheet/${regNo}/${courseId}/markReq`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ markReq: value }),
            });
            const result = await response.json();
            if (response.ok) {
                alert("Success")
                fetch('https://ims-nv9i.onrender.com/marksheet/all')
                    .then(response => response.json())
                    .then(data => {
                        const allData = data.data;
                        console.log(allData)
                        setData(allData);

                        // Filter data based on FranchiseId
                        const filtered = allData.filter(item => item.FranchiseId === franchiseId);
                        setFilteredData(filtered);
                    })
                    .catch(error => console.error('Error fetching data:', error));
            } else {
                console.error('Error updating status:', result.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Marksheet Data</h1>
            <table style={styles.table}>
                <thead>
                    <tr>
                        <th style={styles.th}>MarkSheet No</th>
                        <th style={styles.th}>Student Name</th>
                        <th style={styles.th}>Father Name</th>
                        <th style={styles.th}>Mother Name</th>
                        <th style={styles.th}>Course Name</th>
                        <th style={styles.th}>Course Duration</th>
                        <th style={styles.th}>MarkSheet</th>
                        <th style={styles.th}>Certificate</th>
                        <th style={styles.th}>Marksheet Status</th>
                        <th style={styles.th}>CertificateStatus</th>
                        <th style={styles.th}>Marksheet Action</th>
                        <th style={styles.th}>Certificate Action</th>
                        <th style={styles.th}>Status Action</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.map((item, index) => (
                        <tr key={index}>
                            <td style={styles.td}>{item.MarkSheetNo}</td>
                            <td style={styles.td}>{item.StudentName}</td>
                            <td style={styles.td}>{item.FatherName}</td>
                            <td style={styles.td}>{item.MotherName}</td>
                            <td style={styles.td}>{item.CourseName}</td>
                            <td style={styles.td}>{item.CourseDuration}</td>
                            <td style={styles.td}>
                                <button
                                    style={styles.button}
                                    onClick={() => goToMark(item.RegistrationNo, item.CourseId)}
                                >
                                    Marksheet
                                </button>
                            </td>
                            <td style={styles.td}>
                                <button
                                    style={styles.button}
                                    onClick={() => goToCert(item.RegistrationNo, item.CourseId)}
                                >
                                    Certificate
                                </button>
                            </td>
                            <td style={styles.td}>{item.Status ? item.MarkReq ? item.PrintMar ? "Accepted" : "Pending" : "Not Requested" : "Inactive"}</td>
                            <td style={styles.td}>{item.Status ? item.CerReq ? item.PrintCer ? "Accepted" : "Pending" : "Not Requested" : "Inactive"}</td>
                            <td style={styles.td}>
                                {item.MarkReq ? "Requested" : <button onClick={() => ReqMarksheet(item.RegistrationNo, item.CourseId, item.MarkReq)} style={styles.button}>Send Request</button>}
                            </td>
                            <td style={styles.td}>
                                {item.CerReq ? "Requested" : <button onClick={() => ReqCertificate(item.RegistrationNo, item.CourseId, item.CerReq)} style={styles.button}>Send Request</button>}
                            </td>
                            <td style={styles.td}>
                                {item.Status ? <button onClick={() => toggleStatus(item.RegistrationNo, item.CourseId, item.Status)} style={styles.button}>Deactivate</button> : <button onClick={() => toggleStatus(item.RegistrationNo, item.CourseId, item.Status)} style={styles.button}>Activate</button>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PrintingRequest;
