import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const AddStudentAttendance = () => {
  const navigate = useNavigate();
  const [batches, setBatches] = useState([]);

  useEffect(() => {
    var Id;
    const localdata = JSON.parse(localStorage.getItem("Data"));
    if (localdata) {
      if (localdata.EmployeeData) {
        Id = localdata.EmployeeData.FranchiseId;
      } else if (localdata.FranchiseData) {
        Id = localdata.FranchiseData.FranchiseId;
      } else {
        Id = "Admin";
      }
    } else {
      Id = "Admin";
    }
    const fetchBatches = async () => {
      try {
        const response = await fetch('https://ims-nv9i.onrender.com/batch/all');
        const data = await response.json();
        const filData = data.filter(batch => batch.FranchiseId === Id);
        setBatches(filData);
      } catch (error) {
        console.error('Error fetching batches:', error);
      }
    };

    fetchBatches();
  }, []);

  const goTo = (id) => {
    navigate(`/attendance/add/${id}`);
  };

  return (
    <Container>
      {batches.length > 0 ? (
        batches.map((batch) => (
          <Card key={batch._id}>
            <CardHeader>{batch.BatchName}</CardHeader>
            <CardContent>
              <p><strong>Batch ID:</strong> {batch.BatchId}</p>
              <p><strong>Franchise ID:</strong> {batch.FranchiseId}</p>
              <p><strong>Course:</strong> {batch.Course}</p>
              <p><strong>Total Students:</strong> {batch.StudentsId.length}</p>
              <p><strong>Session Time:</strong> {batch.SessionTime}</p>
            </CardContent>
            <ButtonContainer>
              <Button onClick={() => goTo(batch.BatchId)}>View Details</Button>
            </ButtonContainer>
          </Card>
        ))
      ) : (
        <Message>No batches available</Message>
      )}
    </Container>
  );
};

// Styled-components
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  background-color: #f0f2f5;
  min-height: calc(100vh - 70px);
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  height: fit-content;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; /* Center the button horizontally */
  margin-top: 20px;
`;

const CardHeader = styled.h2`
  text-align:center;
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
`;

const CardContent = styled.div`
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;

  p {
    margin: 5px 0;
  }
`;

const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin:0px auto;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Message = styled.p`
  font-size: 16px;
  color: #999;
  text-align: center;
  width: 100%;
`;

export default AddStudentAttendance;
