import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import Sidebar from "./StudentSidebar"; // Update with the correct path to your Sidebar component

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  background-color: #f0f2f5;
`;

const Header = styled.header`
  height: 70px;
  background-color: #008cba;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const MainContent = styled.main`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const HamburgerButton = styled(FaBars)`
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  color: white;
  display: ${(props) => (props.isOpen ? "none" : "block")};
  @media (min-width: 769px) {
    display: none;
  }
`;

const Container1 = styled.div`
  margin: 20px;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const Heading = styled.h1`
  text-align: center;
  color: #333;
  margin-bottom: 20px;
`;

const Table = styled.table`
font-size: 15px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
`;

const Th = styled.th`
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
  padding: 12px;
  text-align: left;
  border-bottom: 2px solid #ddd;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #ddd;
  color: #555;
`;

const Button = styled.button`
  padding: 8px 16px;
  margin: 4px;
  border: none;
  border-radius: 4px;
  background-color: #008cba;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #006d9a;
  }
`;

const StudentResult = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
      const Student = JSON.parse(localStorage.getItem("Data"));
      fetch('https://ims-nv9i.onrender.com/marksheet/all')
        .then(response => response.json())
        .then(data => {
          const Data = data.data;
          const filData = Data.filter(data => data.RegistrationNo === Student.StudentData.StudentId);
          setData(filData);
        })
        .catch(error => console.error('Error fetching data:', error));
    }, []);
  
    // const goToMark = (reg, couid) => {
    //   navigate(`/marksheetprint/${reg}/${couid}`);
    // };
  
    // const goToCert = (reg, couid) => {
    //   navigate(`/certificatec/${reg}/${couid}`);
    // };
  
    return (
        <DashboardContainer>
            <Header>
                Student Dashboard
                <HamburgerButton isOpen={isSidebarOpen} onClick={toggleSidebar} />
            </Header>
            <Container>
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <MainContent>
                    <Container1>
                        <Heading>Marksheet Data</Heading>
                        <Table>
                            <thead>
                                <tr>
                                    <Th>MarkSheet No</Th>
                                    <Th>Student Name</Th>
                                    <Th>Course Name</Th>
                                    <Th>Course Duration</Th>
                                    <Th>MarkSheet</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <Td>{item.MarkSheetNo}</Td>
                                        <Td>{item.StudentName}</Td>
                                        <Td>{item.CourseName}</Td>
                                        <Td>{item.CourseDuration}</Td>
                                        <Td>
                                            {item.Status ? (
                                                <Button 
                                                onClick={() => navigate(`/marksheetprint/${item.RegistrationNo}/${item.CourseId}`)}
                                                >
                                                    Marksheet
                                                </Button>
                                            ) : (
                                                "Result Declared Soon"
                                            )}
                                        </Td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Container1>
                </MainContent>
            </Container>
        </DashboardContainer>
    );
};

export default StudentResult;