import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../assets/fake.jpg'; // Add the path to your logo here

const PaymentRecordsWrapper = styled.div`
  width: 60%;
  height: calc(100vh - 70px - 100px);
  overflow-y: auto;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa;
`;

const Header1 = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
`;

const DueAmount = styled.div`
  color: red;
  font-weight: bold;
`;

const PaymentDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 20px;
`;

const Amount = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const InvoiceButton = styled.button`
  padding: 10px 15px;
  background-color: orange;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;

  &:hover {
    background-color: darkorange;
  }
`;

const PaymentMeta = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DateTime = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #666;
`;

const StatusButton = styled.div`
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border-radius: 5px;
  font-weight: bold;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  background-color: #f4f4f4;
  width: 100%;
  height: 90vh;
`;

const RightSection = styled.div`
  width: 30%;
  height: fit-content;
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
`;

const DueBadge = styled.div`
  background-color: #f00;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-top: 10px;
  font-weight: bold;
  color: #333;
`;

const Input = styled.input`
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Select = styled.select`
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  padding: 10px;
  margin-top: 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #218838;
  }
`;

const Fees = () => {
  const { StudentId } = useParams();
  const [studentData, setStudentData] = useState(null);
  const [paymentDate, setPaymentDate] = useState('');
  const [amount, setAmount] = useState('');
  const [paymentMode, setPaymentMode] = useState('cash');
  const [paymentReference, setPaymentReference] = useState('');

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await fetch(`https://ims-nv9i.onrender.com/students/get/${StudentId}`);
        const data = await response.json();
        setStudentData(data);
        console.log(data)
      } catch (error) {
        console.error('Error fetching student data:', error);
      }
    };

    fetchStudentData();
  }, [StudentId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure the amount is a valid number
    const enteredAmount = parseFloat(amount);
    if (isNaN(enteredAmount) || enteredAmount <= 0 || enteredAmount > (studentData ? studentData.Fee.DueFee : 0)) {
      toast.error('Check amount');
      return;
    }

    const paymentData = {
      StudentId,
      paymentDate,
      amount: enteredAmount,
      paymentMode,
      paymentReference,
    };

    try {
      const response = await fetch('https://ims-nv9i.onrender.com/students/addPayments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentData),
      });
      const updatedStudent = await response.json();
      setStudentData(updatedStudent);
      toast.success("Payment Added Succefully!!!")
      // Clear form fields after submission
      setPaymentDate('');
      setAmount('');
      setPaymentMode('cash');
      setPaymentReference('');
    } catch (error) {
      console.error('Error adding payment:', error);
    }
  };

  const generatePDF = (payment) => {
    const doc = new jsPDF({
      orientation: 'landscape',
    });

    const mainColor = "#FFC400";
    const textColor = "#000000";
    const darkGrayColor = "#4A4A4A";
    const footerRedColor = "#FF0000";

    const logoWidth = 50;
    const logoHeight = 20;
    const Logo = logo; // Replace with your logo image
    // doc.addImage(Logo, 'PNG', 10, 10, logoWidth, logoHeight);

    doc.setFontSize(10);
    doc.setTextColor(darkGrayColor);
    doc.text(`Invoice# ${"RES"+studentData.StudentId+payment.id}`, 240, 20);
    doc.text(`Date: ${payment.paymentDate}`, 240, 30);

    doc.setFontSize(30);
    doc.setTextColor(mainColor);
    doc.text('INVOICE', 150, 55, { align: 'center' });

    doc.setFontSize(12);
    doc.setTextColor(textColor);
    doc.text('Invoice to:', 20, 70);
    doc.setFontSize(14);
    doc.setFont(undefined, 'bold');
    doc.text(`${studentData.StudentName} (${studentData.StudentId})`, 40, 70);

    doc.autoTable({
      startY: 90,
      headStyles: {
        fillColor: "#323232",
        textColor: "#FFFFFF",
        fontSize: 12,
      },
      bodyStyles: {
        fillColor: "#F4F4F4",
        textColor: textColor,
      },
      margin: { left: 20, right: 20 },
      theme: 'grid',
      head: [['#', 'Payment Description', 'Amount']],
      body: [
        ['1', `${payment.paymentReference}`, `Rs.${payment.amount}`],
      ],
    });

    const finalY = doc.previousAutoTable.finalY;
    doc.setFontSize(12);
    doc.setTextColor(textColor);
    doc.text('Thank you for your business', 20, finalY + 10);

    doc.text(`Sub Total: Rs.${payment.amount}`, 220, finalY + 20);
    doc.text('Tax: 0.00', 222, finalY + 30);

    doc.setFillColor(mainColor);
    doc.rect(215, finalY + 35, 60, 10, 'F');
    doc.setFontSize(16);
    doc.setTextColor(textColor);
    doc.text(`Total: Rs.${payment.amount}/-`, 220, finalY + 43);

    doc.setFontSize(12);
    doc.setTextColor(darkGrayColor);
    doc.text(`Payment Info: ${payment.paymentMode}`, 20, finalY + 60);
    doc.text(`Center: ${studentData.FranchiseName}`, 20, finalY + 70);

    doc.setFontSize(10);
    // doc.setTextColor(footerRedColor);
    // doc.text('Center Code: AIIES0822   Mobile: 8279295460   Website: aiieseducation.in', 20, finalY + 90);
    // doc.text('Address: Dalsinghsarai, Samastipur (848114)', 20, finalY + 100);

    // doc.setFontSize(12);
    // doc.setTextColor(textColor);
    // doc.text('Sohit Kumar', 240, finalY + 90);
    // doc.text('Authorized Sign', 240, finalY + 100);

    const pdfBlob = doc.output('bloburl');
    window.open(pdfBlob, '_blank');
  };

  return (
    <Container>
      <PaymentRecordsWrapper>
        <Header1>
          <h2>Payment Records</h2>
          <DueAmount>Due - ₹{studentData ? studentData.Fee.DueFee : '0'}</DueAmount>
        </Header1>
        {studentData && (
          <>
            {studentData.Fee.Payments.map((payment, index) => (
              <PaymentDetails key={index}>
                <PaymentMeta>
                  <Amount>₹{payment.Amount}</Amount>
                  <div>{payment.PaymentMode}</div>
                  <DateTime>{payment.PaymentDate}</DateTime>
                </PaymentMeta>
                <InvoiceButton onClick={() => generatePDF({
                  paymentDate: payment.PaymentDate,
                  amount: payment.Amount,
                  paymentMode: payment.PaymentMode,
                  paymentReference: payment.PaymentReference || 'N/A',
                  studentName: studentData.StudentName,
                  studentId: studentData.StudentId,
                  id: index
                })}>
                  Download Invoice
                </InvoiceButton>
              </PaymentDetails>
            ))}
          </>
        )}
      </PaymentRecordsWrapper>

      <RightSection>
        <Header>
          <div>Payment Update</div>
          <DueBadge>Due - ₹{studentData ? studentData.Fee.DueFee : '0'}</DueBadge>
        </Header>
        <Form onSubmit={handleSubmit}>
          <Label>Date</Label>
          <Input
            type="date"
            name="date"
            value={paymentDate}
            onChange={(e) => setPaymentDate(e.target.value)}
            required
          />

          <Label>Amount</Label>
          <Input
            type="number"
            name="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />

          <Label>Payment Mode</Label>
          <Select
            name="paymentMode"
            value={paymentMode}
            onChange={(e) => setPaymentMode(e.target.value)}
          >
            <option value="cash">Cash</option>
            <option value="card">Card</option>
            <option value="online">Online</option>
          </Select>

          <Label>Payment Reference</Label>
          <TextArea
            name="paymentReference"
            rows="3"
            value={paymentReference}
            onChange={(e) => setPaymentReference(e.target.value)}
          />

          <SubmitButton type="submit">Submit Payment</SubmitButton>
        </Form>
      </RightSection>
      <ToastContainer />
    </Container>
  );
};

export default Fees;
