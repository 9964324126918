import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import { FaFilePdf } from 'react-icons/fa';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ViewSet = () => {
    const [sets, setSets] = useState([]);

    useEffect(() => {
        const fetchSets = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/set/all');
                if (!response.ok) {
                    throw new Error('Failed to fetch sets');
                }
                const data = await response.json();
                setSets(data);
            } catch (error) {
                console.error('Error fetching sets:', error);
                toast.error('Error fetching sets');
            }
        };
        fetchSets();
    }, []);

    const downloadPdf = (set) => {
        const doc = new jsPDF();
        const pageHeight = doc.internal.pageSize.height;
        const pageWidth = doc.internal.pageSize.getWidth();
        const margin = 10; // Margin from left
        let yPosition = 20; // Start position for questions

        // Font settings
        doc.setFontSize(14); // Font size for titles
        doc.text(`Set Name: ${set.Name}`, margin, yPosition);
        yPosition += 10; // Space after title

        doc.setFontSize(10); // Font size for questions and options

        set.QuestionPaper.forEach((question, index) => {
            // Calculate question height
            const questionText = `${index + 1}. ${question.Question}`;
            const splitQuestionText = doc.splitTextToSize(questionText, pageWidth - margin * 2);
            const questionHeight = splitQuestionText.length * 4 + 10; // Line height + space

            // Calculate options height
            const optionIndent = 5; // Indentation for options
            const options = [
                `A. ${question.Option1}`,
                `B. ${question.Option2}`,
                `C. ${question.Option3}`,
                `D. ${question.Option4}`
            ];

            const optionsHeight = options.reduce((total, option, i) => {
                const splitOptionText = doc.splitTextToSize(option, (pageWidth - margin * 2) / 2 - optionIndent);
                return total + splitOptionText.length * 4 + 10; // Line height + space
            }, 0);

            // Check if we need a new page
            if (yPosition + questionHeight + optionsHeight > pageHeight - margin * 2) {
                doc.addPage();
                yPosition = 20;
                doc.setFontSize(10); // Reset font size for new page
            }

            // Add question text
            doc.text(splitQuestionText, margin, yPosition);
            yPosition += questionHeight;

            // Add options in a grid layout
            options.forEach((option, i) => {
                const splitOptionText = doc.splitTextToSize(option, (pageWidth - margin * 2) / 2 - optionIndent);
                const xPosition = i % 2 === 0 ? margin + optionIndent : margin + (pageWidth - margin * 2) / 2 + optionIndent;
                doc.text(splitOptionText, xPosition, yPosition);
                if (i % 2 !== 0) yPosition += splitOptionText.length * 4 + 10; // Line height + space
            });

            // Add some extra space between questions
            yPosition += 15; // Space between questions
        });

        doc.save(`${set.Name}.pdf`);
        toast.success('PDF downloaded successfully!');
    };

    return (
        <Container>
            <Header>All Sets</Header>
            <SetsGrid>
                {sets.map((set) => (
                    <SetCard key={set._id}>
                        <CardContent>{set.Name}</CardContent>
                        <DownloadButton onClick={() => downloadPdf(set)}>
                            <FaFilePdf />
                            <ButtonText>Download PDF</ButtonText>
                        </DownloadButton>
                    </SetCard>
                ))}
            </SetsGrid>
            <ToastContainer />
        </Container>
    );
};

// Styled Components
const Container = styled.section`
    padding: 20px;
    background-color: #f4f4f4;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Header = styled.h1`
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
`;

const SetsGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    max-width: 1200px;
    width: 100%;
`;

const SetCard = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 250px;
    text-align: center;
`;

const CardContent = styled.p`
    font-size: 18px;
    color: #555;
    margin-bottom: 15px;
`;

const DownloadButton = styled.button`
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
    }
`;

const ButtonText = styled.span`
    font-size: 16px;
`;

export default ViewSet;
