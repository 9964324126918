// Sidebar.js
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { FaTimes, FaChevronDown, FaChevronRight } from "react-icons/fa";

const SidebarContainer = styled.nav`
  width: 250px;
  background-color: #333;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? "0" : "-100%")};
  height: 100%;
  z-index: 1000;
  color: #fff;

  @media (min-width: 769px) {
    position: relative;
    left: 0;
    height: auto;
    z-index: auto;
  }
`;

const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: #fff;
`;

const CloseButton = styled(FaTimes)`
  font-size: 24px;
  cursor: pointer;
  display: ${(props) => (props.isOpen ? "block" : "none")};

  @media (min-width: 769px) {
    display: none;
  }
`;

const SidebarLink = styled(Link)`
  display: block;
  padding: 10px 0;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  border-bottom: 1px solid #444;

  &:hover {
    background-color: #555;
  }
`;

const ExpandableItem = styled.div`
    border-bottom: 1px solid #444;
`;

const ExpandButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  text-align: left;

  &:hover {
    color: #ddd;
  }
`;

const ChildLinksContainer = styled.div`
  padding-left: 20px;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const ChildLink = styled(Link)`
  display: block;
  padding: 5px 0;
  color: #bbb;
  text-decoration: none;
  font-size: 16px;

  &:hover {
    color: #fff;
  }
`;

const Sidebar = ({ isOpen, toggleSidebar }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const navigate = useNavigate()
    useEffect(() => {
        var Id;
        const localdata = JSON.parse(localStorage.getItem("Data"));
        Id = (localdata.Role);
        if (Id !== "Student") {
            navigate('/');
        }
    }, [])

    const logout = () => {
        localStorage.removeItem("Data");
        localStorage.removeItem('Id');
        navigate('/');
    }

    return (
        <SidebarContainer isOpen={isOpen}>
            <SidebarHeader>
                <h3>Navigation</h3>
                <CloseButton isOpen={isOpen} onClick={toggleSidebar} />
            </SidebarHeader>
            <div>
                <SidebarLink to="/student" onClick={toggleSidebar}>
                    Home
                </SidebarLink>
                {/* <ExpandableItem>
                    <ExpandButton onClick={toggleExpand}>
                        Profile
                        {isExpanded ? <FaChevronDown /> : <FaChevronRight />}
                    </ExpandButton>
                    <ChildLinksContainer isOpen={isExpanded}>
                        <ChildLink to="/profile/view" onClick={toggleSidebar}>
                            View Profile
                        </ChildLink>
                        <ChildLink to="/profile/edit" onClick={toggleSidebar}>
                            Edit Profile
                        </ChildLink>
                    </ChildLinksContainer>
                </ExpandableItem> */}
                <SidebarLink to="/student/course" onClick={toggleSidebar}>
                    Course
                </SidebarLink>
                <SidebarLink to="/student/paymentinfo" onClick={toggleSidebar}>
                    Payment Information
                </SidebarLink>
                <SidebarLink to="/student/result" onClick={toggleSidebar}>
                    Result
                </SidebarLink>
                <SidebarLink to="/student/notification" onClick={toggleSidebar}>
                    Notification
                </SidebarLink>
                <SidebarLink to="/student/exam" onClick={toggleSidebar}>
                    Exam
                </SidebarLink>
                <SidebarLink to="/student/certificate" onClick={toggleSidebar}>
                    Certificate
                </SidebarLink>
                <SidebarLink to="/student/admitcard" onClick={toggleSidebar}>
                    Admit Card
                </SidebarLink>
                <SidebarLink to="/student/idcard" onClick={toggleSidebar}>
                  Id Card
                </SidebarLink>
                <div onClick={logout}>
                    <SidebarLink>
                        Logout
                    </SidebarLink>
                </div>
            </div>
        </SidebarContainer>
    );
};

export default Sidebar;
