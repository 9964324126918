import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ConfirmationDialog from './ConfirmationDialog'; // Import the ConfirmationDialog component

const ExamPaper = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [examData, setExamData] = useState(null);
    const [setsData, setSetsData] = useState(null);
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [answers, setAnswers] = useState({});
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        const fetchExamData = async () => {
            try {
                const response = await fetch(`https://ims-nv9i.onrender.com/examQuestions/get/${location.state.id}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log(data)
                localStorage.setItem("ExamData", JSON.stringify(data));
                setExamData(data);
                initializeTimer(data.Duration, data.DateTime);
            } catch (error) {
                console.error('Error fetching exam data:', error);
            }
        };

        const fetchSetsData = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/set/all');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setSetsData(data);
            } catch (error) {
                console.error('Error fetching sets data:', error);
            }
        };

        fetchExamData();
        fetchSetsData();
        loadSavedAnswers();
    }, [location.state.id]);

    useEffect(() => {
        if (examData && setsData) {
            const courseId = examData.CourseId;
            const relevantSets = setsData.filter(set =>
                set.QuestionPaper.some(qp => qp.CourseIds.includes(courseId))
            );

            const questions = relevantSets.flatMap(set =>
                set.QuestionPaper.filter(qp => qp.CourseIds.includes(courseId))
            );

            setFilteredQuestions(questions);
        }
    }, [examData, setsData]);

    const initializeTimer = (duration, dateTime) => {
        const examEndTime = new Date(dateTime).getTime() + duration * 60000; // duration in milliseconds
        const now = Date.now();
        const timeLeft = examEndTime - now;

        if (timeLeft > 0) {
            setTimeRemaining(timeLeft);
            const timerInterval = setInterval(() => {
                setTimeRemaining(prev => {
                    if (prev <= 1000) {
                        clearInterval(timerInterval);
                        handleSubmit(); // Auto-submit when time is up
                        return 0;
                    }
                    return prev - 1000;
                });
            }, 1000);

            return () => clearInterval(timerInterval); // Clear interval on unmount
        } else {
            handleSubmit(); // If timeLeft is 0 or negative, submit immediately
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            saveAnswers();
        }, 10000); // Auto-save every 10 seconds

        return () => clearInterval(interval);
    }, [answers]);

    const handleAnswerChange = (questionId, answer) => {
        const updatedAnswers = {
            ...answers,
            [questionId]: answer !== undefined ? answer : 0
        };
        setAnswers(updatedAnswers);
        localStorage.setItem('examAnswers', JSON.stringify(updatedAnswers));
    };

    const saveAnswers = async () => {
        localStorage.setItem('examAnswers', JSON.stringify(answers));
    };

    const loadSavedAnswers = () => {
        const savedAnswers = JSON.parse(localStorage.getItem('examAnswers'));
        if (savedAnswers) {
            const allQuestions = filteredQuestions.map(question => question._id);
            const updatedAnswers = {};
            allQuestions.forEach(id => {
                updatedAnswers[id] = savedAnswers[id] !== undefined ? savedAnswers[id] : 0;
            });
            setAnswers(updatedAnswers);
        }
    };

    const handleSubmit = async () => {
        if (isDialogOpen) return; // Prevent multiple dialogs

        setIsDialogOpen(true); // Open the confirmation dialog
    };

    const confirmSubmission = async () => {
        setIsDialogOpen(false); // Close the dialog

        const ExamId = JSON.parse(localStorage.getItem("ExamData"))._id;
        const ExamName = JSON.parse(localStorage.getItem("ExamData")).Name;
        const CourseId = JSON.parse(localStorage.getItem("ExamData")).CourseId;
        const data = JSON.parse(localStorage.getItem("Data"));
        const selectedOptions = filteredQuestions.map(question => ({
            questionId: question._id,
            correctAnswer: question.Answer,
            selectedAnswer: answers[question._id] || 0 // Default to 0 if no answer is selected
        }));

        const resultData = {
            // StudentId: data.StudentData.StudentId,
            ExamId,
            ExamName,
            // CourseId,
            SelectedOption: selectedOptions
        };

        console.log(resultData);

        try {
            const response = await fetch(`https://ims-nv9i.onrender.com/students/${data.StudentData.StudentId}/exams`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(resultData)
            });

            if (!response.ok) {
                throw new Error('Failed to submit the exam');
            }

            const responseData = await response.json();
            console.log('Submission successful:', responseData);
            localStorage.removeItem('examAnswers');
            navigate('/student'); // Redirect after successful submission
        } catch (error) {
            console.error('Error submitting answers:', error);
        }
    };

    const cancelSubmission = () => {
        setIsDialogOpen(false); // Close the dialog without submitting
    };

    const formatTime = time => {
        const hours = Math.floor(time / 3600000);
        const minutes = Math.floor((time % 3600000) / 60000);
        const seconds = Math.floor((time % 60000) / 1000);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <Container>
            <Title>Exam Paper</Title>
            {examData && (
                <ExamInfo>
                    <p><strong>Franchise:</strong> {examData.FranchiseName}</p>
                    <p><strong>Course Name:</strong> {examData.Name}</p>
                    <p><strong>Duration:</strong> {examData.Duration} minutes</p>
                    <p><strong>Batch Name:</strong> {examData.BatchName.join(', ')}</p>
                    <p><strong>Number of Students:</strong> {examData.StudentNo}</p>
                </ExamInfo>
            )}
            {timeRemaining !== null && (
                <Timer>
                    <p>Time Remaining: {formatTime(timeRemaining)}</p>
                </Timer>
            )}
            <form onSubmit={e => { e.preventDefault(); handleSubmit(); }}>
                {filteredQuestions.map(question => (
                    <QuestionContainer key={question._id}>
                        <p><strong>Question:</strong> {question.Question}</p>
                        <div>
                            <label>
                                <input
                                    type="radio"
                                    name={question._id}
                                    value="1"
                                    checked={answers[question._id] === 1}
                                    onChange={() => handleAnswerChange(question._id, 1)}
                                />
                                {question.Option1}
                            </label>
                        </div>
                        <div>
                            <label>
                                <input
                                    type="radio"
                                    name={question._id}
                                    value="2"
                                    checked={answers[question._id] === 2}
                                    onChange={() => handleAnswerChange(question._id, 2)}
                                />
                                {question.Option2}
                            </label>
                        </div>
                        <div>
                            <label>
                                <input
                                    type="radio"
                                    name={question._id}
                                    value="3"
                                    checked={answers[question._id] === 3}
                                    onChange={() => handleAnswerChange(question._id, 3)}
                                />
                                {question.Option3}
                            </label>
                        </div>
                        <div>
                            <label>
                                <input
                                    type="radio"
                                    name={question._id}
                                    value="4"
                                    checked={answers[question._id] === 4}
                                    onChange={() => handleAnswerChange(question._id, 4)}
                                />
                                {question.Option4}
                            </label>
                        </div>
                    </QuestionContainer>
                ))}
                <SubmitButton type="submit">Submit</SubmitButton>
            </form>
            <ConfirmationDialog
                isOpen={isDialogOpen}
                message="Are you sure you want to submit your answers?"
                onConfirm={confirmSubmission}
            />
        </Container>
    );
};

// Styled Components
const Container = styled.div`
    margin: 20px;
    font-family: Arial, sans-serif;
`;

const Title = styled.h1`
    text-align: center;
    margin-bottom: 20px;
    color: #333;
`;

const ExamInfo = styled.div`
    margin-bottom: 20px;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    p {
        margin: 5px 0;
    }
`;

const Timer = styled.div`
    text-align: right;
    font-size: 1.2em;
    color: #d9534f;
    margin-bottom: 20px;
`;

const QuestionContainer = styled.div`
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;

    p {
        margin: 0 0 10px 0;
    }

    label {
        display: block;
        margin-bottom: 5px;
        cursor: pointer;
    }

    input[type="radio"] {
        margin-right: 10px;
    }
`;

const SubmitButton = styled.button`
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #5cb85c;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #4cae4c;
    }
`;

export default ExamPaper;
