import React, { useState } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddSet = () => {
    const [setName, setSetName] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://ims-nv9i.onrender.com/set/add-set', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ Name: setName }),
            });
            if (response.ok) {
                toast.success('Set name added successfully!');
                setSetName('');
            } else {
                toast.error('Failed to add set name.');
            }
        } catch (error) {
            toast.error('Error adding set name.');
            console.error('Error adding set name:', error);
        }
    };

    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                <Title>Add New Set</Title>
                <Input
                    type="text"
                    placeholder="Set Name"
                    value={setName}
                    onChange={(e) => setSetName(e.target.value)}
                    required
                />
                <SubmitButton type="submit">Submit</SubmitButton>
            </Form>
            <ToastContainer />
        </Container>
    );
};

// Styled-components
const Container = styled.section`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 70px);
    background-color: #f4f4f4;
    padding: 20px;
`;

const Form = styled.form`
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Title = styled.h2`
    font-size: 24px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
`;

const Input = styled.input`
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
`;

const SubmitButton = styled.button`
    padding: 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #0056b3;
    }
`;

export default AddSet;
