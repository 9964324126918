import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoMdClose } from 'react-icons/io';

const CreateQuestions = () => {
    const [courses, setCourses] = useState([]);
    const [sets, setSets] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [disabledCourses, setDisabledCourses] = useState([]); // New state for disabled buttons
    const [formData, setFormData] = useState({
        question: '',
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: '',
        correctOption: '',
        setName: '',
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/courses/all');
                const data = await response.json();
                setCourses(data);
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };

        const fetchSets = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/set/all');
                const data = await response.json();
                setSets(data);
            } catch (error) {
                console.error('Error fetching sets:', error);
            }
        };

        fetchCourses();
        fetchSets();
    }, []);

    const handleCourseClick = (courseId) => {
        if (!selectedCourses.includes(courseId)) {
            setSelectedCourses([...selectedCourses, courseId]);
            setDisabledCourses([...disabledCourses, courseId]); // Disable button after click
        }
    };

    const handleRemoveCourse = (courseId) => {
        setSelectedCourses(selectedCourses.filter(id => id !== courseId));
        setDisabledCourses(disabledCourses.filter(id => id !== courseId)); // Re-enable button if course is removed
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const newQuestion = {
            Question: formData.question,
            Option1: formData.optionA,
            Option2: formData.optionB,
            Option3: formData.optionC,
            Option4: formData.optionD,
            Answer: formData.correctOption,
            CourseIds: selectedCourses,
        };

        try {
            const response = await fetch(`https://ims-nv9i.onrender.com/set/${formData.setName}/add-question`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newQuestion),
            });

            if (response.ok) {
                toast.success('Question added successfully!');
                setFormData({
                    question: '',
                    optionA: '',
                    optionB: '',
                    optionC: '',
                    optionD: '',
                    correctOption: '',
                    setName: '',
                });
                setSelectedCourses([]);
                setDisabledCourses([]); // Re-enable all buttons
            } else {
                toast.error('Failed to add question.');
            }
        } catch (error) {
            toast.error('Error adding question.');
            console.error('Error adding question:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                <Header>
                    <Label>Select Set</Label>
                    <Select
                        name="setName"
                        value={formData.setName}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select Set</option>
                        {sets.map(set => (
                            <option key={set._id} value={set._id}>
                                {set.Name}
                            </option>
                        ))}
                    </Select>
                </Header>

                <Questions>
                    <Input
                        type="text"
                        name="question"
                        placeholder="Question"
                        value={formData.question}
                        onChange={handleChange}
                        required
                    />
                    <Options>
                        <Input
                            type="text"
                            name="optionA"
                            placeholder="Option A"
                            value={formData.optionA}
                            onChange={handleChange}
                            required
                        />
                        <Input
                            type="text"
                            name="optionB"
                            placeholder="Option B"
                            value={formData.optionB}
                            onChange={handleChange}
                            required
                        />
                        <Input
                            type="text"
                            name="optionC"
                            placeholder="Option C"
                            value={formData.optionC}
                            onChange={handleChange}
                            required
                        />
                        <Input
                            type="text"
                            name="optionD"
                            placeholder="Option D"
                            value={formData.optionD}
                            onChange={handleChange}
                            required
                        />
                        <Input
                            type="text"
                            name="correctOption"
                            placeholder="Correct Option"
                            value={formData.correctOption}
                            onChange={handleChange}
                            required
                        />
                    </Options>
                    <CourseSelection>
                        <Label>Select Course</Label>
                        <CourseButtons>
                            {courses.map(course => (
                                <CourseButton
                                    key={course._id}
                                    onClick={() => handleCourseClick(course.CourseId)}
                                    disabled={disabledCourses.includes(course.CourseId)}
                                >
                                    {course.CourseName}
                                </CourseButton>
                            ))}
                        </CourseButtons>
                        <SelectedCourses>
                            <h3>Selected Courses</h3>
                            <CourseList>
                                {selectedCourses.map((courseId, index) => {
                                    const course = courses.find(c => c.CourseId === courseId);
                                    return (
                                        course && (
                                            <CourseItem key={index}>
                                                {course.CourseName}
                                                <RemoveButton
                                                    onClick={() => handleRemoveCourse(courseId)}
                                                >
                                                    <IoMdClose />
                                                </RemoveButton>
                                            </CourseItem>
                                        )
                                    );
                                })}
                            </CourseList>
                        </SelectedCourses>
                    </CourseSelection>
                </Questions>

                <SubmitSection>
                    <SubmitButton
                        type="submit"
                        disabled={selectedCourses.length === 0 || loading}
                    >
                        {loading ? 'Adding...' : 'Add New Question'}
                    </SubmitButton>
                </SubmitSection>
            </Form>
            <ToastContainer />
        </Container>
    );
};

// Styled-components
const Container = styled.section`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 70px);
    background-color: #f4f4f4;
    padding: 20px;
`;

const Form = styled.form`
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
`;

const Select = styled.select`
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
`;

const Questions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const Input = styled.input`
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
`;

const Options = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const CourseSelection = styled.div`
    display: flex;
    flex-direction: column;
`;

const CourseButtons = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

const CourseButton = styled.button`
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    &:hover {
        background-color: #0056b3;
    }
    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

const SelectedCourses = styled.div`
    margin-top: 20px;
    h3 {
        font-size: 18px;
        color: #333;
    }
`;

const CourseList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const CourseItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
`;

const RemoveButton = styled.button`
    background: none;
    border: none;
    color: #dc3545;
    cursor: pointer;
    font-size: 20px;
    &:hover {
        color: #c82333;
    }
`;

const SubmitSection = styled.div`
    display: flex;
    justify-content: center;
`;

const SubmitButton = styled.button`
    padding: 15px 30px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
        background-color: #218838;
    }
    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

export default CreateQuestions;
