import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormContainer = styled.div`
  width: 100%;
  margin: 20px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const FormHeading = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
`;

const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
`;

const FormColumn = styled.div`
  flex: 1;
  min-width: 300px;
  box-sizing: border-box;
`;

const FormLabel = styled.label`
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const FormSelect = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const FormTextarea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const FormFileInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 5px;
`;

const ErrorText = styled.p`
  color: red;
  font-size: 14px;
`;

const SubmitButton = styled.button`
  background: #008cba;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  &:hover {
    background: #007bb5;
  }
`;

// Validation functions
const isValidMobileNumber = (number) => /^\d{10}$/.test(number);
const isValidAadharNumber = (number) => /^\d{12}$/.test(number);
const isValidPinCode = (number) => /^\d{6}$/.test(number);
const isValidName = (name) => /^[A-Za-z\s]+$/.test(name);

const AddStudent = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        StudentName: '',
        FatherName: '',
        MotherName: '',
        Gender: '',
        DOB: '',
        Qualification: '',
        AadharNo: '',
        StudentPhoto: null,
        StudentSignature: null,
        AadharCard: null,
        QualificationFile: null,
        MobileNo: '',
        EmailId: '',
        Address: '',
        City: '',
        State: '',
        Dist: '',
        PinCode: '',
        FranchiseId: '',
        FranchiseName: ''
    });

    const [errors, setErrors] = useState({});
    const [Id, setId] = useState("");
    const [name,setName] = useState("");

    useEffect(() => {
        const localdata = JSON.parse(localStorage.getItem("Data"));
        if (localdata) {
            if (localdata.EmployeeData) {
                setId(localdata.EmployeeData.FranchiseId);
                setName(localdata.EmployeeData.FranchiseName);
                setFormData(prevState => ({
                    ...prevState,
                    FranchiseId: localdata.EmployeeData.FranchiseId,
                    FranchiseName: localdata.EmployeeData.FranchiseName

                }));
            } else if (localdata.FranchiseData) {
                setId(localdata.FranchiseData.FranchiseId);
                setName(localdata.FranchiseData.FranchiseName);
                setFormData(prevState => ({
                    ...prevState,
                    FranchiseId: localdata.FranchiseData.FranchiseId,
                    FranchiseName: localdata.FranchiseData.FranchiseName
                }));
            } else {
                setId("Admin");
                setName("Admin");
                setFormData(prevState => ({
                    ...prevState,
                    FranchiseId: "Admin",
                    FranchiseName: "Admin"
                }));
            }
        } else {
            setId("Admin");
            setName("Admin");
            setFormData(prevState => ({
                ...prevState,
                FranchiseId: "Admin",
                FranchiseName: "Admin"
            }));
        }
    }, []);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'MobileNo' || name === 'AadharNo' || name === 'PinCode') {
            const regex = /^[0-9]*$/;
            if (!regex.test(value)) return;
        }
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: files ? files[0] : value
        }));
    };

    const validateForm = () => {
        let valid = true;
        let newErrors = {};

        // Name validation
        if (!isValidName(formData.StudentName)) {
            valid = false;
            newErrors.StudentName = 'Student Name should contain only alphabets and spaces.';
        }
        if (!isValidName(formData.FatherName)) {
            valid = false;
            newErrors.FatherName = 'Father Name should contain only alphabets and spaces.';
        }
        if (!isValidName(formData.MotherName)) {
            valid = false;
            newErrors.MotherName = 'Mother Name should contain only alphabets and spaces.';
        }

        // Mobile Number validation
        if (!isValidMobileNumber(formData.MobileNo)) {
            valid = false;
            newErrors.MobileNo = 'Mobile number must be exactly 10 digits.';
        }

        // Pincode validation
        if (!isValidPinCode(formData.PinCode)) {
            valid = false;
            newErrors.PinCode = 'Pincode number must be exactly 6 digits.';
        }

        // Aadhar Number validation
        if (!isValidAadharNumber(formData.AadharNo)) {
            valid = false;
            newErrors.AadharNo = 'Aadhar number must be exactly 12 digits.';
        }

        // Email validation
        if (!formData.EmailId || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.EmailId)) {
            valid = false;
            newErrors.EmailId = 'Invalid email address.';
        }

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            toast.error('Please correct the errors before submitting.');
            return;
        }

        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }

        try {
            const response = await fetch('https://ims-nv9i.onrender.com/students/add', {
                method: 'POST',
                body: data,
            });

            if (!response.ok) {
                const errorData = await response.json();
                toast.error('Error: ' + (errorData.error || 'Unknown error'));
            } else {
                const responseData = await response.json();
                toast.success('Success: ' + responseData.message);
                setFormData({
                    StudentName: '',
                    FatherName: '',
                    MotherName: '',
                    Gender: '',
                    DOB: '',
                    Qualification: '',
                    AadharNo: '',
                    StudentPhoto: null,
                    StudentSignature: null,
                    AadharCard: null,
                    QualificationFile: null,
                    MobileNo: '',
                    EmailId: '',
                    Address: '',
                    City: '',
                    State: '',
                    Dist: '',
                    PinCode: '',
                    FranchiseId: Id,
                    FranchiseName: name
                });
                navigate('/AddStudentToBatchSA', { state: { id: responseData.savedStudent.StudentId } });
            }
        } catch (error) {
            toast.error('Error: ' + error.message);
        }
    };

    // Array of States
    const states = [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli and Daman and Diu",
        "Lakshadweep",
        "Delhi",
        "Puducherry",
        "Ladakh",
        "Jammu and Kashmir",
    ];

    return (
        <FormContainer>
            <FormHeading>Student Registration</FormHeading>
            <form onSubmit={handleSubmit}>
                <FormRow>
                    <FormColumn>
                        <FormLabel htmlFor="StudentName">
                            Student Name:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormInput
                            id="StudentName"
                            name="StudentName"
                            type="text"
                            value={formData.StudentName}
                            onChange={handleChange}
                        />
                        {errors.StudentName && <ErrorText>{errors.StudentName}</ErrorText>}
                    </FormColumn>

                    <FormColumn>
                        <FormLabel htmlFor="FatherName">
                            Father Name:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormInput
                            id="FatherName"
                            name="FatherName"
                            type="text"
                            value={formData.FatherName}
                            onChange={handleChange}
                        />
                        {errors.FatherName && <ErrorText>{errors.FatherName}</ErrorText>}
                    </FormColumn>

                    <FormColumn>
                        <FormLabel htmlFor="MotherName">
                            Mother Name:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormInput
                            id="MotherName"
                            name="MotherName"
                            type="text"
                            value={formData.MotherName}
                            onChange={handleChange}
                        />
                        {errors.MotherName && <ErrorText>{errors.MotherName}</ErrorText>}
                    </FormColumn>
                </FormRow>

                <FormRow>
                    <FormColumn>
                        <FormLabel htmlFor="Gender">
                            Gender:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormSelect
                            id="Gender"
                            name="Gender"
                            value={formData.Gender}
                            onChange={handleChange}
                        >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </FormSelect>
                    </FormColumn>
                </FormRow>

                <FormRow>
                    <FormColumn>
                        <FormLabel htmlFor="DOB">
                            Date of Birth:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormInput
                            id="DOB"
                            name="DOB"
                            type="date"
                            value={formData.DOB}
                            onChange={handleChange}
                        />
                    </FormColumn>

                    <FormColumn>
                        <FormLabel htmlFor="Qualification">
                            Qualification:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormInput
                            id="Qualification"
                            name="Qualification"
                            type="text"
                            value={formData.Qualification}
                            onChange={handleChange}
                        />
                    </FormColumn>

                    <FormColumn>
                        <FormLabel htmlFor="AadharNo">
                            Aadhar Number:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormInput
                            id="AadharNo"
                            name="AadharNo"
                            type="text"
                            value={formData.AadharNo}
                            onChange={handleChange}
                        />
                        {errors.AadharNo && <ErrorText>{errors.AadharNo}</ErrorText>}
                    </FormColumn>
                </FormRow>

                <FormRow>
                    <FormColumn>
                        <FormLabel htmlFor="MobileNo">
                            Mobile Number:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormInput
                            id="MobileNo"
                            name="MobileNo"
                            type="text"
                            value={formData.MobileNo}
                            onChange={handleChange}
                        />
                        {errors.MobileNo && <ErrorText>{errors.MobileNo}</ErrorText>}
                    </FormColumn>

                    <FormColumn>
                        <FormLabel htmlFor="EmailId">
                            Email ID:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormInput
                            id="EmailId"
                            name="EmailId"
                            type="email"
                            value={formData.EmailId}
                            onChange={handleChange}
                        />
                        {errors.EmailId && <ErrorText>{errors.EmailId}</ErrorText>}
                    </FormColumn>

                    <FormColumn>
                        <FormLabel htmlFor="Address">
                            Address:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormTextarea
                            id="Address"
                            name="Address"
                            value={formData.Address}
                            onChange={handleChange}
                        />
                    </FormColumn>
                </FormRow>

                <FormRow>
                    <FormColumn>
                        <FormLabel htmlFor="City">
                            City:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormInput
                            id="City"
                            name="City"
                            type="text"
                            value={formData.City}
                            onChange={handleChange}
                        />
                    </FormColumn>

                    <FormColumn>
                        <FormLabel htmlFor="State">
                            State:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormSelect
                            id="State"
                            name="State"
                            value={formData.State}
                            onChange={handleChange}
                        >
                            <option value="">Select State</option>
                            {states.map(state => (
                                <option key={state} value={state}>
                                    {state}
                                </option>
                            ))}
                        </FormSelect>
                    </FormColumn>

                    <FormColumn>
                        <FormLabel htmlFor="Dist">
                            District:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormInput
                            id="Dist"
                            name="Dist"
                            type="text"
                            value={formData.Dist}
                            onChange={handleChange}
                        />
                    </FormColumn>
                </FormRow>

                <FormRow>
                    <FormColumn>
                        <FormLabel htmlFor="PinCode">
                            Pin Code:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormInput
                            id="PinCode"
                            name="PinCode"
                            type="text"
                            value={formData.PinCode}
                            onChange={handleChange}
                        />
                        {errors.PinCode && <ErrorText>{errors.PinCode}</ErrorText>}
                    </FormColumn>
                </FormRow>

                <FormRow>
                    <FormColumn>
                        <FormLabel htmlFor="StudentPhoto">
                            Student Photo:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormFileInput
                            id="StudentPhoto"
                            name="StudentPhoto"
                            type="file"
                            accept="image/*"
                            onChange={handleChange}
                        />
                    </FormColumn>

                    <FormColumn>
                        <FormLabel htmlFor="StudentSignature">
                            Student Signature:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormFileInput
                            id="StudentSignature"
                            name="StudentSignature"
                            type="file"
                            accept="image/*"
                            onChange={handleChange}
                        />
                    </FormColumn>

                    <FormColumn>
                        <FormLabel htmlFor="AadharCard">
                            Aadhar Card:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormFileInput
                            id="AadharCard"
                            name="AadharCard"
                            type="file"
                            accept="image/*"
                            onChange={handleChange}
                        />
                    </FormColumn>
                </FormRow>

                <FormRow>
                    <FormColumn>
                        <FormLabel htmlFor="QualificationFile">
                            Qualification File:&nbsp;<span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <FormFileInput
                            id="QualificationFile"
                            name="QualificationFile"
                            type="file"
                            accept=".pdf,.doc,.docx"
                            onChange={handleChange}
                        />
                    </FormColumn>
                </FormRow>

                <SubmitButton type="submit">Submit</SubmitButton>
            </form>
            <ToastContainer />
        </FormContainer>
    );
};

export default AddStudent;
