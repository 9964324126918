import React, { useEffect, useState } from 'react';
import { Hourglass } from 'react-loader-spinner';
import { useParams, useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import backgroundUrl from '../../assets/LOKNATHPUR MANSURCHAK ROAD DALSINGHSARAI SAMASTIPUR - 848114, BIHAR (INDIA) (2)-1.png';

const AdmitCard = () => {
    const { StudentId, ExamId } = useParams();
    const [pdfUrl, setPdfUrl] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // const data = {
    //     examQuestion: {
    //         _id: "66b9f27d6d08b02927a5c8f0",
    //         FranchiseId: "FRA-00001",
    //         FranchiseName: "Codify Institute",
    //         Name: "New Exam",
    //         Passing: 35,
    //         CourseId: "COU-00001",
    //         Duration: 20,
    //         Sets: [
    //             "Set A",
    //             "Set B",
    //             "Set C",
    //             "Set D",
    //             "Set E",
    //             "SET F",
    //             "Set H"
    //         ],
    //         DateTime: "2024-08-12T17:00",
    //         StudentNo: 10,
    //         BatchId: [
    //             "1"
    //         ],
    //         BatchName: [
    //             "virar"
    //         ],
    //         __v: 0
    //     },
    //     student: {
    //         Documents: {
    //             StudentPhoto: "1722506762759.jfif",
    //             StudentSignature: "1722506762760.png",
    //             AadharCard: "1722506762760.png",
    //             QualificationFile: "1722506762760.webp"
    //         },
    //         _id: "66ab5e0a8ed8108969b6752a",
    //         StudentId: "STU-00031",
    //         StudentName: "Sakshi narkhede",
    //         FatherName: "Dnyandev Narkhede",
    //         MotherName: "Archana Narkhede",
    //         Gender: "Female",
    //         DOB: "2000-02-07",
    //         Qualification: "BE ",
    //         AadharNo: "123456789876",
    //         MobileNo: "7385759226",
    //         EmailId: "nsakshid0702@gmail.com",
    //         Address: "naer Jivanashram,chinchan Mangel ali, MAHARASHTRA 401503",
    //         City: "Chinchani",
    //         State: "Maharashtra",
    //         Dist: "Palghar",
    //         PinCode: 401503,
    //         FranchiseId: "FRA-00001",
    //         FranchiseName: "Virar",
    //         Courses: [
    //             {
    //                 Result: false,
    //                 CourseId: "1721812062833",
    //                 CourseName: "Web Development",
    //                 PlanName: "Platinum",
    //                 ExamType: "Online",
    //                 BatchId: "BAT-00026",
    //                 BatchName: "Boisar",
    //                 _id: "66ae04ea94db61cb44f02a33"
    //             },
    //             {
    //                 Result: false,
    //                 CourseId: "1721812062833",
    //                 CourseName: "Web Development",
    //                 PlanName: "Platinum",
    //                 ExamType: "Online",
    //                 BatchId: "BAT-00026",
    //                 BatchName: "Boisar",
    //                 _id: "66ae059f94db61cb44f02a3a"
    //             },
    //             {
    //                 Result: false,
    //                 CourseId: "1721812062833",
    //                 CourseName: "Web Development",
    //                 PlanName: "Platinum",
    //                 ExamType: "Online",
    //                 BatchId: "BAT-00026",
    //                 BatchName: "Boisar",
    //                 _id: "66ae05e1e2d34600c33727b1"
    //             },
    //             {
    //                 Result: false,
    //                 CourseId: "1721812062833",
    //                 CourseName: "Web Development",
    //                 PlanName: "Platinum",
    //                 ExamType: "Offline",
    //                 BatchId: "BAT-00026",
    //                 BatchName: "Boisar",
    //                 _id: "66ae0b5fa0e9058fc87b4032"
    //             },
    //             {
    //                 Result: false,
    //                 CourseId: "1721812062833",
    //                 CourseName: "Web Development",
    //                 PlanName: "Platinum",
    //                 ExamType: "Online",
    //                 BatchId: "BAT-00033",
    //                 BatchName: "JAVA",
    //                 _id: "66ae10ccfb9bb7363e55da4b"
    //             },
    //             {
    //                 Result: false,
    //                 CourseId: "COU-00001",
    //                 CourseName: "Java",
    //                 PlanName: "Platinum",
    //                 ExamType: "Online",
    //                 BatchId: "BAT-00033",
    //                 BatchName: "JAVA",
    //                 _id: "66ae190fa341a98acbf70f89"
    //             },
    //             {
    //                 Result: false,
    //                 CourseId: "COU-00002",
    //                 CourseName: "Introduction to Computer Science",
    //                 PlanName: "Basic",
    //                 ExamType: "Online",
    //                 BatchId: "BAT-00033",
    //                 BatchName: "JAVA",
    //                 _id: "66b49c5e2d28291f92f23b03"
    //             }
    //         ],
    //         Date: "2024-08-01",
    //         __v: 7
    //     },
    //     fran: {
    //         DirectorOne: {
    //             Name: "Harshit Raul",
    //             Photo: "1723110048435photo.jfif",
    //             Signature: "1723110048435sign.png",
    //             AadharCard: "1723110048435adhar.png",
    //             Qualification: "1723110048435degree.webp",
    //             Agreement: "1723110048436AUTHORISED TRAINING CERTIFICATE.pdf"
    //         },
    //         _id: "66a38aea2cb6f43663c453cf",
    //         FranchiseId: "FRA-00001",
    //         FranchiseName: "Codify Institute",
    //         Email: "codifyInstitute@gmail.com",
    //         MobileNo: "1234567890",
    //         Address: "Near Viva College, Above Zudio, Virar",
    //         MasterFranchiseId: "-",
    //         City: "Virar",
    //         UnderFranchise: [],
    //         Date: "2024-07-26",
    //         Approved: true,
    //         Requested: true,
    //         __v: 0,
    //         Active: true
    //     }
    // };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://ims-nv9i.onrender.com/students/Admit/${ExamId}/${StudentId}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                console.log(result)
                setData(result);
            } catch (error) {
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [StudentId, ExamId]);

    const generatePdf = async () => {
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: 'a4',
        });

        try {
            // Load the background image
            const backgroundImage = await loadImage(backgroundUrl);

            // Load the student photo
            const studentPhotoUrl = `https://ims-nv9i.onrender.com/uploads/${data.student.Documents.StudentPhoto}`;
            const studentPhoto = await loadImage(studentPhotoUrl);

            // Load the student signature
            const studentSignUrl = `https://ims-nv9i.onrender.com/uploads/${data.student.Documents.StudentSignature}`;
            const studentSign = await loadImage(studentSignUrl);

            // Load the franchise signature
            const franchiseSignUrl = `https://ims-nv9i.onrender.com/uploads/${data.fran.DirectorOne.Signature}`;
            const franchiseSign = await loadImage(franchiseSignUrl);

            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();

            // Add the background image
            doc.addImage(backgroundImage, 'PNG', 0, 0, pageWidth, pageHeight);

            // Add the student photo
            if (studentPhoto) {
                doc.addImage(studentPhoto, 'JPEG', 290, 180, 70, 80); // Adjust position and size as needed
            }

            // Add the student signature
            if (studentSign) {
                doc.addImage(studentSign, 'PNG', 65, 564, 60, 25); // Adjust position and size as needed
            }

            // Add the franchise signature
            if (franchiseSign) {
                doc.addImage(franchiseSign, 'JPEG', 315, 564, 60, 25); // Adjust position and size as needed
            }

            // Add the student details
            doc.setFontSize(11);
            doc.setTextColor(0, 0, 0); // Black color

            // Add Registration Number
            doc.text(`${data.student.StudentId}`, 158, 177);

            // Add Student Name
            doc.text(`${data.student.StudentName}`, 158, 192);

            // Add Father's Name
            doc.text(`${data.student.FatherName}`, 158, 207.5);

            // Add Mother's Name
            doc.text(`${data.student.MotherName}`, 158, 223);

            // Add Aadhar Number
            doc.text(`${data.student.AadharNo}`, 158, 239);

            // Add Date of Birth
            doc.text(`${data.student.DOB}`, 158, 255);

            // Add Gender
            doc.text(`${data.student.Gender}`, 328, 283);

            // Add Course Name
            doc.text(`${data.student.Course.CourseName || 'N/A'}`, 160, 318);

            // Add Course Duration
            doc.text(`${data.examQuestion.Duration} Minutes`, 329, 296);

            // Extract and format the date and time from DateTime
            const examDateTime = new Date(data.examQuestion.DateTime);
            const examDateString = examDateTime.toLocaleDateString(); // Format date
            const examTimeString = examDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Format time

            // Calculate reporting time (30 minutes before exam time)
            examDateTime.setMinutes(examDateTime.getMinutes() - 30);
            const reportingTimeString = examDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

            // Add Exam Date and Time
            doc.text(`${examDateString} ${examTimeString}`, 162, 285);

            // Add Reporting Time
            doc.text(`${reportingTimeString}`, 162, 270);

            // Add FranchiseId
            doc.text(`${data.fran.FranchiseId}`, 160, 302);

            // Add MobileNo
            doc.text(`${data.fran.MobileNo}`, 158, 334);

            // Add FranchiseName
            doc.text(`${data.fran.FranchiseName}`, 180, 357);

            // Add Address
            doc.text(`${data.fran.Address}`, 160, 380);

            // Generate PDF as Blob and create a URL
            const pdfBlob = doc.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Set the PDF URL to display it
            setPdfUrl(pdfUrl);
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    // Helper function to load image as base64
    const loadImage = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                resolve(canvas.toDataURL('image/png'));
            };
            img.onerror = reject;
            img.src = url;
        });
    };

    if (loading) {
        return <div style={{ height: "100vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Hourglass
                visible={true}
                height="80"
                width="80"
                ariaLabel="hourglass-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={['#306cce', '#72a1ed']}
            />
        </div>;
    }

    // if (loading) {
    //     return <p>Loading...</p>;
    // };

    if (error) {
        return <p>{error}</p>;
    };

    return (
        <div>
            <button onClick={generatePdf}>Generate PDF</button>
            {pdfUrl && (
                <iframe
                    src={pdfUrl}
                    width="100%"
                    height="600px"
                    title="Generated PDF"
                />
            )}
        </div>
    );
};

export default AdmitCard;
