import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import QRCode from 'qrcode';
import styled from 'styled-components';
import backgroundUrl from '../../assets/blank computer course certificates_page-0001.jpg'
const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const PdfContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;
`;

const Iframe = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
`;

const DownloadLink = styled.a`
  display: block;
  margin-top: 20px;
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const MarksSummary = styled.div`
  margin-top: 20px;
  display: none; // Set this to 'block' if you want to show it
`;

const CertificateComputer = () => {
    const { StudentId, CourseId } = useParams();
    const [pdfUrl, setPdfUrl] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [marksData, setMarksData] = useState(null); // State to hold calculated marks data

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://ims-nv9i.onrender.com/marksheet/get/${StudentId}/${CourseId}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                console.log(result);
                setData(result);
                calculateMarks(result.markSheet);

            } catch (error) {
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [StudentId, CourseId]);

    function addMonths(dateString, monthsToAdd) {
        // Parse the date string
        const date = new Date(dateString);
    
        // Check if the date parsing is valid
        if (isNaN(date.getTime())) {
            throw new Error('Invalid date format');
        }
    
        // Add the months
        date.setMonth(date.getMonth() + monthsToAdd);
    
        // Handle cases where the new month might push into the next year
        // Ensure the day stays consistent
        const newYear = date.getFullYear();
        const newMonth = date.getMonth();
        const newDay = date.getDate();
    
        // Handle cases where days might overflow
        const lastDayOfMonth = new Date(newYear, newMonth + 1, 0).getDate();
        const adjustedDay = Math.min(newDay, lastDayOfMonth);
    
        // Set the adjusted day to handle overflow
        date.setDate(adjustedDay);
    
        // Convert to YYYY-MM-DD format
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    }
    

    const calculateMarks = (markSheet) => {
        if (!markSheet || !markSheet.Subjects) return;

        const subjects = markSheet.Subjects;

        let superTotal = 0;
        let obtainedTotal = 0;
        let maxMarks = 0;

        const subjectsWithTotals = subjects.map(subject => {
            const totalMarks = subject.TheoryMarks + subject.PracticalMarks;
            const fullMarks = subject.FullMarks;
            const percentage = (totalMarks / fullMarks) * 100;

            superTotal += totalMarks;
            obtainedTotal += totalMarks;
            maxMarks += fullMarks;

            return {
                ...subject,
                TotalMarks: totalMarks,
                Percentage: percentage.toFixed(2),
            };
        });

        const finalPercentage = (obtainedTotal / maxMarks) * 100;
        const finalGrade = getGrade(finalPercentage);

        // Set the calculated marks data to state
        setMarksData({ 
            subjects: subjectsWithTotals, 
            superTotal, 
            maxMarks, 
            finalPercentage: finalPercentage.toFixed(2), 
            finalGrade 
        });

        // Log the calculated data
        console.log('Subjects with total marks and percentage:', subjectsWithTotals);
        console.log('Super Total:', superTotal);
        console.log('Max Marks:', maxMarks);
        console.log('Final Percentage:', finalPercentage.toFixed(2));
        console.log('Final Grade:', finalGrade);
    };

    const getGrade = (percentage) => {
        if (percentage >= 85) return 'A+';
        if (percentage >= 75) return 'A';
        if (percentage >= 65) return 'B';
        if (percentage >= 45) return 'C';
        return 'D';
    };

    const generatePdf = async () => {
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: 'a4',
        });

        try {
            // Load the background image
            const backgroundImage = await loadImage(backgroundUrl);

            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();

            // Add the background image
            doc.addImage(backgroundImage, 'PNG', 0, 0, pageWidth, pageHeight);

            const studentPhotoUrl = `https://ims-nv9i.onrender.com/uploads/${data.student.Documents.StudentPhoto}`;
            const studentPhoto = await loadImage(studentPhotoUrl);

            const studentSignUrl = `https://ims-nv9i.onrender.com/uploads/${data.student.Documents.StudentSignature}`;
            const studentSign = await loadImage(studentSignUrl);

            const franchiseSignUrl = `https://ims-nv9i.onrender.com/uploads/${data.franchise.DirectorOne.Signature}`;
            const franchiseSign = await loadImage(franchiseSignUrl);
            
            // Add the text data
            doc.setFontSize(11);
            doc.setTextColor(0, 0, 0); // Black color
            // Add the student photo
            if (studentPhoto) {
                doc.addImage(studentPhoto, 'JPEG', 320, 174, 40, 40); // Adjust position and size as needed
            }
            if (studentSign) {
                doc.addImage(studentSign, 'JPEG', 320, 214, 40, 15); // Adjust position and size as needed
            }
            if (franchiseSign) {
                doc.addImage(franchiseSign, 'JPEG', 63, 518, 40, 20); // Adjust position and size as needed
            }

            doc.text(`${data.franchise.DirectorOne.Name}`, 62, 550);

            doc.text(`:- ${data.markSheet.StudentName}`, 139, 243);
            doc.text(`:- ${data.markSheet.FatherName}`, 139, 260);
            doc.text(`:- ${data.markSheet.MotherName}`, 139, 278);
            doc.setFontSize(10);
            doc.text(`${data.markSheet.CourseName}`, 201, 311);
            doc.text(`${data.markSheet.CourseDuration}`, 201, 321);
            doc.text(`:- ${data.markSheet.RegistrationNo}`, 332, 244);
            doc.text(`:- ${data.markSheet.DOB}`, 330, 261);
            doc.text(`:- ${data.markSheet.AadharNo}`, 329, 278);
            doc.setFontSize(9);
            doc.text(`${marksData.finalGrade}`, 221, 332);
            doc.text(`${marksData.superTotal}`, 221, 347);
            doc.setFontSize(9);
            doc.text(`${data.markSheet.MarkSheetNo}`, 210, 567.5);
            doc.text(`${data.markSheet.Date}`, 210, 579.5);

            // Generate QR code data URL
            const qrCodeDataUrl = await generateQRCode(`https://ims-frontend-self.vercel.app/verify/${data.markSheet.RegistrationNo}/${data.markSheet.CourseId}`);

            // Add QR code to the PDF
            doc.addImage(qrCodeDataUrl, 'PNG', 64, 174, 40, 40);

            // Generate PDF as Blob and create a URL
            const pdfBlob = doc.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);

            // Set the PDF URL to display it
            setPdfUrl(pdfUrl);
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    // Helper function to load image as base64
    const loadImage = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                resolve(canvas.toDataURL('image/jpeg'));
            };
            img.onerror = reject;
            img.src = url;
        });
    };

    const generateQRCode = (text) => {
        return new Promise((resolve, reject) => {
            QRCode.toDataURL(text, { width: 40 }, (error, url) => {
                if (error) {
                    console.error('Error generating QR code:', error);
                    reject(error);
                } else {
                    resolve(url);
                }
            });
        });
    };

    return (
        <Container>
            <Button onClick={generatePdf}>Generate PDF</Button>
            {pdfUrl && (
                <PdfContainer>
                    <Iframe src={pdfUrl} title="PDF Preview" />
                    <DownloadLink href={pdfUrl} download="generated.pdf">
                        Download PDF
                    </DownloadLink>
                </PdfContainer>
            )}
            {marksData && (
                <MarksSummary>
                    <h2>Marks Summary</h2>
                    <ul>
                        {marksData.subjects.map((subject, index) => (
                            <li key={index}>
                                {subject.Title}: {subject.TotalMarks} (Theory: {subject.TheoryMarks}, Practical: {subject.PracticalMarks}, Percentage: {subject.Percentage}%)
                            </li>
                        ))}
                    </ul>
                    <p><strong>Super Total: </strong>{marksData.superTotal}</p>
                    <p><strong>Max Marks: </strong>{marksData.maxMarks}</p>
                    <p><strong>Final Percentage: </strong>{marksData.finalPercentage}%</p>
                    <p><strong>Final Grade: </strong>{marksData.finalGrade}</p>
                </MarksSummary>
            )}
        </Container>
    );
};

export default CertificateComputer;
