// Employee.js
import React, { useState } from "react";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import Sidebar from "./EmployeeSidebar";
import AddStudentAttendance from "../Universal/AddStudentAttendance";

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f0f2f5;
`;

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  background-color: #f0f2f5;
`;

const Header = styled.header`
height:70px;
  background-color: #008cba;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 24px;
  position: relative;
`;

const MainContent = styled.main`
  display: flex;
  flex: 1;
  @media (max-width: 768px) {
    margin-left: 0;
    padding: 20px 10px;
  }
`;

const HamburgerButton = styled(FaBars)`
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 20px;
  display: ${(props) => (props.isOpen ? "none" : "block")};

  @media (min-width: 769px) {
    display: none;
  }
`;


const EmployeeAddStudentAttendance = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <DashboardContainer>
            <Header>
                Employee Dashboard
                <HamburgerButton isOpen={isSidebarOpen} onClick={toggleSidebar} />
            </Header>
            <Container>
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                <MainContent>
                    <AddStudentAttendance/>
                </MainContent>
            </Container>
        </DashboardContainer>
    );
};

export default EmployeeAddStudentAttendance;
