import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddBatch = () => {
    const [courses, setCourses] = useState([]);
    const [batchName, setBatchName] = useState('');
    const [franchiseId, setFranchiseId] = useState('');
    const [course, setCourse] = useState('');
    const [sessionTime, setSessionTime] = useState('');

    useEffect(() => {
        const localdata = JSON.parse(localStorage.getItem("Data"));
        if (localdata) {
            if (localdata.EmployeeData) {
                setFranchiseId(localdata.EmployeeData.FranchiseId);
            } else if (localdata.FranchiseData) {
                setFranchiseId(localdata.FranchiseData.FranchiseId);
            } else {
                setFranchiseId("Admin");
            }
        } else {
            setFranchiseId("Admin");
        }
        const fetchCourses = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/courses/all');
                const data = await response.json();
                setCourses(data);
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };
        fetchCourses();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const batchData = {
            BatchName: batchName,
            FranchiseId: franchiseId,
            Course: course,
            SessionTime: sessionTime
        };

        try {
            const response = await fetch('https://ims-nv9i.onrender.com/batch/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(batchData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to add batch');
            }

            const data = await response.json();
            toast.success('Batch added successfully!');

            // Clear the form fields
            setBatchName('');
            setCourse('');
            setSessionTime('');
        } catch (error) {
            toast.error(error.message || 'Failed to add batch');
        }
    };

    return (
        <Container>
            <Title>Add New Batch</Title>
            <Form onSubmit={handleSubmit}>
                <Label>
                    Batch Name:
                    <Input
                        type="text"
                        value={batchName}
                        onChange={(e) => setBatchName(e.target.value)}
                        required
                    />
                </Label>
                <Label>
                    Course:
                    <Select name="Course" id="Course" value={course} onChange={(e) => setCourse(e.target.value)}>
                        <option value="">Select Course</option>
                        {courses.map((course) => (
                            <option key={course._id} value={course.CourseName}>{course.CourseName}</option>
                        ))}
                    </Select>
                </Label>
                <Label>
                    Session Time:
                    <Input
                        type="text"
                        value={sessionTime}
                        onChange={(e) => setSessionTime(e.target.value)}
                        required
                    />
                </Label>
                <SubmitButton type="submit">Add Batch</SubmitButton>
            </Form>
            <ToastContainer />
        </Container>
    );
};

// Styled-components
const Container = styled.div`
    width: 100%;
    max-width: 600px;
    height:fit-content;
    margin: auto auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid #e3e6e8;
`;

const Title = styled.h1`
    font-size: 28px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Label = styled.label`
    font-size: 16px;
    color: #555;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Input = styled.input`
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    width: 100%;
    transition: border-color 0.3s;
    
    &:focus {
        border-color: #007bff;
        outline: none;
    }
`;

const Select = styled.select`
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    width: 100%;
    transition: border-color 0.3s;
    
    &:focus {
        border-color: #007bff;
        outline: none;
    }
`;

const SubmitButton = styled.button`
    padding: 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    transition: background-color 0.3s, transform 0.2s;
    
    &:hover {
        background-color: #0056b3;
    }
    
    &:active {
        transform: scale(0.98);
    }
`;

export default AddBatch;
