import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ViewExams = () => {
    const [franchises, setFranchises] = useState([]);
    const [selectedFranchise, setSelectedFranchise] = useState('');
    const [examDetails, setExamDetails] = useState([]);
    const [filteredExamDetails, setFilteredExamDetails] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchFranchises = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/franchises/all');
                if (!response.ok) {
                    throw new Error(`Error fetching franchises: ${response.statusText}`);
                }
                const data = await response.json();
                setFranchises(data);
            } catch (error) {
                console.error('Error fetching franchises:', error);
                setError('Failed to load franchises');
                toast.error('Failed to load franchises');
            }
        };

        const fetchExamDetails = async () => {
            try {
                const response = await fetch('https://ims-nv9i.onrender.com/examQuestions/all');
                if (!response.ok) {
                    throw new Error(`Error fetching exam details: ${response.statusText}`);
                }
                const data = await response.json();
                setExamDetails(data);
                setFilteredExamDetails(data); // Initially set filtered data to all exam details
            } catch (error) {
                console.error('Error fetching exam details:', error);
                setError('Failed to load exam details');
                setExamDetails([]);
                setFilteredExamDetails([]);
                toast.error('Failed to load exam details');
            }
        };

        fetchFranchises();
        fetchExamDetails();
    }, []);

    const handleFranchiseChange = (e) => {
        const selectedFranchiseId = e.target.value;
        setSelectedFranchise(selectedFranchiseId);

        if (selectedFranchiseId) {
            const filteredData = examDetails.filter(
                exam => exam.FranchiseId === selectedFranchiseId
            );
            setFilteredExamDetails(filteredData);
        } else {
            setFilteredExamDetails(examDetails); // If no franchise is selected, show all exams
        }
    };

    return (
        <Container>
            <Form>
                <SelectFranchise>
                    <Label htmlFor="franchise">Select Franchise</Label>
                    <Select
                        name="franchise"
                        id="franchise"
                        value={selectedFranchise}
                        onChange={handleFranchiseChange}
                    >
                        <option value="">Select Franchise</option>
                        {franchises.map(franchise => (
                            <option key={franchise._id} value={franchise.FranchiseId}>
                                {franchise.FranchiseName}
                            </option>
                        ))}
                    </Select>
                </SelectFranchise>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <TableContainer>
                    <Table>
                        <thead>
                            <tr>
                                <th>Exam Name</th>
                                <th>Pass Marks</th>
                                <th>Duration</th>
                                <th>Set Name</th>
                                <th>Date & Time</th>
                                <th>Batch</th>
                                <th>Student No</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredExamDetails.length > 0 ? (
                                filteredExamDetails.map((exam) => (
                                    <tr key={exam._id}>
                                        <td>{exam.Name}</td>
                                        <td>{exam.Passing}</td>
                                        <td>{exam.Duration}</td>
                                        <td>
                                            <List>
                                                {exam.Sets && exam.Sets.length > 0 ? (
                                                    exam.Sets.map((set, index) => (
                                                        <ListItem key={index}>{set}</ListItem>
                                                    ))
                                                ) : (
                                                    <ListItem>No sets available</ListItem>
                                                )}
                                            </List>
                                        </td>
                                        <td>{exam.DateTime}</td>
                                        <td>
                                            <List>
                                                {exam.BatchName && exam.BatchName.length > 0 ? (
                                                    exam.BatchName.map((batch, index) => (
                                                        <ListItem key={index}>{batch}</ListItem>
                                                    ))
                                                ) : (
                                                    <ListItem>No batches available</ListItem>
                                                )}
                                            </List>
                                        </td>
                                        <td>{exam.StudentNo}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7">No exam details available.</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </TableContainer>
            </Form>
            <ToastContainer />
        </Container>
    );
};

// Styled-components
const Container = styled.section`
    height: calc(100vh - 70px -40px);
    background-color: #f4f4f4;
    width:100%;
    padding: 20px;
`;

const Form = styled.div`
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
`;

const SelectFranchise = styled.div`
    margin-bottom: 20px;
`;

const Label = styled.label`
    display: block;
    font-size: 16px;
    margin-bottom: 8px;
    color: #333;
`;

const Select = styled.select`
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 16px;
`;

const ErrorMessage = styled.p`
    color: #dc3545;
    font-size: 16px;
    margin-bottom: 20px;
`;

const TableContainer = styled.div`
    width: 100%;
    overflow-y: auto;
    height: calc(100vh - 70px - 170px);
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    font-size: 14px;

    th, td {
        padding: 10px;
        border-bottom: 1px solid #ddd;
        white-space: nowrap;
    }

    th {
        background-color: #008cba;
        color: #fff;
    }

    td {
        background-color: #f9f9f9;
    }
`;

const List = styled.ul`
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    list-style: none;
    padding: 0;
    margin: 0;
`;

const ListItem = styled.li`
    padding: 5px 0;
    font-size: 14px;
`;

export default ViewExams;
